import React, { Component, Fragment } from 'react';
import {
    Row,
    Nav,
    NavItem,
    NavLink,
    TabPane,
    TabContent,
    Col,
    Button,
    Card,
    Badge,
    CardBody,
    CardTitle,
} from 'reactstrap';
import axios from 'axios';
import { END_POINT, END_POINT_SOCKET } from '../../constants/defaultValues';
import Pagination from '../../containers/pages/Pagination';
import io from 'socket.io-client';
import {
    NotificatioErrController,
    NotificatioSucController,
} from '../../helpers/Utils';
import { NotificationManager } from '../../components/common/react-notifications';
import { Colxx } from '../../components/common/CustomBootstrap';
import IntlMessages from '../../helpers/IntlMessages';
import InvoicePages from '../../views/app/pages/invoice';
import CustomModalContainer from '../app/ui/modal';
import classnames from 'classnames';
import STATUS from '../../constants/status';
import LogsHelper from '../../helpers/Logs';
import ModalKitchen from './ModalKitchen';

import { isF, isU } from '../../helpers/Roles';
import TooltipItem from '../../components/common/TooltipItem';
const ROLE_ALIAS = 'kitchen';

class ContentComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedPageSize: 12,
            pageSizes: [12, 24],
            currentPage: 1,
            totalPage: 1,

            isLoading: false,
            data: [],
            recipe: [],
            collapse: false,
            accordion: [],
            items: [1, 2, 3],
            totalItemCount: 0,
            search: '',

            data_stock: [],
            make_products: [],
            is_loading_data_stock: true,
            page_stock: {},

            selectedItems: [],

            isOpenModalInvoice: false,
            isOpenModalKitchen: false,
            activeTab: '1',

            // store
            store: window.location.host.split('.')[0],
            hash: props.location.pathname.split('/')[2],
        };
        this.socket = io(END_POINT_SOCKET);
    }

    componentDidMount() {
        this.socket.emit('join room', {
            room_id: localStorage.getItem('user_id'),
        });

        // alert mp3
        this.socket.on('alert-mp3', () => {
            try {
                let alert = document.getElementById('alert-mp3');
                alert.play();
            } catch (error) {
                console.log(error);
                console.log('alert mp3 exception');
            }
        });

        // Role
        this.socket.on('update-user-role', (res) => {
            localStorage.setItem('roleSettings', JSON.stringify(res.data));
        });

        this.socket.on('refresh-done-order', (res) => {
            this.dataListRender();
        });

        this.dataListRender();
        this.dataListRenderStock();
        this.dataListRenderMakeProduct();
    }

    dataListRender() {
        const { selectedPageSize, currentPage, search } = this.state;

        let tokenStr = localStorage.getItem('access_token');
        axios
            .get(
                `${
                    END_POINT + '/table/kitchen'
                }?pageSize=${selectedPageSize}&currentPage=${currentPage}&search=${search}`,
                { headers: { Authorization: `Bearer ${tokenStr}` } }
            )
            .then((res) => {
                return res.data;
            })
            .then((data) => {
                this.setState({
                    totalPage: data.totalPage,
                    items: data.data,
                    selectedItems: [],
                    totalItemCount: data.totalItem,
                    isLoading: true,
                });
            })
            .catch((err) => {
                NotificatioErrController(err.response.data.responseText);
            });
    }

    dataListRenderStock = (required = true) => {
        const { selectedPageSize, currentPage, search } = this.state.page_stock;

        let tokenStr = localStorage.getItem('access_token');
        axios
            .get(
                `${
                    END_POINT + '/stock/all-unit'
                }?pageSize=${selectedPageSize}&currentPage=${currentPage}&search=${search}`,
                { headers: { Authorization: `Bearer ${tokenStr}` } }
            )
            .then((res) => res.data)
            .then((data) => {
                // console.log(data);
                let search = window.location.search;
                let params = new URLSearchParams(search);
                let item_id = null;
                let item_vs_percent = params.get('item_id');
                if (item_vs_percent) {
                    const split_item_vs_percent = item_vs_percent.split('.');

                    if (split_item_vs_percent.length) {
                        item_id = split_item_vs_percent[0];

                        const find_item = data.data.find(
                            (_item) => _item.id == item_id
                        );
                        if (find_item !== undefined) {
                            this.onClickedUpdate(find_item);
                            this.onClickedUpdateCount(find_item);
                        }
                    }
                }

                this.setState((prev) => ({
                    ...prev,
                    data_stock: data.data,
                    // is_loading_data_stock: false,
                    // page_stock: {
                    //     ...prev.page,
                    //     totalPage: data.totalPage,
                    //     totalItem: data.totalItem,
                    // },
                }));
            })
            .catch((err) => {
                NotificatioErrController(err.response.data.responseText);
                return false;
            });
    };

    dataListRenderMakeProduct() {
        let tokenStr = localStorage.getItem('access_token');
        return axios
            .get(`${END_POINT + '/make-product/all'}`, {
                headers: { Authorization: `Bearer ${tokenStr}` },
            })
            .then((res) => {
                this.setState((prev) => ({
                    ...prev,
                    make_products: res.data.data,
                }));
            })
            .catch((err) => []);
    }

    renderRecipe = (itemId) => {
        const { make_products } = this.state;
        make_products.filter((p) => {
            const dataRecipe = p.product_id === itemId;
            return (
                <Badge color='primary'>
                    {dataRecipe.stock.name}: {dataRecipe.count} (
                    {dataRecipe.stock.unit})
                </Badge>
            );
        });
    };

    toggleAccordion = (tab) => {
        const prevState = this.state.accordion;
        const state = prevState.map((x, index) => (tab === index ? !x : false));
        this.setState({
            accordion: state,
        });
    };

    changePageSize = (size) => {
        this.setState(
            {
                selectedPageSize: size,
                currentPage: 1,
            },
            () => this.dataListRender()
        );
    };

    onChangePage = (page) => {
        this.setState(
            {
                currentPage: page,
            },
            () => this.dataListRender()
        );
    };

    onSearchKey = (e) => {
        if (!e) return false;

        if (e.target) {
            let {
                target: { value },
            } = e;
            let v = value;
            if (value === undefined) {
                v = e.target.firstElementChild.value; // parent to child

                this.setState(
                    {
                        search: v.toLowerCase(),
                    },
                    () => this.dataListRender()
                );
            } else {
                this.setState(
                    {
                        search: value.toLowerCase(),
                    },
                    () => {
                        setTimeout(() => {
                            this.dataListRender();
                        }, 500);
                    }
                );
            }
        }

        if (e.key === 'Enter') {
            this.setState(
                {
                    search: e.target.value.toLowerCase(),
                },
                () => this.dataListRender()
            );
        }
    };

    onCheckItem = (e, id) => {
        let selectedItems = this.state.selectedItems;
        if (selectedItems.includes(id)) {
            selectedItems = selectedItems.filter((x) => x !== id);
        } else {
            selectedItems.push(id);
        }
        this.setState({
            selectedItems: selectedItems,
        });
        document.activeElement.blur();
    };

    toggleModalInvoice = (items) => {
        if (!isF(ROLE_ALIAS)) return false;

        this.setState({
            isOpenModalInvoice: !this.state.isOpenModalInvoice,
            selectedItems:
                this.state.selectedItems.length < 1
                    ? items.map((d) => d.id)
                    : this.state.selectedItems,
        });

        if (this.state.isOpenModalInvoice) {
            // close
            this.dataListRender();
        }
    };

    toggleTab = (tab) => {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab,
            });
        }
    };

    toggleModalKitchen = () => {
        this.setState({ isOpenModalKitchen: !this.state.isOpenModalKitchen });
    };

    listOrders = (orders) => {
        return orders.map((order) => order.id);
    };

    onUpdateStatusOrder = (ids, status) => {
        if (ids.length > 1) {
            if (
                window.confirm(
                    'Tất cả các món đã làm xong và chuyển sang bước tiếp theo!'
                )
            ) {
                this.onUpdateOrderStatus(ids, status);
            }
        } else if (
            window.confirm('Món đã làm xong và chuyển sang bước tiếp theo!')
        ) {
            this.onUpdateOrderStatus(ids, status);
        }
    };

    onUpdateOrderStatus = (ids, status) => {
        // console.log('ids: ', ids);
        let tokenStr = localStorage.getItem('access_token');
        return axios
            .put(
                `${END_POINT + '/order/status'}`,
                {
                    arrayId: ids,
                    status_id: status,
                },
                { headers: { Authorization: `Bearer ${tokenStr}` } }
            )
            .then((res) => {
                return res.data;
            })
            .then((data) => {
                NotificationManager.primary(
                    'Đã cập nhật',
                    'Thành công',
                    3000,
                    null,
                    null,
                    'filled'
                );

                if (status === STATUS.COMPLETED) {
                    this.socket.emit('alert-paymenter', {
                        user_id: localStorage.getItem('user_id'),
                    });
                }

                this.onUpdateData(ids);
            })
            .catch((err) => {
                NotificationManager.error(
                    'Cập nhật thất bại',
                    'Lỗi !',
                    3000,
                    null,
                    null,
                    'filled'
                );
            });
    };

    onUpdateData = async (orders_id = []) => {
        // console.log('updateStockCalled');
        // Cập nhật stock sau khi có sản phẩm mới thanh toán
        this.socket.emit('refresh-stock', {
            user_id: localStorage.getItem('user_id'),
            orders_id,
        });
        this.socket.emit('refresh-recent-order', {
            user_id: localStorage.getItem('user_id'),
            floor: this.state.selectedFloorTable,
        });
        this.socket.emit('refresh-pending-order', {
            user_id: localStorage.getItem('user_id'),
        });
        this.socket.emit('refresh-done-order', {
            user_id: localStorage.getItem('user_id'),
        });
        this.socket.emit('refresh-refund-order', {
            user_id: localStorage.getItem('user_id'),
        });
        this.socket.emit('refresh-alert-data', {
            user_id: localStorage.getItem('user_id'),
        });

        // this.socket.emit('message get inbox', {
        //     // user_id: localStorage.getItem('user_id'),
        //     user_id: this.state.USER_PARENT_ID,
        // });
    };

    changeCountStock = (name, id) => {
        let newStock = window.prompt(
            `Số lượng nguyên liệu '${name}' còn lại là:`
        );
        // console.log('newStock: ', newStock);

        if (newStock !== null || newStock !== '') {
            // axios submit
            let tokenStr = localStorage.getItem('access_token');
            return axios
                .put(
                    `${END_POINT + '/stock/update-stock'}`,
                    {
                        id: id,
                        count: newStock,
                    },
                    { headers: { Authorization: `Bearer ${tokenStr}` } }
                )
                .then((res) => {
                    return res.data;
                })
                .then((data) => {
                    NotificatioSucController(
                        'Cập nhật số lượng nguyên liệu còn lại thành công!'
                    );

                    this.socket.emit('create logs', {
                        user_id: localStorage.getItem('user_id'),
                        message: LogsHelper['update-stock'],
                    });

                    this.socket.emit('refresh-stock', {
                        user_id: localStorage.getItem('user_id'),
                    });

                    this.dataListRenderStock();
                })
                .catch((err) => {
                    NotificatioErrController(err.response.data.responseText);
                });
        }
    };

    handleResetStock = (id) => {
        if (!isU(ROLE_ALIAS)) return false;

        if (
            window.confirm(
                'Bạn chắc chắn muốn reset tồn kho nguyên liệu này về 0?'
            )
        ) {
            // axios submit
            let tokenStr = localStorage.getItem('access_token');
            return axios
                .put(
                    `${END_POINT + '/stock/reset'}`,
                    {
                        id: id,
                    },
                    { headers: { Authorization: `Bearer ${tokenStr}` } }
                )
                .then((res) => {
                    return res.data;
                })
                .then((data) => {
                    NotificatioSucController(
                        'Reset nguyên liệu về 0 thành công'
                    );

                    this.socket.emit('create logs', {
                        user_id: localStorage.getItem('user_id'),
                        message: LogsHelper['stock-reset'],
                    });

                    this.socket.emit('refresh-stock', {
                        user_id: localStorage.getItem('user_id'),
                    });

                    this.dataListRenderStock();
                })
                .catch((err) => {
                    NotificatioErrController(err.response.data.responseText);
                });
        }
    };

    showProcessingRecipe = (orderId) => {
        const { make_products } = this.state;
        const recipe = make_products.filter(
            (make) => make.product_id === orderId
        );
        this.setState(
            (prevState) => ({
                ...prevState,
                recipe,
            }),
            () => this.toggleModalKitchen()
        );
    };

    render() {
        const {
            currentPage,
            items,
            recipe,
            data_stock,
            make_products,
            selectedPageSize,
            isLoading,
            totalItemCount,
            pageSizes,
            totalPage,
            selectedItems,
            isOpenModalInvoice,
            isOpenModalKitchen,
            activeTab,
        } = this.state;
        // console.log('items: ', items);
        // console.log('data_stock: ', data_stock);
        // console.log('make_products: ', make_products);
        const { match } = this.props;
        const startIndex = (currentPage - 1) * selectedPageSize;
        const endIndex = currentPage * selectedPageSize;

        return !isLoading ? (
            <div className='loading' />
        ) : (
            <Fragment>
                <div className='disable-text-selection'>
                    {/* <audio
                        id='alert-mp3'
                        preload='auto'
                        src='/assets/sound/alert.mp3'
                    ></audio> */}

                    {/* <PageHeading
                        heading='menu.payment'
                        pageSizes={pageSizes}
                        selectedPageSize={selectedPageSize}
                        startIndex={startIndex}
                        endIndex={endIndex}
                        match={match}
                        totalItemCount={totalItemCount}
                        changePageSize={this.changePageSize}
                        onSearchKey={this.onSearchKey}
                    /> */}

                    <Nav
                        tabs
                        className='d-flex justify-content-around separator-tabs ml-0 mb-5'
                    >
                        <NavItem className='w-50'>
                            <NavLink
                                className={classnames('text-center', {
                                    active: activeTab === '1',
                                    'nav-link': true,
                                })}
                                onClick={() => this.toggleTab('1')}
                                location={{}}
                                to='#'
                            >
                                DANH SÁCH MÓN ĐANG CHỜ
                            </NavLink>
                        </NavItem>
                        <NavItem className='w-50'>
                            <NavLink
                                className={classnames('text-center', {
                                    active: activeTab === '2',
                                    'nav-link': true,
                                })}
                                onClick={() => this.toggleTab('2')}
                                location={{}}
                                to='#'
                            >
                                NGUYÊN VẬT LIỆU
                            </NavLink>
                        </NavItem>
                    </Nav>
                    <TabContent activeTab={activeTab} className='mh-53vh'>
                        <TabPane tabId='1'>
                            {items.length ? (
                                items?.map((item, idx) => {
                                    return (
                                        <Card
                                            key={idx}
                                            className='custom-shadow mb-3'
                                        >
                                            <div className='p-3 d-flex justify-content-between'>
                                                <div>
                                                    {item.table === 'null'
                                                        ? 'Tại quầy'
                                                        : `Bàn ${item.table}`}
                                                </div>
                                                <Button
                                                    outline
                                                    color='primary'
                                                    onClick={() =>
                                                        this.onUpdateStatusOrder(
                                                            this.listOrders(
                                                                item.orders
                                                            ),
                                                            STATUS.COMPLETED
                                                        )
                                                    }
                                                >
                                                    <i className='iconsminds-arrow-next' />
                                                </Button>
                                            </div>
                                            <div className='d-flex flex-wrap'>
                                                {item?.orders?.map((order) => {
                                                    // console.log(
                                                    //     'order: ',
                                                    //     order
                                                    // );
                                                    const toppings =
                                                        order.topping.split(
                                                            '|'
                                                        );
                                                    return (
                                                        <Col
                                                            xs='12'
                                                            md='6'
                                                            lg='4'
                                                            key={order.id}
                                                        >
                                                            <Card className='mb-3 custom-shadow'>
                                                                <div className='d-flex flex-row'>
                                                                    <div
                                                                        className={
                                                                            'd-flex flex-grow-1 min-width-zero'
                                                                        }
                                                                    >
                                                                        <div className='d-block position-relative'>
                                                                            <img
                                                                                alt={
                                                                                    order
                                                                                        .product
                                                                                        .name
                                                                                }
                                                                                src={
                                                                                    order.product.image.indexOf(
                                                                                        'http'
                                                                                    ) >=
                                                                                    0
                                                                                        ? order
                                                                                              .product
                                                                                              .image
                                                                                        : `${END_POINT}/public/${order.product.image}`
                                                                                }
                                                                                onError={(
                                                                                    e
                                                                                ) => {
                                                                                    e.target.onerror =
                                                                                        null;
                                                                                    e.target.src =
                                                                                        'https://placehold.co/320x240';
                                                                                }}
                                                                                className='list-thumbnail responsive border-0 m-1'
                                                                            />
                                                                        </div>
                                                                        <div className='w-100 h-100 align-self-center d-flex flex-column justify-content-between min-width-zero p-2'>
                                                                            <div className='d-flex justify-content-between'>
                                                                                <div className='list-item-heading mb-1 ptr-1px truncate'>
                                                                                    <sup className='mr-1 cursor-pointer'>
                                                                                        <i
                                                                                            className='simple-icon-info'
                                                                                            onClick={() =>
                                                                                                this.showProcessingRecipe(
                                                                                                    order
                                                                                                        .product
                                                                                                        .id
                                                                                                )
                                                                                            }
                                                                                        />
                                                                                    </sup>
                                                                                    {
                                                                                        order
                                                                                            .product
                                                                                            .name
                                                                                    }
                                                                                </div>
                                                                                <div>
                                                                                    {
                                                                                        order.time
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                            <div className='w-100'>
                                                                                {order.ice ===
                                                                                    '' ||
                                                                                order.ice ===
                                                                                    '100' ? null : (
                                                                                    <Badge
                                                                                        className='mr-1 mt-1'
                                                                                        color='primary'
                                                                                    >
                                                                                        Đá:{' '}
                                                                                        {
                                                                                            order.ice
                                                                                        }

                                                                                        %
                                                                                    </Badge>
                                                                                )}
                                                                                {order.sugar ===
                                                                                    '' ||
                                                                                order.sugar ===
                                                                                    '100' ? null : (
                                                                                    <Badge
                                                                                        className='mr-1 mt-1'
                                                                                        color='info'
                                                                                    >
                                                                                        Độ
                                                                                        ngọt:{' '}
                                                                                        {
                                                                                            order.sugar
                                                                                        }

                                                                                        %
                                                                                    </Badge>
                                                                                )}
                                                                                {order.size ===
                                                                                    '' ||
                                                                                order.size.split(
                                                                                    ','
                                                                                )[1] ===
                                                                                    '0' ? null : (
                                                                                    <Badge
                                                                                        className='mr-1 mt-1'
                                                                                        color='warning'
                                                                                    >
                                                                                        {
                                                                                            order.size.split(
                                                                                                ','
                                                                                            )[0]
                                                                                        }
                                                                                    </Badge>
                                                                                )}
                                                                                {
                                                                                    order.topping ===
                                                                                        '' ||
                                                                                    order.topping.split(
                                                                                        ','
                                                                                    )[1] ===
                                                                                        '0'
                                                                                        ? null
                                                                                        : toppings.map(
                                                                                              (
                                                                                                  _topping,
                                                                                                  idx
                                                                                              ) => (
                                                                                                  <Badge
                                                                                                      color='success'
                                                                                                      className='mr-1 mt-1'
                                                                                                      key={
                                                                                                          idx
                                                                                                      }
                                                                                                  >
                                                                                                      {
                                                                                                          _topping.split(
                                                                                                              ','
                                                                                                          )[0]
                                                                                                      }
                                                                                                  </Badge>
                                                                                              )
                                                                                          )
                                                                                    // <Badge
                                                                                    //     color='success'
                                                                                    //     className='mr-1 mt-1'
                                                                                    // >
                                                                                    //     Topping:{' '}
                                                                                    //     {
                                                                                    //         order.topping.split(
                                                                                    //             ','
                                                                                    //         )[0]
                                                                                    //     }
                                                                                    // </Badge>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                        <Button
                                                                            className='p-1 align-self-center h-100 d-flex align-items-center button-done-order cursor-pointer'
                                                                            onClick={() =>
                                                                                this.onUpdateStatusOrder(
                                                                                    order.id,
                                                                                    STATUS.COMPLETED
                                                                                )
                                                                            }
                                                                        >
                                                                            <i className='iconsminds-arrow-next text-center' />
                                                                        </Button>
                                                                    </div>
                                                                </div>
                                                            </Card>
                                                        </Col>
                                                    );
                                                })}
                                                <ModalKitchen
                                                    isOpen={isOpenModalKitchen}
                                                    toggle={
                                                        this.toggleModalKitchen
                                                    }
                                                    recipe={recipe}
                                                />
                                            </div>
                                        </Card>
                                    );
                                })
                            ) : (
                                <Card>
                                    <CardBody className='d-flex justify-content-center align-items-center'>
                                        <div>
                                            Danh sách trống! Hãy chờ cho đến khi
                                            có món mới được gọi.
                                        </div>
                                    </CardBody>
                                </Card>
                            )}
                        </TabPane>
                        <TabPane tabId='2'>
                            {data_stock?.length ? (
                                <div className='d-flex flex-wrap'>
                                    {data_stock?.map((stock) => (
                                        <Col
                                            xs='12'
                                            md='6'
                                            lg='4'
                                            key={stock.id}
                                        >
                                            <Card
                                                className='mb-3 custom-shadow'
                                                key={stock.id}
                                            >
                                                <div className='d-flex flex-row'>
                                                    <div
                                                        className={
                                                            'd-flex flex-grow-1 min-width-zero'
                                                        }
                                                    >
                                                        <div
                                                            onClick={() => {
                                                                // this.onClickedItem(item);
                                                            }}
                                                            className='w-100 h-100 align-self-center d-flex flex-column justify-content-between min-width-zero p-3'
                                                        >
                                                            <div className='d-flex justify-content-between'>
                                                                <div className='list-item-heading mb-1 truncate'>
                                                                    {
                                                                        stock.value
                                                                    }
                                                                </div>
                                                                <div>
                                                                    Còn lại:{' '}
                                                                    <strong>
                                                                        {
                                                                            stock.count
                                                                        }{' '}
                                                                        {
                                                                            stock.unit
                                                                        }
                                                                    </strong>
                                                                    <p
                                                                        className='text-small text-primary'
                                                                        onClick={() =>
                                                                            this.changeCountStock(
                                                                                stock.value,
                                                                                stock.id
                                                                            )
                                                                        }
                                                                    >
                                                                        Điều
                                                                        chỉnh
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                Số lượng nhập
                                                                gần nhất:{' '}
                                                                {stock.income}{' '}
                                                                {stock.unit}
                                                            </div>
                                                        </div>
                                                        <div
                                                            className='p-1 align-self-center h-100 d-flex align-items-center button-done-order cursor-pointer text-center'
                                                            onClick={() =>
                                                                this.handleResetStock(
                                                                    stock.id
                                                                )
                                                            }
                                                        >
                                                            Báo hết nguyên liệu
                                                        </div>
                                                    </div>
                                                </div>
                                            </Card>
                                        </Col>
                                    ))}
                                </div>
                            ) : (
                                <Card>
                                    <CardBody className='d-flex justify-content-center align-items-center'>
                                        <div>
                                            Danh sách trống! Hãy thêm nguyên vật
                                            liệu vào để sử dụng.
                                        </div>
                                    </CardBody>
                                </Card>
                            )}
                        </TabPane>
                    </TabContent>
                </div>
            </Fragment>
        );
    }
}

export default ContentComponent;
