import React, { Component } from 'react';
import {
    Card,
    Badge,
    ButtonDropdown,
    CustomInput,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from 'reactstrap';
import { connect } from 'react-redux';
import {
    setContainerClassnames,
    clickOnMobileMenu,
} from '../../../redux/actions';
import IntlMessages from '../../../helpers/IntlMessages';
import { END_POINT } from '../../../constants/defaultValues';
import classnames from 'classnames';
import { AddCommaNumber } from '../../../helpers/Utils';
import { Separator } from '../../../components/common/CustomBootstrap';

class LabelItemLeft extends Component {
    constructor(props) {
        super(props);

        this.state = {
            dropdownSplitOpen: false,
        };
    }

    toggleSplit = () => {
        this.setState((prevState) => ({
            dropdownSplitOpen: !prevState.dropdownSplitOpen,
        }));
    };

    menuButtonClick = (e, menuClickCount, containerClassnames) => {
        setTimeout(() => {
            var event = document.createEvent('HTMLEvents');
            event.initEvent('resize', false, false);
            window.dispatchEvent(event);
        }, 350);
        this.props.setContainerClassnames(
            menuClickCount,
            containerClassnames,
            this.props.selectedMenuHasSubItems
        );
    };

    onClickedItem = (item) => {
        this.props.onClickedItem(
            // callback
            item,
            this.menuButtonClick,
            [null, 1000, this.props.containerClassnames]
        );
    };

    render() {
        const { dropdownSplitOpen } = this.state;
        const {
            make_products,
            item,
            idx,
            selectedItems,
            isDisabledUpdate,
            isDisabledUpdateCost,
            isDisabledDelete,
            onUpdateCost,
            onUpdate,
            onChangeCheckbox,
            onBestter,
            onSoftHide,
            onDelete,
        } = this.props;
        // console.log('make_products: ', make_products);
        // console.log('item: ', item);

        const matchMakeProduct = make_products.find(
            (p) => p.product.name === item.name
        );
        // console.log(matchMakeProduct);

        return (
            <Card className='mb-3 custom-shadow'>
                <div className='d-flex flex-row cursor-pointer'>
                    <div
                        className={'d-flex flex-grow-1 min-width-zero'}
                        onClick={() => {
                            this.onClickedItem(item);
                        }}
                    >
                        <div
                            className={classnames('d-block position-relative', {
                                disable: item.softHide ? 'disable' : '',
                            })}
                        >
                            <img
                                alt={item.name}
                                src={
                                    item.image.indexOf('http') >= 0
                                        ? item.image
                                        : `${END_POINT}/public/${item.image}`
                                }
                                onError={(e) => {
                                    e.target.onerror = null;
                                    e.target.src =
                                        'https://placehold.co/320x240';
                                }}
                                className='list-thumbnail responsive border-0'
                            />
                            {item.bestter ? (
                                <Badge
                                    className='position-absolute badge-top-right'
                                    color={'success'}
                                    pill
                                >
                                    {'Khuyên dùng'}
                                </Badge>
                            ) : (
                                <></>
                            )}
                        </div>
                        <div
                            className={classnames(
                                'card-body card-body-custom w-100 align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero align-items-lg-center',
                                {
                                    disable: item.softHide ? 'disable' : '',
                                }
                            )}
                        >
                            <div className='list-item-heading mb-1 truncate d-block'>
                                <span>{item.name}</span>
                            </div>
                            <div className='w-20 w-sm-100'>
                                {item.active_sale ? (
                                    <>
                                        <Badge
                                            color='primary'
                                            pill
                                            className='spacer-around-1'
                                        >
                                            {AddCommaNumber(item.price_sale)}
                                        </Badge>
                                        <Badge
                                            color='light'
                                            pill
                                            className='mb-2 spacer-around-1'
                                        >
                                            <del>
                                                {AddCommaNumber(item.price)}
                                            </del>
                                        </Badge>
                                    </>
                                ) : (
                                    <Badge color='primary' pill>
                                        {AddCommaNumber(item.price)}
                                    </Badge>
                                )}
                            </div>
                        </div>
                        <div className='custom-control custom-checkbox pl-1 align-self-center pr-4'>
                            <ButtonDropdown
                                isOpen={dropdownSplitOpen}
                                toggle={this.toggleSplit}
                                onClick={() => {
                                    this.onClickedItem(item);
                                }}
                            >
                                <div className='cursor-pointer btn btn-lg btn-primary pl-4 pr-0 check-button check-all'>
                                    <CustomInput
                                        className='custom-checkbox mb-0 d-inline-block'
                                        type='checkbox'
                                        id={idx}
                                        checked={selectedItems.includes(
                                            item.id
                                        )}
                                        onChange={() => {
                                            onChangeCheckbox(item.id);
                                        }}
                                        label={
                                            <span
                                                className={`custom-control-label`}
                                            />
                                        }
                                    />
                                </div>
                                <DropdownToggle
                                    caret
                                    color='primary'
                                    className='dropdown-toggle-split'
                                />
                                <DropdownMenu right>
                                    <DropdownItem
                                        className={classnames('text-left', {
                                            disable: isDisabledUpdate,
                                        })}
                                        onClick={() =>
                                            isDisabledUpdate
                                                ? null
                                                : onUpdate(item)
                                        }
                                    >
                                        <i className='simple-icon-note pr-2' />
                                        <IntlMessages id='pages.update' />
                                    </DropdownItem>
                                    {/* <Separator />
                                    {matchMakeProduct === undefined ? (
                                        <DropdownItem
                                            className={classnames('text-left', {
                                                disable: isDisabledUpdateCost,
                                            })}
                                            onClick={() =>
                                                isDisabledUpdateCost
                                                    ? null
                                                    : onUpdateCost(item)
                                            }
                                        >
                                            <i className='simple-icon-plus pr-2' />
                                            Thêm định lượng
                                        </DropdownItem>
                                    ) : (
                                        <DropdownItem
                                            className={classnames('text-left', {
                                                disable: isDisabledUpdateCost,
                                            })}
                                            onClick={() =>
                                                isDisabledUpdateCost
                                                    ? null
                                                    : onUpdateCost(item)
                                            }
                                        >
                                            <i className='simple-icon-note pr-2' />
                                            <IntlMessages id='product.edit_cost' />
                                        </DropdownItem>
                                    )} */}

                                    <Separator />
                                    <DropdownItem
                                        className={classnames('text-left', {
                                            disable: isDisabledUpdate,
                                        })}
                                        onClick={() =>
                                            isDisabledUpdate
                                                ? null
                                                : onBestter(item)
                                        }
                                    >
                                        {item.bestter ? (
                                            <>
                                                <i className='simple-icon-close pr-2' />
                                                <IntlMessages id='pages.un-bestter' />
                                            </>
                                        ) : (
                                            <>
                                                <i className='simple-icon-check pr-2' />
                                                <IntlMessages id='pages.bestter' />
                                            </>
                                        )}
                                    </DropdownItem>
                                    <Separator />
                                    <DropdownItem
                                        className={classnames('text-left', {
                                            disable: isDisabledUpdate,
                                        })}
                                        onClick={() =>
                                            isDisabledUpdate
                                                ? null
                                                : onSoftHide(item)
                                        }
                                    >
                                        {item.softHide ? (
                                            <>
                                                <i className='simple-icon-lock-open pr-2' />
                                                <IntlMessages id='pages.un-softhide' />
                                            </>
                                        ) : (
                                            <>
                                                <i className='simple-icon-lock pr-2' />
                                                <IntlMessages id='pages.softhide' />
                                            </>
                                        )}
                                    </DropdownItem>
                                    <Separator />
                                    <DropdownItem
                                        className={classnames('text-left red', {
                                            disable: isDisabledDelete,
                                        })}
                                        onClick={() =>
                                            isDisabledDelete
                                                ? null
                                                : onDelete(item)
                                        }
                                    >
                                        <>
                                            <i className='simple-icon-trash pr-2' />
                                            <IntlMessages id='pages.delete' />
                                        </>
                                    </DropdownItem>
                                </DropdownMenu>
                            </ButtonDropdown>
                        </div>
                    </div>
                </div>
            </Card>
        );
    }
}

const mapStateToProps = ({ menu }) => {
    const { containerClassnames, menuClickCount, selectedMenuHasSubItems } =
        menu;
    return {
        containerClassnames,
        menuClickCount,
        selectedMenuHasSubItems,
    };
};
export default connect(mapStateToProps, {
    setContainerClassnames,
    clickOnMobileMenu,
})(LabelItemLeft);
