import React from 'react';
import { Badge, Card, CardBody, CardTitle, Label, Row } from 'reactstrap';
import { Colxx } from '../../../components/common/CustomBootstrap';
import IntlMessages from '../../../helpers/IntlMessages';
import { AddCommaNumber } from '../../../helpers/Utils';

const ContentDisplay = (props) => {
    // console.log(props);
    const findAvaragePrice = props?.data?.find(
        (d) => d.id === props?.values.id
    );
    return (
        <Card className='position-sticky-custom mb-4'>
            <CardBody>
                <CardTitle>
                    <IntlMessages id='stock.thumb-list' />
                </CardTitle>
                <Row className='mt-3 '>
                    <Colxx xxs='12' lg='6'>
                        <p className='text-muted text-small mb-2'>
                            <IntlMessages id='stock.name' />
                        </p>
                        <p className='mb-3 text-capitalize'>
                            {props.values.name ? (
                                props.values.name
                            ) : (
                                <span className='text-lowercase'>
                                    (Chưa có thông tin)
                                </span>
                            )}
                        </p>
                    </Colxx>
                    <Colxx xxs='12' lg='6'>
                        <p className='text-muted text-small mb-2'>Mã SKU</p>
                        <p className='mb-3'>{props.sku}</p>
                    </Colxx>
                </Row>
                <Row className='mt-3 '>
                    <Colxx xxs='12' sm='6'>
                        <p className='text-muted text-small mb-2'>
                            <IntlMessages id='stock.unit' />
                        </p>
                        <p className='mb-3'>
                            {props.values.unit ? (
                                props.values.unit
                            ) : (
                                <span className='text-lowercase'>
                                    (Chưa có thông tin)
                                </span>
                            )}
                        </p>
                    </Colxx>
                    {/* <Colxx xxs='12' sm='6'>
                        <p className='text-muted text-small mb-2'>
                            Số lượng mua gần nhất
                        </p>
                        <p className='mb-3'>
                            {props.values.count ? (
                                props.values.count + ' ' + props.values.unit
                            ) : (
                                <span className='text-lowercase'>
                                    (Chưa có thông tin)
                                </span>
                            )}
                        </p>
                    </Colxx> */}
                </Row>
                {/* <Row>
                    <Colxx xxs='12' lg='6'>
                        <p className='text-muted text-small mb-2'>
                            <IntlMessages id='stock.unit-price' />
                        </p>
                        <p className='mb-3 text-lowercase'>
                            {AddCommaNumber(props.values.unit_price)}
                        </p>
                    </Colxx>
                    <Colxx xxs='12' lg='6'>
                        <p className='text-muted text-small mb-2'>
                            <IntlMessages id='stock.count' />
                        </p>
                        <p className='mb-3 text-capitalize'>
                            {props.values.new_count ? (
                                props.values.new_count
                            ) : '(Chưa có thông tin)'}
                        </p>
                    </Colxx>
                </Row> */}
                <Row>
                    <Colxx xxs='12' lg='6'>
                        <p className='text-muted text-small mb-2'>
                            <IntlMessages id='stock.count-total' />
                        </p>
                        <p className='mb-3 text-lowercase'>
                            {props.values.total + ' ' + props.values.unit}
                        </p>
                    </Colxx>
                    <Colxx xxs='12' lg='6'>
                        <p className='text-muted text-small mb-2'>
                            <IntlMessages id='stock.count-current' />
                        </p>
                        <p className='mb-3 text-lowercase'>
                            {props.values.count +
                                ' ' +
                                props.values.unit}
                        </p>
                    </Colxx>
                </Row>
                <Row>
                    <Colxx xxs='12' lg='6'>
                        <p className='text-muted text-small mb-2'>
                            Giá mua mới nhất
                        </p>
                        <p className='mb-3 text-lowercase'>
                            <Badge color='primary' pill>
                                {AddCommaNumber(
                                    parseInt(findAvaragePrice?.price_new)
                                )}{' '}
                                đ
                            </Badge>
                        </p>
                    </Colxx>
                    <Colxx xxs='12' lg='6'>
                        <p className='text-muted text-small mb-2'>
                            Giá trị tồn kho
                        </p>
                        <p className='mb-3 text-lowercase'>
                            <Badge color='primary' pill>
                                {AddCommaNumber(
                                    (
                                        parseInt(
                                            findAvaragePrice?.count
                                        ) * parseInt(findAvaragePrice?.avarage_price)
                                    ).toFixed(0)
                                )}{' '}
                                đ
                            </Badge>
                        </p>
                    </Colxx>
                </Row>
                <Row>
                    <Colxx xxs='12' lg='6'>
                        <p className='text-muted text-small mb-2'>
                            Giá mua trung bình
                        </p>
                        <p className='mb-3 text-lowercase'>
                            <Badge color='primary' pill>
                                {AddCommaNumber(
                                    parseInt(findAvaragePrice?.avarage_price)
                                )}{' '}
                                đ
                            </Badge>
                        </p>
                    </Colxx>
                    <Colxx xxs='12' lg='6'>
                        <p className='text-muted text-small mb-2'>
                            Tổng đã nhập
                        </p>
                        <p className='mb-3 text-lowercase'>
                            <Badge color='primary' pill>
                                {AddCommaNumber(
                                    (
                                        parseInt(
                                            findAvaragePrice?.total_value
                                        )
                                    ).toFixed(0)
                                )}{' '}
                                đ
                            </Badge>
                        </p>
                    </Colxx>
                </Row>
                <Row>
                    <Colxx xxs='12' lg='6'>
                        <p className='text-muted text-small mb-2'>
                            Còn dưới 20%
                        </p>
                        <p className='mb-3'>
                            {props.values.is_alert_down_20 ? 'Đúng' : 'Sai'}
                        </p>
                    </Colxx>
                    <Colxx xxs='12' lg='6'>
                        <p className='text-muted text-small mb-2'>
                            Còn dưới 10%
                        </p>
                        <p className='mb-3'>
                            {props.values.is_alert_down_10 ? 'Đúng' : 'Sai'}
                        </p>
                    </Colxx>
                </Row>
            </CardBody>
        </Card>
    );
};

export default ContentDisplay;
