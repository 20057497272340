import React from 'react';
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Input,
    Label,
    Form,
    FormGroup,
} from 'reactstrap';
import IntlMessages from '../../../helpers/IntlMessages';
import PerfectScrollbar from 'react-perfect-scrollbar';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import DynamicForm from '../general/DynamicForm';

const ContentComponent = ({
    title,
    isOpen,
    toggle,
    onSubmit,
    data_dynamic_form,
    values,
    onChange,
    onChangeDynamicForm,
    setting,
}) => {
    // console.log('render UpdateComponent');
    return (
        <Modal
            isOpen={isOpen}
            toggle={toggle}
            wrapClassName='modal-right'
            backdrop={true}
        >
            <Form onSubmit={(e) => onSubmit(e)}>
                <ModalHeader toggle={toggle}>{title}</ModalHeader>
                <ModalBody className='pad-125rem scrool modal-right-add'>
                    <PerfectScrollbar
                        options={{
                            suppressScrollX: true,
                            wheelPropagation: false,
                        }}
                        id='modal-right__body'
                    >
                        <FormGroup className='mt-3 has-float-label'>
                            <Label for='title' className='text-muted'>
                                <IntlMessages id='todo.title' /><span style={{color: 'red'}}>*</span>
                            </Label>
                            <Input
                                type='text'
                                name='title'
                                id='title'
                                value={values.title}
                                onChange={onChange}
                            />
                        </FormGroup>
                        <FormGroup className='has-float-label'>
                            <Label for='description' className='text-muted'>
                                <IntlMessages id='todo.description' /><span style={{color: 'red'}}>*</span>
                            </Label>
                            <Input
                                type='textarea'
                                name='description'
                                id='description'
                                value={values.description}
                                onChange={onChange}
                            />
                        </FormGroup>
                        <Label
                            for='momentFormatSelectDateTime'
                            className='text-muted has-float-label'
                        >
                            <IntlMessages id='todo.date-time' /><span style={{color: 'red'}}>*</span>
                        </Label>
                        <DatePicker
                            name='momentFormatSelectDateTime'
                            id='momentFormatSelectDateTime'
                            className='mb-3'
                            onChange={onChange}
                            selected={moment(
                                values.momentFormatSelectDateTime,
                                'YYYY-MM-DD HH:mm'
                            )}
                            showTimeSelect
                            timeFormat='HH:mm'
                            timeIntervals={15}
                            dateFormat='YYYY-MM-DD - HH:mm'
                            timeCaption='Giờ'
                        />

                        <DynamicForm
                            values={values.dynamic_form}
                            data={data_dynamic_form}
                            onChange={onChangeDynamicForm}
                        />
                    </PerfectScrollbar>
                </ModalBody>
                <ModalFooter>
                    <Button color='primary'>
                        <IntlMessages id='general.btn-update' />
                    </Button>{' '}
                </ModalFooter>
            </Form>
        </Modal>
    );
};

export default React.memo(ContentComponent);
