import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { LOGIN_USER, REGISTER_USER, LOGOUT_USER } from '../actions';

import {
    loginUserSuccess,
    loginUserError,
    registerUserSuccess,
    registerUserError,
} from './actions';
import axios from 'axios';
import { END_POINT } from '../../constants/defaultValues';

const loginWithEmailPasswordAsync = async (username, password, domain) =>
    await axios
        .post(END_POINT + '/auth/signin', { username, password, domain })
        .then((res) => res)
        .catch((err) => err.response);

function* loginWithEmailPassword({ payload }) {
    const { username, password, domain } = payload.user;
    const { history } = payload;

    try {
        const loginUser = yield call(
            loginWithEmailPasswordAsync,
            username,
            password,
            domain
        );

        if (loginUser.status === 200) {
            localStorage.setItem('user_id', loginUser.data.user.id);
            localStorage.setItem('b', loginUser.data.user.idParent);
            localStorage.setItem('access_token', loginUser.data.token);
            localStorage.setItem('username', loginUser.data.user.username);
            localStorage.setItem('avatar', loginUser.data.user.image);
            localStorage.setItem(
                'companyUUID',
                loginUser.data.user.companyUUID
            );
            localStorage.setItem(
                'companyAddress',
                loginUser.data.user.companyAddress
            );
            localStorage.setItem(
                'companyName',
                loginUser.data.user.companyName
            );
            localStorage.setItem(
                'companyImage',
                loginUser.data.user.companyImage
            );
            localStorage.setItem(
                'companyWebsite',
                loginUser.data.user.companyWebsite
            );
            localStorage.setItem(
                'companyLogo',
                loginUser.data.user.companyLogo
            );
            localStorage.setItem(
                'roleSettings',
                JSON.stringify(loginUser.data.roleSettings)
            );

            yield put(loginUserSuccess(loginUser));

            if (loginUser.data.isCashier) {
                history.push('/cashier/default');
            }
            if (loginUser.data.isPaymenter) {
                history.push('/payment/default');
            }
            if (loginUser.data.isKitchen) {
                history.push('/kitchen/default');
            } else {
                history.push('/app/applications/profile');
            }
        } else {
            yield put(loginUserError(loginUser));
        }
    } catch (error) {
        yield put(loginUserError(error));
        console.log('login error : ', error);
    }
}

const registerWithEmailPasswordAsync = async (
    phone,
    password,
    fullname,
    domain,
    url,
    email
) =>
    await axios
        .post(END_POINT + '/auth/signup', {
            phone,
            password,
            fullname,
            domain,
            url,
            email,
        })
        .then((res) => res)
        .catch((err) => err.response);

function* registerWithEmailPassword({ payload }) {
    const { phone, fullname, password, domain, url, email } = payload.user;
    try {
        const registerUser = yield call(
            registerWithEmailPasswordAsync,
            phone,
            password,
            fullname,
            domain,
            url,
            email
        );

        if (registerUser.status === 200) {
            yield put(registerUserSuccess(registerUser));
            // history.push('/');
        } else {
            yield put(registerUserError(registerUser));
        }
    } catch (error) {
        console.log('register error : ', error);
    }
}

function logout({ payload }) {
    const { history } = payload;
    try {
        localStorage.removeItem('user_id');
        localStorage.removeItem('b');
        localStorage.removeItem('access_token');
        localStorage.removeItem('username');
        localStorage.removeItem('avatar');
        localStorage.removeItem('companyUUID');
        localStorage.removeItem('companyAddress');
        localStorage.removeItem('companyName');
        localStorage.removeItem('companyImage');
        localStorage.removeItem('companyWebsite');
        localStorage.removeItem('companyLogo');
        localStorage.removeItem('roleSettings');

        history.push('/');
    } catch (error) {
        console.error(error);
    }
}

export function* watchRegisterUser() {
    yield takeEvery(REGISTER_USER, registerWithEmailPassword);
}

export function* watchLoginUser() {
    yield takeEvery(LOGIN_USER, loginWithEmailPassword);
}

export function* watchLogoutUser() {
    yield takeEvery(LOGOUT_USER, logout);
}

export default function* rootSaga() {
    yield all([
        fork(watchLoginUser),
        fork(watchLogoutUser),
        fork(watchRegisterUser),
    ]);
}
