import React, { Component, Fragment } from 'react';
import {
    Card,
    Badge,
    CardTitle,
    Row,
    Button,
    Input,
    ButtonDropdown,
    DropdownToggle,
    DropdownMenu,
    CardBody,
    Table,
    Tooltip,
} from 'reactstrap';
import axios from 'axios';
import { END_POINT } from '../../../constants/defaultValues';
import { NotificationManager } from '../../../components/common/react-notifications';
import { Separator, Colxx } from '../../../components/common/CustomBootstrap';
import SingleLightbox from '../../../components/pages/SingleLightbox';
import IntlMessages from '../../../helpers/IntlMessages';
import GlideComponentCustom from '../../../components/carousel/GlideComponentCustom';
import io from 'socket.io-client';
import { END_POINT_SOCKET } from '../../../constants/defaultValues';
import smoothscroll from 'smoothscroll-polyfill';
import {
    PageHeading,
    ThumbListViewMenuGuest,
    ProductOrderModalMenuGuest,
} from '../../../containers/pages/guest';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { NewMessage } from '../../../components/svg';
import MessageCard from '../../../containers/pages/message/MessageCardGuest';
import classnames from 'classnames';
import {
    getCompanyImage,
    AddCommaNumber,
    RandomNumber,
    getAvatar,
    getQrPayWithCash,
    NotificatioErrController,
} from '../../../helpers/Utils';
import STATUS from '../../../constants/status';
import CustomTooltip from '../../../components/CustomTooltip';
import { GetBank } from '../../../helpers/ListBank';

const GetHashAndTableId = (path) => {
    const splis = path.split('-');

    let hash = path;
    let table_id = 0;

    if (splis.length) {
        hash = splis[0];
        table_id = splis[1];
    }

    return {
        hash,
        table_id,
    };
};

smoothscroll.polyfill();

window.smoothScrollTo = function (endX, endY, duration) {
    let startX = window.scrollX || window.pageXOffset,
        startY = window.scrollY || window.pageYOffset,
        distanceX = endX - startX,
        distanceY = endY - startY,
        startTime = new Date().getTime();

    // Easing function
    let easeInOutQuart = function (time, from, distance, duration) {
        if ((time /= duration / 2) < 1)
            return (distance / 2) * time * time * time * time + from;
        return (-distance / 2) * ((time -= 2) * time * time * time - 2) + from;
    };

    let timer = window.setInterval(function () {
        let time = new Date().getTime() - startTime,
            newX = easeInOutQuart(time, startX, distanceX, duration),
            newY = easeInOutQuart(time, startY, distanceY, duration);
        if (time >= duration) {
            window.clearInterval(timer);
        }
        window.scrollTo(newX, newY);
    }, 1000 / 60); // 60 fps
};

class ProductGuest extends Component {
    constructor(props) {
        super(props);

        this.state = {
            activeTabDetail: false,
            messageInput: '',
            displayMode: 'thumblist',

            categories: [],
            products: [],

            dropdownSplitOpen: false,
            categoryId: 0,
            search: '',
            items: [],
            isLoading: false,

            orders: [],
            orders_fr_server: [],
            ordersFiltered: [],
            modalOrderOpen: false,
            openMenu: false,
            openPayBill: false,
            store: window.location.host.split('.')[0],
            menu: GetHashAndTableId(props.location.pathname.split('/')[2]),
            table_identify: undefined,
            company: null,
            customer_phone: '',
            customer_name: '',
            isVerify: true,
            startAt: 0,
            reMount: false,
            conversationData: [],
            didUpdate: false,
            isLoadedMore: false,
            isEndMessagesData: false,
            isFirstLoad: true,
            isCreatedAlert: false,
            dropDown: false,
            tooltipOpen: false,
            ipAddress: '',

            table_chair: {
                pin_id: undefined,
                id: undefined,
                floor: undefined,
                identify: undefined,
            },
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.onToggleNote = this.onToggleNote.bind(this);
        this.listenToScroll = this.listenToScroll.bind(this);

        this.socket = io(END_POINT_SOCKET);
    }

    componentDidMount() {
        this.socket.emit('join room', {
            room_id: localStorage.getItem('user_id'),
        });

        this.socket.on('message get conversation guest', (res) => {
            const {
                data: { data, isEnd },
            } = res;

            this.setState({
                conversationData: data,
                didUpdate: false,
                isLoadedMore: true,
                isEndMessagesData: isEnd,
            });

            this.socket.on('refresh-done-order', (res) => {
                this.getIdOrdersNow();
            });
        });

        // verify store and hash
        this.isVerifyHashAndTime();

        // working, loading data
        this.dataListRender();
        this.getIdOrdersNow();
        this.filterOrdersByTableId();
        this.getIpAddress();

        window.addEventListener('scroll', this.listenToScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.listenToScroll);

        this.socket.close();
    }

    getIdOrdersNow = () => {
        let tokenStr = localStorage.getItem('access_token');
        axios
            .get(`${END_POINT + '/order/all_orders_today'}`, {
                headers: { Authorization: `Bearer ${tokenStr}` },
            })
            .then((res) => res.data)
            .then(async (data) => {
                // console.log('data_guest: ', data.data);
                this.setState((prev) => ({
                    ...prev,
                    orders_fr_server: data.data,
                }));
                this.filterOrdersByTableId();
            });
    };

    filterOrdersByTableId = async () => {
        const { menu, orders_fr_server } = this.state;
        const orders = orders_fr_server?.filter((order) => {
            return (
                order.table_id === Number(menu.table_id) &&
                (order.status.uuid === 111 ||
                    order.status.uuid === 222 ||
                    order.status.uuid === 444)
            );
        });

        await this.setState((prev) => ({
            ...prev,
            ordersFiltered: this.consolidateOrders(orders),
        }));
    };

    consolidateOrders = (orders) => {
        const consolidatedOrders = {};

        orders.forEach((order) => {
            const key = `${order.product_id}-${order.time}-${order.status_id}-${order.ice}-${order.sugar}-${order.size}-${order.topping}`;

            if (!consolidatedOrders[key]) {
                consolidatedOrders[key] = {
                    ...order,
                    count: 1,
                };
            } else {
                consolidatedOrders[key].count += 1;
            }
        });

        return Object.values(consolidatedOrders);
    };

    listenToScroll() {
        const winScroll =
            document.body.scrollTop || document.documentElement.scrollTop;

        let nav_items = document.getElementsByClassName('nav_items');
        if (nav_items.length < 1) return false;

        let item = null;
        let itemp = null;
        if (winScroll > 10 && winScroll <= 450) {
            for (itemp of nav_items) {
                itemp.classList.remove('active');
            }
            nav_items.item(0).classList.add('active');
        }

        if (winScroll > 450) {
            let offset = 90;

            let nav_len = nav_items.length;
            for (let i = 0; i < nav_len; i++) {
                let id = nav_items.item(i).id;
                item = document.getElementById('item_' + id);

                let posItem = item ? item.getBoundingClientRect().top : 0;
                if (posItem >= 0 && posItem <= offset) {
                    // active item
                    for (itemp of nav_items) {
                        itemp.classList.remove('active');
                    }

                    nav_items.item(i).classList.add('active');

                    // startAt glide
                    if (nav_len <= 3 || i === 0 || i === 1) {
                        this.setState({ startAt: 0 });
                    } else if (i < nav_len - 1) {
                        this.setState({ reMount: true, startAt: i - 1 });
                    }
                }

                // you're at the bottom of the page
                if (
                    window.innerHeight + window.scrollY >=
                    document.body.offsetHeight
                ) {
                    for (itemp of nav_items) {
                        itemp.classList.remove('active');
                    }

                    nav_items.item(nav_len - 1).classList.add('active');
                }
                // console.log(id, item.getBoundingClientRect().top, window.innerHeight)
            }
        }
    }

    isVerifyHashAndTime() {
        let {
            store,
            menu: { hash, table_id },
        } = this.state;

        return axios
            .get(
                `${
                    END_POINT + '/product/guest/verify'
                }?store=${store}&hash=${hash}&tableId=${table_id}`
            )
            .then((res) => {
                return res.data;
            })
            .then((data) => {
                if (!data.data) {
                    this.setState({ isVerify: false });
                } else {
                    const { table_chair } = data;

                    if (table_chair) {
                        const { id, floor, identify } = table_chair;

                        this.setState((prev) => ({
                            table_chair: {
                                ...prev.table_chair,
                                floor,
                                identify,
                                id,
                                pin_id: `${RandomNumber(5)}`,
                            },
                        }));
                    } else {
                        this.setState({ isVerify: false });
                    }
                }
            })
            .catch((err) => {
                console.log(err);
                this.setState({ isVerify: false });
            });
    }

    offset(el) {
        let rect = el.getBoundingClientRect(),
            scrollLeft =
                window.pageXOffset || document.documentElement.scrollLeft,
            scrollTop =
                window.pageYOffset || document.documentElement.scrollTop;
        return { top: rect.top + scrollTop, left: rect.left + scrollLeft };
    }

    scrollToMyRef(e) {
        // document.getElementById('item_' + e.target.id).scrollIntoView({
        //   behavior: 'smooth',
        // });

        let pos = this.offset(document.getElementById('item_' + e.target.id));
        if ('scrollBehavior' in document.documentElement.style) {
            //Checks if browser supports scroll function
            window.scroll({
                top: pos.top - 50,
                left: 0,
                behavior: 'smooth',
            });
        } else {
            // window.smoothScrollTo(0, pos, 500); //polyfill below
            document.getElementById('item_' + e.target.id).scrollIntoView({
                behavior: 'smooth',
            });
        }
    }

    renderCategories() {
        return this.state.items.map((categories, index) => (
            <p
                onClick={(e) => {
                    this.scrollToMyRef(e);
                }}
                key={categories.id}
                id={index}
                className='nav_items cursor-pointer'
            >
                {categories.name}
            </p>
        ));
    }

    onSearchKey = (e) => {
        if (e.key === 'Enter') {
            this.setState(
                {
                    search: e.target.value.toLowerCase(),
                },
                () => this.dataListRender()
            );
        }
    };

    onSearchChange = (e) => {
        this.setState(
            {
                search: e.target.value.toLowerCase(),
            },
            () => this.dataListRender()
        );
    };

    dataListRender() {
        const { categoryId, search, store } = this.state;

        if (!store) return false;

        axios
            .get(
                `${
                    END_POINT + '/product/guest'
                }?store=${store}&categoryId=${categoryId}&search=${search}`
            )
            .then((res) => {
                return res.data;
            })
            .then((data) => {
                let menu = [];

                // best seller - goi y mon ngon
                let template = {
                    id: 0,
                    name: 'Khuyên dùng',
                    data: data.data.filter((p) => !!p.bestter),
                };
                menu.push(template);

                // group by category
                let category_id = [];
                data.data
                    .filter((p) => !p.bestter)
                    .forEach((p) => {
                        if (!category_id.includes(p.category.id)) {
                            category_id.push(p.category.id);

                            template = {
                                id: p.category.id,
                                name: p.category.name,
                                data: [],
                            };
                            template.data.push(p);
                            menu.push(template);
                        } else {
                            menu.forEach((m) => {
                                if (p.category.id === m.id) {
                                    m.data.push(p);
                                }
                            });
                        }
                    });

                const getUserInfo = this.getUserInformation();
                return Promise.all([getUserInfo])
                    .then((promises) => {
                        localStorage.setItem(
                            'user_id',
                            promises[0].data.user_id
                        );

                        // messages
                        const { id, pin_id } = this.state.table_chair;

                        if (id === undefined || pin_id === undefined)
                            return false;

                        this.socket.emit('join room', {
                            room_id: `${pin_id}.${id}`,
                        });
                        this.socket.emit('message get conversation guest', {
                            user_id: promises[0].data.user_id,
                            pin_id,
                            sender_id: pin_id,
                            table_chair_id: id,
                        });

                        // state
                        this.setState({
                            totalPage: data.totalPage,
                            items: menu, //data.data,
                            totalItemCount: data.totalItem,
                            isLoading: true,
                            company: promises[0].data,
                            startAt: 0,
                        });

                        document.title =
                            'Menu - ' + promises[0].data.companyName;
                    })
                    .catch((err) => console.log(err));
            })
            .catch((err) => {
                NotificationManager.error(
                    err.response.data.responseText,
                    'Lỗi !',
                    3000,
                    null,
                    null,
                    'filled'
                );
                return false;
            });
    }

    getUserInformation() {
        return axios
            .get(END_POINT + `/user/guest/store?store=${this.state.store}`)
            .then((res) => res.data)
            .catch((err) => console.log(err));
    }

    // ORDER
    toggleModalOrder = () => {
        this.setState({
            modalOrderOpen: !this.state.modalOrderOpen,
        });
    };
    updateOrders = ({ product, requireCreated = false }) => {
        const find_order = this.state.orders.find(
            (_product) => _product.id === product.id
        );

        // Kiểm tra size, topping, ice, sugar, price_sale, active_sale
        let price = product.price;
        if (product.active_sale) {
            price = product.price_sale;
        }

        // size
        if (product.sizeSelectedId.length) {
            const find_size = product.size.find((_size) =>
                product.sizeSelectedId.includes(_size.id)
            );
            price = parseFloat(price) + parseFloat(find_size.value);
        }
        // topping
        if (product.toppingSelectedId.length) {
            const find_topping = product.topping.filter((_size) =>
                product.toppingSelectedId.includes(_size.id)
            );

            for (let i = 0; i < find_topping.length; i++) {
                price = parseFloat(price) + parseFloat(find_topping[i].value);
            }
        }

        // Update giá tiền nếu đã tồn tại
        if (find_order) {
            const orders = this.state.orders.map((o) =>
                o.id === find_order.id
                    ? {
                          ...o,
                          price: price,
                          note: product.note,
                          iceSelectedValue: product.iceSelectedValue,
                          sugarSelectedValue: product.sugarSelectedValue,
                          sizeSelectedId: product.sizeSelectedId,
                          toppingSelectedId: product.toppingSelectedId,
                      }
                    : o
            );

            this.setState({ orders });
        } else {
            /* Tạo mới nếu chưa có
             *
             * Chỉ tạo mới khi click nút thêm sản phẩm, input hoặc nhấn nút +, -
             */
            let orders = this.state.orders;

            if (requireCreated) {
                orders.push({
                    id: product.id,
                    name: product.name,
                    price: price,
                    count: 1,
                    note: product.note,
                    iceSelectedValue: product.iceSelectedValue,
                    sugarSelectedValue: product.sugarSelectedValue,
                    sizeSelectedId: product.sizeSelectedId,
                    toppingSelectedId: product.toppingSelectedId,
                    previewDisplay: false,
                });
            }
            this.setState({ orders });
        }
    };

    plus = (id) => {
        let order = this.getOrder(id);

        if (!order) {
            // init

            const product = this.getProductInfo(id);
            if (!product) return false;

            this.updateOrders({ product, requireCreated: true });
        } else {
            // plus
            const orders = this.state.orders.map((o) =>
                o.id === order.id ? { ...o, count: o.count + 1 } : o
            );

            this.setState({ orders });
        }
    };
    sub = (id) => {
        let order = this.getOrder(id);

        if (!order) return false;

        // sub
        const orders = this.state.orders
            .map((o) =>
                o.id === order.id
                    ? { ...o, count: o.count - 1 >= 0 ? o.count - 1 : 0 }
                    : o
            )
            .filter((order) => order.count > 0);

        this.setState({ orders });
    };
    change = (e, id) => {
        if (!e || !e.target) return false;

        let { value } = e.target;
        let order = this.getOrder(id);

        value = parseInt(value);

        if (!order) {
            // init
            const product = this.getProductInfo(id);
            if (!product) return false;

            this.updateOrders({ product, requireCreated: true });
        } else {
            // change
            const orders = this.state.orders
                .map((o) =>
                    o.id === order.id
                        ? { ...o, count: value >= 0 ? value : 0 }
                        : o
                )
                .filter((order) => order.count > 0);

            this.setState({ orders });
        }
    };

    onChange = (e) => {
        if (e.target) {
            const {
                target: { name, value },
            } = e;

            this.setState((prev) => ({
                ...prev,
                [name]: value,
            }));
        } else {
        }
    };

    onToggleNote(id) {
        const orders = this.state.orders.map((o) =>
            o.id === id ? { ...o, noteDisplay: !o.noteDisplay } : o
        );

        this.setState({ orders });
    }

    onRemoveOrder = (id) => {
        let orders = this.state.orders.filter((_order) => _order.id !== id);

        this.setState({ orders });
    };

    onToggleDetail = ({ product, name, value }) => {
        // console.log('product: ', product);
        // console.log('name: ', name);
        // console.log('value: ', value);
        const products = this.state.items;
        // console.log('products: ', products);
        let find_product = products.map((categories) =>
            categories.data.find(
                (_product) => _product.id === product.id && !_product.softHide
            )
        );

        if (find_product) {
            find_product = find_product.find(function (el) {
                return el != null;
            });
        }

        if (find_product) {
            // toggle hiển thị hoặc không
            if (name === 'detailDisplay') {
                find_product[name] = !find_product[name];

                this.setState((prev) => ({
                    ...prev,
                    items: products,
                }));

                // console.log('items: ', this.state.items);
            } else if (name === 'orderDisplay') {
                find_product[name] = !find_product[name];

                this.setState(
                    (prev) => ({
                        ...prev,
                        items: products,
                    }),
                    () =>
                        this.updateOrders({
                            product: find_product,
                            requireCreated: true,
                        })
                );
            }

            // check vào các giá trị size, topping, ice, sugar
            else {
                let _value = value;

                // size và topping thì cho mảng các giá trị
                // Vì Topping thì có thể mix nhiều loại
                // Size thì luôn luôn required 1 loại
                if (name === 'sizeSelectedId' || name === 'toppingSelectedId') {
                    if (name === 'sizeSelectedId') {
                        _value = [_value];
                    } else if (find_product[name].includes(value)) {
                        _value = find_product[name].filter(
                            (_item) => _item !== value
                        );
                    } else if (name === 'toppingSelectedId') {
                        find_product[name].push(_value);
                        _value = find_product[name];
                    }
                }

                find_product[name] = _value;
                // console.log('find_product: ', find_product);

                this.setState(
                    (prev) => ({
                        ...prev,
                        items: products,
                    }),
                    () => this.updateOrders({ product: find_product })
                );
            }
        }
    };

    handleSubmit() {
        if (this.state.orders.length < 1) {
            NotificationManager.error(
                'Chưa đặt sản phẩm. Thêm ít nhất 1 sản phẩm',
                'Lỗi !',
                3000,
                null,
                null,
                'filled'
            );
            return false;
        }

        // axios submit
        axios
            .post(`${END_POINT + '/bill/guest'}`, {
                data: this.state.orders,
                store: this.state.store,
                phone: this.state.customer_phone,
                name: this.state.customer_name,
                hash: this.state.menu.hash || '',
                table_id: this.state.menu.table_id || '',
            })
            .then((res) => {
                // console.log('res: ', res.data);
                return res.data;
            })
            .then((data) => {
                NotificationManager.primary(
                    data.responseText,
                    '',
                    3000,
                    null,
                    null,
                    'filled'
                );

                const { bill_id } = data;

                // real-time
                this.socket.emit('refresh-bestseller', {
                    user_id: localStorage.getItem('user_id'),
                    type: localStorage.getItem('selectedDropdownBestSeller'),
                });
                this.socket.emit('refresh-recent-order', {
                    user_id: localStorage.getItem('user_id'),
                    floor: 0,
                    table_id: this.state.menu.table_id || '',
                });
                this.socket.emit('refresh-pending-order', {
                    user_id: localStorage.getItem('user_id'),
                });
                this.socket.emit('refresh-done-order', {
                    user_id: localStorage.getItem('user_id'),
                });
                this.socket.emit('refresh-refund-order', {
                    user_id: localStorage.getItem('user_id'),
                });

                // refresh alert data
                this.socket.emit('refresh-alert-data', {
                    user_id: localStorage.getItem('user_id'),
                });

                // re-mind order after 2 minutes
                this.socket.emit('remind-created-orders', {
                    data: this.state.orders,
                    user_id: localStorage.getItem('user_id'),
                    bill_id,
                });

                this.setState({
                    orders: [],
                    orderCount: 1,
                    orderProduct: '',
                    modalOrderOpen: !this.state.modalOrderOpen,
                    openMenu: false,
                });

                this.getIdOrdersNow();
                // this.renderOrders();
            })
            .catch((err) => {
                NotificationManager.error(
                    err.response.data.responseText,
                    'Lỗi !',
                    3000,
                    null,
                    null,
                    'filled'
                );
            });
    }
    // END ORDER

    getOrder(id) {
        const orders = this.state.orders.filter((order) => order.id === id);
        return orders.length ? orders[0] : null;
    }
    getProductInfo(id) {
        const products = this.state.items
            .map((categories) =>
                categories.data.filter((product) => product.id === id)
            )
            .filter((items) => items.length > 0);

        return products.length > 0 ? products[0][0] : null;
    }

    calTotalPrice = (orders) => {
        let total = 0;
        for (let i = 0; i < orders.length; i++) {
            let order = orders[i];

            total += parseInt(order.price) * order.count;
        }
        return total;
    };

    calcNumberOrder = (orders) => {
        let sum = 0;
        let i = 0;
        let orders_len = orders.length;
        let order = null;
        for (; i < orders_len; i++) {
            order = orders[i];
            sum += order.count;
        }
        return sum;
    };

    /**
     * New Message
     */
    handleGetConversation = () => {
        let offset = 0;
        if (this.state.conversationData) {
            offset = this.state.conversationData.length;
        }

        const { id, pin_id } = this.state.table_chair;

        this.socket.emit('message get conversation guest', {
            user_id: this.state.company.user_id,
            pin_id,
            table_chair_id: id,
            sender_id: pin_id,
            offset,
        });

        this.setState({ isLoadedMore: false });
    };
    handleSendToConversation = (messageInput) => {
        const { id, pin_id } = this.state.table_chair;

        this.setState({ isCreatedAlert: false }, () => {
            this.socket.emit('message send conversation guest', {
                user_id: localStorage.getItem('user_id'),
                message: messageInput,
                pin_id: pin_id,
                sender_id: pin_id,
                table_chair_id: id,
            });
        });
    };

    // UI
    isLoaded = (e) => {
        this.setState({ isFirstLoad: false });
    };
    handleToggleDetail = (e) => {
        if (this.state.activeTabDetail) {
            this.setState({
                activeTabDetail: !this.state.activeTabDetail,
                isFirstLoad: true,
                didUpdate: false,
            });
        } else {
            this.setState({
                activeTabDetail: !this.state.activeTabDetail,
                didUpdate: false,
            });
        }
    };
    handleChatInputPress = (e) => {
        if (e.key === 'Enter') {
            let { messageInput } = this.state;
            if (messageInput.length > 0) {
                this.handleSendButtonClick();
            }
        }
    };
    handleChatInputChange = (e) => {
        this.setState({
            messageInput: e.target.value,
        });
    };
    handleSendButtonClick = (e) => {
        let { messageInput } = this.state;

        if (messageInput.length > 0) {
            this.handleSendToConversation(messageInput);

            this.setState({
                messageInput: '',
            });
        }
    };
    /**
     * End Message
     */

    incrementItem = (item) => {
        // Find Order
        const find_order = this.state.orders.find(
            (_product) => _product.id === item.id
        );

        // Update giá tiền nếu đã tồn tại
        if (find_order) {
            const orders = this.state.orders.map((o) =>
                o.id === find_order.id
                    ? {
                          ...o,
                          count: o.count + 1,
                          price: item.price,
                          note: item.note,
                          iceSelectedValue: item.iceSelectedValue,
                          sugarSelectedValue: item.sugarSelectedValue,
                          sizeSelectedId: item.sizeSelectedId,
                          toppingSelectedId: item.toppingSelectedId,
                      }
                    : o
            );
            this.setState({ orders });
        }
    };

    decrementItem = (item) => {
        // Find Order
        const find_order = this.state.orders.find(
            (_product) => _product.id === item.id
        );

        // Update giá tiền nếu đã tồn tại
        if (find_order.count > 1) {
            const orders = this.state.orders.map((o) =>
                o.id === find_order.id
                    ? {
                          ...o,
                          count: o.count - 1,
                          price: item.price,
                          note: item.note,
                          iceSelectedValue: item.iceSelectedValue,
                          sugarSelectedValue: item.sugarSelectedValue,
                          sizeSelectedId: item.sizeSelectedId,
                          toppingSelectedId: item.toppingSelectedId,
                      }
                    : o
            );
            this.setState({ orders });
        }
    };

    toggleOpenMenu = () => {
        this.setState((prev) => ({
            ...prev,
            openMenu: !this.state.openMenu,
        }));
    };

    toggleOrderBill = () => {
        this.setState((prev) => ({
            ...prev,
            openPayBill: !this.state.openPayBill,
        }));
    };

    getOriginalPrice = (item) => {
        if (!item) return 0;

        return item.price * 1;
    };

    getTotalPrice = (items) => {
        if (!items.length) return 0;

        const cost = items.reduce((total, item) => {
            return total + item.price * item.count;
        }, 0);

        return cost;
    };

    tooltipOpen = () => {
        this.setState({
            tooltipOpen: !this.state.tooltipOpen,
        });
    };

    getIpAddress = () => {
        let tokenStr = localStorage.getItem('access_token');
        axios
            .get(`${END_POINT + '/getIPAddress'}`, {
                headers: { Authorization: `Bearer ${tokenStr}` },
            })
            .then((res) => {
                console.log('IP: ', res.data);
                return res.data;
            })
            .then((data) => {
                this.setState({
                    ipAddress: data,
                });
            })
            .catch((err) => {
                NotificatioErrController(err.response.data.responseText);
            });
    };

    render() {
        const {
            items,
            openMenu,
            openPayBill,
            dropDown,
            // selectedOrderOption,
            // orderOptions,
            orders,
            ordersFiltered,
            modalOrderOpen,
            company,
            isVerify,
            startAt,
            reMount,
            activeTabDetail,
            messageInput,
            conversationData,
            isLoadedMore,
            isEndMessagesData,
            isFirstLoad,
            customer_phone,
            customer_name,
        } = this.state;

        // console.log('ordersFiltered: ', ordersFiltered);
        // console.log('orders: ', orders);
        // console.log('menu: ', this.state.menu);

        // console.log(
        //     'consolidatedOrders: ',
        //     this.consolidateOrders(ordersFiltered)
        // );
        let total_price = this.getTotalPrice(ordersFiltered);

        const namePay = localStorage.getItem('namePay');
        const numberPay = localStorage.getItem('numberPay');
        const bankPay = localStorage.getItem('bankPay');
        const qrPayImage = localStorage.getItem('qrPayImage');

        return !this.state.isLoading ? (
            <div className='loading' />
        ) : (
            <div className='menu-guest'>
                {openPayBill === true ? (
                    <Colxx xxs='12' className='mb-4'>
                        <div
                            className='d-flex align-items-center table-modal-header center mt-3 mb-3'
                            onClick={this.toggleOrderBill}
                        >
                            <div className='float-left'>
                                <i className='simple-icon-arrow-left p-2 cursor-pointer' />
                            </div>
                            <h3 className='m-0-auto pad-l-25px pad-10px text-uppercase'>
                                Hoá đơn thanh toán
                            </h3>
                        </div>
                        <Card
                            className={classnames('mb-3 invoice-contents p-2', {
                                // card: !cardWrapper,
                            })}
                            // className='mb-5 invoice-contents'
                        >
                            <CardBody className='d-flex flex-column justify-content-between p-2'>
                                <div
                                    // className='d-flex flex-column'
                                    className={classnames(
                                        'd-flex flex-column align-self-center order-invoice p-0 w-100',
                                        {
                                            // 'card-body': !cardWrapper,
                                        }
                                    )}
                                >
                                    <Table borderless>
                                        <thead>
                                            <style>{`@media print {.no-print{display: none;}}`}</style>
                                            <tr>
                                                <th className='text-muted pd-light text-extra-small mb-2 text-to-print'>
                                                    #
                                                </th>
                                                <th className='text-left pd-light text-muted text-extra-small mb-2 text-to-print'>
                                                    Tên sản phẩm
                                                </th>
                                                <th className='text-center pd-light text-muted text-extra-small mb-2 text-to-print'>
                                                    Đơn giá
                                                </th>
                                                <th className='text-right pd-light text-muted text-extra-small mb-2 text-to-print'>
                                                    Thành tiền
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {ordersFiltered.length &&
                                                ordersFiltered.map((item) => (
                                                    <React.Fragment
                                                        key={item.id}
                                                    >
                                                        <tr>
                                                            <td className='text-to-print pd-light text-primary text-center'>
                                                                {item.count}x
                                                            </td>
                                                            <td className='text-to-print pd-light text-left'>
                                                                {
                                                                    item.product
                                                                        .name
                                                                }
                                                            </td>
                                                            <td className='text-to-print text-center pd-light'>
                                                                {AddCommaNumber(
                                                                    this.getOriginalPrice(
                                                                        item
                                                                    )
                                                                )}
                                                            </td>
                                                            <td className='text-right text-to-print pd-light'>
                                                                {AddCommaNumber(
                                                                    Number(
                                                                        item.price
                                                                    ) *
                                                                        item.count
                                                                )}
                                                            </td>
                                                        </tr>
                                                        {/* {(discount &&
                                                        discount.saved &&
                                                        discount.note) ||
                                                    (item.discount &&
                                                        item.discount.note) ? (
                                                        <tr>
                                                            <td
                                                                className='discount-note no-print pd-light'
                                                                colSpan='4'
                                                            >
                                                                {`* ${GetDiscountOrderNote(
                                                                    discount,
                                                                    item
                                                                )}`}
                                                            </td>
                                                        </tr>
                                                    ) : null} */}
                                                    </React.Fragment>
                                                ))}
                                        </tbody>
                                    </Table>
                                </div>
                                <div className='d-flex flex-column'>
                                    <Table
                                        borderless
                                        className='d-flex justify-content-end mb-2'
                                    >
                                        <tbody>
                                            <tr className='font-weight-bold'>
                                                <td className='text-semi-muted text-to-print text-right pd-light mb-3'>
                                                    Tổng tiền:
                                                </td>
                                                <td className='text-right text-bold text-to-print pd-light font-weight-bold'>
                                                    {AddCommaNumber(
                                                        total_price
                                                    )}
                                                </td>
                                            </tr>
                                            {/* {(discount &&
                                            discount.saved &&
                                            discount.value &&
                                            discount.type) ||
                                        find_item_discount ? (
                                            <tr>
                                                <td className='text-semi-muted text-to-print text-right pd-light'>
                                                    Chiết khấu:
                                                </td>
                                                <td className='text-right text-to-print pd-light'>
                                                    {AddCommaNumber(
                                                        total_discount_price
                                                    )}
                                                </td>
                                            </tr>
                                        ) : null}
                                        {(tax_fee &&
                                            tax_fee.saved &&
                                            tax_fee.value &&
                                            tax_fee.type) ||
                                        find_item_tax_fee ? (
                                            <tr>
                                                <td className='text-semi-muted text-to-print text-right pd-light'>
                                                    Thuế:
                                                </td>
                                                <td className='text-right text-to-print pd-light'>
                                                    {AddCommaNumber(
                                                        total_tax_fee_price
                                                    )}
                                                </td>
                                            </tr>
                                        ) : null}
                                        {(fee_other &&
                                            fee_other.saved &&
                                            fee_other.value &&
                                            fee_other.type) ||
                                        find_item_fee_other ? (
                                            <tr>
                                                <td className='text-semi-muted text-to-print text-right pd-light'>
                                                    Phụ phí:
                                                </td>
                                                <td className='text-right text-to-print pd-light'>
                                                    {AddCommaNumber(
                                                        total_fee_other_price
                                                    )}
                                                </td>
                                            </tr>
                                        ) : null}
                                        {(discount &&
                                            discount.saved &&
                                            discount.value &&
                                            discount.type) ||
                                        find_item_discount ||
                                        (tax_fee &&
                                            tax_fee.saved &&
                                            tax_fee.value &&
                                            tax_fee.type) ||
                                        find_item_tax_fee ||
                                        (fee_other &&
                                            fee_other.saved &&
                                            fee_other.value &&
                                            fee_other.type) ||
                                        find_item_fee_other ? (
                                            <tr className='font-weight-bold'>
                                                <td className='text-semi-muted text-to-print text-right pd-light'>
                                                    Số tiền thanh toán:
                                                </td>
                                                <td className='text-right text-bold text-to-print pd-light font-weight-bold'>
                                                    {AddCommaNumber(
                                                        total_payment_price
                                                    )}
                                                </td>
                                            </tr>
                                        ) : null} */}
                                        </tbody>
                                    </Table>

                                    {/* <div className='border-bottom mb-3' />
                                <p className='text-muted text-small text-center mb-1'>
                                    Chân thành cảm ơn quý khách!{' '}
                                </p>
                                <span className='text-small text-center'>
                                    Bản quyền thuộc vmass.vn.
                                </span> */}
                                </div>
                            </CardBody>
                        </Card>
                        {namePay === 'null' ||
                        numberPay === 'null' ||
                        bankPay === 'null' ||
                        qrPayImage === 'null' ||
                        !namePay ||
                        !numberPay ||
                        !bankPay ||
                        !qrPayImage ? null : (
                            <Card>
                                <div className='d-flex align-items-center p-3'>
                                    <Colxx xxs='5' className='pl-0 text-center'>
                                        {bankPay && numberPay ? (
                                            <SingleLightbox
                                                thumb={getQrPayWithCash(
                                                    bankPay,
                                                    numberPay,
                                                    total_price
                                                )}
                                                large={getQrPayWithCash(
                                                    bankPay,
                                                    numberPay,
                                                    total_price
                                                )}
                                                className='img-thumbnail card-img-bill'
                                            />
                                        ) : (
                                            <h5 className='text-to-print text-center'>
                                                Thông Tin Thanh Toán
                                            </h5>
                                        )}
                                    </Colxx>
                                    <Colxx xxs='7' className='text-left p-0'>
                                        <p className='mb-1'>{namePay}</p>
                                        <p className='mb-1'>{numberPay}</p>
                                        <p className='mb-1'>
                                            {GetBank(bankPay).label}
                                        </p>
                                    </Colxx>
                                </div>
                            </Card>
                        )}

                        <div className='order-btn-preview'>
                            <Colxx xxs='12' className='p-0'>
                                <Button
                                    color='danger'
                                    className='top-right-button'
                                    // onClick={this.toggleOrderBill}
                                    block
                                >
                                    <span>Gọi phục vụ</span>
                                </Button>
                            </Colxx>
                        </div>
                    </Colxx>
                ) : (
                    <Fragment>
                        {/* MESSAGE */}
                        <div className='message-guest-container'>
                            <div
                                className={classnames('message-screen-detail', {
                                    active: activeTabDetail,
                                })}
                            >
                                <Row className='custom-row-no-margin'>
                                    <Colxx
                                        xxs='12'
                                        md='12'
                                        xl='12'
                                        className='col-left custom-col-12-no-padding'
                                    >
                                        <Card>
                                            {/* min-height-100vh */}
                                            <div className='message-screen-detail__header'>
                                                <span
                                                    className='icon'
                                                    onClick={
                                                        this.handleToggleDetail
                                                    }
                                                >
                                                    <i className='iconsminds-to-left' />
                                                </span>
                                                <span className='text'>
                                                    {company.companyName !=
                                                    'null'
                                                        ? company.companyName
                                                        : ''}
                                                </span>
                                            </div>
                                            <div className='message-screen-detail__content'>
                                                <PerfectScrollbar
                                                    onYReachStart={(
                                                        container
                                                    ) => {
                                                        if (!isEndMessagesData)
                                                            container.scrollTop =
                                                                63 * 8 - 33; // height * size - (height / 2)

                                                        if (
                                                            isLoadedMore &&
                                                            !isEndMessagesData &&
                                                            !isFirstLoad
                                                        ) {
                                                            // console.log(
                                                            //     'load more'
                                                            // );

                                                            setTimeout(() => {
                                                                this.handleGetConversation();
                                                            }, 1);
                                                        }

                                                        if (isFirstLoad) {
                                                            this.isLoaded();
                                                        }
                                                    }}
                                                    ref={(ref) => {
                                                        this._scrollBarRef =
                                                            ref;
                                                    }}
                                                    containerRef={(ref) => {}}
                                                    options={{
                                                        suppressScrollX: true,
                                                        wheelPropagation: true,
                                                    }}
                                                >
                                                    <div className='mb-3' />
                                                    {conversationData.map(
                                                        (item, idx) => (
                                                            <div
                                                                key={idx}
                                                                ref={`message_guest_${idx}`}
                                                            >
                                                                <MessageCard
                                                                    idx={idx}
                                                                    company={
                                                                        company
                                                                    }
                                                                    item={item}
                                                                    pin_id={
                                                                        this
                                                                            .state
                                                                            .table_chair
                                                                            .pin_id
                                                                    }
                                                                />
                                                            </div>
                                                        )
                                                    )}
                                                </PerfectScrollbar>
                                            </div>
                                            <div className='message-screen-detail__container__submit'>
                                                <div className='message-screen-detail__submit d-flex justify-content-between align-items-center'>
                                                    <Input
                                                        className='form-control flex-grow-1'
                                                        type='text'
                                                        placeholder={
                                                            'Viết tin nhắn'
                                                        }
                                                        value={messageInput}
                                                        onKeyPress={(e) =>
                                                            this.handleChatInputPress(
                                                                e
                                                            )
                                                        }
                                                        onChange={(e) =>
                                                            this.handleChatInputChange(
                                                                e
                                                            )
                                                        }
                                                    />
                                                    <div>
                                                        <Button
                                                            color='primary'
                                                            className='icon-button large ml-1'
                                                            onClick={() =>
                                                                this.handleSendButtonClick()
                                                            }
                                                        >
                                                            <i className='simple-icon-arrow-right' />
                                                        </Button>
                                                    </div>
                                                </div>
                                            </div>
                                        </Card>
                                    </Colxx>
                                </Row>
                            </div>
                        </div>
                        {/* END MESSAGE */}

                        {company.companyImage ? (
                            <SingleLightbox
                                thumb={getCompanyImage(company)}
                                large={getCompanyImage(company)}
                                className='responsive border-0 card-img-top mb-3 mxh-700'
                            />
                        ) : (
                            <SingleLightbox
                                thumb='/assets/img/balloon.jpg'
                                large='/assets/img/balloon.jpg'
                                className='responsive border-0 card-img-top mb-3 mxh-700'
                            />
                        )}
                        {/* CONTENT */}
                        <div
                            className={classnames(
                                'disable-text-selection smooth-scroll-container p-0-2',
                                {
                                    softhide: activeTabDetail,
                                }
                            )}
                        >
                            <Row className='custom-row-no-margin'>
                                <Colxx
                                    xxs='12'
                                    md='12'
                                    xl='12'
                                    className='col-left custom-col-12-no-padding'
                                >
                                    <div className='mb-5' id='item_home'>
                                        <div>
                                            <div className='mb-3'>
                                                <div className='menu-guest-header align-items-center'>
                                                    <h5 className='card-title'>
                                                        {company.companyName
                                                            ? company.companyName
                                                            : ''}
                                                    </h5>
                                                    <div
                                                        onClick={
                                                            this
                                                                .handleToggleDetail
                                                        }
                                                        // className='pr-2'
                                                    >
                                                        <i className='simple-icon-paper-plane' />
                                                    </div>
                                                </div>
                                                <p>
                                                    {company.companyAddress
                                                        ? company.companyAddress
                                                        : ''}
                                                </p>
                                            </div>
                                            <Separator className='mb-3' />
                                            {ordersFiltered.length &&
                                            openMenu === false ? (
                                                <div className='table-modal-header center mt-3 mb-3'>
                                                    <h3 className='m-0-auto pad-l-25px pad-10px text-uppercase'>
                                                        Danh sách các món đã
                                                        đặt!
                                                    </h3>
                                                </div>
                                            ) : (
                                                <PageHeading
                                                    heading='menu.order'
                                                    onSearchKey={
                                                        this.onSearchKey
                                                    }
                                                    onSearchChange={
                                                        this.onSearchChange
                                                    }
                                                    // changeOrderBy={this.changeOrderBy}
                                                    // selectedOrderOption={selectedOrderOption}
                                                    // orderOptions={orderOptions}
                                                />
                                            )}
                                        </div>
                                    </div>
                                </Colxx>
                            </Row>
                            {ordersFiltered.length && openMenu === false ? (
                                <>
                                    <div className='disable-text-selection smooth-scroll-container custom-row-no-margin-tlr-80'>
                                        {ordersFiltered.map((order) => {
                                            const toppings =
                                                order.topping.split('|');
                                            return (
                                                <Card
                                                    className='flex-row mb-3'
                                                    key={order.id}
                                                >
                                                    <section className='d-block position-relative'>
                                                        <img
                                                            onError={(e) => {
                                                                e.target.onerror =
                                                                    null;
                                                                e.target.src =
                                                                    'https://placehold.co/320x240';
                                                            }}
                                                            alt={order.name}
                                                            src={
                                                                order.product.image.indexOf(
                                                                    'http'
                                                                ) >= 0
                                                                    ? order
                                                                          .product
                                                                          .image
                                                                    : END_POINT +
                                                                      '/public/' +
                                                                      order
                                                                          .product
                                                                          .image
                                                            }
                                                            className='list-thumbnail responsive border-0 m-1'
                                                        />
                                                        <Badge
                                                            className='position-absolute badge-top-right'
                                                            color={
                                                                order.status
                                                                    .uuid ===
                                                                STATUS.CREATED
                                                                    ? 'primary'
                                                                    : order
                                                                          .status
                                                                          .uuid ===
                                                                      STATUS.CLOSED
                                                                    ? 'danger'
                                                                    : order
                                                                          .status
                                                                          .uuid ===
                                                                      STATUS.PENDING
                                                                    ? 'info'
                                                                    : order
                                                                          .status
                                                                          .uuid ===
                                                                      STATUS.COMPLETED
                                                                    ? 'success'
                                                                    : 'danger'
                                                            }
                                                            pill
                                                        >
                                                            {order.status.name}
                                                        </Badge>
                                                    </section>
                                                    <div className='w-100 d-flex flex-column justify-content-between min-width-zero p-2'>
                                                        <div className='d-flex justify-content-between'>
                                                            <div className='list-item-heading mb-1 truncate'>
                                                                x{order.count}{' '}
                                                                {
                                                                    order
                                                                        .product
                                                                        .name
                                                                }
                                                            </div>
                                                            <div>
                                                                {order.time}
                                                            </div>
                                                        </div>
                                                        <div className='w-100'>
                                                            {order.size ===
                                                                '' ||
                                                            order.size.split(
                                                                ','
                                                            )[1] ===
                                                                '0' ? null : (
                                                                <Badge
                                                                    className='mr-1 mt-1'
                                                                    color='warning'
                                                                >
                                                                    {
                                                                        order.size.split(
                                                                            ','
                                                                        )[0]
                                                                    }
                                                                </Badge>
                                                            )}
                                                            {order.ice === '' ||
                                                            order.ice ===
                                                                '100' ? null : (
                                                                <Badge
                                                                    className='mr-1 mt-1'
                                                                    color='primary'
                                                                >
                                                                    Đá:{' '}
                                                                    {order.ice}%
                                                                </Badge>
                                                            )}
                                                            {order.sugar ===
                                                                '' ||
                                                            order.sugar ===
                                                                '100' ? null : (
                                                                <Badge
                                                                    className='mr-1 mt-1'
                                                                    color='info'
                                                                >
                                                                    Độ ngọt:{' '}
                                                                    {
                                                                        order.sugar
                                                                    }
                                                                    %
                                                                </Badge>
                                                            )}
                                                            {order.topping ===
                                                                '' ||
                                                            order.topping.split(
                                                                ','
                                                            )[1] === '0'
                                                                ? null
                                                                : toppings.map(
                                                                      (
                                                                          _topping,
                                                                          idx
                                                                      ) => (
                                                                          <Badge
                                                                              color='success'
                                                                              className='mr-1 mt-1'
                                                                              key={
                                                                                  idx
                                                                              }
                                                                          >
                                                                              {
                                                                                  _topping.split(
                                                                                      ','
                                                                                  )[0]
                                                                              }
                                                                          </Badge>
                                                                      )
                                                                  )}
                                                        </div>
                                                    </div>
                                                </Card>
                                            );
                                        })}
                                    </div>
                                    <div className='d-flex order-btn-preview'>
                                        <Colxx xxs='5' className='p-0 pr-1'>
                                            <Button
                                                color='danger'
                                                className={`top-right-button ${
                                                    openPayBill
                                                        ? 'button-transition clicked'
                                                        : 'button-transition'
                                                }`}
                                                onClick={this.toggleOrderBill}
                                                block
                                            >
                                                <span>Thanh toán</span>
                                            </Button>
                                        </Colxx>
                                        <Colxx xxs='5' className='p-0 pr-1'>
                                            <Button
                                                color='info'
                                                className='top-right-button'
                                                onClick={this.toggleOpenMenu}
                                                block
                                            >
                                                <span>Thêm món</span>
                                            </Button>
                                        </Colxx>
                                        <Colxx xxs='2' className='p-0'>
                                            <Button
                                                color='info'
                                                className='top-right-button'
                                                block
                                            >
                                                <i className='iconsminds-bell' />
                                            </Button>
                                        </Colxx>
                                        {/* <Colxx xxs='2' className='p-0'>
                                            <ButtonDropdown
                                                direction='up'
                                                isOpen={dropDown}
                                                toggle={() => {
                                                    this.setState({
                                                        dropDown:
                                                            !this.state
                                                                .dropDown,
                                                    });
                                                }}
                                            >
                                                <DropdownToggle
                                                    className='w-100'
                                                    caret
                                                    color='info'
                                                    direction='up'
                                                >
                                                    ...
                                                </DropdownToggle>
                                                <DropdownMenu
                                                    right
                                                    className='p-3 text-center'
                                                >
                                                    <Button
                                                        color='danger'
                                                        className='mb-2 mx-auto'
                                                    >
                                                        Trả món
                                                    </Button>
                                                    <Button
                                                        color='warning'
                                                        className='mb-2 mx-auto'
                                                    >
                                                        Gọi phục vụ
                                                    </Button>
                                                </DropdownMenu>
                                            </ButtonDropdown>
                                        </Colxx> */}
                                    </div>
                                </>
                            ) : (
                                <>
                                    {items.length ? (
                                        <div
                                            id='nav_bar'
                                            className='sticky mb-2'
                                        >
                                            <GlideComponentCustom
                                                settings={{
                                                    startAt: startAt,
                                                    gap: 5,
                                                    perView: 3,
                                                    type: 'slider',
                                                    breakpoints: {
                                                        850: { perView: 3 },
                                                        1200: { perView: 3 },
                                                        1900: { perView: 3 },
                                                    },
                                                    hideNav: true,
                                                    reMount: reMount,
                                                }}
                                            >
                                                {this.renderCategories()}
                                            </GlideComponentCustom>
                                        </div>
                                    ) : null}
                                    <Row className='custom-row-no-margin-tlr mr-bt-50px'>
                                        <Colxx
                                            xxs='12'
                                            md='12'
                                            xl='12'
                                            className='col-left custom-col-12-no-padding'
                                        >
                                            {items.length
                                                ? items.map(
                                                      (categories, index) => {
                                                          return categories.data
                                                              .length ? (
                                                              <React.Fragment
                                                                  key={
                                                                      categories.id
                                                                  }
                                                              >
                                                                  <div
                                                                      id={
                                                                          'item_' +
                                                                          index
                                                                      }
                                                                      className='mb-3 pt-30px custom-shadow'
                                                                  >
                                                                      <div>
                                                                          <CardTitle>
                                                                              {
                                                                                  categories.name
                                                                              }
                                                                          </CardTitle>
                                                                          {categories
                                                                              .data
                                                                              .length &&
                                                                              categories.data.map(
                                                                                  (
                                                                                      product
                                                                                  ) => {
                                                                                      return (
                                                                                          <ThumbListViewMenuGuest
                                                                                              key={
                                                                                                  product.id
                                                                                              }
                                                                                              product={
                                                                                                  product
                                                                                              }
                                                                                              order={this.getOrder(
                                                                                                  product.id
                                                                                              )}
                                                                                              sub={
                                                                                                  this
                                                                                                      .sub
                                                                                              }
                                                                                              plus={
                                                                                                  this
                                                                                                      .plus
                                                                                              }
                                                                                              change={
                                                                                                  this
                                                                                                      .change
                                                                                              }
                                                                                              isVerify={
                                                                                                  isVerify
                                                                                              }
                                                                                              onToggleDetail={
                                                                                                  this
                                                                                                      .onToggleDetail
                                                                                              }
                                                                                              detailDisplay={
                                                                                                  product.detailDisplay
                                                                                              }
                                                                                              orderDisplay={
                                                                                                  product.orderDisplay
                                                                                              }
                                                                                              sizeSelectedId={
                                                                                                  product.sizeSelectedId
                                                                                              }
                                                                                              toppingSelectedId={
                                                                                                  product.toppingSelectedId
                                                                                              }
                                                                                              toppingSelectedIdLen={
                                                                                                  product
                                                                                                      .toppingSelectedId
                                                                                                      .length
                                                                                              }
                                                                                              iceSelectedValue={
                                                                                                  product.iceSelectedValue
                                                                                              }
                                                                                              sugarSelectedValue={
                                                                                                  product.sugarSelectedValue
                                                                                              }
                                                                                              showNote={
                                                                                                  product.showNote
                                                                                              }
                                                                                          />
                                                                                      );
                                                                                  }
                                                                              )}
                                                                      </div>
                                                                  </div>
                                                              </React.Fragment>
                                                          ) : null;
                                                      }
                                                  )
                                                : null}
                                        </Colxx>{' '}
                                        <ProductOrderModalMenuGuest
                                            isOpen={modalOrderOpen}
                                            orders={orders}
                                            incrementItem={this.incrementItem}
                                            decrementItem={this.decrementItem}
                                            phone={customer_phone}
                                            name={customer_name}
                                            companyName={company.companyName}
                                            onChange={this.onChange}
                                            onToggleModal={
                                                this.toggleModalOrder
                                            }
                                            onRemoveOrder={this.onRemoveOrder}
                                            onToggleNote={this.onToggleNote}
                                            handleSubmit={this.handleSubmit}
                                            calTotalPrice={this.calTotalPrice}
                                        />
                                        <div
                                            hidden={!this.state.orders.length}
                                            className='order-btn-preview'
                                        >
                                            <Button
                                                color='primary'
                                                size='lg'
                                                className='top-right-button pl-3'
                                                onClick={this.toggleModalOrder}
                                                block
                                            >
                                                <Row>
                                                    <Colxx
                                                        xxs='5'
                                                        md='5'
                                                        xl='5'
                                                    >
                                                        <IntlMessages id='general.btn-order-preview' />{' '}
                                                    </Colxx>
                                                    <Colxx
                                                        xxs='4'
                                                        md='4'
                                                        xl='4'
                                                        className='fw-100'
                                                    >
                                                        {this.calcNumberOrder(
                                                            orders
                                                        ) + ' sản phẩm'}{' '}
                                                    </Colxx>
                                                    <Colxx
                                                        xxs='3'
                                                        md='3'
                                                        xl='3'
                                                    >
                                                        {AddCommaNumber(
                                                            this.calTotalPrice(
                                                                orders
                                                            )
                                                        )}
                                                        <sup className='fz-sup'>
                                                            đ
                                                        </sup>
                                                    </Colxx>
                                                </Row>
                                            </Button>
                                        </div>
                                    </Row>
                                </>
                            )}
                        </div>
                        {/* END CONTENT */}
                    </Fragment>
                )}
            </div>
        );
    }
}
export default ProductGuest;
