import React, { Component } from 'react';
import {
    Row,
    Col,
    Nav,
    NavItem,
    NavLink,
    TabPane,
    TabContent,
    CardBody,
    Card,
} from 'reactstrap';
import axios from 'axios';
import { END_POINT, END_POINT_SOCKET } from '../../../constants/defaultValues';
import {
    SetItemsSelected,
    NotificatioErrController,
    NotificatioSucController,
} from '../../../helpers/Utils';
import io from 'socket.io-client';
import {
    PageHeading,
    ContentFormAddExcel,
    ListItemLeftForm,
    ListItemFormMobile,
    ContentDisplay,
    CustomerApplicationMenu,
} from '../../../containers/pages/customer';
import { Add, Pagination } from '../../../containers/pages/general';
import LogsHelper from '../../../helpers/Logs';
import IntlMessages from '../../../helpers/IntlMessages';
import { isC, isR, isU, isD } from '../../../helpers/Roles';
import classNames from 'classnames';
import FooterPage from '../../../containers/pages/FooterPage';
import { Separator } from '../../../components/common/CustomBootstrap';
import FooterCustomer from '../../../containers/pages/customer/FooterCustomer';
import ContentMultiForm from '../../../containers/pages/customer/ContentMultiForm';
import AddCustomer from '../../../containers/pages/customer/AddCustomer';
import UpdateCustomer from '../../../containers/pages/customer/UpdateCustomer';
import { getCurrentDate, getCurrentTime } from '../../../helpers/Utils';

const DEFAULT_FORM = {
    id: '',
    name: '',
    phone: '',
    email: '',
    address: '',
    point: '',
    armorial: '',
    used: 0,
    debt: '',
    debt_more: '',
    debt_less: '',
    tags: '',
    dynamic_form: [],
};

const ROLE_ALIAS = 'customer';

class ContentComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            page: {
                pageSizes: [12, 24],
                selectedPageSize: 12,
                currentPage: 1,
                totalPage: 1,
                totalItem: 0,
                search: '',
            },
            selectedItems: [],
            data: [],
            data_all: [],
            data_length: 0,
            hide_button_add: false,

            data_customer: null,
            filter: null,

            data_dynamic_form: [],
            is_loading_data: true,
            data_tags: [],
            show: {
                add: false,
                add_excel: false,
                update: false,
                view: false,
                viewonly: false,
            },

            accordion: [],
            activeTab: '1',
            license: {},

            displayMode: 'thumblist',

            form: DEFAULT_FORM,
        };

        this.socket = io(END_POINT_SOCKET);
    }

    componentDidMount() {
        this.socket.emit('join room', {
            room_id: localStorage.getItem('user_id'),
        });

        this.dataListTagsRender();
        this.dataAllRender();
        this.getLicense();
    }

    getLicense = () => {
        let tokenStr = localStorage.getItem('access_token');
        axios
            .get(`${END_POINT + '/user-license'}`, {
                headers: {
                    Authorization: `Bearer ${tokenStr}`,
                },
            })
            .then((res) => {
                return res.data;
            })
            .then((data) => {
                let user_license = data.data;

                let currentDate = getCurrentDate();
                let currentTime = getCurrentTime();

                if (
                    user_license.expired_date < currentDate &&
                    user_license.upgraded === false
                ) {
                    this.setState((prev) => ({
                        license: {
                            using: false,
                        },
                    }));
                }

                if (
                    (user_license.expired_date > currentDate ||
                        (user_license.expired_date === currentDate &&
                            user_license.expired_time >= currentTime)) &&
                    user_license.purchased_package === 'pro'
                ) {
                    this.setState((prev) => ({
                        license: {
                            using: true,
                            purchase_type: 'pro',
                        },
                    }));
                }
                this.hideButtonAdd();
            })
            .catch((err) => {
                NotificatioErrController(err.response.data.responseText);
            });
    };

    hideButtonAdd = () => {
        let { license, data_length } = this.state;
        if (license.using === false && data_length > 20) {
            return this.setState({
                hide_button_add: true,
            });
        }
        if (
            license.using === true &&
            license.purchase_type === 'pro' &&
            data_length > 100
        ) {
            this.setState({
                hide_button_add: true,
            });
        }
    };

    /** Axios func helper */
    dataListRender = () => {
        const { selectedPageSize, currentPage, search } = this.state.page;

        let tokenStr = localStorage.getItem('access_token');
        axios
            .get(
                `${
                    END_POINT + '/customer/json'
                }?pageSize=${selectedPageSize}&currentPage=${currentPage}&search=${search}`,
                { headers: { Authorization: `Bearer ${tokenStr}` } }
            )
            .then((res) => res.data)
            .then((data) => {
                let anyAccordion = [];
                let numeroPerguntas = data.data.length;
                for (var i = 0; i < numeroPerguntas; i++) {
                    // if (!i) {
                    //     anyAccordion.push(true);
                    // }
                    anyAccordion.push(false);
                }
                this.setState(
                    (prev) => ({
                        ...prev,
                        data: data.data,
                        data_dynamic_form: data.dataDynamicForm,
                        is_loading_data: false,
                        page: {
                            ...prev.page,
                            totalPage: data.totalPage,
                            totalItem: data.totalItem,
                        },
                        accordion: anyAccordion,
                    }),
                    () => {
                        if (this.state.form.id) {
                            const {
                                id,
                                name,
                                phone,
                                email,
                                address,
                                point,
                                armorial,
                                used,
                                debt,
                                tags,
                                dynamic_form,
                            } = this.state.form;
                            this.setState((prev) => ({
                                ...prev,
                                form: {
                                    ...prev.form,
                                    id,
                                    name,
                                    phone,
                                    email,
                                    address,
                                    point,
                                    armorial,
                                    used,
                                    debt,
                                    tags,
                                    dynamic_form,
                                },
                            }));
                        } else {
                            this.setState((prev) => ({
                                ...prev,
                                selectedItems: [],
                                show: {
                                    ...prev.show,
                                    update: false,
                                },
                                form: DEFAULT_FORM,
                            }));
                        }
                    }
                );
            })
            .catch((err) => {
                NotificatioErrController(err.response.data.responseText);
                return false;
            });
    };

    dataListTagsRender = () => {
        let tokenStr = localStorage.getItem('access_token');
        axios
            .get(`${END_POINT + '/customer/tags'}`, {
                headers: { Authorization: `Bearer ${tokenStr}` },
            })
            .then((res) => res.data)
            .then((data) => {
                this.setState(
                    (prev) => ({
                        ...prev,
                        data_tags: data.data,
                    }),
                    () => this.dataListRender()
                );
            })
            .catch((err) => {
                NotificatioErrController(err.response.data.responseText);
                return false;
            });
    };

    dataAllRender = () => {
        let tokenStr = localStorage.getItem('access_token');
        axios
            .get(`${END_POINT + '/customer'}`, {
                headers: { Authorization: `Bearer ${tokenStr}` },
            })
            .then((res) => res.data)
            .then((data) => {
                this.setState((prev) => ({
                    ...prev,
                    data_all: data.data,
                    data_length: data.data.length,
                }));
            })
            .catch((err) => {
                NotificatioErrController(err.response.data.responseText);
                return false;
            });
    };

    onHandlerDelete = () => {
        if (!isD(ROLE_ALIAS)) return false;

        let tokenStr = localStorage.getItem('access_token');
        axios
            .delete(`${END_POINT + '/customer'}`, {
                data: {
                    arrayId: this.state.selectedItems,
                },
                headers: { Authorization: `Bearer ${tokenStr}` },
            })
            .then((res) => res.data)
            .then((data) => {
                NotificatioSucController('Thành công');

                this.socket.emit('create logs', {
                    user_id: localStorage.getItem('user_id'),
                    message: LogsHelper['customer-delete'],
                });

                this.dataListTagsRender();
                this.dataAllRender();
            })
            .catch((err) => {
                NotificatioErrController(err.response.data.responseText);
                return false;
            });
    };

    onHandlerUpdate = (e) => {
        if (!isU(ROLE_ALIAS)) return false;
        e.preventDefault();

        const {
            id,
            name,
            phone,
            email,
            address,
            point,
            armorial,
            used,
            debt,
            debt_more,
            debt_less,
            tags,
            dynamic_form,
        } = this.state.form;

        if (!name || !id) {
            NotificatioErrController('Dữ liệu không hợp lệ');
            return;
        }

        let tokenStr = localStorage.getItem('access_token');
        axios
            .put(
                `${END_POINT + '/customer'}`,
                {
                    id,
                    name,
                    phone,
                    email,
                    address,
                    point,
                    armorial,
                    used,
                    debt:
                        debt_less > 0 && debt_more > 0
                            ? (
                                  parseFloat(debt.toString().replace(/\./g, '')) -
                                  parseFloat(
                                      debt_less.toString().replace(/\./g, '')
                                  ) +
                                  parseFloat(
                                      debt_more.toString().replace(/\./g, '')
                                  )
                              ).toString()
                            : debt_less > 0
                            ? (
                                  parseFloat(debt.toString().replace(/\./g, '')) -
                                  parseFloat(
                                      debt_less.toString().replace(/\./g, '')
                                  )
                              ).toString()
                            : debt_more > 0
                            ? (
                                  parseFloat(debt.toString().replace(/\./g, '')) +
                                  parseFloat(
                                      debt_more.toString().replace(/\./g, '')
                                  )
                              ).toString()
                            : debt.toString().replace(/\./g, ''),
                    tags,
                    dynamic_form,
                },
                {
                    headers: { Authorization: `Bearer ${tokenStr}` },
                }
            )
            .then((res) => res.data)
            .then((data) => {
                NotificatioSucController('Thành công');

                this.socket.emit('create logs', {
                    user_id: localStorage.getItem('user_id'),
                    message: LogsHelper['customer-update'],
                });

                if (debt_more > 0 || debt_less > 0) {
                    this.socket.emit('create logs', {
                        user_id: localStorage.getItem('user_id'),
                        message: LogsHelper['customer-debt-update'],
                    });
                }

                this.dataListTagsRender();
                this.dataAllRender();
                this.toggle('update');
                this.setState((prev) => ({
                    ...prev,
                    form: {
                        ...prev.form,
                        debt_less: '',
                        debt_more: '',
                    },
                }));
            })
            .catch((err) => {
                NotificatioErrController(err.response.data.responseText);
                return false;
            });
    };

    onHandlerAdd = (e) => {
        if (!isC(ROLE_ALIAS)) return false;

        // console.log(this.state.form);
        e.preventDefault();
        const {
            name,
            phone,
            email,
            address,
            point,
            armorial,
            used,
            debt,
            tags,
            dynamic_form,
        } = this.state.form;

        if (!name) {
            NotificatioErrController('Tên khách hàng cần được thêm vào');
            return;
        }
        if (!phone) {
            NotificatioErrController('Số điện thoại cần được thêm vào');
            return;
        }

        // console.log('data_all: ', this.state.data_all);

        // if (this.state.data_all.length > 20) {
        //     return NotificatioErrController(
        //         'Gói bạn đang dùng đã đạt giới hạn thêm khách hàng'
        //     );
        // }

        let tokenStr = localStorage.getItem('access_token');
        axios
            .post(
                `${END_POINT + '/customer'}`,
                {
                    name,
                    phone,
                    email,
                    address,
                    point,
                    armorial,
                    used,
                    debt,
                    tags,
                    dynamic_form,
                },
                {
                    headers: { Authorization: `Bearer ${tokenStr}` },
                }
            )
            .then((res) => res.data)
            .then((data) => {
                NotificatioSucController('Thành công');

                this.socket.emit('create logs', {
                    user_id: localStorage.getItem('user_id'),
                    message: LogsHelper['customer-create'],
                });

                this.dataListTagsRender();
                this.dataAllRender();
                // this.toggle('add');
                this.setState((prev) => ({
                    ...prev,
                    form: {
                        ...prev.form,
                        form: DEFAULT_FORM,
                    },
                }));
            })
            .catch((err) => {
                NotificatioErrController(err.response.data.responseText);
                return false;
            });
    };

    dataAllProductsRender = () => {
        let tokenStr = localStorage.getItem('access_token');
        axios
            .get(`${END_POINT + '/product/all'}`, {
                headers: { Authorization: `Bearer ${tokenStr}` },
            })
            .then((res) => res.data)
            .then((data) => {
                this.setState((prev) => ({
                    ...prev,
                    data_products: data.data,
                }));
            })
            .catch((err) => {
                NotificatioErrController(err.response.data.responseText);
                return false;
            });
    };

    dataAllOrdersRender = () => {
        let tokenStr = localStorage.getItem('access_token');
        axios
            .get(`${END_POINT + '/order/all'}`, {
                headers: { Authorization: `Bearer ${tokenStr}` },
            })
            .then((res) => res.data)
            .then((data) => {
                this.setState((prev) => ({
                    ...prev,
                    data_orders: data.data,
                }));
            })
            .catch((err) => {
                NotificatioErrController(err.response.data.responseText);
                return false;
            });
    };

    // onHandlerAddExcel = () => {
    //     // if (!isC(ROLE_ALIAS)) return false;

    //     // // console.log(this.state.form);

    //     // const {
    //     //     name,
    //     //     phone,
    //     //     email,
    //     //     address,
    //     //     point,
    //     //     armorial,
    //     //     used,
    //     //     tags,
    //     //     dynamic_form,
    //     // } = this.state.form;

    //     // if (!name) {
    //     //     NotificatioErrController('Tên khách hàng cần được thêm vào');
    //     //     return;
    //     // }
    //     // if (!phone) {
    //     //     NotificatioErrController('Số điện thoại cần được thêm vào');
    //     //     return;
    //     // }

    //     let tokenStr = localStorage.getItem('access_token');
    //     axios
    //         .post(
    //             `${END_POINT + '/customer/create-from-excel'}`,
    //             {
    //                 name,
    //                 phone,
    //                 email,
    //                 address,
    //                 point,
    //                 armorial,
    //                 used,
    //                 tags,
    //                 dynamic_form,
    //             },
    //             {
    //                 headers: { Authorization: `Bearer ${tokenStr}` },
    //             }
    //         )
    //         .then((res) => res.data)
    //         .then((data) => {
    //             NotificatioSucController('Thành công');

    //             this.socket.emit('create logs', {
    //                 user_id: localStorage.getItem('user_id'),
    //                 message: LogsHelper['customer-create'],
    //             });

    //             this.dataListTagsRender();
    //             // this.toggle('add');
    //         })
    //         .catch((err) => {
    //             NotificatioErrController(err.response.data.responseText);
    //             return false;
    //         });
    // };
    /** Axios func helper */

    /** State func helper */
    toggle = (name) => {
        if (name === 'add' && !isC(ROLE_ALIAS)) return false;
        if (name === 'add-excel' && !isC(ROLE_ALIAS)) return false;
        if (name === 'update' && !isU(ROLE_ALIAS)) return false;

        this.setState((prev) => ({
            ...prev,
            show: {
                ...prev.show,
                add_excel: name === 'add' ? false : prev.show.add_excel,
                update: name === 'add' ? false : prev.show.update,
                view: name === 'add' ? false : prev.show.view,
                [name]: !prev.show[name],
            },
            selectedItems: name === 'add' ? [] : prev.selectedItems,
            form: name === 'add' ? DEFAULT_FORM : prev.form,
        }));
    };

    onChangeForm = (e, _name) => {
        if (e.target) {
            let {
                target: { name, value },
            } = e;
            this.setState((prev) => ({
                ...prev,
                form: {
                    ...prev.form,
                    [name]: value,
                },
            }));
        } else {
            this.setState((prev) => ({
                ...prev,
                form: {
                    ...prev.form,
                    [_name]: e,
                },
            }));
        }
    };
    onChangeDynamicForm = (e, _name) => {
        if (e.target) {
            let {
                target: { name, value },
            } = e;

            this.setState((prev) => ({
                ...prev,
                form: {
                    ...prev.form,
                    dynamic_form: {
                        ...prev.form.dynamic_form,
                        [name]: value,
                    },
                },
            }));
        } else {
            let value = e.value ? e.value : e;

            this.setState((prev) => ({
                ...prev,
                form: {
                    ...prev.form,
                    dynamic_form: {
                        ...prev.form.dynamic_form,
                        [_name]: value,
                    },
                },
            }));
        }
    };

    onSubmitForm = (type) => {
        if (type === 'update') {
            this.onHandlerUpdate();
        } else if (type === 'add') {
            this.onHandlerAdd();
        } else if (type === 'add_excel') {
            // this.onHandlerAddExcel();
        }
    };

    onChangePage = (page) => {
        this.setState(
            (prev) => ({
                ...prev,
                page: {
                    ...prev.page,
                    currentPage: page,
                },
            }),
            () => this.dataListRender()
        );
    };

    onSearchKey = (e) => {
        if (!e) return false;

        if (e.target) {
            let {
                target: { value },
            } = e;
            let v = value;
            if (value === undefined) {
                v = e.target.firstElementChild.value; // parent to child

                value = v.toLowerCase();
                this.setState(
                    (prev) => ({
                        ...prev,
                        page: {
                            ...prev.page,
                            search: value,
                        },
                    }),
                    () => this.dataListRender()
                );
            } else {
                value = value.toLowerCase();
                this.setState(
                    (prev) => ({
                        ...prev,
                        page: {
                            ...prev.page,
                            search: value,
                        },
                    }),
                    () => this.dataListRender()
                );
            }
        }

        if (e.key === 'Enter') {
            let value = e.target.value.toLowerCase();
            this.setState(
                (prev) => ({
                    ...prev,
                    page: {
                        ...prev.page,
                        search: value,
                    },
                }),
                () => this.dataListRender()
            );
        }
    };

    onChangePageSize = (size) => {
        this.setState(
            (prev) => ({
                ...prev,
                page: {
                    ...prev.page,
                    selectedPageSize: size,
                    currentPage: 1,
                },
            }),
            () => this.dataListRender()
        );
    };

    onClickedItem = (item, callback, args) => {
        if (this.state.form.id !== item.id) {
            const {
                id,
                name,
                phone,
                email,
                address,
                point,
                armorial,
                used,
                debt,
                tags,
                dynamic_form,
            } = item;

            this.setState(
                (prev) => ({
                    ...prev,
                    show: {
                        ...prev.show,
                        update: false,
                        view: true,
                        viewonly: false,
                    },
                    form: {
                        ...prev.form,
                        id,
                        name: name ? name : '',
                        phone,
                        email: email ? email : '',
                        address: address ? address : '',
                        point,
                        armorial,
                        used,
                        debt,
                        tags,
                        dynamic_form,
                    },
                    selectedItems: [item.id],
                }),
                () => {
                    callback(args[0], args[1], args[2]);
                }
            );
            this.dataAllOrdersRender();
            this.dataAllProductsRender();
        }
    };

    onClickedUpdate = (item) => {
        const {
            id,
            name,
            phone,
            email,
            address,
            point,
            armorial,
            used,
            debt,
            tags,
            dynamic_form,
        } = item;

        this.setState((prev) => ({
            ...prev,
            show: {
                ...prev.show,
                update: true,
            },
            form: {
                ...prev.form,
                id,
                name,
                phone,
                email,
                address,
                point,
                armorial,
                used,
                debt,
                tags,
                dynamic_form,
            },
        }));
    };

    onClickedDelete = (item) => {
        let selectedSet = SetItemsSelected(
            item.id,
            this.state.selectedItems,
            'require'
        );
        if (window.confirm('Bạn chắc chắn muốn xoá khách hàng này?')) {
            this.setState(
                (prev) => ({
                    ...prev,
                    selectedItems: selectedSet,
                }),
                () => this.onHandlerDelete()
            );
        }
    };

    onClickedCheckbox = (id, callback) => {
        // let selectedSet = SetItemsSelected(id, this.state.selectedItems);

        this.setState((prev) => ({
            ...prev,
            selectedItems: [id],
        }));
    };

    toggleAccordion = (tab) => {
        const prevState = this.state.accordion;
        const state = prevState.map((x, index) => (tab === index ? !x : false));
        this.setState({
            accordion: state,
        });
    };

    toggleTab = (tab) => {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab,
            });
        }
    };

    filterData = (key, value) => {
        const { data_all, data_tags } = this.state;
        // console.log('value: ', value);
        if (key === 'email' && value === 'none-empty') {
            const filteredEmail = data_all.filter((item) => {
                return item[key] !== '' && item[key] !== null;
            });
            // console.log(filteredEmail);
            this.setState((prev) => ({
                ...prev,
                data: filteredEmail,
                filter: { column: key, value },
            }));
        } else if (key === 'email' && value === '') {
            const filteredEmail = data_all.filter((item) => {
                return item[key] === '' || item[key] === null;
            });
            // console.log(filteredEmail);
            this.setState((prev) => ({
                ...prev,
                data: filteredEmail,
                filter: { column: key, value },
            }));
        } else if (key === 'tags') {
            // Lọc data_tags để lấy ra các customer_id có label tương ứng
            const filteredCustomerIds = data_tags
                .filter((tag) => tag.label === value)
                .map((tag) => tag.customer_id);
            // console.log('filteredCustomerIds: ', filteredCustomerIds);

            // Lọc customers để lấy ra các khách hàng có customer_id trong danh sách filteredCustomerIds
            const filteredCustomers = data_all.filter((customer) =>
                filteredCustomerIds.includes(customer.id)
            );

            // Hiển thị thông tin khách hàng hoặc thực hiện các công việc khác với mảng filteredCustomers
            // console.log('filteredCustomers: ', filteredCustomers);
            // Hoặc cập nhật state để hiển thị trên giao diện, tùy thuộc vào cách bạn quản lý trạng thái của ứng dụng

            // console.log(filteredTags);
            this.setState((prev) => ({
                ...prev,
                data: filteredCustomers,
                filter: { column: key, value },
            }));
        } else if (key === 'debt' && value === 'have_debt') {
            const filteredEmail = data_all.filter((item) => {
                return Number(item[key]) !== 0;
            });
            this.setState((prev) => ({
                ...prev,
                data: filteredEmail,
                filter: { column: key, value },
            }));
        } else if (key === 'debt' && value === '') {
            const filteredEmail = data_all.filter((item) => {
                return Number(item[key]) === 0;
            });
            this.setState((prev) => ({
                ...prev,
                data: filteredEmail,
                filter: { column: key, value },
            }));
        } else if (key === '' && value === '') {
            this.setState((prev) => ({
                ...prev,
                data: data_all,
                filter: null,
            }));
        } else {
            const filteredItems = data_all.filter(
                (item) => item[key] === value
            );
            // console.log(filteredItems);
            this.setState((prev) => ({
                ...prev,
                data: filteredItems,
                filter: { column: key, value },
            }));
        }
    };

    // ChatGPT
    // filterData = (key, value) => {
    //     const { data_all } = this.state;
    //     let filteredData = data_all;

    //     if (key === 'email') {
    //       if (value === 'none-empty') {
    //         filteredData = data_all.filter(item => item[key] !== '' && item[key] !== null);
    //       } else if (value === '') {
    //         filteredData = data_all.filter(item => item[key] === '' || item[key] === null);
    //       }
    //     } else if (key && value) {
    //       filteredData = data_all.filter(item => item[key] === value);
    //     }

    //     this.setState(({ filter }) => ({
    //       data: filteredData,
    //       filter: key && value ? { column: key, value } : null,
    //     }));
    //   };

    changeDisplayMode = (mode) => {
        this.setState((prev) => ({
            ...prev,
            displayMode: mode,
            show: {
                ...prev.show,
                view: false,
            },
        }));
        return false;
    };

    render() {
        const { match } = this.props;
        const {
            hide_button_add,
            page,
            selectedItems,
            data,
            data_all,
            data_tags,
            data_products,
            data_orders,
            is_loading_data,
            show,
            form,
            accordion,
            filter,
            displayMode,

            // Dynamic Form
            data_dynamic_form,
        } = this.state;

        if (!isR(ROLE_ALIAS))
            return (
                <Card className='text-muted mh-40vh h-100'>
                    <CardBody className='d-flex justify-content-center align-items-center'>
                        Bạn không có quyền sử dụng tính năng này!
                    </CardBody>
                </Card>
            );

        return is_loading_data ? (
            <div className='loading' />
        ) : (
            <div
                // className={classnames(
                //     'disable-text-selection',
                //     displayMode === 'thumblist' ? 'app-row' : ''
                // )}
                className='app-row disable-text-selection'
            >
                <PageHeading
                    heading='analytics.customer'
                    hide_button_add={hide_button_add}
                    setting={page}
                    match={match}
                    totalItem={page.totalItem}
                    isDisabledAdd={!isC(ROLE_ALIAS)}
                    toggleFunc={() => {
                        this.toggle('add');
                        this.toggleAccordion();
                    }}
                    toggleAddExcel={() => this.toggle('add_excel')}
                    changePageSizeFunc={this.onChangePageSize}
                    onSearchKeyFunc={this.onSearchKey}
                    // data={data}
                    // data_all={data_all}
                    displayMode={displayMode}
                    changeDisplayMode={this.changeDisplayMode}
                />
                <AddCustomer
                    title={<IntlMessages id='customer.add-new-modal-title' />}
                    isOpen={show.add}
                    toggle={() => {
                        this.toggle('add');
                    }}
                    data_dynamic_form={data_dynamic_form}
                    data_tags={data_tags}
                    values={form}
                    setting={show}
                    onChange={this.onChangeForm}
                    onChangeDynamicForm={this.onChangeDynamicForm}
                    onSubmit={this.onHandlerAdd}
                >
                    {/* <ContentForm
                    /> */}
                </AddCustomer>
                <Add
                    title={<IntlMessages id='customer.add-new-modal-title' />}
                    isOpen={show.add_excel}
                    toggle={() => {
                        this.toggle('add_excel');
                    }}
                    onSubmit={this.onHandlerAddExcel}
                >
                    <ContentFormAddExcel
                    // data_dynamic_form={data_dynamic_form}
                    // data_tags={data_tags}
                    // values={form}
                    // setting={show}
                    // onChange={this.onChangeForm}
                    // onChangeDynamicForm={this.onChangeDynamicForm}
                    />
                </Add>
                <UpdateCustomer
                    title={<IntlMessages id='customer.title' />}
                    onSubmit={this.onHandlerUpdate}
                    isOpen={show.update}
                    toggle={() => {
                        this.toggle('update');
                    }}
                    data_dynamic_form={data_dynamic_form}
                    data_tags={data_tags}
                    values={form}
                    setting={show}
                    onChange={this.onChangeForm}
                    onChangeDynamicForm={this.onChangeDynamicForm}
                />
                {data.length === 0 ? (
                    <Card className='mt-4 mb-4 mh-53vh'>
                        <CardBody>Chưa có dữ liệu...</CardBody>
                    </Card>
                ) : (
                    <>
                        <Nav
                            tabs
                            className='d-flex justify-content-between separator-tabs ml-0 mb-5'
                        >
                            <NavItem>
                                <NavLink
                                    className={classNames({
                                        active: this.state.activeTab === '1',
                                        'nav-link': true,
                                    })}
                                    onClick={() => this.toggleTab('1')}
                                    location={{}}
                                    to='#'
                                >
                                    <IntlMessages id='general.info' />
                                </NavLink>
                            </NavItem>
                            {/* <NavItem>
                        <NavLink
                            className={classNames({
                                active: this.state.activeTab === '2',
                                'nav-link': true,
                            })}
                            onClick={() => this.toggleTab('2')}
                            location={{}}
                            to='#'
                        >
                            <span className='mr-2'>
                                <i className='simple-icon-settings'></i>
                            </span>
                            <IntlMessages id='general.setting' />
                        </NavLink>
                    </NavItem> */}
                        </Nav>
                        <TabContent
                            activeTab={this.state.activeTab}
                            className='mh-53vh'
                        >
                            <TabPane tabId='1'>
                                <Row className='content-page__row d-none d-md-flex'>
                                    <Col
                                        xs='12'
                                        sm='12'
                                        md='12'
                                        lg={
                                            show.view &&
                                            displayMode === 'thumblist'
                                                ? 6
                                                : 12
                                        }
                                        xl={
                                            show.view &&
                                            displayMode === 'thumblist'
                                                ? 6
                                                : 12
                                        }
                                    >
                                        {data?.length ? (
                                            displayMode === 'thumblist' ? (
                                                <>
                                                    {data?.map((item, idx) => (
                                                        <ListItemLeftForm
                                                            onClickedItem={
                                                                this
                                                                    .onClickedItem
                                                            }
                                                            onUpdate={
                                                                this
                                                                    .onClickedUpdate
                                                            }
                                                            onDelete={
                                                                this
                                                                    .onClickedDelete
                                                            }
                                                            onChangeCheckbox={
                                                                this
                                                                    .onClickedCheckbox
                                                            }
                                                            isDisabledUpdate={
                                                                !isU(ROLE_ALIAS)
                                                            }
                                                            isDisabledDelete={
                                                                !isD(ROLE_ALIAS)
                                                            }
                                                            selectedItems={
                                                                selectedItems
                                                            }
                                                            key={idx}
                                                            idx={idx}
                                                            item={item}
                                                        />
                                                    ))}
                                                    <Pagination
                                                        currentPage={
                                                            page.currentPage
                                                        }
                                                        totalPage={
                                                            page.totalPage
                                                        }
                                                        onChangePage={(i) =>
                                                            this.onChangePage(i)
                                                        }
                                                    />
                                                </>
                                            ) : (
                                                <ContentMultiForm
                                                    data={data_all}
                                                    data_tags={data_tags}
                                                />
                                            )
                                        ) : (
                                            <Card className='d-flex justify-content-center align-items-center'>
                                                <CardBody>
                                                    Không có dữ liệu khách hàng
                                                    nào phù hợp với lựa chọn của
                                                    bạn.
                                                </CardBody>
                                            </Card>
                                        )}
                                    </Col>
                                    {show.view ? (
                                        displayMode === 'thumblist' ? (
                                            <Col
                                                xs='12'
                                                sm='12'
                                                md='12'
                                                lg='6'
                                                xl='6'
                                            >
                                                <ContentDisplay
                                                    // data_dynamic_form={data_dynamic_form}
                                                    // data_tags={data_tags}
                                                    values={form}
                                                    data_products={
                                                        data_products
                                                    }
                                                    data_orders={data_orders}
                                                    dataAllProductsRender={
                                                        this
                                                            .dataAllProductsRender
                                                    }
                                                    dataAllOrdersRender={
                                                        this.dataAllOrdersRender
                                                    }
                                                />
                                            </Col>
                                        ) : null
                                    ) : null}
                                </Row>
                                <Row className='content-page__row d-block d-md-none mb-4'>
                                    <Col>
                                        {data?.length ? (
                                            data?.map((item, idx) => {
                                                // console.log(item);
                                                return (
                                                    <div
                                                        className='d-flex mb-3 card'
                                                        key={item.id}
                                                    >
                                                        <ListItemFormMobile
                                                            onClickedItem={
                                                                this
                                                                    .onClickedItem
                                                            }
                                                            onUpdate={
                                                                this
                                                                    .onClickedUpdate
                                                            }
                                                            onDelete={
                                                                this
                                                                    .onClickedDelete
                                                            }
                                                            onChangeCheckbox={
                                                                this
                                                                    .onClickedCheckbox
                                                            }
                                                            isDisabledUpdate={
                                                                !isU(ROLE_ALIAS)
                                                            }
                                                            isDisabledDelete={
                                                                !isD(ROLE_ALIAS)
                                                            }
                                                            selectedItems={
                                                                selectedItems
                                                            }
                                                            key={idx}
                                                            idx={idx}
                                                            item={item}
                                                            form={form}
                                                            accordion={
                                                                accordion
                                                            }
                                                            toggleAccordion={
                                                                this
                                                                    .toggleAccordion
                                                            }
                                                            data_tags={
                                                                data_tags
                                                            }
                                                        />
                                                    </div>
                                                );
                                            })
                                        ) : (
                                            <Card className='d-flex justify-content-center align-items-center'>
                                                <CardBody>
                                                    Không có dữ liệu khách hàng
                                                    nào phù hợp với lựa chọn của
                                                    bạn.
                                                </CardBody>
                                            </Card>
                                        )}
                                        {/* {data?.length < 12 ? null : ( */}
                                        <Pagination
                                            currentPage={page.currentPage}
                                            totalPage={page.totalPage}
                                            onChangePage={(i) =>
                                                this.onChangePage(i)
                                            }
                                        />
                                        {/* )} */}
                                    </Col>
                                </Row>
                            </TabPane>
                            {/* <TabPane tabId='2'>
                        Setting />
                        <ContentMultiForm />
                    </TabPane> */}
                        </TabContent>
                    </>
                )}
                <CustomerApplicationMenu
                    data={data_all}
                    data_tags={data_tags}
                    filter={filter}
                    filterData={(key, value) => this.filterData(key, value)}
                />
                <Separator />
                <FooterPage>
                    <FooterCustomer />
                </FooterPage>
            </div>
        );
    }
}

export default ContentComponent;
