import React from 'react';
import { Label, Row } from 'reactstrap';
import { Colxx, Separator } from '../../../components/common/CustomBootstrap';
import IntlMessages from '../../../helpers/IntlMessages';
import {
    GetDateGetSalary,
    GetHourWorkingOnDay,
    GetSalaryBy,
    GetStatusWorking,
} from '../../../helpers/UserStaff';
import { AddCommaNumber } from '../../../helpers/Utils';

const ContentDisplayMobile = (props) => {
    // console.log(props.values);
    return (
        <>
            {props.is_loading_data_detail ? (
                <div className='loading' />
            ) : (
                <>
                    <Row>
                        <Colxx xxs='6' sm='6' lg='6'>
                            <p className='text-muted text-small mb-2'>
                                <IntlMessages id='user-staff.name' />
                            </p>
                            <p className='text-capitalize'>
                                {props.values.name ? (
                                    props.values.name
                                ) : (
                                    <span className='text-lowercase'>
                                        (Chưa có thông tin)
                                    </span>
                                )}
                            </p>
                        </Colxx>
                        <Colxx xxs='6' sm='6' lg='6'>
                            <p className='text-muted text-small mb-2'>
                                <IntlMessages id='user-staff.permission_name' />
                            </p>
                            <p className='text-capitalize'>
                                {props.values.permission_name ? (
                                    props.values.permission_name
                                ) : (
                                    <span className='text-lowercase'>
                                        (Chưa có thông tin)
                                    </span>
                                )}
                            </p>
                        </Colxx>
                    </Row>
                    <Row>
                        <Colxx xxs='6' sm='6' lg='6'>
                            <p className='text-muted text-small mb-2'>
                                <IntlMessages id='phone' />
                            </p>
                            <p className='text-capitalize'>
                                {props.values.phone ? (
                                    props.values.phone
                                ) : (
                                    <span className='text-lowercase'>
                                        (Chưa có thông tin)
                                    </span>
                                )}
                            </p>
                        </Colxx>
                        <Colxx xxs='6' sm='6' lg='6'>
                            <p className='text-muted text-small mb-2'>
                                <IntlMessages id='email' />
                            </p>
                            <p className='text-lowercase'>
                                {props.values.email ? (
                                    props.values.email
                                ) : (
                                    <span className='text-lowercase'>
                                        (Chưa có thông tin)
                                    </span>
                                )}
                            </p>
                        </Colxx>
                    </Row>
                    <Row>
                        <Colxx xxs='12' sm='12' lg='12'>
                            <p className='text-muted text-small mb-2'>
                                <IntlMessages id='address' />
                            </p>
                            <p className='text-capitalize'>
                                {props.values.address ? (
                                    props.values.address
                                ) : (
                                    <span className='text-lowercase'>
                                        (Chưa có thông tin)
                                    </span>
                                )}
                            </p>
                        </Colxx>
                    </Row>
                    <Separator className='mb-4' />
                    <Row>
                        <Colxx xxs='6' sm='6' lg='6'>
                            <p className='text-muted text-small mb-2'>
                                <IntlMessages id='user-staff.date_join' />
                            </p>
                            {props.setting?.add ? null : (
                                <p>{props.values?.date_join}</p>
                            )}
                        </Colxx>
                        <Colxx xxs='6' sm='6' lg='6'>
                            <p className='text-muted text-small mb-2'>
                                <IntlMessages id='user-staff.date_get_salary' />
                            </p>
                            <p>
                                {
                                    GetDateGetSalary(
                                        props.values.date_get_salary
                                    ).value
                                }
                            </p>
                        </Colxx>
                    </Row>
                    <Row>
                        <Colxx xxs='6' sm='6' lg='6'>
                            <p className='text-muted text-small mb-2'>
                                <IntlMessages id='user-staff.hour_working_on_day' />
                            </p>
                            <p>
                                {
                                    GetHourWorkingOnDay(
                                        props.values.hour_working_on_day
                                    ).value
                                }
                            </p>
                        </Colxx>
                        <Colxx xxs='6' sm='6' lg='6'>
                            <p className='text-muted text-small mb-2'>
                                <IntlMessages id='user-staff.status_working' />
                            </p>
                            <p className='mb-2 text-capitalize'>
                                {
                                    GetStatusWorking(
                                        props.values.status_working
                                    ).label
                                }
                            </p>
                        </Colxx>
                    </Row>
                    <Row>
                        <Colxx xxs='6' sm='6' lg='6'>
                            <p className='text-muted text-small mb-2'>
                                <IntlMessages id='user-staff.salary_by' />
                            </p>
                            <p className='mb-2 text-capitalize'>
                                {GetSalaryBy(props.values.salary_by).label}
                            </p>
                        </Colxx>
                        <Colxx xxs='6' sm='6' lg='6'>
                            <p className='text-muted text-small mb-2'>
                                <IntlMessages id='user-staff.salary_value' />
                            </p>
                            <p>
                                {AddCommaNumber(props.values.salary_value) +
                                    ' / ' +
                                    GetSalaryBy(props.values.salary_by).label}
                            </p>
                        </Colxx>
                    </Row>
                    <Separator className='mb-4' />
                    <Row></Row>
                    <Row>
                        <Colxx xxs='6' sm='6' lg='6'>
                            <p className='text-muted text-small mb-2'>
                                <IntlMessages id='user-staff.current_commission' />
                            </p>
                            <p>
                                {AddCommaNumber(
                                    props.values.current_commission
                                )}
                            </p>
                        </Colxx>
                        <Colxx xxs='6' sm='6' lg='6'>
                            <p className='text-muted text-small mb-2'>
                                <IntlMessages id='user-staff.current_advence' />
                            </p>
                            <p>
                                {AddCommaNumber(props.values.current_advence)}
                            </p>
                        </Colxx>
                    </Row>
                    <Row>
                        <Colxx xxs='6' sm='6' lg='6'>
                            <p className='text-muted text-small mb-2'>
                                <IntlMessages id='user-staff.current_bonus' />
                            </p>
                            <p>{AddCommaNumber(props.values.current_bonus)}</p>
                        </Colxx>
                        <Colxx xxs='6' sm='6' lg='6'>
                            <p className='text-muted text-small mb-2'>
                                <IntlMessages id='user-staff.current_fined' />
                            </p>
                            <p>{AddCommaNumber(props.values.current_fined)}</p>
                        </Colxx>
                    </Row>
                    <Row>
                        <Colxx xxs='6' sm='6' lg='6'>
                            <p className='text-muted text-small mb-2'>
                                <IntlMessages id='user-staff.current_overtime' />
                            </p>
                            <p>
                                {AddCommaNumber(props.values.current_overtime)}
                            </p>
                        </Colxx>
                        <Colxx xxs='6' sm='6' lg='6'>
                            <p className='text-muted text-small mb-2'>
                                <IntlMessages id='user-staff.current_dayoff' />
                            </p>
                            <p>{AddCommaNumber(props.values.current_dayoff)}</p>
                        </Colxx>
                    </Row>
                    <Separator className='mb-4' />
                    <Row>
                        <Colxx xxs='6' sm='6' lg='6'>
                            <p className='text-muted text-small mb-2'>
                                <IntlMessages id='user-staff.current_day_working' />
                            </p>
                            <p>
                                {AddCommaNumber(
                                    props.values.current_day_working
                                )}
                            </p>
                        </Colxx>
                        <Colxx xxs='6' sm='6' lg='6'>
                            <p className='text-muted text-small mb-2'>
                                <IntlMessages id='user-staff.current_salary' />
                            </p>
                            <p>{AddCommaNumber(props.values.current_salary)}</p>
                        </Colxx>
                    </Row>
                    <Separator className='mb-4' />
                    <Row>
                        <Colxx xxs='6' sm='6' lg='6'>
                            <p className='text-muted text-small mb-2'>
                                <IntlMessages id='user-staff.total_day_working' />
                            </p>
                            <p>
                                {AddCommaNumber(props.values.total_day_working)}
                            </p>
                        </Colxx>
                        <Colxx xxs='6' sm='6' lg='6'>
                            <p className='text-muted text-small mb-2'>
                                <IntlMessages id='user-staff.total_salary' />
                            </p>
                            <p>{AddCommaNumber(props.values.total_salary)}</p>
                        </Colxx>
                    </Row>
                </>
            )}
        </>
    );
};

export default ContentDisplayMobile;
