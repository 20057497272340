import React, { memo } from 'react';
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Input,
    Label,
    Form,
    FormGroup,
    Badge,
    Row,
} from 'reactstrap';
import IntlMessages from '../../../helpers/IntlMessages';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { AddCommaNumber } from '../../../helpers/Utils';
import { Colxx } from '../../../components/common/CustomBootstrap';

const ContentComponent = ({
    title,
    isOpen,
    toggle,
    onSubmit,
    values,
    sku,
    data,
    setting,
    onChange,
}) => {
    // console.log('render AddComponent');
    // console.log('values: ', values)
    const findAvaragePrice = data?.find((d) => d.id === values.id);
    return (
        <Modal
            isOpen={isOpen}
            toggle={toggle}
            wrapClassName='modal-right'
            backdrop={true}
            autoFocus={false}
        >
            <Form onSubmit={(e) => onSubmit(e)}>
                <ModalHeader toggle={toggle}>{title}</ModalHeader>
                <ModalBody className='scrool modal-right-add'>
                    <PerfectScrollbar
                        options={{
                            suppressScrollX: true,
                            wheelPropagation: false,
                        }}
                        id='modal-right__body'
                    >
                        <Row>
                            <Colxx xxs='8'>
                                <FormGroup className='mt-3 has-float-label'>
                                    <Label for='name' className='text-muted'>
                                        <IntlMessages id='stock.name' /><span style={{color: 'red'}}>*</span>
                                    </Label>
                                    <Input
                                        type='text'
                                        name='name'
                                        id='name'
                                        value={values.name}
                                        disabled={true}
                                    />
                                </FormGroup>
                            </Colxx>
                            <Colxx xxs='4' className='pl-0'>
                                <FormGroup className='mt-3 has-float-label'>
                                    <Label for='name' className='text-muted'>
                                        Mã SKU
                                    </Label>
                                    <Input
                                        disabled
                                        type='text'
                                        name='sku'
                                        id='sku'
                                        value={sku}
                                    />
                                </FormGroup>
                            </Colxx>
                        </Row>
                        <FormGroup>
                            <Label for='name' className='text-muted'>
                                Giá mua trung bình
                            </Label>
                            <p>
                                <Badge color='primary' pill>
                                    {AddCommaNumber(
                                        parseInt(
                                            findAvaragePrice?.avarage_price
                                        )
                                    )}
                                </Badge>
                            </p>
                        </FormGroup>
                        <FormGroup className='has-float-label'>
                            <Label for='unit_price' className='text-muted'>
                                <IntlMessages id='stock.unit-price-new' /><span style={{color: 'red'}}>*</span>{' '}
                               {values.unit ? `/ ${values.unit}` : null}
                            </Label>
                            <Input
                                autoFocus={true}
                                name='unit_price'
                                id='unit_price'
                                value={AddCommaNumber(values.unit_price)}
                                onChange={onChange}
                            />
                        </FormGroup>
                        <FormGroup className='has-float-label'>
                            <Label for='update_count' className='text-muted'>
                                <IntlMessages id='stock.count' /><span style={{color: 'red'}}>*</span>{' '}
                                {values.unit ? `( ${values.unit} )` : null}
                            </Label>
                            <Input
                                name='new_count'
                                id='update_count'
                                type='number'
                                step='1'
                                value={values.new_count}
                                placeholder={0}
                                onChange={onChange}
                            />
                        </FormGroup>
                    </PerfectScrollbar>
                </ModalBody>
                <ModalFooter>
                    <Button color='primary'>
                        <IntlMessages id='general.btn-add' />
                    </Button>{' '}
                </ModalFooter>
            </Form>
        </Modal>
    );
};

export default memo(ContentComponent);
