import React, { memo, useState } from 'react';
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Input,
    Label,
    Form,
    FormGroup,
    Nav,
    NavItem,
    NavLink,
    TabPane,
    TabContent,
    Row,
} from 'reactstrap';
import IntlMessages from '../../../../helpers/IntlMessages';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { DynamicForm } from '../../general';
import classNames from 'classnames';
import { Colxx } from '../../../../components/common/CustomBootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import {
    SetSelectPromotionDiscount,
    SetSelectPromotionGift,
} from '../../../../helpers/SelectPromotion';
import Select from 'react-select';
import {
    CustomOptionComponentDiscount,
    CustomOptionComponentGift,
} from './CustomOptionComponent';
import CustomSelectInput from '../../../../components/common/CustomSelectInput';

const ContentComponent = ({
    title,
    isOpen,
    toggle,
    onSubmit,
    values,
    discountRange,
    oneProduct,
    oneTypeProduct,
    quantityProduct,
    quantityTypeProduct,
    customerPoint,
    productGetProduct,
    orderGetProduct,
    customerPointGetProduct,
    lengthData,
    onAddItem,
    onAddProduct,
    onRemoveDiscount,
    onRemoveProduct,
    onChange,
    onChangeDynamicForm,
    onChangeItem,
    data_dynamic_form,
}) => {
    const [activeTab, setActiveTab] = useState('1');
    const [selectedOptionDiscount, setSelectedOptionDiscount] = useState(null);
    const [selectedOptionGift, setSelectedOptionGift] = useState(null);

    const onChangeSelectedDiscount = (selected) => {
        setSelectedOptionDiscount(selected.value);
        onChange(selected, 'type');
    };
    const onChangeSelectedGift = (selected) => {
        setSelectedOptionGift(selected.value);
        onChange(selected, 'type');
    };

    const toggleTab = (num) => {
        setActiveTab(num);
    };

    return (
        <Modal
            isOpen={isOpen}
            toggle={toggle}
            wrapClassName='modal-right'
            backdrop={true}
            autoFocus={false}
        >
            <Form onSubmit={(e) => onSubmit(e)}>
                <ModalHeader toggle={toggle}>{title}</ModalHeader>
                <ModalBody className='scrool modal-right-add'>
                    <PerfectScrollbar
                        options={{
                            suppressScrollX: true,
                            wheelPropagation: false,
                        }}
                        id='modal-right__body'
                    >
                        <FormGroup>
                            <Label className='text-muted' for='name'>
                                <IntlMessages id='promotion-name' /><span style={{color: 'red'}}>*</span>
                            </Label>
                            <Input
                                autoFocus={true}
                                type='text'
                                name='name'
                                id='name'
                                value={values.name}
                                onChange={onChange}
                            />
                        </FormGroup>
                        <Row className='d-flex'>
                            <Colxx xxs='6'>
                                <FormGroup>
                                    <Label
                                        className='text-muted'
                                        for='quantity'
                                    >
                                        <IntlMessages id='promotion-quantity' /><span style={{color: 'red'}}>*</span>
                                    </Label>
                                    <Input
                                        type='text'
                                        name='quantity'
                                        id='quantity'
                                        value={values.quantity}
                                        onChange={onChange}
                                    />
                                </FormGroup>
                            </Colxx>
                            <Colxx>
                                <FormGroup>
                                    <Label className='text-muted' for='code'>
                                        <IntlMessages id='promotion-code' /><span style={{color: 'red'}}>*</span>
                                    </Label>
                                    <Input
                                        disabled
                                        type='text'
                                        name='code'
                                        id='code'
                                        value={`CTKM-${lengthData + 1}`}
                                    />
                                </FormGroup>
                            </Colxx>
                        </Row>
                        {/* <FormGroup> */}
                        <Label className='text-muted' for='date_start'>
                            <IntlMessages id='promotion-date_start' /><span style={{color: 'red'}}>*</span>
                        </Label>
                        <DatePicker
                            className='mb-3'
                            name='momentFormatSelectDateTimeStart'
                            id='momentFormatSelectDateTimeStart'
                            onChange={(e) =>
                                onChange(e, 'momentFormatSelectDateTimeStart')
                            }
                            selected={moment(
                                values.momentFormatSelectDateTimeStart,
                                'YYYY-MM-DD HH:mm'
                            )}
                            showTimeSelect
                            timeFormat='HH:mm'
                            timeIntervals={15}
                            dateFormat='YYYY-MM-DD / HH:mm'
                            timeCaption='Giờ'
                        />
                        {/* </FormGroup> */}
                        {/* <FormGroup> */}
                        <Label className='text-muted' for='date_end'>
                            <IntlMessages id='promotion-date_end' /><span style={{color: 'red'}}>*</span>
                        </Label>
                        <DatePicker
                            className='mb-3'
                            name='momentFormatSelectDateTimeEnd'
                            id='momentFormatSelectDateTimeEnd'
                            onChange={(e) =>
                                onChange(e, 'momentFormatSelectDateTimeEnd')
                            }
                            selected={moment(
                                values.momentFormatSelectDateTimeEnd,
                                'YYYY-MM-DD HH:mm'
                            )}
                            showTimeSelect
                            timeFormat='HH:mm'
                            timeIntervals={15}
                            dateFormat='YYYY-MM-DD / HH:mm'
                            timeCaption='Giờ'
                        />
                        {/* </FormGroup> */}
                        <FormGroup>
                            <Label className='text-muted' for='description'>
                                <IntlMessages id='promotion-description' />
                            </Label>
                            <Input
                                type='text'
                                name='description'
                                id='description'
                                value={values.description}
                                onChange={onChange}
                            />
                        </FormGroup>
                        <DynamicForm
                            values={values.dynamic_form}
                            data={data_dynamic_form}
                            onChange={onChangeDynamicForm}
                        />
                        <Nav
                            tabs
                            className='d-flex justify-content-around separator-tabs ml-0 mb-5 cursor-pointer'
                        >
                            <NavItem className='w-50 text-center'>
                                <NavLink
                                    className={classNames({
                                        active: activeTab === '1',
                                        'nav-link': true,
                                        'mr-0': true,
                                    })}
                                    onClick={() => toggleTab('1')}
                                    location={{}}
                                    to='#'
                                >
                                    Giảm giá
                                </NavLink>
                            </NavItem>
                            <NavItem className='w-50 text-center'>
                                <NavLink
                                    className={classNames({
                                        active: activeTab === '2',
                                        'nav-link': true,
                                    })}
                                    onClick={() => toggleTab('2')}
                                    location={{}}
                                    to='#'
                                >
                                    Quà tặng
                                </NavLink>
                            </NavItem>
                        </Nav>
                        <TabContent activeTab={activeTab}>
                            <TabPane tabId='1'>
                                <FormGroup>
                                    <Select
                                        components={{
                                            Input: CustomSelectInput,
                                        }}
                                        className='react-select'
                                        classNamePrefix='react-select'
                                        placeholder={
                                            <IntlMessages id='select-one-time-discount' />
                                        }
                                        options={SetSelectPromotionDiscount()}
                                        onChange={(e) => {
                                            onChangeSelectedDiscount(e);
                                        }}
                                    />
                                </FormGroup>
                                {selectedOptionDiscount && (
                                    <CustomOptionComponentDiscount
                                        selectedOption={selectedOptionDiscount}
                                        values={values}
                                        discountRange={discountRange}
                                        oneProduct={oneProduct}
                                        oneTypeProduct={oneTypeProduct}
                                        quantityProduct={quantityProduct}
                                        quantityTypeProduct={
                                            quantityTypeProduct
                                        }
                                        customerPoint={customerPoint}
                                        onAddItem={onAddItem}
                                        onAddProduct={onAddProduct}
                                        onRemoveDiscount={onRemoveDiscount}
                                        onRemoveProduct={onRemoveProduct}
                                        onChange={onChange}
                                        onChangeItem={onChangeItem}
                                    />
                                )}
                            </TabPane>
                            <TabPane tabId='2'>
                                <FormGroup>
                                    <Select
                                        components={{
                                            Input: CustomSelectInput,
                                        }}
                                        className='react-select'
                                        classNamePrefix='react-select'
                                        name='type'
                                        placeholder={
                                            <IntlMessages id='select-one-time-gift' />
                                        }
                                        options={SetSelectPromotionGift()}
                                        onChange={(e) => {
                                            onChangeSelectedGift(e);
                                        }}
                                    />
                                </FormGroup>
                                {selectedOptionGift && (
                                    <CustomOptionComponentGift
                                        selectedOption={selectedOptionGift}
                                        onChange={onChange}
                                        productGetProduct={productGetProduct}
                                        orderGetProduct={orderGetProduct}
                                        customerPointGetProduct={
                                            customerPointGetProduct
                                        }
                                        onAddItem={onAddItem}
                                        onAddProduct={onAddProduct}
                                        onRemoveDiscount={onRemoveDiscount}
                                        onRemoveProduct={onRemoveProduct}
                                        onChangeItem={onChangeItem}
                                    />
                                )}
                            </TabPane>
                        </TabContent>
                    </PerfectScrollbar>
                </ModalBody>
                <ModalFooter>
                    <Button color='primary'>
                        <IntlMessages id='general.btn-add' />
                    </Button>{' '}
                </ModalFooter>
            </Form>
        </Modal>
    );
};

export default memo(ContentComponent);
