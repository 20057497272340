import React, { memo } from 'react';
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Input,
    Label,
    Form,
    FormGroup,
} from 'reactstrap';
import IntlMessages from '../../../helpers/IntlMessages';
import PerfectScrollbar from 'react-perfect-scrollbar';

import Select from 'react-select';
import CustomSelectInput from '../../../components/common/CustomSelectInput';
import {
    LogsValueOvertimeCoefficient,
    GetLogsValueOvertimeCoefficient,
    LogsType,
    GetLogsType,
} from '../../../helpers/UserStaff';
import { AddCommaNumber } from '../../../helpers/Utils';

const ContentComponent = ({
    title,
    isOpen,
    toggle,
    onSubmit,
    values,
    data_all,
    setting,
    onChange,
}) => {
    // console.log('render AddComponent');
    return (
        <Modal
            isOpen={isOpen}
            toggle={toggle}
            wrapClassName='modal-right'
            backdrop={true}
        >
            <Form onSubmit={(e) => onSubmit(e)}>
                <ModalHeader toggle={toggle}>{title}</ModalHeader>
                <ModalBody className='scrool modal-right-add'>
                    <PerfectScrollbar
                        options={{
                            suppressScrollX: true,
                            wheelPropagation: false,
                        }}
                        id='modal-right__body'
                    >
                        <FormGroup>
                            {/* User Staff */}
                            <Label
                                for='logs_users_staff'
                                className='text-muted'
                            >
                                <IntlMessages id='user-staff.logs_users_staff' /><span style={{color: 'red'}}>*</span>
                            </Label>
                            <Select
                                components={{ Input: CustomSelectInput }}
                                className='react-select'
                                classNamePrefix='react-select'
                                name='logs_users_staff'
                                isMulti
                                placeholder={
                                    <IntlMessages id='user-staff.select-all-users' />
                                }
                                options={data_all}
                                defaultValue={values.logs_users_staff}
                                onChange={(e) => {
                                    onChange(e, 'logs_users_staff');
                                }}
                            />
                        </FormGroup>

                        {/* Type */}
                        <FormGroup>
                            <Label for='logs_type' className='text-muted'>
                                <IntlMessages id='user-staff.logs_type' /><span style={{color: 'red'}}>*</span>
                            </Label>
                            <Select
                                components={{ Input: CustomSelectInput }}
                                className='react-select'
                                classNamePrefix='react-select'
                                name='logs_type'
                                placeholder={
                                    <IntlMessages id='user-staff.select-one' />
                                }
                                options={LogsType()}
                                defaultValue={GetLogsType(values.logs_type)}
                                onChange={(e) => {
                                    onChange(e, 'logs_type');
                                }}
                            />
                        </FormGroup>

                        {values.logs_type === 'overtime_hour' ? (
                            <FormGroup>
                                {/* Over time overtime_coefficient */}
                                <Label
                                    for='logs_value_overtime_coefficient'
                                    className='text-muted'
                                >
                                    <IntlMessages id='user-staff.overtime_coefficient' /><span style={{color: 'red'}}>*</span>
                                </Label>
                                <Select
                                    components={{ Input: CustomSelectInput }}
                                    className='react-select'
                                    classNamePrefix='react-select'
                                    name='logs_value_overtime_coefficient'
                                    placeholder={
                                        <IntlMessages id='user-staff.select-one' />
                                    }
                                    options={LogsValueOvertimeCoefficient()}
                                    defaultValue={GetLogsValueOvertimeCoefficient(
                                        values.logs_value_overtime_coefficient
                                    )}
                                    onChange={(e) => {
                                        onChange(
                                            e,
                                            'logs_value_overtime_coefficient'
                                        );
                                    }}
                                />
                            </FormGroup>
                        ) : null}

                        <FormGroup>
                            {values.logs_type === 'overtime_hour' ||
                            values.logs_type === 'day_off_hour' ? (
                                <Label for='logs_value' className='text-muted'>
                                    <IntlMessages id='user-staff.logs_value_hour' /><span style={{color: 'red'}}>*</span>
                                </Label>
                            ) : (
                                <Label for='logs_value' className='text-muted'>
                                    <IntlMessages id='user-staff.logs_value_salary' /><span style={{color: 'red'}}>*</span>
                                </Label>
                            )}
                            <Input
                                name='logs_value'
                                id='logs_value'
                                value={AddCommaNumber(values.logs_value)}
                                onChange={onChange}
                            />
                        </FormGroup>
                    </PerfectScrollbar>
                </ModalBody>
                <ModalFooter>
                    <Button color='primary'>
                        <IntlMessages id='general.btn-add' />
                    </Button>{' '}
                </ModalFooter>
            </Form>
        </Modal>
    );
};

export default memo(ContentComponent);
