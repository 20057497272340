import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { END_POINT } from '../../constants/defaultValues';
import { Colxx } from '../../components/common/CustomBootstrap';
import { Row, Card } from 'reactstrap';
import {
    NotificatioErrController,
    NotificatioSucController,
} from '../../helpers/Utils';

class ContentComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            store: window.location.host.split('.')[0],
            submited: false,
            verify: false,
        };
    }

    componentDidMount() {
        const urlParams = new URLSearchParams(window.location.search);
        const username = urlParams.get('username');

        this.onSubmit({ username });
    }

    onSubmit = ({ username }) => {
        const { store } = this.state;

        if (!store || !username) {
            this.setState((prev) => ({
                ...prev.state,
                submited: true,
                verify: false,
            }));

            return;
        }

        return axios
            .get(
                `${
                    END_POINT + '/auth/verify'
                }?username=${username}&store=${store}`
            )
            .then((res) => res.data)
            .then((data) => {
                this.setState(
                    (prev) => ({
                        ...prev.state,
                        submited: true,
                        verify: data.data,
                    }),
                    () => {
                        NotificatioSucController(data.responseText);

                        setTimeout(() => {
                            window.location.href = '/';
                        }, 3000);
                    }
                );
            })
            .catch((err) => {
                this.setState(
                    (prev) => ({
                        ...prev.state,
                        submited: true,
                        verify: false,
                    }),
                    () =>
                        NotificatioErrController(err.response.data.responseText)
                );
                return false;
            });
    };

    render() {
        const { submited, verify } = this.state;

        return (
            <Row className='h-100'>
                <Colxx xxs='12' md='10' className='mx-auto my-auto'>
                    <Card className='auth-card'>
                        <div className='position-relative image-side '>
                            <p className='text-white h2'>ĐĂNG KÝ</p>
                            <p className='white mb-0'>
                                Xác minh tài khoản <br />
                            </p>
                        </div>
                        <div className='form-side'>
                            {submited ? (
                                verify ? (
                                    <div>
                                        <p>Xác minh tài khoản thành công.</p>
                                        <p>
                                            {' '}
                                            Tự động chuyển hướng đến trang chủ
                                            sau 3 giây.
                                        </p>
                                    </div>
                                ) : (
                                    <p>Xác minh tài khoản không thành công</p>
                                )
                            ) : (
                                <p>Vui lòng chờ trong giây lát</p>
                            )}
                        </div>
                    </Card>
                </Colxx>
            </Row>
        );
    }
}

export default ContentComponent;
