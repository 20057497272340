import classNames from 'classnames';
import React, { Fragment } from 'react';
import {
    Badge,
    Button,
    Card,
    CardBody,
    CardTitle,
    FormGroup,
    Input,
    Label,
    Row,
} from 'reactstrap';
import { Colxx } from '../../../components/common/CustomBootstrap';
import IntlMessages from '../../../helpers/IntlMessages';
import { AddCommaNumber } from '../../../helpers/Utils';

const ContentDisplay = (props) => {
    // console.log(props);
    return (
        <Card className='mb-4'>
            <CardBody>
                <CardTitle>
                    <IntlMessages id='product.title' />
                </CardTitle>
                <Row className='mt-3 '>
                    <Colxx xxs='12' lg='6'>
                        <p className='text-muted text-small mb-2'>
                            <IntlMessages id='name' />
                        </p>
                        <p className='mb-3 text-capitalize'>
                            {props.values.name ? (
                                props.values.name
                            ) : (
                                <span className='text-lowercase'>
                                    (Chưa có thông tin)
                                </span>
                            )}
                        </p>
                    </Colxx>
                    <Colxx xxs='12' lg='6'>
                        <p className='text-muted text-small mb-2'>Mã SKU</p>
                        <p className='mb-3'>{props.sku}</p>
                    </Colxx>
                </Row>
                <Row>
                    <Colxx xxs='12' lg='6'>
                        <p className='text-muted text-small mb-2'>Barcode</p>
                        <p className='mb-3'>
                            {props.values.barcode ? (
                                props.values.barcode
                            ) : (
                                <span className='text-lowercase'>
                                    (chưa thiết lập)
                                </span>
                            )}
                        </p>
                    </Colxx>
                </Row>
                <Row>
                    <Colxx xxs='12' lg='6'>
                        <p className='text-muted text-small mb-2'>
                            <IntlMessages id='category.thumb-list' />
                        </p>
                        <p className='mb-3'>{props.values.category.name}</p>
                    </Colxx>
                    <Colxx xxs='12' lg='6'>
                        <p className='text-muted text-small mb-2'>
                            <IntlMessages id='product.price.original' />
                        </p>
                        <p className='mb-3 text-lowercase'>
                            {props.values.price_sale !== '0' ? (
                                <>
                                    <Badge
                                        color='primary'
                                        pill
                                        className='spacer-around-1'
                                    >
                                        {AddCommaNumber(
                                            props.values.price_sale
                                        )}
                                    </Badge>
                                    <Badge
                                        color='light'
                                        pill
                                        className='spacer-around-1'
                                    >
                                        <del>
                                            {AddCommaNumber(props.values.price)}
                                        </del>
                                    </Badge>
                                </>
                            ) : (
                                <Badge color='primary' pill>
                                    {AddCommaNumber(props.values.price)}
                                </Badge>
                            )}
                        </p>
                    </Colxx>
                </Row>
                {props.values?.size?.length ? <>
                <Row>
                    <Colxx xxs='6' lg='6'>
                        <p className='text-muted text-small mb-2'>
                            {props.values.sizeTitle ? (
                                <span>{props.values.sizeTitle}</span>
                            ) : (
                                <IntlMessages id='product.size' />
                            )}
                        </p>
                    </Colxx>
                    <Colxx xxs='6' lg='6'>
                        <p className='text-muted text-small mb-2'>
                            <IntlMessages id='product.add_price' />
                        </p>
                    </Colxx>
                </Row>
                <Row className='mb-4'>
                    {props.values?.size?.length ? (
                        props.values?.size?.map((s, idx) => (
                            <Fragment key={idx}>
                                <Colxx xxs='6' lg='6'>
                                    <p className='text-capitalize'>{s.name}</p>
                                </Colxx>
                                <Colxx xxs='6' lg='6'>
                                    <Badge
                                        color='primary'
                                        pill
                                        className='spacer-around-1'
                                    >
                                        {AddCommaNumber(s.value)}
                                    </Badge>
                                </Colxx>
                            </Fragment>
                        ))
                    ) : (
                        <span className='p-rl-15px'>(chưa thiết lập)</span>
                    )}
                </Row>
                </> : null}
                {props.values?.topping?.length ? <>
                <Row>
                    <Colxx xxs='6' lg='6'>
                        <p className='text-muted text-small mb-2'>
                            {props.values.toppingTitle ? (
                                <span>{props.values.toppingTitle}</span>
                            ) : (
                                <IntlMessages id='product.topping' />
                            )}
                        </p>
                    </Colxx>
                    <Colxx xxs='6' lg='6'>
                        <p className='text-muted text-small mb-2'>
                            <IntlMessages id='product.add_price' />
                        </p>
                    </Colxx>
                </Row>
                <Row className='mb-4'>
                    {props.values?.topping?.length ? (
                        props.values?.topping?.map((s, idx) => (
                            <Fragment key={idx}>
                                <Colxx xxs='6' lg='6'>
                                    <p className='text-capitalize'>{s.name}</p>
                                </Colxx>
                                <Colxx xxs='6' lg='6'>
                                    <Badge
                                        color='primary'
                                        pill
                                        className='spacer-around-1'
                                    >
                                        {AddCommaNumber(s.value)}
                                    </Badge>
                                </Colxx>
                            </Fragment>
                        ))
                    ) : (
                        <span className='p-rl-15px'>(chưa thiết lập)</span>
                    )}
                </Row>
                </> : null}
                {props.values?.sugar?.length ? <>
                <Row>
                    <Colxx xxs='12' lg='12'>
                        <p className='text-muted text-small mb-2'>
                            {props.values.sugarTitle ? (
                                <span>{props.values.sugarTitle}</span>
                            ) : (
                                <IntlMessages id='product.sugar' />
                            )}
                        </p>
                    </Colxx>
                </Row>
                <Row className='mb-4'>
                    {props.values?.sugar?.length ? (
                        props.values?.sugar?.map((_item, idx) => (
                            <Fragment key={idx}>
                                <Colxx _id={idx} className='pad-2px w-20'>
                                    <Button
                                        color='secondary'
                                        size='sm'
                                        outline
                                        block
                                    >
                                        {`${_item} %`}
                                    </Button>
                                </Colxx>
                            </Fragment>
                        ))
                    ) : (
                        <span className='p-rl-15px'>(chưa thiết lập)</span>
                    )}
                </Row>
                </> : null}
                {props.values?.ice?.length ? <>
                <Row>
                    <Colxx xxs='12' lg='6'>
                        <p className='text-muted text-small mb-2'>
                            {props.values.iceTitle ? (
                                <span>{props.values.iceTitle}</span>
                            ) : (
                                <IntlMessages id='product.ice' />
                            )}
                        </p>
                    </Colxx>
                </Row>

                <Row className='mb-4'>
                    {props.values?.ice?.length ? (
                        props.values?.ice?.map((_item, idx) => (
                            <Fragment key={idx}>
                                <Colxx _id={idx} className='pad-2px w-20'>
                                    <Button
                                        color='secondary'
                                        size='sm'
                                        outline
                                        block
                                    >
                                        {`${_item} %`}
                                    </Button>
                                </Colxx>
                            </Fragment>
                        ))
                    ) : (
                        <span className='p-rl-15px'>(chưa thiết lập)</span>
                    )}
                </Row>
                </> : null}
            </CardBody>
        </Card>
    );
};

export default ContentDisplay;
