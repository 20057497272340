import React from 'react';
import {
    getAvatar,
    AddCommaNumber,
    AddCommaPhoneNumber,
    getQrPayWithCash,
    getDateWithFormat,
} from '../../helpers/Utils';
import { Card, CardBody, Row, Table } from 'reactstrap';
import classnames from 'classnames';
// import { DynamicForm } from './form-dynamic';
import { Colxx, Separator } from '../../components/common/CustomBootstrap';
import moment from 'moment';
import SingleLightbox from '../../components/pages/SingleLightbox';
import { GetBank } from '../../helpers/ListBank';

class ComponentToPrint extends React.Component {
    componentWillUnmount() {
        document.body.classList.remove('noscroll');
    }

    render() {
        // console.log(this.props);
        let {
            items,
            companyName,
            companyAddress,
            companyLogo,
            companyWebsite,
            // billDate,
            discount,
            tax_fee,
            fee_other,
            pin_print = '',
            cardWrapper,
        } = this.props;

        // console.log('billDate: ', billDate);
        // console.log('items: ', items);

        let total_price = GetTotalPrice(items);
        let total_discount_price = GetTotalDiscountPrice(items, discount);
        let total_tax_fee_price = GetTotalDiscountPrice(items, tax_fee);
        let total_fee_other_price = GetTotalDiscountPrice(items, fee_other);
        let total_payment_price = GetTotalPaymentPrice(
            total_price,
            total_discount_price,
            total_tax_fee_price,
            total_fee_other_price
        );
        let find_item_discount = items.find(
            (item) => item.discount && item.discount.value
        );
        let find_item_tax_fee = items.find(
            (item) => item.tax_fee && item.tax_fee.value
        );
        let find_item_fee_other = items.find(
            (item) => item.fee_other && item.fee_other.value
        );

        const shopDefault = 'VMASS';
        const addressDefault =
            '380/71 Lê Văn Lương, Phường Tân Hưng, Quận 7, TPHCM';
        // const websiteDefault = 'https://vmass.vn';

        const fullName = localStorage.getItem('fullname');
        const hotline = localStorage.getItem('hotline');
        const namePay = localStorage.getItem('namePay');
        const numberPay = localStorage.getItem('numberPay');
        const bankPay = localStorage.getItem('bankPay');
        const qrPayImage = localStorage.getItem('qrPayImage');
        const billDate = getDateWithFormat();
        const timeNow = moment(new Date()).format('HH:mm');

        return !items.length ? (
            <div className='loading' />
        ) : (
            <Row className='invoice-react' id='bill-to-print'>
                <Colxx xxs='12' className='mb-4'>
                    <Card
                        className={classnames('mb-5 invoice-contents p-2', {
                            card: !cardWrapper,
                        })}
                        // className='mb-5 invoice-contents'
                    >
                        <CardBody className='d-flex flex-column justify-content-between p-1'>
                            <div
                                // className='d-flex flex-column'
                                className={classnames(
                                    'd-flex flex-column align-self-center order-invoice p-0 w-100',
                                    {
                                        'card-body': !cardWrapper,
                                    }
                                )}
                            >
                                <div className='d-flex flex-row justify-content-between pt-2 pb-2'>
                                    <div className='d-flex align-self-center'>
                                        {companyLogo && companyLogo !== null ? (
                                            <p className='mb-2 text-to-print company-avatar'>
                                                <img
                                                    // onError={(e) => { e.target.onerror = null; e.target.src = `http://localhost:5000/public/${companyLogo}` }}
                                                    alt=''
                                                    src={
                                                        companyLogo !==
                                                            'null' &&
                                                        companyLogo !==
                                                            'undefined'
                                                            ? getAvatar(
                                                                  companyLogo
                                                              )
                                                            : '/assets/img/logo.jpeg'
                                                    }
                                                />
                                            </p>
                                        ) : null}
                                    </div>
                                    <div className='d-flex pr-2 text-right align-self-center text-to-print'>
                                        <div className='text-small text-semi-muted mb-0 text-to-print'>
                                            <p className='mb-2 text-to-print company-name-invoice'>
                                                {companyName !== 'null' &&
                                                companyName !== 'undefined'
                                                    ? companyName
                                                    : shopDefault}
                                            </p>
                                            <p className='mb-2 text-small text-to-print'>
                                                {companyAddress !== 'null' &&
                                                companyAddress !== 'undefined'
                                                    ? companyAddress
                                                    : addressDefault}
                                            </p>
                                            <p className='mb-0 text-small text-to-print'>
                                                {companyWebsite !== 'null' &&
                                                companyWebsite !== 'undefined'
                                                    ? companyWebsite
                                                    : null}
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className='d-flex flex-row justify-content-between mb-3'>
                                    <div className='d-flex flex-column justify-content-center align-items-start w-55 mr-2 p-2 text-semi-muted bg-area-print'>
                                        <span className='mb-2 text-small text-to-print'>
                                            Nhân viên: {fullName}
                                        </span>
                                        <span className='text-small text-to-print'>
                                            Hotline:{' '}
                                            {hotline}
                                        </span>
                                    </div>
                                    <div className='w-45 d-flex flex-column justify-content-center text-right p-2 text-semi-muted bg-area-print'>
                                        <span className='mb-2 text-small text-to-print'>
                                            Hoá đơn: #{pin_print}
                                        </span>
                                        <span className='mb-2 text-small text-to-print'>
                                            {billDate}
                                        </span>
                                        <span className='mb-0 text-small text-to-print'>
                                            {timeNow}
                                        </span>
                                    </div>
                                </div>
                                <Separator className='mb-2' />
                                <Table borderless>
                                    <thead>
                                        <style>{`@media print {.no-print{display: none;}}`}</style>
                                        <tr>
                                            <th className='text-muted pd-light text-extra-small mb-2 text-to-print'>
                                                #
                                            </th>
                                            <th className='text-left pd-light text-muted text-extra-small mb-2 text-to-print'>
                                                Tên sản phẩm
                                            </th>
                                            <th className='text-center pd-light text-muted text-extra-small mb-2 text-to-print'>
                                                Đơn giá
                                            </th>
                                            <th className='text-right pd-light text-muted text-extra-small mb-2 text-to-print'>
                                                Thành tiền
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {items?.length &&
                                            items[0] !== null &&
                                            items.map((item, idx) => (
                                                <React.Fragment key={idx}>
                                                    <tr>
                                                        <td className='text-to-print pd-light text-primary text-center'>
                                                            {item.count}x
                                                        </td>
                                                        <td className='text-to-print pd-light text-left'>
                                                            {item.name}
                                                        </td>
                                                        <td className='text-to-print text-center pd-light'>
                                                            {AddCommaNumber(
                                                                GetOriginalPrice(
                                                                    item
                                                                )
                                                            )}
                                                        </td>
                                                        <td className='text-right text-to-print pd-light'>
                                                            {AddCommaNumber(
                                                                item.price *
                                                                    item.count
                                                            )}
                                                        </td>
                                                    </tr>
                                                    {(discount &&
                                                        discount.saved &&
                                                        discount.note) ||
                                                    (item.discount &&
                                                        item.discount.note) ? (
                                                        <tr>
                                                            <td
                                                                className='discount-note no-print pd-light'
                                                                colSpan='4'
                                                            >
                                                                {`* ${GetDiscountOrderNote(
                                                                    discount,
                                                                    item
                                                                )}`}
                                                            </td>
                                                        </tr>
                                                    ) : null}
                                                </React.Fragment>
                                            ))}
                                        {/* {discount &&
                                        discount.saved &&
                                        discount.note ? (
                                            <tr>
                                                <td
                                                    className='discount-note no-print'
                                                    colSpan={5}
                                                >
                                                    {`* Chiết khấu: ${GetDiscountOrderNote(
                                                        discount,
                                                        ''
                                                    )}`}
                                                </td>
                                            </tr>
                                        ) : null}
                                        {tax_fee &&
                                        tax_fee.saved &&
                                        tax_fee.note ? (
                                            <tr>
                                                <td
                                                    className='discount-note no-print'
                                                    colSpan={5}
                                                >
                                                    {`* Thuế phí: ${GetDiscountOrderNote(
                                                        tax_fee,
                                                        ''
                                                    )}`}
                                                </td>
                                            </tr>
                                        ) : null}
                                        {fee_other &&
                                        fee_other.saved &&
                                        fee_other.note ? (
                                            <tr>
                                                <td
                                                    className='discount-note no-print'
                                                    colSpan={5}
                                                >
                                                    {`* Phí khác: ${GetDiscountOrderNote(
                                                        fee_other,
                                                        ''
                                                    )}`}
                                                </td>
                                            </tr>
                                        ) : null} */}
                                    </tbody>
                                </Table>
                            </div>
                            <div className='d-flex flex-column'>
                                <Table
                                    borderless
                                    className='d-flex justify-content-end mb-2'
                                >
                                    <tbody>
                                        <tr className='font-weight-bold'>
                                            <td className='text-semi-muted text-to-print text-right pd-light mb-3'>
                                                Tổng tiền:
                                            </td>
                                            <td className='text-right text-bold text-to-print pd-light font-weight-bold'>
                                                {AddCommaNumber(total_price)}
                                            </td>
                                        </tr>
                                        {(discount &&
                                            discount.saved &&
                                            discount.value &&
                                            discount.type) ||
                                        find_item_discount ? (
                                            <tr>
                                                <td className='text-semi-muted text-to-print text-right pd-light'>
                                                    Chiết khấu:
                                                </td>
                                                <td className='text-right text-to-print pd-light'>
                                                    {AddCommaNumber(
                                                        total_discount_price
                                                    )}
                                                </td>
                                            </tr>
                                        ) : null}
                                        {(tax_fee &&
                                            tax_fee.saved &&
                                            tax_fee.value &&
                                            tax_fee.type) ||
                                        find_item_tax_fee ? (
                                            <tr>
                                                <td className='text-semi-muted text-to-print text-right pd-light'>
                                                    Thuế:
                                                </td>
                                                <td className='text-right text-to-print pd-light'>
                                                    {AddCommaNumber(
                                                        total_tax_fee_price
                                                    )}
                                                </td>
                                            </tr>
                                        ) : null}
                                        {(fee_other &&
                                            fee_other.saved &&
                                            fee_other.value &&
                                            fee_other.type) ||
                                        find_item_fee_other ? (
                                            <tr>
                                                <td className='text-semi-muted text-to-print text-right pd-light'>
                                                    Phụ phí:
                                                </td>
                                                <td className='text-right text-to-print pd-light'>
                                                    {AddCommaNumber(
                                                        total_fee_other_price
                                                    )}
                                                </td>
                                            </tr>
                                        ) : null}
                                        {(discount &&
                                            discount.saved &&
                                            discount.value &&
                                            discount.type) ||
                                        find_item_discount ||
                                        (tax_fee &&
                                            tax_fee.saved &&
                                            tax_fee.value &&
                                            tax_fee.type) ||
                                        find_item_tax_fee ||
                                        (fee_other &&
                                            fee_other.saved &&
                                            fee_other.value &&
                                            fee_other.type) ||
                                        find_item_fee_other ? (
                                            <tr className='font-weight-bold'>
                                                <td className='text-semi-muted text-to-print text-right pd-light'>
                                                    Số tiền thanh toán:
                                                </td>
                                                <td className='text-right text-bold text-to-print pd-light font-weight-bold'>
                                                    {AddCommaNumber(
                                                        total_payment_price
                                                    )}
                                                </td>
                                            </tr>
                                        ) : null}

                                        {/* <tr className='font-weight-bold'>
                                            <td className='text-semi-muted'>
                                                Total :
                                            </td>
                                            <td className='text-right'>
                                                $ 68.14
                                            </td>
                                        </tr> */}
                                    </tbody>
                                </Table>
                                {namePay === 'null' ||
                                numberPay === 'null' ||
                                bankPay === 'null' ||
                                !namePay ||
                                !numberPay ||
                                !bankPay ? null : (
                                    <>
                                        <Separator className='mb-3' />
                                        <div className='d-flex align-items-center mb-2'>
                                            <Colxx xxs='4' className='pl-0'>
                                                {bankPay && numberPay ? (
                                                    <SingleLightbox
                                                        thumb={getQrPayWithCash(
                                                            bankPay,
                                                            numberPay,
                                                            total_payment_price
                                                        )}
                                                        large={getQrPayWithCash(
                                                            bankPay,
                                                            numberPay,
                                                            total_payment_price
                                                        )}
                                                        className='img-thumbnail card-img-print'
                                                    />
                                                ) : (
                                                    <h5 className='text-to-print text-center'>
                                                        Thông Tin Thanh Toán
                                                    </h5>
                                                )}
                                            </Colxx>
                                            <Colxx
                                                xxs='8'
                                                className='text-left px-0'
                                            >
                                                <p className='mb-1'>
                                                    {namePay}
                                                </p>
                                                <p className='mb-1'>
                                                    {numberPay}
                                                </p>
                                                <p className='mb-1'>
                                                    {GetBank(bankPay).label}
                                                </p>
                                            </Colxx>
                                        </div>
                                    </>
                                )}
                                <div className='border-bottom mb-3' />
                                {/* <Separator className='mb-3' /> */}
                                <p className='text-muted text-small text-center mb-1'>
                                    Chân thành cảm ơn quý khách!{' '}
                                </p>
                                <span className='text-small text-center'>
                                    Bản quyền thuộc vmass.vn.
                                </span>
                            </div>
                        </CardBody>
                    </Card>
                </Colxx>
            </Row>
        );
    }
}

export default ComponentToPrint;

const GetTotalPrice = (items) => {
    if (!items.length) return 0;

    const cost = items.reduce((total, item) => {
        return total + item.price * item.count;
    }, 0);

    return cost;
};

const GetOriginalPrice = (item) => {
    if (!item) return 0;

    return item.price * 1;
};

const GetTotalPaymentPrice = (
    total_price,
    total_price_discount,
    total_price_tax_fee,
    total_price_fee_other
) => {
    let total_price_val = parseFloat(total_price);
    let total_price_discount_val = parseFloat(total_price_discount);
    let total_price_tax_fee_val = parseFloat(total_price_tax_fee);
    let total_price_fee_other_val = parseFloat(total_price_fee_other);
    let total = 0;

    if (
        !isNaN(total_price_val) &&
        !isNaN(total_price_discount_val) &&
        !isNaN(total_price_tax_fee_val) &&
        !isNaN(total_price_fee_other_val)
    ) {
        total =
            total_price_val -
            total_price_discount_val +
            total_price_tax_fee_val +
            total_price_fee_other_val;
    }

    return total > 0 ? Math.round(total) : 0;
};

const GetDiscountOrderValue = (items, discount, item) => {
    if (!items.length) return 0;

    const reducer = (a, o) => a + o.count;
    const items_count = items.reduce(reducer, 0);

    let price = 0;
    if (discount && discount.saved && discount.value) {
        let discount_value = parseFloat(discount.value);
        if (!isNaN(discount_value)) {
            if (discount.type === 'percent') {
                price = discount_value;
            } else if (discount.type === 'price') {
                price = (discount_value / items_count) * item.count;
            }
        }
        price = (Math.round(price * 100) / 100).toFixed(1);
    } else if (item.discount) {
        price = item.discount.value;
    }

    return Number(price);
};

const GetDiscountOrderType = (discount, item) => {
    let type = 'price';

    if (discount && discount.saved && discount.type) {
        type = discount.type;
    } else if (item.discount) {
        type = item.discount.type;
    }
    return type === 'percent' ? '%' : '';
};

const GetDiscountOrderNote = (discount, item) => {
    let note = '';

    if (discount && discount.saved && discount.note) {
        note = discount.note;
    } else if (item.discount) {
        note = item.discount.note;
    }
    return note;
};

const GetTotalDiscountPrice = (items, discount) => {
    if (!items.length) return 0;

    let total_discount = 0;
    for (let i = 0; i < items.length; i++) {
        let item = items[i];

        // load from create
        if (discount) {
            if (discount.type === 'percent') {
                let price_percent =
                    (item.price * item.count * discount.value) / 100;
                total_discount = total_discount + parseFloat(price_percent);
            }
            if (discount.type === 'price') {
                total_discount = parseFloat(discount.value);
                break;
            }
        }

        // // load from server
        // else if (item.discount) {
        //     if (item.discount.type === 'percent') {
        //         let price_percent =
        //             (item.price * item.count * item.discount.value) / 100;
        //         total_discount = total_discount + parseFloat(price_percent);
        //     }
        //     if (item.discount.type === 'price') {
        //         total_discount =
        //             total_discount + parseFloat(item.discount.value);
        //     }
        // }
    }

    return Math.round(total_discount);
};
