import React, { useState, useEffect } from 'react';
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Input,
    InputGroup,
    Label,
    Form,
    FormGroup,
    CustomInput,
    Row,
    Badge,
    InputGroupAddon,
} from 'reactstrap';
import IntlMessages from '../../../helpers/IntlMessages';
import PerfectScrollbar from 'react-perfect-scrollbar';

import Select from 'react-select';
import CustomSelectInput from '../../../components/common/CustomSelectInput';
import { END_POINT } from '../../../constants/defaultValues';
import { AddCommaNumber } from '../../../helpers/Utils';
import { SetIceOrSugar } from '../../../helpers/Product';
import { Creatable } from 'react-select';
import { Colxx, Separator } from '../../../components/common/CustomBootstrap';
import SizeAndTopping from './SizeAndTopping';
import IceAndSugar from './IceAndSugar';
import DynamicForm from '../general/DynamicForm';
import TooltipItem from '../../../components/common/TooltipItem';

const ContentComponent = ({
    title,
    isOpen,
    show_add_more_topping,
    onShowMoreItem,
    onShowQuantitative,
    show_quantitative,
    toggle,
    onSubmit,
    onAddSizeAndTopping,
    onChangeSizeAndTopping,
    onRemoveSizeAndTopping,
    onChangeIceAndSugar,
    data_dynamic_form,
    data_categories,
    values,
    form_cost,
    sku,
    make_products,
    data_stocks,
    setting,
    onAddStock,
    onAddNewStock,
    onChange,
    onChangeCost,
    onDeleteStock,
    onDeleteImage,
    onChangeDynamicForm,
}) => {
    // const [make, setMake] = useState([])
    // console.log('render UpdateComponent');
    console.log('values: ', values);
    console.log('data_stocks: ', data_stocks);
    
    const avatar = values?.image?.preview;

    useEffect(() => {
        return () => {
            avatar && URL.revokeObjectURL(avatar);
        };
    }, [avatar]);
    
    let default_category = data_categories.find(
        (cate) => cate.id === values.category_id
    );

    if (!default_category) {
        default_category = '';
    } else {
        default_category = default_category.value;
    }

    const make_product = make_products?.filter(
        (product) => product.product_id === values.id
    );

    // const makeProduct = (id) => {
    //     let make_p = make_products?.filter(
    //         (product) => product.product_id === values.id)
    //     setMake(make_p)
    // }

    let priceCostWithValuesId = make_product
        ?.map((item) => {
            let price = data_stocks?.find(
                (i) => i.id === item.stock.id
            )?.avarage_price;
            // console.log('price: ', price);
            return parseFloat(item.count) * parseFloat(price);
        })
        .reduce((sum, item) => sum + item, 0);

    // console.log('make_product: ', make_product);
    // console.log('form_cost: ', form_cost);

    let findStock = data_stocks?.find(stock => stock.sku === values.sku)
    console.log('findStock: ', findStock);
    
    const [categoryTooltip] = useState({
        placement: 'top',
        text: '',
        body: 'Là danh mục cha của sản phẩm này. Ví dụ: Danh mục "Cà phê" là danh mục cha của sản phẩm "Cà phê sữa đá".',
    });

    const [priceTooltip] = useState({
        placement: 'top',
        text: '',
        body: 'Giá này là giá bán ra của sản phẩm nếu như "Giá khuyến mãi" không được tick chọn.',
    });

    const [priceSaleTooltip] = useState({
        placement: 'top',
        text: '',
        body: 'Nếu muốn giảm giá bán, hãy tick chọn vào ô này và điền Giá khuyến mãi để thay đổi giá (giảm giá) của sản phẩm.',
    });

    const [sizeTooltip] = useState({
        placement: 'top',
        text: '',
        body: 'Là nơi để nhập tên và giá tiền theo kích thước tương ứng được cộng thêm vào giá bán của sản phẩm này.',
    });

    const [toppingTooltip] = useState({
        placement: 'top',
        text: '',
        body: 'Là nơi để nhập các "sản phẩm đi kèm" với sản phẩm này và giá tiền tương ứng được cộng thêm vào giá bán.',
    });

    const [iceTooltip] = useState({
        placement: 'top',
        text: '',
        body: 'Tick chọn 4 ô để khách hàng/người gọi sản phẩm có thể chọn Lượng đá của sản phẩm trong Menu.',
    });

    const [sugarTooltip] = useState({
        placement: 'top',
        text: '',
        body: 'Tick chọn 4 ô để khách hàng/người gọi sản phẩm có thể chọn Độ ngọt của sản phẩm trong Menu.',
    });

    return (
        <Modal
            isOpen={isOpen}
            toggle={toggle}
            wrapClassName='modal-right'
            backdrop={true}
        >
            <Form onSubmit={(e) => onSubmit(e)}>
                <ModalHeader toggle={toggle}>{title}</ModalHeader>
                <ModalBody className='pad-125rem scrool modal-right-add'>
                    <PerfectScrollbar
                        options={{
                            suppressScrollX: true,
                            wheelPropagation: false,
                        }}
                        id='modal-right__body'
                    >
                        <Row>
                            <Colxx xxs='12'>
                                <FormGroup>
                                    <Label for='name' className='text-muted'>
                                        <IntlMessages id='pages.product-name' /><span style={{color: 'red'}}>*</span>
                                    </Label>
                                    <Input
                                        type='text'
                                        name='name'
                                        id='name'
                                        value={values.name}
                                        onChange={onChange}
                                    />
                                </FormGroup>
                            </Colxx>
                        </Row>
                        <Row>
                            <Colxx xxs='8'>
                                <FormGroup>
                                    <Label for='name' className='text-muted'>
                                        Barcode
                                    </Label>
                                    <Input
                                        type='number'
                                        name='barcode'
                                        id='barcode'
                                        value={values.barcode}
                                        onChange={onChange}
                                    />
                                </FormGroup>
                            </Colxx>
                            <Colxx xxs='4' className='pl-0'>
                                <FormGroup>
                                    <Label for='name' className='text-muted'>
                                        Mã SKU
                                    </Label>
                                    <Input
                                        disabled
                                        type='text'
                                        name='sku'
                                        id='sku'
                                        value={sku}
                                    />
                                </FormGroup>
                            </Colxx>
                        </Row>
                        <FormGroup>
                            <Label for='category' className='text-muted'>
                                <IntlMessages id='pages.category' /><span style={{color: 'red'}}>*</span>
                                <TooltipItem item={categoryTooltip} id={9} />
                            </Label>
                            <Select
                                components={{ Input: CustomSelectInput }}
                                className='react-select'
                                classNamePrefix='react-select'
                                name='category'
                                placeholder={
                                    <IntlMessages id='pages.select-category' />
                                }
                                options={data_categories}
                                onChange={(e) => onChange(e, 'category')}
                                defaultInputValue={default_category}
                            />
                        </FormGroup>

                        {/* PRICE ORIGINAL & PRICE SALE */}
                        <Row>
                            <Colxx
                                sm={12}
                                md={setting.add ? 12 : 5}
                                lg={setting.add ? 12 : 5}
                            >
                                <FormGroup>
                                    <Label for='price' className='text-muted'>
                                        <IntlMessages id='product.price.original' /><span style={{color: 'red'}}>*</span>
                                        <TooltipItem
                                            item={priceTooltip}
                                            id={10}
                                        />
                                    </Label>
                                    <Input
                                        name='price'
                                        id='price'
                                        placeholder={'0'}
                                        value={AddCommaNumber(values.price)}
                                        onChange={onChange}
                                    />
                                </FormGroup>
                            </Colxx>
                            <Colxx
                                sm={12}
                                md={setting.add ? 12 : 7}
                                lg={setting.add ? 12 : 7}
                            >
                                <FormGroup check className='mb-1'>
                                    <Input
                                        checked={values.active_sale}
                                        onChange={() =>
                                            onChange(
                                                !values.active_sale,
                                                'active_sale'
                                            )
                                        }
                                        type='checkbox'
                                        name='check'
                                        id='checkbox-sale'
                                    />{' '}
                                    <Label
                                        for='checkbox-sale'
                                        check
                                        className='text-muted'
                                    >
                                        <IntlMessages id='product.price.sale' />
                                        <TooltipItem
                                            item={priceSaleTooltip}
                                            id={11}
                                        />
                                    </Label>
                                </FormGroup>
                                <FormGroup>
                                    <Input
                                        disabled={
                                            values.active_sale ? false : true
                                        }
                                        name='price_sale'
                                        id='price_sale'
                                        placeholder={'0'}
                                        value={
                                            values.price_sale
                                                ? AddCommaNumber(
                                                      values.price_sale
                                                  )
                                                : undefined
                                        }
                                        onChange={onChange}
                                    />
                                </FormGroup>
                            </Colxx>
                        </Row>
                        {/* TỒN KHO VÀ TỔNG NHẬP */}
                        <Row>
                            <Colxx sm={4} className='pr-0'>
                                <FormGroup>
                                    <Label
                                        for='price_cost'
                                        className='text-muted'
                                    >
                                        Giá vốn
                                    </Label>
                                    <Input
                                        disabled={
                                            show_quantitative ? true : false || findStock?.avarage_price > 0
                                        }
                                        name='price_cost'
                                        id='price_cost'
                                        placeholder={'0'}
                                        value={AddCommaNumber(
                                            findStock?.avarage_price
                                        )}
                                        onChange={onChange}
                                    />
                                </FormGroup>
                            </Colxx>
                            <Colxx sm={4}>
                                <FormGroup className={'mb-0'}>
                                    <Label
                                        for='quantity'
                                        className='text-muted'
                                    >
                                        Số lượng
                                    </Label>
                                </FormGroup>
                                <FormGroup>
                                    <Input
                                        disabled={
                                            show_quantitative ? true : false || findStock?.total > 0
                                        }
                                        name='quantity'
                                        id='quantity'
                                        placeholder={'0'}
                                        value={AddCommaNumber(findStock?.total)}
                                        onChange={onChange}
                                    />
                                </FormGroup>
                            </Colxx>
                            <Colxx sm={4} className='pl-0'>
                                <FormGroup className={'mb-0'}>
                                    <Label for='ton_kho' className='text-muted'>
                                        Tồn kho
                                    </Label>
                                </FormGroup>
                                <FormGroup>
                                    <Input
                                        disabled
                                        name='ton_kho'
                                        id='ton_kho'
                                        value={AddCommaNumber(
                                            findStock?.count
                                        )}
                                        onChange={onChange}
                                    />
                                </FormGroup>
                            </Colxx>
                        </Row>
                        {/* IMAGE */}
                        <FormGroup>
                            <Label for='image' className='text-muted'>
                                <IntlMessages id='pages.image' />
                                {' - 320x240px '}
                                {setting.viewonly && !setting.add ? null : (
                                    <span
                                        onClick={() => onDeleteImage()}
                                        className={
                                            'cursor-pointer glyph-icon simple-icon-pencil'
                                        }
                                    />
                                )}
                            </Label>
                            {values.image ? (
                                values.image.name ? (
                                    <>
                                        <InputGroup
                                            className='mb-3'
                                            encType='multipart/form-data'
                                        >
                                            <CustomInput
                                                label={values.image.name}
                                                type='file'
                                                id='image'
                                                name='image'
                                                onChange={onChange}
                                            />
                                        </InputGroup>
                                        <img
                                            src={values?.image?.preview}
                                            alt=''
                                            width={'200px'}
                                        />
                                    </>
                                ) : (
                                    <FormGroup>
                                        <img
                                            onError={(e) => {
                                                e.target.onerror = null;
                                                e.target.src =
                                                    'https://placehold.co/320x240';
                                            }}
                                            alt={''}
                                            src={
                                                values.image.indexOf('http') >=
                                                0
                                                    ? values.image
                                                    : `${END_POINT}/public/${values.image}`
                                            }
                                            className='list-thumbnail responsive border-0'
                                        />
                                    </FormGroup>
                                )
                            ) : (
                                <InputGroup
                                    className='mb-3'
                                    encType='multipart/form-data'
                                >
                                    <CustomInput
                                        type='file'
                                        id='image'
                                        name='image'
                                        onChange={onChange}
                                    />
                                </InputGroup>
                            )}
                        </FormGroup>
                        <div className='custom-line mb-4 mt-3' />
                        <FormGroup check>
                            <Input
                                type='checkbox'
                                name='showItem'
                                onChange={onShowMoreItem}
                                checked={show_add_more_topping}
                                id='show-more-item'
                            />
                            <Label for='show-more-item' check>
                                Thêm options
                            </Label>
                        </FormGroup>

                        {show_add_more_topping ? (
                            <>
                                {/* SIZE */}
                                <FormGroup className='mb-4 mt-4'>
                                    {setting.viewonly && !setting.add ? (
                                        <Label for='sizeTitle'>
                                            {values.sizeTitle ? (
                                                <span>{values.sizeTitle}</span>
                                            ) : (
                                                <IntlMessages id='product.size' />
                                            )}
                                        </Label>
                                    ) : (
                                        <div className='d-flex'>
                                            <Input
                                                className='mb-3 custom-input-underline w-30 pad-2px'
                                                type='text'
                                                name='sizeTitle'
                                                id='sizeTitle'
                                                disabled={
                                                    setting.viewonly &&
                                                    !setting.add
                                                }
                                                value={values.sizeTitle}
                                                placeholder='Kích thước'
                                                onChange={onChange}
                                            />
                                            <TooltipItem
                                                item={sizeTooltip}
                                                id={12}
                                            />
                                        </div>
                                    )}
                                    <SizeAndTopping
                                        onAdd={() =>
                                            onAddSizeAndTopping('size')
                                        }
                                        onChange={onChangeSizeAndTopping}
                                        onRemove={onRemoveSizeAndTopping}
                                        name={'size'}
                                        values={values.size}
                                        disabled={
                                            setting.viewonly && !setting.add
                                        }
                                    />
                                </FormGroup>

                                {/* TOPPING */}
                                <div className='custom-line mb-4 mt-3' />
                                <FormGroup className='mb-4'>
                                    {setting.viewonly && !setting.add ? (
                                        <Label for='toppingTitle'>
                                            {values.toppingTitle ? (
                                                <span>
                                                    {values.toppingTitle}
                                                </span>
                                            ) : (
                                                <IntlMessages id='product.topping' />
                                            )}
                                        </Label>
                                    ) : (
                                        <div className='d-flex'>
                                            <Input
                                                className='mb-3 custom-input-underline w-30 pad-2px'
                                                type='text'
                                                name='toppingTitle'
                                                id='toppingTitle'
                                                disabled={
                                                    setting.viewonly &&
                                                    !setting.add
                                                }
                                                value={values.toppingTitle}
                                                placeholder='Topping'
                                                onChange={onChange}
                                            />
                                            <TooltipItem
                                                item={toppingTooltip}
                                                id={13}
                                            />
                                        </div>
                                    )}
                                    <SizeAndTopping
                                        onAdd={() =>
                                            onAddSizeAndTopping('topping')
                                        }
                                        onChange={onChangeSizeAndTopping}
                                        onRemove={onRemoveSizeAndTopping}
                                        name={'topping'}
                                        values={values.topping}
                                        disabled={
                                            setting.viewonly && !setting.add
                                        }
                                    />
                                </FormGroup>

                                {/* ICE */}
                                <div className='custom-line mb-4 mt-3' />
                                <FormGroup className='mb-4'>
                                    {setting.viewonly && !setting.add ? (
                                        <Label for='iceTitle'>
                                            {values.iceTitle ? (
                                                <span>{values.iceTitle}</span>
                                            ) : (
                                                <IntlMessages id='product.ice' />
                                            )}
                                        </Label>
                                    ) : (
                                        <div className='d-flex'>
                                            <Input
                                                className='mb-3 custom-input-underline w-30 pad-2px'
                                                type='text'
                                                name='iceTitle'
                                                id='iceTitle'
                                                disabled={
                                                    setting.viewonly &&
                                                    !setting.add
                                                }
                                                value={values.iceTitle}
                                                placeholder='Lượng đá'
                                                onChange={onChange}
                                            />
                                            <TooltipItem
                                                item={iceTooltip}
                                                id={14}
                                            />
                                        </div>
                                    )}
                                    <IceAndSugar
                                        selectedValue={values.ice}
                                        onSelected={onChangeIceAndSugar}
                                        name='ice'
                                        disabled={
                                            setting.viewonly && !setting.add
                                        }
                                    />
                                </FormGroup>

                                {/* SUGAR */}
                                <div className='custom-line mb-4 mt-3' />
                                <FormGroup className='mb-4'>
                                    {setting.viewonly && !setting.add ? (
                                        <Label for='sugarTitle'>
                                            {values.sugarTitle ? (
                                                <span>{values.sugarTitle}</span>
                                            ) : (
                                                <IntlMessages id='product.sugar' />
                                            )}
                                        </Label>
                                    ) : (
                                        <div className='d-flex'>
                                            <Input
                                                className='mb-3 custom-input-underline w-30 pad-2px'
                                                type='text'
                                                name='sugarTitle'
                                                id='sugarTitle'
                                                disabled={
                                                    setting.viewonly &&
                                                    !setting.add
                                                }
                                                value={values.sugarTitle}
                                                placeholder='Độ ngọt'
                                                onChange={onChange}
                                            />
                                            <TooltipItem
                                                item={sugarTooltip}
                                                id={15}
                                            />
                                        </div>
                                    )}
                                    <IceAndSugar
                                        selectedValue={values.sugar}
                                        onSelected={onChangeIceAndSugar}
                                        name='sugar'
                                        disabled={
                                            setting.viewonly && !setting.add
                                        }
                                    />
                                </FormGroup>
                            </>
                        ) : null}
                        <FormGroup check>
                            <Input
                                type='checkbox'
                                name='showQuantitative'
                                onChange={onShowQuantitative}
                                checked={show_quantitative}
                                id='show-show_quantitative'
                            />
                            <Label for='show-show_quantitative' check>
                                Tạo định lượng nhiều nguyên liệu
                            </Label>
                        </FormGroup>
                        {show_quantitative ? (
                            <>
                                <div className='custom-line mb-3 mt-4' />
                                <h5 className='text-center'>Định lượng</h5>
                                <Row>
                                    <Colxx
                                        sm={form_cost?.stock?.__isNew__ ? '8' : '12'}
                                    >
                                        <FormGroup>
                                            <Label
                                                for='stock-name'
                                                className='text-muted'
                                            >
                                                Tên nguyên liệu
                                            </Label>
                                            <Creatable
                                                className={'react-select-creatable'}
                                                onChange={(val) =>
                                                    onChangeCost(val, 'stock')
                                                }
                                                options={data_stocks}
                                                placeholder={
                                                    'Chọn 1 nguyên liệu hoặc tạo mới'
                                                }
                                                name='stock-name'
                                                id='stock-name'
                                                value={data_stocks?.value}
                                            />
                                        </FormGroup>
                                    </Colxx>
                                    {form_cost?.stock?.__isNew__ ? (
                                        <Colxx sm='4'>
                                            <FormGroup>
                                                <Label
                                                    for='unit'
                                                    className='text-muted'
                                                >
                                                    Đơn vị tính
                                                </Label>
                                                <Input
                                                    type='text'
                                                    name='unit'
                                                    onChange={onChangeCost}
                                                />
                                            </FormGroup>
                                        </Colxx>
                                    ) : null}
                                </Row>
                                {/* <Colxx sm='6'> */}
                                <FormGroup className='make_product_unit'>
                                    <Label for='unit' className='text-muted'>
                                        Số lượng dùng
                                    </Label>
                                    <InputGroup>
                                        <Input
                                            className={'order-number'}
                                            type={'text'}
                                            name='count'
                                            placeholder={'0'}
                                            step='0'
                                            // value={form_cost?.count || ''}
                                            onChange={onChangeCost}
                                        />
                                        <InputGroupAddon addonType='prepend'>
                                            {form_cost?.stock?.__isNew__
                                                ? form_cost?.unit || ''
                                                : form_cost?.stock?.unit || ''}
                                        </InputGroupAddon>
                                    </InputGroup>
                                </FormGroup>
                                <div className='d-flex justify-content-center cursor-pointer'>
                                    <Button
                                        outline
                                        size='sm'
                                        onClick={() =>
                                            form_cost?.stock?.__isNew__
                                                ? onAddNewStock()
                                                : onAddStock()
                                        }
                                    >
                                        + Thêm định lượng
                                    </Button>
                                </div>
                                {form_cost.make_new?.length ||
                                form_cost.make?.length ||
                                make_product?.length ? (
                                    <Separator className='mt-3 mb-3' />
                                ) : null}
                                <ul className={'orders-pending'}>
                                    {form_cost.make_new?.map((stock, idx) => (
                                        <li key={idx} className='mr-2'>
                                            <Button
                                                color='primary'
                                                className='px-3 py-2'
                                            >
                                                {stock.name}
                                                <Badge className='ml-2' color='light'>
                                                    {stock.count +
                                                        ' (' +
                                                        stock.unit +
                                                        ')'}
                                                </Badge>{' '}
                                                {
                                                    <Badge
                                                        onClick={() =>
                                                            onDeleteStock(
                                                                stock.name,
                                                                'name'
                                                            )
                                                        }
                                                        color='danger'
                                                    >
                                                        x
                                                    </Badge>
                                                }
                                            </Button>
                                        </li>
                                    ))}
                                </ul>
                                <ul className={'orders-pending'}>
                                    {form_cost.make?.map((stock, idx) => (
                                        <li key={idx} className='mr-2'>
                                            <Button
                                                color='primary'
                                                className='px-3 py-2'
                                            >
                                                {stock.name}
                                                <Badge className='ml-2' color='light'>
                                                    {stock.count +
                                                        ' (' +
                                                        stock.unit +
                                                        ')'}
                                                </Badge>{' '}
                                                {setting.viewonly &&
                                                !setting.add ? null : (
                                                    <Badge
                                                        onClick={() =>
                                                            onDeleteStock(
                                                                stock.id,
                                                                'id'
                                                            )
                                                        }
                                                        color='danger'
                                                    >
                                                        x
                                                    </Badge>
                                                )}
                                            </Button>
                                        </li>
                                    ))}
                                </ul>
                                {make_product?.length ? (
                                    <ul className={'orders-pending'}>
                                        {make_product?.map((stock, idx) => (
                                            <li key={idx} className='mr-2'>
                                                <Button
                                                    color='primary'
                                                    className='px-3 py-2'
                                                    onClick={null}
                                                >
                                                    {stock.stock.name}
                                                    <Badge
                                                        className='ml-2'
                                                        color='light'
                                                    >
                                                        {stock.count +
                                                            ' (' +
                                                            stock.stock.unit +
                                                            ')'}
                                                    </Badge>{' '}
                                                    <Badge
                                                        onClick={() =>
                                                            onDeleteStock(
                                                                stock.stock.id
                                                            )
                                                        }
                                                        color='danger'
                                                    >
                                                        x
                                                    </Badge>
                                                </Button>
                                            </li>
                                        ))}
                                    </ul>
                                ) : null}
                                <Row className='mt-3'>
                                    <Colxx xxs='12' lg='5'>
                                        <p className='text-muted text-small'>
                                            <IntlMessages id='make-product.cost' />
                                        </p>
                                        <p className='mb-3 text-lowercase'>
                                            <Badge color='primary' pill>
                                                {AddCommaNumber(priceCostWithValuesId)}
                                            </Badge>
                                        </p>
                                    </Colxx>
                                    <Colxx xxs='12' lg='7'>
                                        <p className='text-muted text-small'>
                                            <IntlMessages id='make-product.percent-cost' />
                                        </p>
                                        <p className='mb-3 text-capitalize'>
                                            <Badge color='primary' pill>
                                                {priceCostWithValuesId > 0
                                                    ? (
                                                          (priceCostWithValuesId /
                                                              (values.active_sale
                                                                  ? parseFloat(
                                                                        values.price_sale
                                                                    )
                                                                  : parseFloat(
                                                                        values.price
                                                                    ))) *
                                                          100
                                                      ).toFixed(2)
                                                    : 0}{' '}
                                                %
                                            </Badge>
                                        </p>
                                    </Colxx>
                                </Row>
                            </>
                        ) : null}
                            
                        <DynamicForm
                            values={values.dynamic_form}
                            data={data_dynamic_form}
                            onChange={onChangeDynamicForm}
                            disabled={setting.viewonly && !setting.add}
                        />
                    </PerfectScrollbar>
                </ModalBody>
                <ModalFooter>
                    <Button color='primary'>
                        <IntlMessages id='general.btn-update' />
                    </Button>{' '}
                </ModalFooter>
            </Form>
        </Modal>
    );
};

export default React.memo(ContentComponent);

const GetImage = (image) => {
    if (image.target.files) {
        console.log(image.target.files);

        return image.target.files[0].name;
    }

    return image;
};
