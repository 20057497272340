import React from 'react';
import { Badge, Button, Card, CardBody, CardTitle, Row } from 'reactstrap';
import { Colxx } from '../../../components/common/CustomBootstrap';
import IntlMessages from '../../../helpers/IntlMessages';
import { AddCommaNumber } from '../../../helpers/Utils';

const ContentDisplay = (props) => {
    const { make_products, values, data_stocks, toggleFunc } = props;
    // console.log('values: ', values);
    const make_product = make_products.filter(
        (product) => product.product_id === values.id
    );
    // console.log('make_product: ', make_product);

    let priceCost = make_product
        .map((item) => {
            let price = data_stocks?.find(
                (i) => i.id === item.stock.id
            )?.avarage_price;
            // console.log('data_stocks: ', data_stocks);
            // console.log('price: ', price);
            return parseFloat(price);
        })
        .reduce((sum, item) => sum + item, 0);

    // console.log('priceCost: ', priceCost);

    return (
        <Card className='mb-4'>
            <CardBody>
                <Row>
                    <Colxx sm='12' className='d-flex justify-content-between'>
                        <CardTitle>
                            <IntlMessages id='make-product.thumb-list' />
                        </CardTitle>
                        <Button
                            className='mb-5'
                            size='xs'
                            color='primary'
                            onClick={toggleFunc}
                        >
                            Thêm định lượng
                        </Button>
                    </Colxx>
                </Row>
                <Row>
                    <Colxx xxs='12' lg='6'>
                        <p className='text-muted text-small'>
                            <IntlMessages id='make-product.product-name' />
                        </p>
                        <p className='mb-3 text-capitalize'>{values.name}</p>
                    </Colxx>
                    <Colxx xxs='12' lg='6'>
                        <p className='text-muted text-small'>
                            <IntlMessages id='make-product.price' />
                        </p>
                        <p className='mb-3 text-capitalize'>
                            <Badge color='primary' pill>
                                {values.active_sale
                                    ? AddCommaNumber(values.price_sale)
                                    : AddCommaNumber(values.price)}
                            </Badge>
                        </p>
                    </Colxx>
                </Row>
                <Row>
                    <Colxx xxs='12' lg='6'>
                        <p className='text-muted text-small'>
                            <IntlMessages id='make-product.cost' />
                        </p>
                        <p className='mb-3 text-lowercase'>
                            <Badge color='primary' pill>
                                {AddCommaNumber(priceCost.toFixed())}
                            </Badge>
                        </p>
                    </Colxx>
                    <Colxx xxs='12' lg='6'>
                        <p className='text-muted text-small'>
                            <IntlMessages id='make-product.percent-cost' />
                        </p>
                        <p className='mb-3 text-capitalize'>
                            <Badge color='primary' pill>
                                {(
                                    (priceCost /
                                        (values.active_sale
                                            ? parseFloat(values.price_sale)
                                            : parseFloat(values.price))) *
                                    100
                                ).toFixed(2)}{' '}
                                %
                            </Badge>
                        </p>
                    </Colxx>
                </Row>
                <Row>
                    <Colxx xxs='12' lg='6'>
                        <p className='text-muted text-small'>
                            <IntlMessages id='make-product.number-product-used' />
                        </p>
                        <p className='mb-3 text-lowercase'>
                            {make_product?.length}
                        </p>
                    </Colxx>
                </Row>
                <Row>
                    {make_product?.length ? (
                        <ul className={'orders-pending'}>
                            {make_product?.map((stock, idx) => (
                                <li key={idx} className='pr-2'>
                                    <Button color='primary'>
                                        {stock.stock.name}
                                    </Button>
                                </li>
                            ))}
                        </ul>
                    ) : null}
                </Row>
            </CardBody>
        </Card>
    );
};

export default ContentDisplay;
