import React, { Component, Fragment } from 'react';
import { injectIntl } from 'react-intl';
import { Row, Card, CardBody, CardTitle } from 'reactstrap';
import { Colxx, Separator } from '../../../components/common/CustomBootstrap';
import Breadcrumb from '../../../containers/navs/Breadcrumb';
import Logs from '../../../containers/dashboards/Logs';
import StockStatus from '../../../containers/dashboards/StockStatus';
import SalesChartCard from '../../../containers/dashboards/SalesChartCard';

import axios from 'axios';
import { END_POINT, END_POINT_SOCKET } from '../../../constants/defaultValues';
import Pagination from '../../../containers/pages/Pagination';
import {
    NotificatioErrController,
    NotificatioSucController,
    CheckConfirmPassword,
} from '../../../helpers/Utils';
import io from 'socket.io-client';
import LogsHelper from '../../../helpers/Logs';
import PasswordBox from '../../../components/PasswordBox';
import { ThemeColors } from '../../../helpers/ThemeColors';
import IntlMessages from '../../../helpers/IntlMessages';
import { ContentMini } from '../../../components/preloader';
import moment from 'moment';
import { isRorF, isR, isRorRBDorRC } from '../../../helpers/Roles';
import classnames from 'classnames';

import {
    OutcomeTable,
    IncomeTable,
    OrderPaymentTable,
    StockLogTable,
    DebtTable,
    MakeProductTable,
    OrderTable,
    Heading,
    TotalCount,
} from '../../../containers/pages/analytics';

const colors = ThemeColors();

function collect(props) {
    return { data: props.data };
}

class ContentComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            license: false,

            setting: {
                compare: false,
                all: false,
                date_start: moment(),
                date_end: moment(),

                dateRange: {
                    startDate: new Date(),
                    endDate: new Date(),
                },
                dateLabel: `Hôm nay`,
            },

            data_outcome: {
                data: [],
                loading: true,
            },
            data_income: {
                data: [],
                loading: true,
            },
            data_order_payment: {
                data: [],
                loading: true,
            },
            data_stock_log: {
                data: [],
                loading: true,
            },
            data_debt: {
                data: [],
                loading: true,
            },
            data_make_product: {
                data: [],
                loading: true,
            },
            data_order_table: {
                data: [],
                loading: true,
            },
            data_log: {
                data: [],
                loading: true,
            },
            data_stock: {
                data: [],
                loading: true,
            },
            data_chart: {
                loading: true,
                labels: [],
                report: {
                    dates: [],
                    datas: [],
                },
                datasets: [
                    {
                        label: 'Doanh thu',
                        data: [],
                        borderColor: colors.themeColor1,
                        pointBackgroundColor: colors.foregroundColor,
                        pointBorderColor: colors.themeColor1,
                        pointHoverBackgroundColor: colors.themeColor1,
                        pointHoverBorderColor: colors.foregroundColor,
                        pointRadius: 6,
                        pointBorderWidth: 2,
                        pointHoverRadius: 8,
                        fill: false,
                        fileName: 'Report',
                    },
                    {
                        label: 'Chi phí',
                        data: [],
                        borderColor: 'rgba(220,220,220,1)',
                        pointBackgroundColor: colors.foregroundColor,
                        pointBorderColor: 'rgba(220,220,220,1)',
                        pointHoverBackgroundColor: 'rgba(220,220,220,1)',
                        pointHoverBorderColor: 'rgba(220,220,220,1)',
                        pointRadius: 6,
                        pointBorderWidth: 2,
                        pointHoverRadius: 8,
                        fill: false,
                        fileName: 'Report',
                    },
                ],
            },
            data_companies: {
                data: [],
                loading: true,
                selected: 'null',
            },
            stocks: {
                data: [],
            },
            products: {
                data: [],
            },
        };
        this.socket = io(END_POINT_SOCKET);
    }

    componentDidMount() {
        this.socket.emit('join room', {
            room_id: localStorage.getItem('user_id'),
        });

        this.socket.on('refresh-stock', (res) => {
            const {
                data: { data },
            } = res;
            this.setState((prev) => ({
                ...prev,
                data_stock: {
                    loading: false,
                    data: data,
                },
            }));
        });

        this.socket.on('analytics chart', (res) => {
            let cloneObj = Object.assign({}, this.state.data_chart);
            cloneObj.loading = false;
            cloneObj.labels = res.data.labels;
            cloneObj.report.dates = res.data.labels;
            cloneObj.report.income = res.data.income;
            cloneObj.report.outcome = res.data.outcome;
            cloneObj.datasets[0].data = res.data.income;
            cloneObj.datasets[0].label = 'Doanh thu';
            cloneObj.datasets[1].data = res.data.outcome;
            cloneObj.datasets[1].label = 'Chi phí';

            // cloneObj.datasets[0].borderColor = colors.themeColor1;
            // cloneObj.datasets[0].backgroundColor = colors.themeColor1_10;
            // cloneObj.datasets[0].borderWidth = 2;
            // cloneObj.datasets[1].borderColor = colors.themeColor2;
            // cloneObj.datasets[1].backgroundColor = colors.themeColor2_10;
            // cloneObj.datasets[1].borderWidth = 2;

            this.setState((prev) => ({
                ...prev,
                data_chart: cloneObj,
            }));
            // console.log('data_chart: ', this.state.data_chart);
        });

        this.onUpdateData();
    }

    onUpdateData = async () => {
        const { all, compare, date_start, date_end } = this.state.setting;
        const { selected } = this.state.data_companies;

        this.socket.emit('refresh-stock', {
            user_id: localStorage.getItem('user_id'),
            company_uuid: selected,
        });

        let _date_start = moment(date_start).clone().format('YYYY-MM-DD');
        let _date_end = moment(date_end).clone().format('YYYY-MM-DD');

        this.socket.emit('analytics chart', {
            user_id: localStorage.getItem('user_id'),
            all,
            date_start: _date_start,
            date_end: _date_end,
            company_uuid: selected,
        });

        this.dataCompanies();
        this.dataLog();
        this.dataOutcome();
        this.dataIncome();
        this.dataOrderPayment();
        this.dataStockLog();
        this.dataDebt();
        this.dataMakeProduct();
        this.dataOrderTable();
        this.dataStocks();
        this.dataProducts();
    };

    /** Func Helper */
    dataCompanies = () => {
        let tokenStr = localStorage.getItem('access_token');
        axios
            .get(`${END_POINT + '/companies/child'}`, {
                headers: { Authorization: `Bearer ${tokenStr}` },
            })
            .then((res) => res.data)
            .then((res) => {
                const first_value = {
                    label: 'Cửa hàng của tôi',
                    value: 'null',
                };

                const last_value = {
                    label: 'Tất cả',
                    value: 'all',
                };

                let data = [];
                data.push(first_value);
                data = data.concat(res.data);

                if (res.data.length) {
                    data.push(last_value);
                }

                this.setState((prev) => ({
                    ...prev,
                    data_companies: {
                        ...prev.data_companies,
                        loading: false,
                        data: data,
                    },
                }));
            })
            .catch((err) => {
                NotificatioErrController(
                    'Có lỗi xảy ra khi lấy dữ liệu ĐẠI LÝ'
                );
                return false;
            });
    };
    dataLog = () => {
        let tokenStr = localStorage.getItem('access_token');
        const { all, compare, date_start, date_end } = this.state.setting;
        const { selected } = this.state.data_companies;

        let _date_start = moment(date_start).clone().format('YYYY-MM-DD');
        let _date_end = moment(date_end).clone().format('YYYY-MM-DD');

        axios
            .get(
                `${END_POINT}/analytics/log?all=${all}&compare=${compare}&date_start=${_date_start}&date_end=${_date_end}&company_uuid=${selected}`,
                { headers: { Authorization: `Bearer ${tokenStr}` } }
            )
            .then((res) => res.data)
            .then((res) => {
                this.setState((prev) => ({
                    ...prev,
                    data_log: {
                        loading: false,
                        data: res.data,
                    },
                }));
            })
            .catch((err) =>
                NotificatioErrController(
                    'Có lỗi xảy ra khi lấy dữ liệu HOẠT ĐỘNG'
                )
            );
    };
    dataOutcome = () => {
        let tokenStr = localStorage.getItem('access_token');
        const { all, compare, date_start, date_end } = this.state.setting;
        const { selected } = this.state.data_companies;

        let _date_start = moment(date_start).clone().format('YYYY-MM-DD');
        let _date_end = moment(date_end).clone().format('YYYY-MM-DD');

        axios
            .get(
                `${END_POINT}/analytics/outcome?all=${all}&compare=${compare}&date_start=${_date_start}&date_end=${_date_end}&company_uuid=${selected}`,
                { headers: { Authorization: `Bearer ${tokenStr}` } }
            )
            .then((res) => res.data)
            .then((res) => {
                this.setState((prev) => ({
                    ...prev,
                    data_outcome: {
                        loading: false,
                        data: res.data.data,
                        total_price: res.data.total_price,
                        total_count: res.data.total_count,
                        compare_total: res.data.compare_total,
                    },
                }));
            })
            .catch((err) =>
                NotificatioErrController(
                    'Có lỗi xảy ra khi lấy dữ liệu PHIẾU CHI'
                )
            );
    };
    dataIncome = () => {
        let tokenStr = localStorage.getItem('access_token');
        const { all, compare, date_start, date_end } = this.state.setting;
        const { selected } = this.state.data_companies;

        let _date_start = moment(date_start).clone().format('YYYY-MM-DD');
        let _date_end = moment(date_end).clone().format('YYYY-MM-DD');

        axios
            .get(
                `${END_POINT}/analytics/income?all=${all}&compare=${compare}&date_start=${_date_start}&date_end=${_date_end}&company_uuid=${selected}`,
                { headers: { Authorization: `Bearer ${tokenStr}` } }
            )
            .then((res) => res.data)
            .then((res) => {
                this.setState((prev) => ({
                    ...prev,
                    data_income: {
                        loading: false,
                        data: res.data.data,
                        total_price: res.data.total_price,
                        total_count: res.data.total_count,
                        compare_total: res.data.compare_total,
                    },
                }));
            })
            .catch((err) =>
                NotificatioErrController(
                    'Có lỗi xảy ra khi lấy dữ liệu PHIẾU THU'
                )
            );
    };
    dataOrderPayment = () => {
        let tokenStr = localStorage.getItem('access_token');
        const { all, compare, date_start, date_end } = this.state.setting;
        const { selected } = this.state.data_companies;

        let _date_start = moment(date_start).clone().format('YYYY-MM-DD');
        let _date_end = moment(date_end).clone().format('YYYY-MM-DD');

        axios
            .get(
                `${END_POINT}/analytics/order?all=${all}&compare=${compare}&date_start=${_date_start}&date_end=${_date_end}&company_uuid=${selected}`,
                { headers: { Authorization: `Bearer ${tokenStr}` } }
            )
            .then((res) => res.data)
            .then((res) => {
                this.setState((prev) => ({
                    ...prev,
                    data_order_payment: {
                        loading: false,
                        data: res.data.data,
                        total_price: res.data.total_price,
                        total_count: res.data.total_count,
                        compare_total: res.data.compare_total,
                    },
                }));
            })
            .catch((err) =>
                NotificatioErrController(
                    'Có lỗi xảy ra khi lấy dữ liệu SẢN PHẨM ĐÃ BÁN'
                )
            );
    };
    dataStockLog = () => {
        let tokenStr = localStorage.getItem('access_token');
        const { all, compare, date_start, date_end } = this.state.setting;
        const { selected } = this.state.data_companies;

        let _date_start = moment(date_start).clone().format('YYYY-MM-DD');
        let _date_end = moment(date_end).clone().format('YYYY-MM-DD');

        axios
            .get(
                `${END_POINT}/analytics/stock-log?all=${all}&compare=${compare}&date_start=${_date_start}&date_end=${_date_end}&company_uuid=${selected}`,
                { headers: { Authorization: `Bearer ${tokenStr}` } }
            )
            .then((res) => res.data)
            .then((res) => {
                // console.log(res);
                return this.setState((prev) => ({
                    ...prev,
                    data_stock_log: {
                        loading: false,
                        data: res.data.data,
                        total_price: res.data.total_price,
                    },
                }));
            })
            .catch((err) =>
                NotificatioErrController(
                    'Có lỗi xảy ra khi lấy dữ liệu NGUYÊN LIỆU NHẬP'
                )
            );
    };
    dataDebt = () => {
        let tokenStr = localStorage.getItem('access_token');
        const { all, compare, date_start, date_end } = this.state.setting;
        const { selected } = this.state.data_companies;

        let _date_start = moment(date_start).clone().format('YYYY-MM-DD');
        let _date_end = moment(date_end).clone().format('YYYY-MM-DD');

        axios
            .get(
                `${END_POINT}/analytics/debt?all=${all}&compare=${compare}&date_start=${_date_start}&date_end=${_date_end}&company_uuid=${selected}`,
                { headers: { Authorization: `Bearer ${tokenStr}` } }
            )
            .then((res) => res.data)
            .then((res) => {
                this.setState((prev) => ({
                    ...prev,
                    data_debt: {
                        loading: false,
                        data: res.data.data,
                        total_price_income: res.data.total_price_income,
                        total_price_pending: res.data.total_price_pending,
                        total_count: res.data.total_count,
                    },
                }));
            })
            .catch((err) =>
                NotificatioErrController(
                    'Có lỗi xảy ra khi lấy dữ liệu CÔNG NỢ'
                )
            );
    };
    dataMakeProduct = () => {
        let tokenStr = localStorage.getItem('access_token');
        const { all, compare, date_start, date_end } = this.state.setting;
        const { selected } = this.state.data_companies;

        let _date_start = moment(date_start).clone().format('YYYY-MM-DD');
        let _date_end = moment(date_end).clone().format('YYYY-MM-DD');

        axios
            .get(
                `${END_POINT}/analytics/make-product?all=${all}&compare=${compare}&date_start=${_date_start}&date_end=${_date_end}&company_uuid=${selected}`,
                { headers: { Authorization: `Bearer ${tokenStr}` } }
            )
            .then((res) => res.data)
            .then((res) => {
                this.setState((prev) => ({
                    ...prev,
                    data_make_product: {
                        loading: false,
                        data: res.data.data,
                        total_price: res.data.total_price,
                    },
                }));
            })
            .catch((err) =>
                NotificatioErrController(
                    'Có lỗi xảy ra khi lấy dữ liệu NGUYÊN LIỆU SỬ DỤNG'
                )
            );
    };
    dataOrderTable = () => {
        let tokenStr = localStorage.getItem('access_token');
        const { all, compare, date_start, date_end } = this.state.setting;
        const { selected } = this.state.data_companies;

        let _date_start = moment(date_start).clone().format('YYYY-MM-DD');
        let _date_end = moment(date_end).clone().format('YYYY-MM-DD');

        axios
            .get(
                `${END_POINT}/analytics/table?all=${all}&compare=${compare}&date_start=${_date_start}&date_end=${_date_end}&company_uuid=${selected}`,
                { headers: { Authorization: `Bearer ${tokenStr}` } }
            )
            .then((res) => res.data)
            .then((res) => {
                // console.log('data: ', res.data.data);
                return this.setState((prev) => ({
                    ...prev,
                    data_order_table: {
                        loading: false,
                        data: res.data.data,
                        total_price: res.data.total_price,
                        total_discount: res.data.total_discount,
                        total_count: res.data.total_count,
                        total_table_has_guest: res.data.total_table_has_guest,
                        compare_total: res.data.compare_total,
                    },
                }));
            })
            .catch((err) =>
                NotificatioErrController(
                    'Có lỗi xảy ra khi lấy dữ liệu TỪNG BÀN'
                )
            );
    };

    dataStocks = () => {
        let tokenStr = localStorage.getItem('access_token');
        axios
            .get(`${END_POINT + '/stock/all-unit'}`, {
                headers: { Authorization: `Bearer ${tokenStr}` },
            })
            .then((res) => res.data)
            .then((data) => {
                // console.log(data);
                this.setState((prev) => ({
                    ...prev,
                    stocks: {
                        data: data.data,
                    },
                }));
            })
            .catch((err) => {
                NotificatioErrController(
                    'Có lỗi xảy ra khi lấy dữ liệu Nguyên vật liệu'
                );
            });
    };

    dataProducts = () => {
        let tokenStr = localStorage.getItem('access_token');
        axios
            .get(`${END_POINT + '/product/all'}`, {
                headers: { Authorization: `Bearer ${tokenStr}` },
            })
            .then((res) => res.data)
            .then((data) => {
                this.setState((prev) => ({
                    ...prev,
                    products: {
                        data: data.data,
                    },
                }));
            })
            .catch((err) => {
                NotificatioErrController(err.response.data.responseText);
                return false;
            });
    };

    /** Func */
    onToggleSetting = (_name, _value) => {
        this.setState((prev) => ({
            ...prev,
            setting: {
                ...prev.setting,
                [_name]: _value,
            },
        }));
    };

    onChangeDate = ({ date_start, date_end, all }) => {
        this.setState(
            (prev) => ({
                ...prev,
                setting: {
                    ...prev.setting,
                    date_start: date_start,
                    date_end: date_end,
                    all: all,
                    compare: all == true ? false : prev.setting.compare,
                },
                data_outcome: {
                    ...prev.data_outcome,
                    loading: true,
                },
                data_income: {
                    ...prev.data_income,
                    loading: true,
                },
                data_order_payment: {
                    ...prev.data_order_payment,
                    loading: true,
                },
                data_stock_log: {
                    ...prev.data_stock_log,
                    loading: true,
                },
                data_debt: {
                    ...prev.data_debt,
                    loading: true,
                },
                data_make_product: {
                    ...prev.data_make_product,
                    loading: true,
                },
                data_order_table: {
                    ...prev.data_order_table,
                    loading: true,
                },
                data_log: {
                    ...prev.data_log,
                    loading: true,
                },
                data_stock: {
                    ...prev.data_stock,
                    loading: true,
                },
                data_chart: {
                    ...prev.data_chart,
                    loading: true,
                },
            }),
            () => this.onUpdateData()
        );
    };

    onChangeCompanies = (selected) => {
        this.setState(
            (prev) => ({
                ...prev,
                data_outcome: {
                    ...prev.data_outcome,
                    loading: true,
                },
                data_income: {
                    ...prev.data_income,
                    loading: true,
                },
                data_order_payment: {
                    ...prev.data_order_payment,
                    loading: true,
                },
                data_stock_log: {
                    ...prev.data_stock_log,
                    loading: true,
                },
                data_debt: {
                    ...prev.data_debt,
                    loading: true,
                },
                data_make_product: {
                    ...prev.data_make_product,
                    loading: true,
                },
                data_order_table: {
                    ...prev.data_order_table,
                    loading: true,
                },
                data_log: {
                    ...prev.data_log,
                    loading: true,
                },
                data_stock: {
                    ...prev.data_stock,
                    loading: true,
                },
                data_chart: {
                    ...prev.data_chart,
                    loading: true,
                },
                data_companies: {
                    ...prev.data_companies,
                    selected: selected.value,
                },
            }),
            () => this.onUpdateData()
        );
    };

    onChangeSetting = ({
        dateLabel,
        dateRange,
        all,
        date_start,
        date_end,
        compare,
    }) => {
        dateLabel =
            dateLabel == null ? this.state.setting.dateLabel : dateLabel;
        dateRange =
            dateRange == null ? this.state.setting.dateRange : dateRange;
        all = all == null ? this.state.setting.all : all;
        compare = compare == null ? this.state.setting.compare : compare;
        date_start =
            date_start == null ? this.state.setting.date_start : date_start;
        date_end = date_end == null ? this.state.setting.date_end : date_end;

        this.setState((prev) => ({
            ...prev,
            setting: {
                ...prev.setting,
                dateLabel,
                dateRange,
                all,
                date_start,
                date_end,
                compare,
            },
        }));
    };
    /** End Func */

    render() {
        const {
            setting,
            data_outcome,
            data_income,
            data_order_payment,
            data_stock_log,
            data_debt,
            data_make_product,
            data_order_table,
            data_log,
            data_stock,
            data_chart,
            data_companies,
            stocks,
            products,
        } = this.state;
        // console.log('stocks: ', stocks);
        // console.log('products: ', products);

        // console.log(data_make_product);
        // console.log('data_order_table: ', data_order_table);
        // console.log('data_outcome: ', data_outcome.data);
        // console.log('data_order_payment: ', data_order_payment);

        return (
            <div className='disable-text-selection'>
                {data_companies.loading ? null : (
                    <Heading
                        onChangeSetting={this.onChangeSetting}
                        onToggleSetting={this.onToggleSetting}
                        onChangeDate={this.onChangeDate}
                        onChangeCompanies={this.onChangeCompanies}
                        data_companies={data_companies}
                        setting={setting}
                    />
                )}

                {data_outcome.loading ||
                data_income.loading ||
                data_order_payment.loading ||
                data_order_table.loading ? (
                    <Row>
                        <Colxx lg='12' xl='12' className='mb-4'>
                            <Card className='h-100 dashboard-list-with-thumbs-lg'>
                                <CardBody>
                                    <ContentMini />
                                </CardBody>
                            </Card>
                        </Colxx>
                    </Row>
                ) : (
                    <TotalCount
                        all={setting.all}
                        stocks={stocks}
                        products={products}
                        data_outcome={data_outcome}
                        data_income={data_income}
                        data_order_table={data_order_table}
                        data_order_payment={data_order_payment}
                        costStock={data_make_product.total_price}
                    />
                )}

                <Row>
                    <Colxx lg='12' xl='8' className='mb-4'>
                        {data_chart.loading ? (
                            <Card className='h-100 dashboard-list-with-thumbs-lg'>
                                <CardBody>
                                    <ContentMini />
                                </CardBody>
                            </Card>
                        ) : (
                            <SalesChartCard
                                license={true}
                                lineChartData={data_chart}
                            />
                        )}
                    </Colxx>
                    <Colxx lg='12' xl='4' className='mb-4'>
                        {data_log.loading ? (
                            <Card className='h-100 dashboard-list-with-thumbs-lg'>
                                <CardBody>
                                    <ContentMini />
                                </CardBody>
                            </Card>
                        ) : (
                            <Logs data={data_log.data} />
                        )}
                    </Colxx>
                </Row>

                <Row>
                    {/* OUTCOME */}
                    <Colxx lg='12' xl='6' className='mb-4'>
                        <Card
                            className={classnames(
                                'h-100 dashboard-list-with-thumbs-lg',
                                {
                                    disable: !isRorRBDorRC('analytics_outcome'),
                                }
                            )}
                        >
                            <CardBody>
                                {data_outcome.loading ? (
                                    <ContentMini />
                                ) : (
                                    <OutcomeTable
                                        setting={setting}
                                        title={
                                            <IntlMessages id='analytics.dashboards.outcome' />
                                        }
                                        data={data_outcome.data}
                                        total_count={data_outcome.total_count}
                                        total_price={data_outcome.total_price}
                                    />
                                )}
                            </CardBody>
                        </Card>
                    </Colxx>

                    {/* INCOME */}
                    <Colxx lg='12' xl='6' className='mb-4'>
                        <Card
                            className={classnames(
                                'h-100 dashboard-list-with-thumbs-lg',
                                {
                                    disable: !isRorRBDorRC('analytics_income'),
                                }
                            )}
                        >
                            <CardBody>
                                {data_income.loading ? (
                                    <ContentMini />
                                ) : (
                                    <IncomeTable
                                        setting={setting}
                                        title={
                                            <IntlMessages id='analytics.dashboards.income' />
                                        }
                                        data={data_income.data}
                                        total_count={data_income.total_count}
                                        total_price={data_income.total_price}
                                    />
                                )}
                            </CardBody>
                        </Card>
                    </Colxx>

                    {/* ORDER PAYMENT */}
                    <Colxx lg='12' xl='6' className='mb-4'>
                        <Card
                            className={classnames(
                                'h-100 dashboard-list-with-thumbs-lg',
                                {
                                    disable: !isRorRBDorRC(
                                        'analytics_order_payment'
                                    ),
                                }
                            )}
                        >
                            <CardBody>
                                {data_order_payment.loading ? (
                                    <ContentMini />
                                ) : (
                                    <OrderPaymentTable
                                        setting={setting}
                                        title={
                                            <IntlMessages id='analytics.dashboards.order-payment' />
                                        }
                                        data={data_order_payment.data}
                                        total_count={
                                            data_order_payment.total_count
                                        }
                                        total_price={
                                            data_order_payment.total_price
                                        }
                                    />
                                )}
                            </CardBody>
                        </Card>
                    </Colxx>

                    {/* STOCK LOG */}
                    <Colxx lg='12' xl='6' className='mb-4'>
                        <Card
                            className={classnames(
                                'h-100 dashboard-list-with-thumbs-lg',
                                {
                                    disable: !isRorRBDorRC(
                                        'analytics_stock_logs'
                                    ),
                                }
                            )}
                        >
                            <CardBody>
                                {data_stock_log.loading ? (
                                    <ContentMini />
                                ) : (
                                    <StockLogTable
                                        setting={setting}
                                        title={
                                            <IntlMessages id='analytics.dashboards.stock-log' />
                                        }
                                        data={data_stock_log.data}
                                        total_price={data_stock_log.total_price}
                                    />
                                )}
                            </CardBody>
                        </Card>
                    </Colxx>

                    {/* DEBT */}
                    <Colxx lg='12' xl='6' className='mb-4'>
                        <Card
                            className={classnames(
                                'h-100 dashboard-list-with-thumbs-lg',
                                {
                                    disable: !isRorRBDorRC('analytics_debt'),
                                }
                            )}
                        >
                            <CardBody>
                                {data_debt.loading ? (
                                    <ContentMini />
                                ) : (
                                    <DebtTable
                                        setting={setting}
                                        title={
                                            <IntlMessages id='analytics.dashboards.debt' />
                                        }
                                        data={data_debt.data}
                                        total_count={data_debt.total_count}
                                        total_price_income={
                                            data_debt.total_price_income
                                        }
                                        total_price_pending={
                                            data_debt.total_price_pending
                                        }
                                    />
                                )}
                            </CardBody>
                        </Card>
                    </Colxx>

                    {/* MAKE PRODUCT */}
                    <Colxx lg='12' xl='6' className='mb-4'>
                        <Card
                            className={classnames(
                                'h-100 dashboard-list-with-thumbs-lg',
                                {
                                    disable: !isRorRBDorRC(
                                        'analytics_make_product'
                                    ),
                                }
                            )}
                        >
                            <CardBody>
                                {data_make_product.loading ? (
                                    <ContentMini />
                                ) : (
                                    <MakeProductTable
                                        setting={setting}
                                        title={
                                            <IntlMessages id='analytics.dashboards.make-product' />
                                        }
                                        data={data_make_product.data}
                                        total_price={
                                            data_make_product.total_price
                                        }
                                    />
                                )}
                            </CardBody>
                        </Card>
                    </Colxx>

                    {/* ORDER TABLE */}
                    <Colxx lg='12' xl='6' className='mb-4'>
                        <Card
                            className={classnames(
                                'h-100 dashboard-list-with-thumbs-lg',
                                {
                                    disable: !isRorRBDorRC('analytics_table'),
                                }
                            )}
                        >
                            <CardBody>
                                {data_order_table.loading ? (
                                    <ContentMini />
                                ) : (
                                    <OrderTable
                                        setting={setting}
                                        title={
                                            <IntlMessages id='analytics.dashboards.order-table' />
                                        }
                                        data={data_order_table.data}
                                        total_count={
                                            data_order_table.total_count
                                        }
                                        total_price={
                                            data_order_table.total_price
                                        }
                                        total_discount={
                                            data_order_table.total_discount
                                        }
                                    />
                                )}
                            </CardBody>
                        </Card>
                    </Colxx>

                    <Colxx lg='12' xl='6' className='mb-4'>
                        {data_stock.loading ? (
                            <Card className='h-100 dashboard-list-with-thumbs-lg'>
                                <CardBody>
                                    <ContentMini />
                                </CardBody>
                            </Card>
                        ) : (
                            <StockStatus
                                items={data_stock.data}
                                unit={stocks.data}
                            />
                        )}
                    </Colxx>
                </Row>
            </div>
        );
    }
}

export default ContentComponent;
