import React from 'react';
import { Row, Card, CardBody, Badge } from 'reactstrap';
import { Colxx, Separator } from '../../../components/common/CustomBootstrap';
import IntlMessages from '../../../helpers/IntlMessages';
import { AddCommaNumber } from '../../../helpers/Utils';

const ContentComponent = ({
    stocks,
    products,
    data_outcome,
    data_income,
    data_order_table,
    data_order_payment,
    costStock,
    all,
}) => {

    const stocksRemaining = stocks?.data?.filter((item) => item.count > 0)?.length;

    const totalInventoryRemaining = stocks?.data?.reduce((acc, curr) => {
        const { avarage_price, count } = curr;
        return count > 0 && !isNaN(count) && !isNaN(avarage_price)
            ? Math.floor(acc + avarage_price * count)
            : acc;
    }, 0);
    
    const totalInventory = stocks?.data?.reduce(
        (acc, { total_value }) => acc + (isNaN(total_value) ? 0 : Math.floor(total_value)),
        0
    );

    const get_income = GetIncomeDataAndCompare({
        income: data_income,
        outcome: data_outcome,
    });
    
    const filterOutcomeNotStock = data_outcome?.data?.filter((item) => item.id !== 1)

    const fixedCost = filterOutcomeNotStock?.reduce((acc, curr) => { return acc + Number(curr.price) }, 0);

    const netRevenue = Number(data_income.total_price) - Number(costStock) - Number(fixedCost);

    return (
        <>
            <Card className='progress-banner w-100 mb-4'>
                <CardBody>
                    <Row className='d-flex align-items-center justify-content-center mb-3'>
                        <Colxx
                            xxs='6'
                            md='3'
                            className='d-flex flex-column align-items-center justify-content-center mt-3 order-2 order-md-1'
                        >
                        <p>
                            <IntlMessages id='analytics.revenue' />
                        </p>
                        <div className='d-flex flex-column flex-lg-row'>
                            <h5 className='mr-2 text-white'>
                                {`${AddCommaNumber(data_income.total_price)} đ`}
                            </h5>
                        </div>
                        {!all ? (
                            <>
                                <Badge
                                    color={
                                        data_income.compare_total.compare_price
                                            .increment
                                            ? 'success'
                                            : 'danger'
                                    }
                                >
                                    {`${
                                        data_income.compare_total.compare_price
                                            .increment
                                            ? '+'
                                            : ''
                                    } ${
                                        data_income.compare_total.compare_price
                                            .value
                                    } %`}
                                </Badge>
                            </>
                        ) : null}
                        </Colxx>
                        <Colxx
                            xxs='12'
                            md='6'
                            className='d-flex flex-column align-items-center justify-content-center order-1 order-md-2'
                        >
                            <h4>
                                Lợi nhuận
                            </h4>
                            <Row className='d-flex align-items-end justify-content-around w-50 mt-1 mb-2'>
                                    <h2 className='text-white mb-0'>
                                    {`${AddCommaNumber(
                                            netRevenue
                                        )} đ`}
                                    </h2>
                            </Row>
                            <Row className='d-flex align-items-end justify-content-around w-50 mb-3'>
                                    {!all ? (
                                        <>
                                            <Badge
                                                color={
                                                    data_income.compare_total
                                                        .compare_price.increment
                                                        ? 'success'
                                                        : 'danger'
                                                }
                                            >
                                                {`${
                                                    get_income.compare_price.increment
                                                        ? '+'
                                                        : ''
                                                } ${get_income.compare_price.value} %`}
                                            </Badge>
                                        </>
                                    ) : null}
                            </Row>
                            
                        </Colxx>
                        <Colxx
                            xxs='6'
                            md='3'
                            className='d-flex flex-column align-items-center justify-content-center mt-3 order-3 order-md-3'
                        >
                            <h4></h4>
                            <p>
                                <IntlMessages id='analytics.costs' />
                            </p>
                            <div className='d-flex flex-column flex-lg-row'>
                                <h5 className='mr-2 text-white'>
                                    {`${AddCommaNumber(
                                        fixedCost
                                    )} đ`}
                                </h5>
                            </div>
                            {!all ? (
                                <>
                                    <Badge
                                        color={
                                            data_income.compare_total
                                                .compare_price.increment
                                                ? 'success'
                                                : 'danger'
                                        }
                                    >
                                        {`${
                                            data_outcome.compare_total
                                                .compare_price.increment
                                                ? '+'
                                                : ''
                                        } ${
                                            data_outcome.compare_total
                                                .compare_price.value
                                        } %`}
                                    </Badge>
                                </>
                            ) : null}
                        </Colxx>
                    </Row>
                    <Separator className='mb-3 mt-5' />
                    <Row>
                        <Colxx
                            xxs='6'
                            md='3'
                            className='d-flex flex-column align-items-center justify-content-center mt-3 order-3 order-md-1'
                        >
                            <p>
                                <IntlMessages id='analytics.average-selling-price' />
                            </p>
                            <div className='d-flex flex-column flex-lg-row'>
                                <h5 className='mr-2 text-white'>
                                    {data_order_payment?.total_count
                                        ? AddCommaNumber(
                                              (
                                                  parseFloat(
                                                      data_order_payment?.total_price
                                                  ) /
                                                  data_order_payment?.total_count
                                              ).toFixed(0)
                                          )
                                        : 0}{' '}
                                    đ
                                </h5>
                            </div>
                        </Colxx>
                        <Colxx
                            xxs='6'
                            md='3'
                            className='d-flex flex-column align-items-center justify-content-center mt-3 order-2 order-md-2'
                        >
                            <p>
                                <IntlMessages id='analytics.number-products-sold' />
                            </p>
                            <div className='d-flex flex-column flex-lg-row'>
                                <h5 className='mr-2 text-white'>
                                    {`${data_order_payment.total_count} sản phẩm`}
                                </h5>
                            </div>
                            {!all ? (
                                <Badge
                                    color={
                                        data_income.compare_total.compare_price
                                            .increment
                                            ? 'success'
                                            : 'danger'
                                    }
                                >
                                    {`${
                                        data_order_payment.compare_total
                                            .compare_count.increment
                                            ? '+'
                                            : ''
                                    } ${
                                        data_order_payment.compare_total
                                            .compare_count.value
                                    } %`}
                                </Badge>
                            ) : null}
                        </Colxx>
                        <Colxx
                            xxs='6'
                            md='3'
                            className='d-flex flex-column align-items-center justify-content-center mt-3 order-1 order-md-3'
                        >
                            <p>
                                <IntlMessages id='analytics.invoice-quantity' />
                            </p>
                            <div className='d-flex flex-column flex-lg-row'>
                                <h5 className='mr-2 text-white'>
                                    {`${data_order_table.total_table_has_guest} hoá đơn`}
                                </h5>
                            </div>
                            {!all ? (
                                <Badge
                                    color={
                                        data_income.compare_total.compare_price
                                            .increment
                                            ? 'success'
                                            : 'danger'
                                    }
                                >
                                    {`${
                                        data_order_table.compare_total
                                            .compare_count.increment
                                            ? '+'
                                            : ''
                                    } ${
                                        data_order_table.compare_total
                                            .compare_count.value
                                    } %`}
                                </Badge>
                            ) : null}
                        </Colxx>
                        <Colxx
                            xxs='6'
                            md='3'
                            className='d-flex flex-column align-items-center justify-content-center mt-3 order-4 order-md-4'
                        >
                            <p>
                                <IntlMessages id='analytics.total-product' />
                            </p>
                            <div className='d-flex flex-column flex-lg-row'>
                                <h5 className='mr-2 text-white'>
                                    {products?.data?.length}{' '}
                                    <IntlMessages id='analytics.product' />
                                </h5>
                            </div>
                        </Colxx>
                        <Colxx
                            xxs='6'
                            md='3'
                            className='d-flex flex-column align-items-center justify-content-center mt-3 order-5 order-md-5'
                        >
                            <p>
                                <IntlMessages id='analytics.amount-of-inventory' />
                            </p>
                            <div className='d-flex flex-column flex-lg-row'>
                                <h5 className='mr-2 text-white'>
                                    {stocksRemaining}{' '}
                                    sản phẩm
                                </h5>
                            </div>
                        </Colxx>
                        <Colxx
                            xxs='6'
                            md='3'
                            className='d-flex flex-column align-items-center justify-content-center mt-3 order-6 order-md-6'
                        >
                            <p>
                                <IntlMessages id='analytics.inventory-value' />
                            </p>
                            <div className='d-flex flex-column flex-lg-row'>
                                <h5 className='mr-2 text-white'>
                                    {AddCommaNumber(totalInventoryRemaining)} đ
                                </h5>
                            </div>
                        </Colxx>
                        <Colxx
                            xxs='6'
                            md='3'
                            className='d-flex flex-column align-items-center justify-content-center mt-3 order-7 order-md-7 border-right-above-md'
                        >
                            <p>
                                Lượng hàng đã nhập
                            </p>
                            <div className='d-flex flex-column flex-lg-row'>
                                <h5 className='mr-2 text-white'>
                                    {stocks?.data?.length} sản phẩm
                                </h5>
                            </div>
                        </Colxx>
                        <Colxx
                            xxs='6'
                            md='3'
                            className='d-flex flex-column align-items-center justify-content-center mt-3 order-8 order-md-8 border-right-above-md '
                        >
                            <p>
                                Tổng giá trị hàng hóa
                            </p>
                            <div className='d-flex flex-column flex-lg-row'>
                                <h5 className='mr-2 text-white'>
                                    {AddCommaNumber(totalInventory)} đ
                                </h5>
                            </div>
                        </Colxx>
                        
                    </Row>
                </CardBody>
            </Card>
        </>
    );
};

const GetIncomeDataAndCompare = ({ income, outcome }) => {
    let result = { compare_price: {} };
    const total_get_income = Math.round(
        parseFloat(income.total_price) - parseFloat(outcome.total_price)
    );
    const total_flip_get_income = Math.round(
        parseFloat(income.compare_total.compare_price.flip_value) -
            parseFloat(outcome.compare_total.compare_price.flip_value)
    );

    result.total_price = total_get_income;

    // So sánh
    // Giá tiền
    let percent_change_price = 0;
    const change_price =
        parseFloat(total_get_income) - parseFloat(total_flip_get_income);
    percent_change_price =
        total_flip_get_income == 0
            ? 0
            : (change_price * 100) /
              Math.abs(parseFloat(total_flip_get_income));

    result.compare_price.increment = change_price <= 0 ? false : true;
    result.compare_price.value = Math.round(percent_change_price * 100) / 100;
    result.compare_price.flip_value = total_flip_get_income;

    return result;
};

export default ContentComponent;
