import React, { memo, useState } from 'react';
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Input,
    Label,
    Form,
    FormGroup,
    Row,
} from 'reactstrap';
import IntlMessages from '../../../helpers/IntlMessages';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Colxx } from '../../../components/common/CustomBootstrap';
import { Creatable } from 'react-select';
import DynamicForm from '../general/DynamicForm';
import TooltipItem from '../../../components/common/TooltipItem';
import { AddCommaNumber, AddCommaPhoneNumber } from '../../../helpers/Utils';

// import { Formik, Field } from 'formik';
// import * as Yup from 'yup';

// const SignupSchema = Yup.object().shape({
//     firstName: Yup.string()
//         .min(2, 'Too Short!')
//         .max(50, 'Too Long!')
//         .required('Required'),
//     email: Yup.string().email('Invalid email').required('Required'),
// });

const ContentComponent = ({
    title,
    isOpen,
    toggle,
    onSubmit,
    setting,
    children,
    values,
    onChange,
    data_tags,
    data_dynamic_form,
    onChangeDynamicForm,
}) => {
    // console.log('render AddComponent');
    const [tagsTooltip] = useState({
        placement: 'top',
        text: '',
        body: 'Dùng để phân loại khách hàng. Ví dụ: "Khách mới", "Khách VIP"... ',
    });

    return (
        <Modal
            isOpen={isOpen}
            toggle={toggle}
            wrapClassName='modal-right'
            backdrop={true}
            autoFocus={false}
        >
            <Form onSubmit={(e) => onSubmit(e)}>
                <ModalHeader toggle={toggle}>{title}</ModalHeader>
                <ModalBody className='scrool modal-right-add'>
                    <PerfectScrollbar
                        options={{
                            suppressScrollX: true,
                            wheelPropagation: false,
                        }}
                        id='modal-right__body'
                    >
                        <FormGroup className='mt-3 has-float-label'>
                            <Label for='name' className='text-muted'>
                                <IntlMessages id='customer.name' /><span style={{color: 'red'}}>*</span>
                            </Label>
                            <Input
                                autoFocus={true}
                                // autoFocus
                                type='text'
                                name='name'
                                id='name'
                                value={values.name}
                                onChange={onChange}
                            />
                        </FormGroup>
                        <Row>
                            <Colxx sm={12} md={12} lg={12}>
                                <FormGroup className='has-float-label'>
                                    <Label for='phone' className='text-muted'>
                                        <IntlMessages id='customer.phone' /><span style={{color: 'red'}}>*</span>
                                    </Label>
                                    <Input
                                        type='phone'
                                        name='phone'
                                        id='phone'
                                        value={AddCommaPhoneNumber(
                                            values.phone
                                        )}
                                        onChange={onChange}
                                    />
                                </FormGroup>
                            </Colxx>
                            <Colxx sm={12} md={12} lg={12}>
                                <FormGroup className='has-float-label'>
                                    <Label for='email' className='text-muted'>
                                        <IntlMessages id='customer.email' />
                                    </Label>
                                    <Input
                                        type='email'
                                        name='email'
                                        id='email'
                                        value={values.email}
                                        onChange={onChange}
                                    />
                                </FormGroup>
                            </Colxx>
                        </Row>
                        <FormGroup className='has-float-label'>
                            <Label for='address' className='text-muted'>
                                <IntlMessages id='customer.address' />
                            </Label>
                            <Input
                                type='text'
                                name='address'
                                id='address'
                                value={values.address}
                                onChange={onChange}
                            />
                        </FormGroup>
                        <FormGroup className='has-float-label'>
                            <Label for='address' className='text-muted'>
                                Công nợ (nếu có)
                            </Label>
                            <Input
                                type='text'
                                name='debt'
                                id='debt'
                                value={AddCommaNumber(values.debt)}
                                onChange={onChange}
                            />
                        </FormGroup>

                        <FormGroup className='has-float-label'>
                            <Label for='tags' className='text-muted'>
                                <IntlMessages id='customer.tags' />
                                <TooltipItem item={tagsTooltip} id={5} />
                            </Label>
                            <Creatable
                                className={'react-select-creatable'}
                                isMulti
                                onChange={(val) => onChange(val, 'tags')}
                                options={data_tags}
                                placeholder={'Chọn 1 tag hoặc tạo mới'}
                                name='tags'
                                id='tags'
                                value={values.tags}
                            />
                        </FormGroup>

                        <DynamicForm
                            values={values.dynamic_form}
                            data={data_dynamic_form}
                            onChange={onChangeDynamicForm}
                            // disabled={setting.viewonly && !setting.add}
                        />
                    </PerfectScrollbar>
                </ModalBody>
                <ModalFooter>
                    <Button color='primary'>
                        <IntlMessages id='customer.btn-add' />
                    </Button>{' '}
                </ModalFooter>
            </Form>
            {/* )} */}
            {/* </Formik> */}
        </Modal>
    );
};

export default memo(ContentComponent);
