import React, { memo } from 'react';
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Form,
    Input,
    Label,
    FormGroup,
} from 'reactstrap';
import IntlMessages from '../../../helpers/IntlMessages';
import PerfectScrollbar from 'react-perfect-scrollbar';

import { AddCommaNumber } from '../../../helpers/Utils';

const ContentComponent = ({
    title,
    isOpen,
    toggle,
    onSubmit,
    values,
    setting,
    onChange,
}) => {
    // console.log('render AddComponent');
    return (
        <Modal
            isOpen={isOpen}
            toggle={toggle}
            wrapClassName='modal-right'
            backdrop={true}
            autoFocus={false}
        >
            <Form onSubmit={(e) => onSubmit(e)}>
                <ModalHeader toggle={toggle}>{title}</ModalHeader>
                <ModalBody className='scrool modal-right-add'>
                    <PerfectScrollbar
                        options={{
                            suppressScrollX: true,
                            wheelPropagation: false,
                        }}
                        id='modal-right__body'
                    >
                        <FormGroup className='mt-3 has-float-label'>
                            <Label for='item_name' className='text-muted'>
                                <IntlMessages id='company-provider.item_name' /><span style={{color: 'red'}}>*</span>
                            </Label>
                            <Input
                                type='text'
                                name='item_name'
                                id='item_name'
                                disabled={true}
                                value={values.item_name}
                            />
                        </FormGroup>
                        <FormGroup className='has-float-label'>
                            <Label for='price_outcome' className='text-muted'>
                                <IntlMessages id='company-provider.price' /><span style={{color: 'red'}}>*</span>
                            </Label>
                            <Input
                                autoFocus={true}
                                name='price_outcome'
                                id='price_outcome'
                                value={AddCommaNumber(values.price_outcome)}
                                onChange={onChange}
                            />
                        </FormGroup>
                    </PerfectScrollbar>
                </ModalBody>
                <ModalFooter>
                    <Button color='primary'>
                        <IntlMessages id='general.btn-add' />
                    </Button>{' '}
                </ModalFooter>
            </Form>
        </Modal>
    );
};

export default memo(ContentComponent);
