import React from 'react';
import classnames from 'classnames';
import { END_POINT } from '../../../constants/defaultValues';
import { Colxx } from '../../../components/common/CustomBootstrap';
import { Input, InputGroup, Badge, Row, Collapse } from 'reactstrap';
import { AddCommaNumber } from '../../../helpers/Utils';
import ProductDetail from './ProductDetail';

const ThumbListViewMenu = ({
    product,
    order,
    sub,
    plus,
    change,
    isVerify,
    onToggleDetail,
    detailDisplay,
    orderDisplay,
    // sizeSelectedId,
    // toppingSelectedId,
    // iceSelectedValue,
    // sugarSelectedValue,
    // toppingSelectedIdLen,
    // showNote,
}) => {
    // console.log('order: ', order);
    return (
        <div>
            <div
                className={classnames({
                    card: detailDisplay,
                    'mb-3': detailDisplay,
                })}
            >
                <div
                    className={classnames('card d-flex flex-row mb-3', {
                        disable: product.softHide ? 'disable' : '',
                    })}
                >
                    <div
                        className={
                            'd-flex flex-grow-1 min-width-zero z-index-2 position-relative'
                        }
                        // onClick={() =>
                        //     detailDisplay
                        //         ? null
                        //         : onToggleDetail({
                        //               product,
                        //               name: 'detailDisplay',
                        //           })
                        // }
                    >
                        <div
                            className='d-block position-relative cursor-pointer m-1'
                            onClick={
                                product.softHide
                                    ? null
                                    : () => {
                                          plus(product.id);
                                      }
                            }
                        >
                            <img
                                alt={product.name}
                                src={
                                    product.image.indexOf('http') >= 0
                                        ? product.image
                                        : END_POINT + '/public/' + product.image
                                }
                                onError={(e) => {
                                    e.target.onerror = null;
                                    e.target.src =
                                        'https://placehold.co/320x240';
                                }}
                                className='list-thumbnail responsive border-0'
                            />
                            {product.bestter ? (
                                <Badge
                                    className='position-absolute badge-top-right'
                                    color={'success'}
                                    pill
                                >
                                    {'Khuyên dùng'}
                                </Badge>
                            ) : (
                                <></>
                            )}
                            {/* <span className='count-order'>{order?.count}</span> */}
                        </div>
                        <div
                            className={classnames('custom-card-body', {
                                'cursor-body-menu':
                                    product.ice.length ||
                                    product.size.length ||
                                    product.sugar.length ||
                                    product.topping.length,
                                // 'cursor-pointer':
                                //     !product.ice.length &&
                                //     !product.size.length &&
                                //     !product.sugar.length &&
                                //     !product.topping.length,
                            })}
                            onClick={
                                product.ice.length ||
                                product.size.length ||
                                product.sugar.length ||
                                product.topping.length
                                    ? () =>
                                          onToggleDetail({
                                              product,
                                              name: 'detailDisplay',
                                          })
                                    : null
                                // : () => {
                                //       plus(product.id);
                                //   }
                            }
                        >
                            <Row>
                                <Colxx className='col-8'>
                                    <p
                                        className={classnames(
                                            'list-item-heading mb-1 d-block w-100 w-sm-100',
                                            {
                                                'color-primary':
                                                    order?.count > 0,
                                            }
                                        )}
                                    >
                                        {order?.count > 0
                                            ? `x${order?.count} - `
                                            : null}
                                        {product.name}
                                    </p>
                                </Colxx>
                                {product.softHide ? (
                                    <Colxx className='col-4'></Colxx>
                                ) : (
                                    <Colxx className='col-4'>
                                        <Row className='order-number-center m-end'>
                                            <Colxx
                                                xxs='12'
                                                xs='12'
                                                md='12'
                                                lg='12'
                                                className='no-padding m-0-auto d-flex flex-column justify-content-between'
                                            >
                                                <div>
                                                    {product.active_sale ? (
                                                        <>
                                                            <Badge
                                                                color='light'
                                                                pill
                                                                className='mb-1'
                                                            >
                                                                <del>
                                                                    {AddCommaNumber(
                                                                        product.price
                                                                    )}
                                                                </del>
                                                            </Badge>
                                                            <Badge
                                                                color='primary'
                                                                pill
                                                                className='mb-1'
                                                            >
                                                                {AddCommaNumber(
                                                                    product.price_sale
                                                                )}
                                                            </Badge>
                                                        </>
                                                    ) : (
                                                        <Badge
                                                            color='primary'
                                                            pill
                                                        >
                                                            {AddCommaNumber(
                                                                product.price
                                                            )}
                                                        </Badge>
                                                    )}
                                                    <br />
                                                </div>
                                            </Colxx>
                                        </Row>
                                    </Colxx>
                                )}
                                {(product.ice.length ||
                                    product.size.length ||
                                    product.sugar.length ||
                                    product.topping.length) &&
                                detailDisplay === false ? (
                                    // <span className='arrow-down-menu'>
                                    //     Thêm tuỳ chọn
                                    // </span>
                                    <i className='iconsminds-arrow-down arrow-down-menu' />
                                ) : (product.ice.length ||
                                      product.size.length ||
                                      product.sugar.length ||
                                      product.topping.length) &&
                                  detailDisplay === true ? (
                                    <i className='iconsminds-arrow-up arrow-down-menu' />
                                ) : null}
                            </Row>
                            {/* {detailDisplay ? (
                                <Row className='row-bottom'>
                                    <Colxx className='col-12'>
                                        {product.showNote ? (
                                            <InputGroup size='sm'>
                                                <NoteInput
                                                    product={product}
                                                    onToggleDetail={
                                                        onToggleDetail
                                                    }
                                                />
                                            </InputGroup>
                                        ) : (
                                            <span
                                                onClick={(e) =>
                                                    onToggleDetail({
                                                        product,
                                                        name: 'showNote',
                                                        value: true,
                                                    })
                                                }
                                                className='product-note'
                                            >
                                                Lời nhắn cho cửa hàng !
                                            </span>
                                        )}
                                    </Colxx>
                                </Row>
                            ) : null} */}
                        </div>
                    </div>
                </div>
                <Collapse isOpen={detailDisplay}>
                    <Colxx
                        xxs='12'
                        xs='12'
                        md='12'
                        lg='12'
                        className='d-flex flex-row'
                    >
                        <ProductDetail
                            product={product}
                            onSelected={onToggleDetail}
                            onToggleDetail={onToggleDetail}
                            orderDisplay={orderDisplay}
                            order={order}
                            sub={sub}
                            plus={plus}
                            change={change}
                            isVerify={isVerify}
                        />
                    </Colxx>
                </Collapse>
            </div>
        </div>
    );
};

export default React.memo(ThumbListViewMenu);

class NoteInput extends React.Component {
    constructor(props) {
        super(props);

        this.textInput = React.createRef();
        this.focus = this.focus.bind(this);
    }

    focus() {
        // Explicitly focus the text input using the raw DOM API
        // Note: we're accessing "current" to get the DOM node
        this.textInput.current.focus();
    }

    componentDidUpdate() {
        if (this.textInput && this.textInput.current) {
            this.focus();
        }
    }

    render() {
        const { product, onToggleDetail } = this.props;

        return (
            <Input
                ref={this.textInput}
                autoFocus={true}
                onChange={(e) =>
                    onToggleDetail({
                        product,
                        name: 'note',
                        value: e.target.value,
                    })
                }
                className='custom-input-underline'
                placeholder='Ghi chú'
            />
        );
    }
}
