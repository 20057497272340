import React, { memo, Fragment } from 'react';
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    InputGroupAddon,
    Badge,
    Form,
    FormGroup,
    Input,
    InputGroup,
    Row,
    Label,
} from 'reactstrap';
import IntlMessages from '../../../helpers/IntlMessages';
import PerfectScrollbar from 'react-perfect-scrollbar';

import Select, { Creatable } from 'react-select';
import { Colxx, Separator } from '../../../components/common/CustomBootstrap';
import CustomSelectInput from '../../../components/common/CustomSelectInput';
import { AddCommaNumber } from '../../../helpers/Utils';

const ContentComponent = ({
    title,
    isOpen,
    toggle,
    onSubmit,
    make_products,
    data_stocks,
    values,
    form_cost,
    setting,
    onChangeCost,
    onAddStock,
    onDeleteStock,
}) => {
    // console.log('render AddComponent');

    const make_product = make_products.filter(
        (product) => product.product_id === values.id
    );

    return (
        <Modal
            isOpen={isOpen}
            toggle={toggle}
            wrapClassName='modal-right'
            backdrop={true}
        >
            <Form onSubmit={(e) => onSubmit(e)} className='make-product'>
                <ModalHeader toggle={toggle}>{title}</ModalHeader>
                <ModalBody className='scrool modal-right-add'>
                    <PerfectScrollbar
                        options={{
                            suppressScrollX: true,
                            wheelPropagation: false,
                        }}
                        id='modal-right__body'
                    >
                        <Row>
                            <Colxx className='mb-3'>
                                <p className='text-muted text-small mb-2'>
                                    <IntlMessages id='make-product.product-name' /><span style={{color: 'red'}}>*</span>
                                </p>
                                <p>{values.name}</p>
                            </Colxx>
                        </Row>
                        <Row>
                            <Colxx className='mb-3'>
                                <p className='text-muted text-small mb-2'>
                                    <IntlMessages id='product.price.original' /><span style={{color: 'red'}}>*</span>
                                </p>
                                <p className='mb-3 text-lowercase'>
                                    {values.price_sale !== '0' ? (
                                        <>
                                            <Badge
                                                color='primary'
                                                pill
                                                className='spacer-around-1'
                                            >
                                                {AddCommaNumber(
                                                    values.price_sale
                                                )}
                                            </Badge>
                                            <Badge
                                                color='light'
                                                pill
                                                className='spacer-around-1'
                                            >
                                                <del>
                                                    {AddCommaNumber(
                                                        values.price
                                                    )}
                                                </del>
                                            </Badge>
                                        </>
                                    ) : (
                                        <Badge color='primary' pill>
                                            {AddCommaNumber(values.price)}
                                        </Badge>
                                    )}
                                </p>
                            </Colxx>
                        </Row>

                        <Fragment>
                            <Row className='make_product_unit'>
                                <Colxx
                                    sm={
                                        form_cost?.stock?.__isNew__ ? '8' : '12'
                                    }
                                    className='mb-2'
                                >
                                    <p className='text-muted text-small mb-2'>
                                        <IntlMessages id='make-product.stock-name' /><span style={{color: 'red'}}>*</span>
                                    </p>

                                    <FormGroup className='mb-0'>
                                        {/* <Select
                                            // isDisabled={!setting.add}
                                            defaultInputValue={''}
                                            components={{
                                                Input: CustomSelectInput,
                                            }}
                                            className='react-select'
                                            classNamePrefix='react-select'
                                            name='stock'
                                            options={data_stocks}
                                            onChange={(val, extra) =>
                                                onChange(val, 'stock')
                                            }
                                            placeholder={
                                                <IntlMessages id='make-product.select-stock' />
                                            }
                                        /> */}

                                        <Creatable
                                            className={'react-select-creatable'}
                                            onChange={(val) =>
                                                onChangeCost(val, 'stock')
                                            }
                                            options={data_stocks}
                                            placeholder={'Chọn hoặc tạo mới'}
                                            name='stock'
                                            value={data_stocks?.value}
                                        />
                                    </FormGroup>
                                </Colxx>

                                {form_cost?.stock?.__isNew__ ? (
                                    <Colxx sm='4'>
                                        <FormGroup>
                                            <Label for='unit'>
                                                Đơn vị tính
                                            </Label>
                                            <Input
                                                type='text'
                                                name='unit'
                                                onChange={onChangeCost}
                                            />
                                        </FormGroup>
                                    </Colxx>
                                ) : null}
                            </Row>
                            <Row>
                                <Colxx sm='8' className='mb-2 px-0'>
                                    <p className='text-muted text-small mb-2'>
                                        <IntlMessages id='pages.order-number' /><span style={{color: 'red'}}>*</span>
                                    </p>
                                    <FormGroup className='mb-0'>
                                        <InputGroup>
                                            <Input
                                                className={'order-number'}
                                                type={'number'}
                                                name='count'
                                                placeholder={'0'}
                                                step='0'
                                                value={form_cost?.count || ''}
                                                onChange={onChangeCost}
                                                // disabled={!setting.add}
                                            />
                                            <InputGroupAddon addonType='prepend'>
                                                {form_cost?.stock?.unit || ''}
                                            </InputGroupAddon>
                                        </InputGroup>
                                    </FormGroup>
                                </Colxx>
                                <Colxx sm='4' className='pr-0'>
                                    <p className='text-muted text-small mb-2'>
                                        Thêm
                                    </p>
                                    <Button
                                        className='btn-add-make-product'
                                        color='primary'
                                        onClick={() => onAddStock()}
                                    >
                                        +
                                    </Button>
                                </Colxx>
                            </Row>
                            <Separator className='mt-3 mb-3' />
                            {form_cost.make?.length ? (
                                <ul className={'orders-pending'}>
                                    {form_cost.make?.map((stock, idx) => (
                                        <li key={idx} className='mr-2'>
                                            <Button
                                                color='primary'
                                                className='px-3 py-2'
                                            >
                                                {stock.name}
                                                <Badge
                                                    className='ml-2'
                                                    color='light'
                                                >
                                                    {stock.count +
                                                        ' (' +
                                                        stock.unit +
                                                        ')'}
                                                </Badge>{' '}
                                                {setting.viewonly &&
                                                !setting.add ? null : (
                                                    <Badge
                                                        onClick={() =>
                                                            onDeleteStock(
                                                                stock.id
                                                            )
                                                        }
                                                        color='danger'
                                                    >
                                                        x
                                                    </Badge>
                                                )}
                                            </Button>
                                        </li>
                                    ))}
                                </ul>
                            ) : (
                                ''
                            )}
                            {make_product?.length ? (
                                <ul className={'orders-pending'}>
                                    {make_product?.map((stock, idx) => (
                                        <li key={idx} className='mr-2'>
                                            <Button
                                                disabled={
                                                    setting.viewonly &&
                                                    !setting.add
                                                }
                                                color='primary'
                                                className='px-3 py-2'
                                            >
                                                {stock.stock.name}
                                                <Badge
                                                    className='ml-2'
                                                    color='light'
                                                >
                                                    {stock.count +
                                                        ' (' +
                                                        stock.stock.unit +
                                                        ')'}
                                                </Badge>{' '}
                                                {/* <Badge
                                                    onClick={() =>
                                                        onDeleteStock(stock.id)
                                                    }
                                                    color='danger'
                                                >
                                                    x
                                                </Badge> */}
                                            </Button>
                                        </li>
                                    ))}
                                </ul>
                            ) : (
                                <p className='d-flex justify-content-center align-items-center text-muted'>
                                    Sản phẩm này chưa được định lượng!
                                </p>
                            )}
                            <Separator className='mt-3 mb-3' />
                            <Row>
                                <Colxx sm='6'>
                                    <p className='text-muted text-small mb-2'>
                                        Giá cost
                                    </p>
                                    <p className='text-muted'>
                                        (chưa có thông tin)
                                    </p>
                                </Colxx>
                                <Colxx sm='6'>
                                    <p className='text-muted text-small mb-2'>
                                        % Giá cost/Giá bán
                                    </p>
                                    <p className='text-muted'>
                                        (chưa có thông tin)
                                    </p>
                                </Colxx>
                            </Row>
                        </Fragment>
                    </PerfectScrollbar>
                </ModalBody>
                <ModalFooter>
                    <Button color='primary'>
                        <IntlMessages id='general.btn-add' />
                    </Button>{' '}
                </ModalFooter>
            </Form>
        </Modal>
    );
};

export default memo(ContentComponent);
