import React, { Component } from 'react';
import {
    Row,
    Col,
    Nav,
    NavItem,
    TabContent,
    TabPane,
    Card,
    CardBody,
} from 'reactstrap';
import axios from 'axios';
import { END_POINT, END_POINT_SOCKET } from '../../../constants/defaultValues';
import {
    SetItemsSelected,
    NotificatioErrController,
    NotificatioSucController,
    // CheckConfirmPassword,
    RandomString,
    getCurrentDate,
    getCurrentTime,
} from '../../../helpers/Utils';
import io from 'socket.io-client';
import {
    PageHeading,
    ContentForm,
    ListItemLeftForm,
    ListItemFormMobile,
    ProductApplicationMenu,
    ContentDisplay,
    ContentDisplayCost,
    Category,
    ContentFormCost,
} from '../../../containers/pages/product';
import { Add, Update, Pagination } from '../../../containers/pages/general';
import LogsHelper from '../../../helpers/Logs';
// import PasswordBox from '../../../components/PasswordBox'
import IntlMessages from '../../../helpers/IntlMessages';
import PerfectScrollbar from 'react-perfect-scrollbar';
import classnames from 'classnames';
import { isC, isR, isU, isD } from '../../../helpers/Roles';
import { NavLink } from 'react-router-dom';
import { Colxx, Separator } from '../../../components/common/CustomBootstrap';
import TooltipItem from '../../../components/common/TooltipItem';
import FooterPage from '../../../containers/pages/FooterPage';
import FooterCustomer from '../../../containers/pages/customer/FooterCustomer';
import Setting from '../../../containers/pages/product/Setting';
import AddProduct from '../../../containers/pages/product/AddProduct';
import AddCategoryProduct from '../../../containers/pages/product/AddCategoryProduct';
import UpdateProduct from '../../../containers/pages/product/UpdateProduct';
import ContentMultiForm from '../../../containers/pages/product/ContentMultiForm';

const DEFAULT_FORM = {
    id: '',
    name: '',
    sku: '',
    barcode: '',
    price: '',
    price_sale: '',
    active_sale: false,
    category: null,
    category_id: 0,
    price_cost: '',
    quantity: '',
    quantitative: '',
    image: '',
    size: [],
    topping: [],
    ice: [],
    sugar: [],
    sizeTitle: '',
    toppingTitle: '',
    iceTitle: '',
    sugarTitle: '',
    dynamic_form: [],
};

const DEFAULT_FORM_COST = {
    id: '',
    count: 0,
    stock: null,
    product: null,
    make: [],
    make_new: [],
    data_products: [],
    data_stocks: [],

    new_product_id: '',
};

const ROLE_ALIAS = 'product';

class ContentComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            page: {
                pageSizes: [12, 24],
                selectedPageSize: 12,
                currentPage: 1,
                totalPage: 1,
                totalItem: 0,
                search: '',
            },
            selectedItems: [],
            data: [],
            data_all: [],
            license: {},
            data_length: 0,
            hide_button_add: false,

            data_product: null,
            filter: null,

            data_dynamic_form: [],
            data_categories: [],
            data_stocks: [],
            make_products: [],
            is_loading_data: true,
            show: {
                add: false,
                update: false,
                update_cost: false,
                view: false,
                viewonly: false,
            },
            accordion: [],
            activeTab: '1',
            displayMode: 'thumblist',
            form: DEFAULT_FORM,
            form_cost: DEFAULT_FORM_COST,
            categoriesTooltip: {
                placement: 'top',
                text: '',
                body: 'Là danh mục cha của các sản phẩm có trong "Sản phẩm", "Danh mục" cha này được tạo trước khi tạo "Sản phẩm". (Lưu ý: Nếu xóa danh mục cha thì các sản phẩm Sản phẩm thuộc danh mục cha cũng bị xóa theo, hãy cẩn thận!).',
            },
            show_add_more_topping: false,
            show_quantitative: false,
            sku: '',
            sku_stock: '',
            editProduct: false,
        };

        this.socket = io(END_POINT_SOCKET);
    }

    componentDidMount() {
        this.socket.emit('join room', {
            room_id: localStorage.getItem('user_id'),
        });

        this.dataListRender();
        this.dataAllRender();
        this.dataAllRenderSoftDel();
        this.dataAllRenderStockSoftDel();
        this.getLicense();
    }

    getLicense = () => {
        let tokenStr = localStorage.getItem('access_token');
        axios
            .get(`${END_POINT + '/user-license'}`, {
                headers: {
                    Authorization: `Bearer ${tokenStr}`,
                },
            })
            .then((res) => {
                return res.data;
            })
            .then((data) => {
                let user_license = data.data;

                let currentDate = getCurrentDate();
                let currentTime = getCurrentTime();

                if (
                    user_license.expired_date < currentDate &&
                    user_license.upgraded === false
                ) {
                    this.setState((prev) => ({
                        license: {
                            using: false,
                        },
                    }));
                }

                if (
                    (user_license.expired_date > currentDate ||
                        (user_license.expired_date === currentDate &&
                            user_license.expired_time >= currentTime)) &&
                    user_license.purchased_package === 'pro'
                ) {
                    this.setState((prev) => ({
                        license: {
                            using: true,
                            purchase_type: 'pro',
                        },
                    }));
                }
                this.hideButtonAdd();
            })
            .catch((err) => {
                NotificatioErrController(err.response.data.responseText);
            });
    };

    hideButtonAdd = () => {
        let { license, data_length } = this.state;
        if (license.using === false && data_length > 10) {
            return this.setState({
                hide_button_add: true,
            });
        }
        if (
            license.using === true &&
            license.purchase_type === 'pro' &&
            data_length > 20
        ) {
            this.setState({
                hide_button_add: true,
            });
        }
    };

    /** Axios func helper */
    dataListRender = () => {
        const { selectedPageSize, currentPage, search } = this.state.page;

        let tokenStr = localStorage.getItem('access_token');
        axios
            .get(
                `${
                    END_POINT + '/product/json'
                }?pageSize=${selectedPageSize}&currentPage=${currentPage}&search=${search}`,
                { headers: { Authorization: `Bearer ${tokenStr}` } }
            )
            .then((res) => res.data)
            .then((data) => {
                const allCategories = this.dataListRenderCategories();
                const StockUnits = this.dataListRenderStocksUnit();
                const MakeProducts = this.dataListRenderMakeProduct();

                return Promise.all([allCategories, StockUnits, MakeProducts])
                    .then((promises) => {
                        let anyAccordion = [];
                        let numeroPerguntas = data.data.length;
                        for (var i = 0; i < numeroPerguntas; i++) {
                            anyAccordion.push(false);
                        }
                        this.setState(
                            (prev) => ({
                                ...prev,
                                data: data.data,
                                data_dynamic_form: data.dataDynamicForm,
                                data_categories: promises[0],
                                data_stocks: promises[1],
                                make_products: promises[2],
                                is_loading_data: false,
                                page: {
                                    ...prev.page,
                                    totalPage: data.totalPage,
                                    totalItem: data.totalItem,
                                },
                                accordion: anyAccordion,
                            }),
                            () => {
                                if (this.state.form.id) {
                                    const {
                                        id,
                                        name,
                                        barcode,
                                        sku,
                                        price,
                                        image,
                                        category,
                                        category_id,
                                        price_sale,
                                        active_sale,
                                        size,
                                        topping,
                                        ice,
                                        sugar,
                                        sizeTitle,
                                        toppingTitle,
                                        iceTitle,
                                        sugarTitle,
                                        dynamic_form,
                                    } = this.state.form;
                                    // const { count, stock, make } =
                                    //     this.state.form_cost;

                                    this.setState((prev) => ({
                                        ...prev,
                                        form: {
                                            ...prev.form,
                                            id,
                                            name,
                                            barcode,
                                            sku,
                                            price,
                                            image,
                                            category,
                                            category_id,
                                            price_sale,
                                            active_sale,
                                            size,
                                            topping,
                                            ice,
                                            sugar,
                                            sizeTitle,
                                            toppingTitle,
                                            iceTitle,
                                            sugarTitle,
                                            dynamic_form,
                                        },
                                        // form_cost: {
                                        //     ...prev.form_cost,
                                        //     count,
                                        //     stock,
                                        //     make,
                                        // },
                                    }));
                                } else {
                                    this.setState((prev) => ({
                                        ...prev,
                                        selectedItems: [],
                                        show: {
                                            ...prev.show,
                                            update: false,
                                            update_cost: false,
                                        },
                                        form: DEFAULT_FORM,
                                        form_cost: DEFAULT_FORM_COST,
                                    }));
                                }
                                // if(this.state.form_cost.id){
                                //     const {
                                //         id,
                                //         count,
                                //         stock,
                                //         product,
                                //         make,
                                //         data_products,
                                //         data_stocks,
                                //         product_id
                                //     } = this.state.form_cost

                                //     this.setState((prev) => ({
                                //         ...prev,
                                //         form_cost: {
                                //             ...prev.form_cost,
                                //             id,
                                //             count,
                                //             stock,
                                //             product,
                                //             make,
                                //             data_products,
                                //             data_stocks,
                                //             product_id
                                //         },
                                //     }));
                                // } else {
                                //     this.setState((prev) => ({
                                //         ...prev,
                                //         form_cost: DEFAULT_FORM_COST,
                                //     }));
                                // }
                            }
                        );
                    })
                    .catch((err) => console.log(err));
            })
            .catch((err) => {
                NotificatioErrController(err.response.data.responseText);
                return false;
            });
    };

    dataListRenderCategories() {
        let tokenStr = localStorage.getItem('access_token');
        return axios
            .get(`${END_POINT + '/category'}`, {
                headers: { Authorization: `Bearer ${tokenStr}` },
            })
            .then((res) => {
                return res.data.data;
            })
            .catch((err) => []);
    }

    dataListRenderStocksUnit() {
        let tokenStr = localStorage.getItem('access_token');
        return axios
            .get(`${END_POINT + '/stock/all-unit'}`, {
                headers: { Authorization: `Bearer ${tokenStr}` },
            })
            .then((res) => {
                return res.data.data;
            })
            .catch((err) => []);
    }

    dataListRenderMakeProduct() {
        let tokenStr = localStorage.getItem('access_token');
        return axios
            .get(`${END_POINT + '/make-product/all'}`, {
                headers: { Authorization: `Bearer ${tokenStr}` },
            })
            .then((res) => {
                return res.data.data;
            })
            .catch((err) => []);
    }

    dataAllRender = () => {
        let tokenStr = localStorage.getItem('access_token');
        axios
            .get(`${END_POINT + '/product/all'}`, {
                headers: { Authorization: `Bearer ${tokenStr}` },
            })
            .then((res) => res.data)
            .then((data) => {
                this.setState((prev) => ({
                    ...prev,
                    data_all: data.data,
                    data_length: data.data.length,
                }));
            })
            .catch((err) => {
                NotificatioErrController(err.response.data.responseText);
                return false;
            });
    };

    dataAllRenderSoftDel = () => {
        let tokenStr = localStorage.getItem('access_token');
        axios
            .get(`${END_POINT + '/product/all-soft-delete'}`, {
                headers: { Authorization: `Bearer ${tokenStr}` },
            })
            .then((res) => res.data)
            .then((data) => {
                // console.log('dataAllSoftDel: ', data.data);
                const getSKU = data.data.length + 1;

                this.setState((prev) => ({
                    ...prev,
                    sku: `SP${getSKU}`,
                }));
            })
            .catch((err) => {
                NotificatioErrController(err.response.data.responseText);
                return false;
            });
    };

    dataAllRenderStockSoftDel = () => {
        let tokenStr = localStorage.getItem('access_token');
        axios
            .get(`${END_POINT + '/stock/all-soft-del'}`, {
                headers: { Authorization: `Bearer ${tokenStr}` },
            })
            .then((res) => res.data)
            .then((data) => {
                // console.log('dataStockSoftDel: ', data.data);
                const getSKU = data.data.length + 1;
                this.setState((prev) => ({
                    ...prev,
                    sku_stock: `NVL${getSKU}`,
                }));
            })
            .catch((err) => {
                NotificatioErrController(err.response.data.responseText);
                return false;
            });
    };

    onHandlerDelete = () => {
        if (!isD(ROLE_ALIAS)) return false;

        let tokenStr = localStorage.getItem('access_token');
        axios
            .delete(`${END_POINT + '/product'}`, {
                data: {
                    arrayId: this.state.selectedItems,
                },
                headers: { Authorization: `Bearer ${tokenStr}` },
            })
            .then((res) => res.data)
            .then((data) => {
                NotificatioSucController('Thành công');

                this.socket.emit('create logs', {
                    user_id: localStorage.getItem('user_id'),
                    message: LogsHelper['product-delete'],
                });

                this.dataListRender();
                this.dataAllRender();
            })
            .catch((err) => {
                NotificatioErrController(err.response.data.responseText);
                return false;
            });
    };

    onHandlerUpdate = (e) => {
        if (!isU(ROLE_ALIAS)) return false;
        e.preventDefault();

        const {
            show_quantitative,
            form: {
                id,
                name,
                barcode,
                price_cost,
                quantity,
                price,
                category_id,
                image,
                price_sale,
                active_sale,
                size,
                topping,
                ice,
                sugar,
                sizeTitle,
                toppingTitle,
                iceTitle,
                sugarTitle,
                dynamic_form,
            }
        } = this.state;

        if (!id || !name || !price || !category_id) {
            NotificatioErrController('Dữ liệu không hợp lệ');
            return;
        }

        let formData = new FormData();
        if (image) {
            formData.append('image', image);
        }

        formData.append('id', id);
        formData.append('name', name);
        formData.append('barcode', barcode);
        formData.append('sku', this.state.sku);
        formData.append('price', price.toString().replace(/\./g, ''));
        formData.append(
            'price_sale',
            price_sale ? price_sale.toString().replace(/\./g, '') : '0'
        );
        formData.append('active_sale', active_sale);
        formData.append('category_id', category_id);
        formData.append('size', JSON.stringify(size));
        formData.append('topping', JSON.stringify(topping));
        formData.append('ice', JSON.stringify(ice));
        formData.append('sugar', JSON.stringify(sugar));
        formData.append('sizeTitle', sizeTitle);
        formData.append('toppingTitle', toppingTitle);
        formData.append('iceTitle', iceTitle);
        formData.append('sugarTitle', sugarTitle);
        formData.append('dynamic_form', JSON.stringify(dynamic_form));

        let tokenStr = localStorage.getItem('access_token');
        axios
            .put(`${END_POINT + '/product'}`, formData, {
                headers: {
                    Authorization: `Bearer ${tokenStr}`,
                    'content-type': 'multipart/form-data',
                },
            })
            .then((res) => res.data)
            .then((data) => {
                // console.log('dataWhenPutProduct: ', data.data);
                NotificatioSucController('Thành công');

                this.socket.emit('create logs', {
                    user_id: localStorage.getItem('user_id'),
                    message: LogsHelper['product-update'],
                });

                this.dataListRender();
                this.dataAllRender();
                this.toggle(this.state.editProduct ? '' : 'update');

                if (
                    this.state.form_cost?.make?.length &&
                    this.state.form_cost?.make_new?.length
                ) {
                    this.onHandlerAddStock();
                    this.onAddCost(data.data);
                } else if (this.state.form_cost?.make_new?.length) {
                    this.onHandlerAddStock();
                } else if (this.state.form_cost?.make?.length) {
                    this.onAddCost(data.data);
                }
                
                if (
                    !show_quantitative &&
                    Number(price_cost) > 0 &&
                    Number(quantity) > 0
                ) {
                    this.onHandlerAddStock();
                }
                
                this.setState((prev) => ({
                    ...prev,
                    // form: DEFAULT_FORM,
                    form_cost: {
                        ...prev.form_cost,
                        new_product_id: data.data.id,
                    },
                    editProduct: false,
                }));
            })
            .catch((err) => {
                NotificatioErrController(err.response.data.responseText);
                return false;
            });
    };
    // onHandlerUpdateCost = () => {
    //     if (!isU(ROLE_ALIAS)) return false;
    //     this.toggle('update_cost');
    // };

    handleKeyPress = (e) => {
        const { data, form, data_stocks } = this.state;

        if (e.key === 'Enter') {
            e.preventDefault();
            // console.log('Enter key press prevented');
            const product = data.find((d) => d.barcode === form.barcode);
            // console.log('product: ', product);
            if (product) {
                const stock = data_stocks.find((s) => s.sku === product.sku);
                this.setState({
                    form: {
                        id: product?.id,
                        name: product?.name,
                        sku: product?.sku,
                        barcode: product?.barcode,
                        price: product?.price,
                        category: product?.category,
                        category_id: product?.category_id,
                        image: product?.image,
                        price_sale: product?.price_sale,
                        active_sale: product?.active_sale,
                        price_cost: stock?.avarage_price,
                        quantitative: stock?.count,
                        size: product?.size,
                        topping: product?.topping,
                        ice: product?.ice,
                        sugar: product?.sugar,
                        sizeTitle: product?.sizeTitle,
                        toppingTitle: product?.toppingTitle,
                        iceTitle: product?.iceTitle,
                        sugarTitle: product?.sugarTitle,
                        dynamic_form: product?.dynamic_form,
                    },
                    editProduct: true,
                });
            }
        }
        // console.log('form: ', form);
    };

    onHandlerAdd = (e) => {
        if (!isC(ROLE_ALIAS)) return false;
        e.preventDefault();

        const {
            show_quantitative,
            form: {
                name,
                barcode,
                price_cost,
                quantity,
                price,
                category,
                image,
                price_sale,
                active_sale,
                size,
                topping,
                ice,
                sugar,
                sizeTitle,
                toppingTitle,
                iceTitle,
                sugarTitle,
                dynamic_form,
            }
        } = this.state;

        if (!name) {
            NotificatioErrController('Tên sản phẩm cần được thêm vào');
            return;
        }
        if (!category) {
            NotificatioErrController('Loại sản phẩm cần được chọn');
            return;
        }
        if (!price) {
            NotificatioErrController('Giá bán cần điền vào');
            return;
        }

        if (category.__isNew__) {
            return this.onHandlerAddCategory();
        } else {
            let formData = new FormData();
            if (image) {
                formData.append('image', image);
            }

            formData.append('name', name);
            formData.append('barcode', barcode);
            formData.append('sku', this.state.sku);
            formData.append('price', price.toString().replace(/\./g, ''));
            formData.append(
                'price_sale',
                price_sale ? price_sale.toString().replace(/\./g, '') : '0'
            );
            formData.append('active_sale', active_sale);
            formData.append('category_id', category.id);
            formData.append('size', JSON.stringify(size));
            formData.append('topping', JSON.stringify(topping));
            formData.append('ice', JSON.stringify(ice));
            formData.append('sugar', JSON.stringify(sugar));
            formData.append('sizeTitle', sizeTitle);
            formData.append('toppingTitle', toppingTitle);
            formData.append('iceTitle', iceTitle);
            formData.append('sugarTitle', sugarTitle);
            formData.append('dynamic_form', JSON.stringify(dynamic_form));

            let tokenStr = localStorage.getItem('access_token');
            axios
                .post(`${END_POINT + '/product'}`, formData, {
                    headers: {
                        Authorization: `Bearer ${tokenStr}`,
                        'content-type': 'multipart/form-data',
                    },
                })
                .then((res) => res.data)
                .then((data) => {
                    // console.log('dataAfterAddProduct: ', data.data);
                    NotificatioSucController('Thành công');

                    this.socket.emit('create logs', {
                        user_id: localStorage.getItem('user_id'),
                        message: LogsHelper['product-create'],
                    });

                    // if (this.state.form_cost?.make_new?.length) {
                    //     await this.onHandlerAddStock();
                    // }

                    if (
                        this.state.form_cost?.make?.length &&
                        this.state.form_cost?.make_new?.length
                    ) {
                        this.onHandlerAddStock();
                        this.onAddCost(data.data);
                    } else if (this.state.form_cost?.make_new?.length) {
                        this.onHandlerAddStock();
                    } else if (this.state.form_cost?.make?.length) {
                        this.onAddCost(data.data);
                    }
                    
                    const priceCostWithoutCommaAndDot = price_cost.replace(
                        /[,.]/g,
                        ''
                    );
                    const quantityWithoutCommaAndDot = quantity.replace(
                        /[,.]/g,
                        ''
                    );

                    if (
                        !show_quantitative &&
                        Number(priceCostWithoutCommaAndDot) > 0 &&
                        Number(quantityWithoutCommaAndDot) > 0
                    ) {
                        this.onHandlerAddStock();
                    }

                    // this.toggle('add');
                    this.setState((prev) => ({
                        ...prev,
                        // form: DEFAULT_FORM,
                        form_cost: {
                            ...prev.form_cost,
                            new_product_id: data.data.id,
                        },
                    }));

                    this.dataListRender();
                    this.dataAllRender();
                    this.dataAllRenderSoftDel();
                    this.dataAllRenderStockSoftDel();
                })
                .catch((err) => {
                    NotificatioErrController(err.response.data.responseText);
                    return false;
                });
        }
    };

    onHandlerAddCategory = () => {
        // if (!isC(ROLE_ALIAS)) return false;
        // e.preventDefault();

        const { category, dynamic_form } = this.state.form;

        // if (!category) {
        //     NotificatioErrController('Dữ liệu không hợp lệ');
        //     return;
        // }

        let tokenStr = localStorage.getItem('access_token');
        axios
            .post(
                `${END_POINT + '/category'}`,
                {
                    name: category.value,
                    dynamic_form,
                },
                {
                    headers: { Authorization: `Bearer ${tokenStr}` },
                }
            )
            .then((res) => res.data)
            .then((data) => {
                // console.log('data_category_new: ', data.data);
                NotificatioSucController('Thành công');

                this.socket.emit('create logs', {
                    user_id: localStorage.getItem('user_id'),
                    message: LogsHelper['category-create'],
                });
                this.onHandlerAddProductAfterCategory(data.data.id);
            })
            .catch((err) => {
                NotificatioErrController(err.response.data.responseText);
                return false;
            });
    };

    onHandlerAddProductAfterCategory = (id) => {
        // if (!isC(ROLE_ALIAS)) return false;
        // e.preventDefault();

        const {
            name,
            barcode,
            price,
            category,
            image,
            price_sale,
            active_sale,
            size,
            topping,
            ice,
            sugar,
            sizeTitle,
            toppingTitle,
            iceTitle,
            sugarTitle,
            dynamic_form,
        } = this.state.form;

        if (!name) {
            NotificatioErrController('Tên sản phẩm cần được thêm vào');
            return;
        }
        // if (!category) {
        //     NotificatioErrController('Loại sản phẩm cần được chọn');
        //     return;
        // }
        if (!price) {
            NotificatioErrController('Giá bán cần điền vào');
            return;
        }

        let formData = new FormData();
        if (image) {
            formData.append('image', image);
        }

        formData.append('name', name);
        formData.append('barcode', barcode);
        formData.append('sku', this.state.sku);
        formData.append('price', price.toString().replace(/\./g, ''));
        formData.append(
            'price_sale',
            price_sale ? price_sale.toString().replace(/\./g, '') : '0'
        );
        formData.append('active_sale', active_sale);
        formData.append('category_id', id);
        formData.append('size', JSON.stringify(size));
        formData.append('topping', JSON.stringify(topping));
        formData.append('ice', JSON.stringify(ice));
        formData.append('sugar', JSON.stringify(sugar));
        formData.append('sizeTitle', sizeTitle);
        formData.append('toppingTitle', toppingTitle);
        formData.append('iceTitle', iceTitle);
        formData.append('sugarTitle', sugarTitle);
        formData.append('dynamic_form', JSON.stringify(dynamic_form));

        let tokenStr = localStorage.getItem('access_token');
        axios
            .post(`${END_POINT + '/product'}`, formData, {
                headers: {
                    Authorization: `Bearer ${tokenStr}`,
                    'content-type': 'multipart/form-data',
                },
            })
            .then((res) => res.data)
            .then((data) => {
                // console.log('dataAfterAddProduct: ', data.data);
                NotificatioSucController('Thành công');

                this.socket.emit('create logs', {
                    user_id: localStorage.getItem('user_id'),
                    message: LogsHelper['product-create'],
                });

                // if (this.state.form_cost?.make_new?.length) {
                //     await this.onHandlerAddStock();
                // }

                if (
                    this.state.form_cost?.make?.length &&
                    this.state.form_cost?.make_new?.length
                ) {
                    this.onHandlerAddStock();
                    this.onAddCost(data.data);
                } else if (this.state.form_cost?.make_new?.length) {
                    this.onHandlerAddStock();
                } else if (this.state.form_cost?.make?.length) {
                    this.onAddCost(data.data);
                }

                // this.toggle('add');
                this.setState((prev) => ({
                    ...prev,
                    // form: DEFAULT_FORM,
                    form_cost: {
                        ...prev.form_cost,
                        new_product_id: data.data.id,
                    },
                    sku: '',
                }));

                this.dataListRender();
                this.dataAllRender();
                this.dataAllRenderSoftDel();
            })
            .catch((err) => {
                NotificatioErrController(err.response.data.responseText);
                return false;
            });
    };

    onHandlerSoftHide(id) {
        if (!isU(ROLE_ALIAS)) return false;

        if (!id) return false;

        let tokenStr = localStorage.getItem('access_token');
        axios
            .post(
                `${END_POINT + '/product/softhide'}`,
                {
                    id: id,
                },
                {
                    headers: {
                        Authorization: `Bearer ${tokenStr}`,
                    },
                }
            )
            .then((res) => {
                return res.data;
            })
            .then((data) => {
                NotificatioSucController(data.responseText);

                this.dataListRender();
                this.dataAllRender();
            })
            .catch((err) => {
                NotificatioErrController(err.response.data.responseText);
            });
    }

    onHandlerBestter(id) {
        if (!isU(ROLE_ALIAS)) return false;

        if (!id) return false;

        let tokenStr = localStorage.getItem('access_token');
        axios
            .post(
                `${END_POINT + '/product/bestter'}`,
                {
                    id: id,
                },
                {
                    headers: {
                        Authorization: `Bearer ${tokenStr}`,
                    },
                }
            )
            .then((res) => {
                return res.data;
            })
            .then((data) => {
                NotificatioSucController(data.responseText);

                this.dataListRender();
                this.dataAllRender();
                this.setState((prev) => ({
                    ...prev,
                    form: {
                        ...prev.form,
                        form: DEFAULT_FORM,
                    },
                }));
            })
            .catch((err) => {
                NotificatioErrController(err.response.data.responseText);
            });
    }
    /** Axios func helper */

    /** State func helper */
    toggle = (name) => {
        if (name === 'add' && !isC(ROLE_ALIAS)) return false;
        if (name === 'update' && !isU(ROLE_ALIAS)) return false;
        if (name === 'update_cost' && !isU(ROLE_ALIAS)) return false;

        this.setState((prev) => ({
            ...prev,
            show: {
                ...prev.show,
                update:
                    name === 'add' || name === 'update_cost'
                        ? false
                        : prev.show.update,
                update_cost:
                    name === 'add' || name === 'update'
                        ? false
                        : prev.show.update_cost,
                view: name === 'add' ? false : prev.show.view,
                [name]: !prev.show[name],
            },
            selectedItems: name === 'add' ? [] : prev.selectedItems,
            form: name === 'add' || name === '' ? DEFAULT_FORM : prev.form,
            // form_cost: name === 'add' ? DEFAULT_FORM_COST : prev.form_cost,
        }));
        if (name === 'add') {
            this.dataListRender();
        }
    };

    hideDisplayContent = () => {
        this.setState((prev) => ({
            ...prev,
            show: {
                ...prev.show,
                view: false,
            },
        }));
    };

    onChangeSizeAndTopping = (value, name, id, type) => {
        let find_elm = this.state.form[name].find((_elm) => _elm.id === id);

        if (find_elm) {
            find_elm[type] = value;
        }

        this.setState((prev) => ({
            ...prev,
            form: {
                ...prev.form,
                [name]: this.state.form[name],
            },
        }));
    };

    onChangeIceAndSugar = ({ name, value }) => {
        let find_elm = this.state.form[name].find((_elm) => _elm === value);

        let _filter_value = this.state.form[name];
        if (!find_elm) {
            this.state.form[name].push(value);

            _filter_value = this.state.form[name];
        } else {
            _filter_value = this.state.form[name].filter(
                (_item) => _item !== value
            );
        }

        this.setState((prev) => ({
            ...prev,
            form: {
                ...prev.form,
                [name]: _filter_value,
            },
        }));
    };

    onAddSizeAndTopping = (name) => {
        let value_more = {
            id: RandomString(5),
            name: '',
            value: '0',
        };

        const clone_value = this.state.form[name];
        clone_value.push(value_more);
        this.setState((prev) => ({
            ...prev,
            form: {
                ...prev.form,
                [name]: clone_value,
            },
        }));
    };

    onRemoveSizeAndTopping = (name, id) => {
        const remove_value = this.state.form[name].filter(
            (_elm) => _elm.id !== id
        );
        this.setState((prev) => ({
            ...prev,
            form: {
                ...prev.form,
                [name]: remove_value,
            },
        }));
    };

    onChangeForm = (e, _name) => {
        // if (!e)
        //   return false;

        if (e.target) {
            let {
                target: { name, value },
            } = e;

            if (name === 'image') {
                value = e.target.files[0];
                value.preview = URL.createObjectURL(value);
            }

            this.setState((prev) => ({
                ...prev,
                form: {
                    ...prev.form,
                    [name]: value,
                },
            }));
        } else {
            this.setState((prev) => ({
                ...prev,
                form: {
                    ...prev.form,
                    [_name]: e,
                },
            }));
        }
    };

    onChangeFormCost = (e, _name) => {
        if (e && e.target) {
            let {
                target: { name, value },
            } = e;

            this.setState((prev) => ({
                ...prev,
                form_cost: {
                    ...prev.form_cost,
                    [name]: value,
                },
            }));
        } else {
            this.setState((prev) => ({
                ...prev,
                form_cost: {
                    ...prev.form_cost,
                    [_name]: e,
                },
            }));
        }
    };

    onChangeDynamicForm = (e, _name) => {
        if (e.target) {
            let {
                target: { name, value },
            } = e;

            this.setState((prev) => ({
                ...prev,
                form: {
                    ...prev.form,
                    dynamic_form: {
                        ...prev.form.dynamic_form,
                        [name]: value,
                    },
                },
            }));
        } else {
            let value = e.value ? e.value : e;

            this.setState((prev) => ({
                ...prev,
                form: {
                    ...prev.form,
                    dynamic_form: {
                        ...prev.form.dynamic_form,
                        [_name]: value,
                    },
                },
            }));
        }
    };

    onDeleteImage = () => {
        this.setState((prev) => ({
            ...prev,
            form: {
                ...prev.form,
                image: '',
            },
        }));
    };

    onSubmitForm = (type) => {
        if (type === 'update') {
            this.onHandlerUpdate();
        } else if (type === 'add') {
            this.onHandlerAdd();
        } else if (type === 'update_cost') {
            this.onHandlerAddCost();
        }
    };

    onChangePage = (page) => {
        this.setState(
            (prev) => ({
                ...prev,
                page: {
                    ...prev.page,
                    currentPage: page,
                },
            }),
            () => this.dataListRender()
        );
    };

    onSearchKey = (e) => {
        if (!e) return false;

        if (e.target) {
            let {
                target: { value },
            } = e;
            let v = value;
            if (value === undefined) {
                v = e.target.firstElementChild.value; // parent to child

                value = v.toLowerCase();
                this.setState(
                    (prev) => ({
                        ...prev,
                        page: {
                            ...prev.page,
                            search: value,
                        },
                    }),
                    () => this.dataListRender()
                );
            } else {
                value = value.toLowerCase();
                this.setState(
                    (prev) => ({
                        ...prev,
                        page: {
                            ...prev.page,
                            search: value,
                        },
                    }),
                    () => this.dataListRender()
                );
            }
        }

        if (e.key === 'Enter') {
            let value = e.target.value.toLowerCase();
            this.setState(
                (prev) => ({
                    ...prev,
                    page: {
                        ...prev.page,
                        search: value,
                    },
                }),
                () => this.dataListRender()
            );
        }
    };

    onChangePageSize = (size) => {
        this.setState(
            (prev) => ({
                ...prev,
                page: {
                    ...prev.page,
                    selectedPageSize: size,
                    currentPage: 1,
                },
            }),
            () => this.dataListRender()
        );
    };

    onClickedItem = (item, callback, args) => {
        if (this.state.form.id !== item.id) {
            const {
                id,
                name,
                sku,
                barcode,
                price,
                image,
                category,
                category_id,
                price_sale,
                active_sale,
                size,
                topping,
                ice,
                sugar,
                sizeTitle,
                toppingTitle,
                iceTitle,
                sugarTitle,
                dynamic_form,
            } = item;

            this.setState(
                (prev) => ({
                    ...prev,
                    show: {
                        ...prev.show,
                        update: false,
                        update_cost: false,
                        view: true,
                        viewonly: false,
                    },
                    sku,
                    form: {
                        ...prev.form,
                        id,
                        name,
                        sku,
                        barcode,
                        price,
                        image,
                        category,
                        category_id,
                        price_sale,
                        active_sale,
                        size,
                        topping,
                        ice,
                        sugar,
                        sizeTitle,
                        toppingTitle,
                        iceTitle,
                        sugarTitle,
                        dynamic_form,
                    },
                    selectedItems: [item.id],
                }),
                () => {
                    callback(args[0], args[1], args[2]);
                }
            );
        }
    };

    onClickedUpdate = (item) => {
        const {
            id,
            name,
            sku,
            price,
            image,
            category,
            category_id,
            price_sale,
            active_sale,
            size,
            topping,
            ice,
            sugar,
            sizeTitle,
            toppingTitle,
            iceTitle,
            sugarTitle,
            dynamic_form,
        } = item;

        this.setState((prev) => ({
            ...prev,
            show: {
                ...prev.show,
                update: true,
                update_cost: false,
            },
            sku,
            form: {
                ...prev.form,
                id,
                name,
                price,
                sku,
                image,
                category,
                category_id,
                price_sale,
                active_sale,
                size,
                topping,
                ice,
                sugar,
                sizeTitle,
                toppingTitle,
                iceTitle,
                sugarTitle,
                dynamic_form,
            },
        }));
    };

    onClickedUpdateCost = (item) => {
        this.setState((prev) => ({
            ...prev,
            show: {
                ...prev.show,
                update: false,
                update_cost: true,
            },
        }));
    };

    onClickedDelete = (item) => {
        let selectedSet = SetItemsSelected(
            item.id,
            this.state.selectedItems,
            'require'
        );
        if (window.confirm('Bạn chắc chắn muốn xoá sản phẩm này?')) {
            this.setState(
                (prev) => ({
                    ...prev,
                    selectedItems: selectedSet,
                }),
                () => this.onHandlerDelete()
            );
        }
    };

    onClickedSoftHide = (item) => {
        this.setState(
            (prev) => ({
                ...prev,
                selectedItems: [item.id],
            }),
            () => this.onHandlerSoftHide(item.id)
        );
    };

    onClickedBestter = (item) => {
        this.setState(
            (prev) => ({
                ...prev,
                selectedItems: [item.id],
            }),
            () => this.onHandlerBestter(item.id)
        );
    };

    onClickedCheckbox = (id, callback) => {
        // let selectedSet = SetItemsSelected(id, this.state.selectedItems);

        this.setState((prev) => ({
            ...prev,
            selectedItems: [id],
        }));
    };
    /** State func helper */

    toggleAccordion = (tab) => {
        const prevState = this.state.accordion;
        const state = prevState.map((x, index) => (tab === index ? !x : false));
        this.setState({
            accordion: state,
        });
    };

    toggleTab = (tab) => {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab,
            });
        }
    };

    filterData = (key, value) => {
        const { data_all } = this.state;
        if (key === '' && value === '') {
            this.setState((prev) => ({
                ...prev,
                data: data_all,
                filter: null,
            }));
        } else if (key === 'name') {
            const filteredItems = data_all.filter(
                (item) => item.category.name === value
            );
            this.setState((prev) => ({
                ...prev,
                data: filteredItems,
                filter: { column: key, value },
            }));
        } else if (key === 'make_product' && value === 'done') {
            const make_products = this.state.make_products;
            const filteredProduct = data_all.filter((item) =>
                make_products.map((make) => make.product_id).includes(item.id)
            );
            // console.log(filteredProduct);
            this.setState((prev) => ({
                ...prev,
                data: filteredProduct,
                filter: { column: key, value },
            }));
        } else if (key === 'make_product' && value === 'not_yet') {
            const make_products = this.state.make_products;
            const filteredProduct = data_all.filter(
                (item) =>
                    make_products
                        .map((make) => make.product_id)
                        .indexOf(item.id) === -1
            );
            // console.log(filteredProduct);
            this.setState((prev) => ({
                ...prev,
                data: filteredProduct,
                filter: { column: key, value },
            }));
        } else {
            const filteredItems = data_all.filter(
                (item) => item[key] === value
            );
            this.setState((prev) => ({
                ...prev,
                data: filteredItems,
                filter: { column: key, value },
            }));
        }
    };

    onClickedFormAddStock = () => {
        const { count, stock, make } = this.state.form_cost;
        // console.log('form_cost: ', this.state.form_cost);
        // console.log('form: ', this.state.form);

        if (!stock) {
            NotificatioErrController('Chọn 1 nguyên liệu để tạo');
            return false;
        }
        if (!count) {
            NotificatioErrController(
                `Cần thêm số lượng (${stock.unit}) cần sử dụng`
            );
            return false;
        }

        let existsId = make.filter((s) => s.id === stock.id);
        if (existsId.length > 0) {
            NotificatioErrController('Đã chọn nguyên liệu này');
            return false;
        }

        let aStock = {
            id: stock.id,
            name: stock.name,
            unit: stock.unit,
            count: count,
        };
        make.push(aStock);

        this.setState({
            form_cost: DEFAULT_FORM_COST,
        });
    };

    onClickedFormAddNewStock = () => {
        const { count, stock, make_new, unit } = this.state.form_cost;
        // console.log('form_cost: ', this.state.form_cost);
        // console.log('form: ', this.state.form);

        if (!stock) {
            NotificatioErrController('Chọn 1 nguyên liệu để tạo');
            return false;
        }
        if (!count) {
            NotificatioErrController(
                `Cần thêm số lượng (${stock.unit}) cần sử dụng`
            );
            return false;
        }

        let aStock = {
            // id: stock.id,
            name: stock.value,
            unit,
            count,
        };
        make_new.push(aStock);

        this.setState((prev) => ({
            ...prev,
            form_cost: {
                ...prev.form_cost,
                make_new,
                count: 0,
            },
        }));
    };

    onClickedFormDeleteStock = (id, name) => {
        let make;
        if (name === 'name') {
            make = this.state.form_cost.make_new.filter((s) => s.name !== id);
            this.onChangeFormCost(make, 'make_new');
        } else if (name === 'id') {
            make = this.state.form_cost.make.filter((s) => s.id !== id);
            this.onChangeFormCost(make, 'make');
        }
    };

    onHandlerAddStock = () => {
        if (!isC(ROLE_ALIAS)) return false;
        // e.preventDefault();

        const { make_new } = this.state.form_cost;
        const { sku, name, price_cost, quantity } = this.state.form;
        
        const priceCostWithoutCommaAndDot = price_cost.replace(/[,.]/g, '');
        const quantityWithoutCommaAndDot = quantity.replace(/[,.]/g, '');

        let stockNews = [];
        for (let item of make_new) {
            const newStock = {
                name: item.name,
                sku: this.state.sku,
                unit: item.unit,
                count: 0,
                unit_price: 0,
            };
            stockNews.push(newStock);
        }
        // console.log('stockNews: ', stockNews);

        let tokenStr = localStorage.getItem('access_token');
        if (stockNews.length) {
        axios.post(
                `${END_POINT + '/stock/create-stock-from-product'}`,
                stockNews,
                {
                    headers: { Authorization: `Bearer ${tokenStr}` },
                }
            )
            .then((res) => res.data)
            .then((data) => {
                NotificatioSucController('Thêm nguyên liệu thành công');

                // console.log('newStock: ', data.data);
                // console.log('data_stocks: ', this.state.data_stocks);

                this.socket.emit('create logs', {
                    user_id: localStorage.getItem('user_id'),
                    message: LogsHelper['stock-create'],
                });

                this.socket.emit('refresh-stock', {
                    user_id: localStorage.getItem('user_id'),
                });

                this.dataListRender();
                this.dataAllRender();
                this.onAddNewCost(data.data);
                // this.toggle('add');
            })
            .catch((err) => {
                NotificatioErrController(err.response.data.responseText);
                return false;
            });
        } else if (Number(priceCostWithoutCommaAndDot) > 0 && Number(quantityWithoutCommaAndDot) > 0) {
            axios
                .post(
                    `${END_POINT + '/stock'}`,
                    {
                        name,
                        sku: sku ? sku : this.state.sku,
                        count: quantityWithoutCommaAndDot,
                        unit_price: priceCostWithoutCommaAndDot.toString(),
                    },
                    {
                        headers: { Authorization: `Bearer ${tokenStr}` },
                    }
                )
                .then((res) => res.data)
                .then((data) => {
                    NotificatioSucController('Thêm nguyên liệu thành công');

                    this.socket.emit('create logs', {
                        user_id: localStorage.getItem('user_id'),
                        message: LogsHelper['stock-create'],
                    });

                    this.socket.emit('refresh-stock', {
                        user_id: localStorage.getItem('user_id'),
                    });
                    
                    const new_cost = {
                        name,
                        count: 1,
                    };
                    // console.log('beforeAddNewCost');
                    this.onAddNewCost({ data, new_cost });

                    // this.dataListRender();
                    // this.dataAllRender();
                    // this.dataAllRenderSoftDel();
                })
                .catch((err) => {
                    NotificatioErrController(err.response.data.responseText);
                    return false;
                }
            );
        }
    };

    onAddNewCost = ({ data, new_cost }) => {
        if (!isC(ROLE_ALIAS)) return false;

        const {
            form_cost: { new_product_id, make_new },
        } = this.state;

        let updatedMake = [];
        if (Object.keys(new_cost).length > 0) {
            new_cost['id'] = data.data.id;
            updatedMake.push(new_cost);
        } else {
            const make = make_new.map((item) => {
                const stock = data.find((s) => s.data.name === item.name);
                return {
                    ...item,
                    id: stock ? stock.data.id : null,
                };
            });
            updatedMake = make;
        }
        // const updatedMake = make_new.map((item) => {
        //     const stock = data.find((s) => s.data.name === item.name);
        //     return {
        //         ...item,
        //         id: stock.data.id,
        //     };
        // });

        let tokenStr = localStorage.getItem('access_token');
        axios
            .post(
                `${END_POINT + '/make-product'}`,
                {
                    product_id: new_product_id,
                    stocks: updatedMake,
                },
                {
                    headers: { Authorization: `Bearer ${tokenStr}` },
                }
            )
            .then((res) => res.data)
            .then((data) => {
                NotificatioSucController('Thêm định lượng thành công');

                this.socket.emit('create logs', {
                    user_id: localStorage.getItem('user_id'),
                    message: LogsHelper['make-product-create'],
                });

                this.dataListRender();
                this.dataAllRender();
                this.setState((prev) => ({
                    ...prev,
                    // form: {
                    //     ...prev.form,
                    //     product: null,
                    // },
                    form_cost: {
                        ...prev.form_cost,
                        make: [],
                        new_product_id: '',
                    },
                }));
                // this.toggle('update_cost');
            })
            .catch((err) => {
                NotificatioErrController(err.response.data.responseText);
                return false;
            });
    };

    onAddCost = (data) => {
        if (!isC(ROLE_ALIAS)) return false;
        // e.preventDefault();

        const { dynamic_form } = this.state.form;
        // console.log(this.state.form);
        const { make } = this.state.form_cost;
        // console.log('make: ', make);

        if (make.length < 1) {
            NotificatioErrController('Chưa thêm nguyên liệu');
            return false;
        }

        let tokenStr = localStorage.getItem('access_token');
        axios
            .post(
                `${END_POINT + '/make-product'}`,
                {
                    product_id: data.id,
                    stocks: make,
                    dynamic_form,
                },
                {
                    headers: { Authorization: `Bearer ${tokenStr}` },
                }
            )
            .then((res) => res.data)
            .then((data) => {
                NotificatioSucController('Thêm định lượng thành công');

                this.socket.emit('create logs', {
                    user_id: localStorage.getItem('user_id'),
                    message: LogsHelper['make-product-create'],
                });

                this.dataListRender();
                this.dataAllRender();
                this.setState((prev) => ({
                    ...prev,
                    // form: {
                    //     ...prev.form,
                    //     product: null,
                    // },
                    form_cost: {
                        ...prev.form_cost,
                        make: [],
                    },
                }));
                // this.toggle('update_cost');
            })
            .catch((err) => {
                NotificatioErrController(err.response.data.responseText);
                return false;
            });
    };

    onHandlerAddCost = (e) => {
        if (!isC(ROLE_ALIAS)) return false;
        e.preventDefault();

        const { dynamic_form, id } = this.state.form;
        // console.log(this.state.form);
        const { make } = this.state.form_cost;

        if (make.length < 1) {
            NotificatioErrController('Chưa thêm nguyên liệu');
            return false;
        }

        let tokenStr = localStorage.getItem('access_token');
        axios
            .post(
                `${END_POINT + '/make-product'}`,
                {
                    product_id: id,
                    stocks: make,
                    dynamic_form,
                },
                {
                    headers: { Authorization: `Bearer ${tokenStr}` },
                }
            )
            .then((res) => res.data)
            .then((data) => {
                NotificatioSucController('Thêm định lượng thành công');

                this.socket.emit('create logs', {
                    user_id: localStorage.getItem('user_id'),
                    message: LogsHelper['make-product-create'],
                });

                this.dataListRender();
                this.dataAllRender();
                this.setState((prev) => ({
                    ...prev,
                    // form: {
                    //     ...prev.form,
                    //     product: null,
                    // },
                    form_cost: {
                        ...prev.form_cost,
                        make: [],
                    },
                }));
                this.toggle('update_cost');
            })
            .catch((err) => {
                NotificatioErrController(err.response.data.responseText);
                return false;
            });
    };

    changeDisplayMode = (mode) => {
        this.setState((prev) => ({
            ...prev,
            displayMode: mode,
            show: {
                ...prev.show,
                view: false,
            },
        }));
        return false;
    };

    onShowMoreItem = () => {
        const { show_add_more_topping } = this.state;
        this.setState({
            show_add_more_topping: !show_add_more_topping,
        });
    };

    onShowQuantitative = () => {
        const { show_quantitative } = this.state;
        this.setState({
            show_quantitative: !show_quantitative,
        });
    };

    render() {
        // console.log(this.state.data_stocks);
        const { match } = this.props;
        const {
            page,
            hide_button_add,
            selectedItems,
            data,
            data_all,
            filter,
            data_categories,
            data_stocks,
            make_products,
            is_loading_data,
            show,
            form,
            sku,
            form_cost,
            accordion,
            displayMode,
            show_add_more_topping,
            show_quantitative,
            editProduct,
            // Dynamic Form
            data_dynamic_form,
        } = this.state;
        // console.log('make_products: ', make_products);
        // console.log('data_stocks: ', data_stocks);
        // console.log('form_cost: ', form_cost);
        // console.log('form: ', form);
        // console.log('sku: ', sku);
        // console.log('sku_stock: ', this.state.sku_stock);
        // console.log('data: ', data);
        // console.log('data_all: ', data_all);
        // console.log('data_categories: ', data_categories);

        // const dataRender = data_product?.length ? data_product : data;
        // console.log('dataRender: ', dataRender);

        if (!isR(ROLE_ALIAS))
            return (
                <Card className='text-muted mh-40vh h-100'>
                    <CardBody className='d-flex justify-content-center align-items-center'>
                        Bạn không có quyền sử dụng tính năng này!
                    </CardBody>
                </Card>
            );

        return is_loading_data ? (
            <div className='loading' />
        ) : (
            <div
                // className={classnames(
                //     'disable-text-selection',
                //     displayMode === 'thumblist' ? 'app-row' : ''
                // )}
                className='app-row disable-text-selection'
            >
                <PageHeading
                    heading='menu.thumb-list'
                    hide_button_add={hide_button_add}
                    setting={page}
                    match={match}
                    totalItem={page.totalItem}
                    isDisabledAdd={!isC(ROLE_ALIAS)}
                    toggleFunc={() => {
                        this.toggle('add');
                        this.toggleAccordion();
                    }}
                    changePageSizeFunc={this.onChangePageSize}
                    onSearchKeyFunc={this.onSearchKey}
                    displayMode={displayMode}
                    changeDisplayMode={this.changeDisplayMode}
                />
                <AddProduct
                    title={editProduct ? ( 'Cập nhật sản phẩm' ) : <IntlMessages id='pages.add-new-modal-title' />}
                    isOpen={show.add}
                    show_add_more_topping={show_add_more_topping}
                    onShowMoreItem={this.onShowMoreItem}
                    show_quantitative={show_quantitative}
                    onShowQuantitative={this.onShowQuantitative}
                    toggle={() => {
                        this.toggle('add');
                    }}
                    onAddSizeAndTopping={this.onAddSizeAndTopping}
                    onChangeSizeAndTopping={this.onChangeSizeAndTopping}
                    onRemoveSizeAndTopping={this.onRemoveSizeAndTopping}
                    data_dynamic_form={data_dynamic_form}
                    values={form}
                    form_cost={form_cost}
                    sku={sku}
                    data_categories={data_categories}
                    data_stocks={data_stocks}
                    make_products={make_products}
                    setting={show}
                    editProduct={editProduct}
                    onChange={this.onChangeForm}
                    onChangeCost={this.onChangeFormCost}
                    onAddStock={this.onClickedFormAddStock}
                    onAddNewStock={this.onClickedFormAddNewStock}
                    onSubmitCost={this.onHandlerAddCost}
                    onDeleteImage={this.onDeleteImage}
                    onDeleteStock={this.onClickedFormDeleteStock}
                    onChangeIceAndSugar={this.onChangeIceAndSugar}
                    onChangeDynamicForm={this.onChangeDynamicForm}
                    onSubmit={this.onHandlerAdd}
                    onUpdate={this.onHandlerUpdate}
                    handleKeyPress={this.handleKeyPress}
                />
                <UpdateProduct
                    title={<IntlMessages id='product.title' />}
                    onSubmit={this.onHandlerUpdate}
                    isOpen={show.update}
                    show_add_more_topping={show_add_more_topping}
                    onShowMoreItem={this.onShowMoreItem}
                    show_quantitative={show_quantitative}
                    onShowQuantitative={this.onShowQuantitative}
                    toggle={() => {
                        this.toggle('update');
                    }}
                    onAddSizeAndTopping={this.onAddSizeAndTopping}
                    onChangeSizeAndTopping={this.onChangeSizeAndTopping}
                    onRemoveSizeAndTopping={this.onRemoveSizeAndTopping}
                    onChangeIceAndSugar={this.onChangeIceAndSugar}
                    data_dynamic_form={data_dynamic_form}
                    data_categories={data_categories}
                    values={form}
                    form_cost={form_cost}
                    sku={sku}
                    data_stocks={data_stocks}
                    make_products={make_products}
                    setting={show}
                    onAddStock={this.onClickedFormAddStock}
                    onAddNewStock={this.onClickedFormAddNewStock}
                    onChange={this.onChangeForm}
                    onChangeCost={this.onChangeFormCost}
                    onDeleteStock={this.onClickedFormDeleteStock}
                    onDeleteImage={this.onDeleteImage}
                    onChangeDynamicForm={this.onChangeDynamicForm}
                />
                <AddCategoryProduct
                    title={
                        <IntlMessages id='make-product.add-new-modal-title' />
                    }
                    onSubmit={this.onHandlerAddCost}
                    isOpen={show.update_cost}
                    toggle={() => {
                        this.toggle('update_cost');
                    }}
                    data_dynamic_form={data_dynamic_form}
                    data_categories={data_categories}
                    data_stocks={data_stocks}
                    make_products={make_products}
                    values={form}
                    form_cost={form_cost}
                    setting={show}
                    onChangeCost={this.onChangeFormCost}
                    onDeleteImage={this.onDeleteImage}
                    onAddStock={this.onClickedFormAddStock}
                    onDeleteStock={this.onClickedFormDeleteStock}
                    onChangeDynamicForm={this.onChangeDynamicForm}
                />
                <Nav
                    tabs
                    className='d-flex justify-content-between separator-tabs ml-0 mb-5'
                >
                    <div className='d-flex'>
                        <NavItem>
                            <NavLink
                                className={classnames({
                                    active: this.state.activeTab === '1',
                                    'nav-link': true,
                                })}
                                onClick={() => this.toggleTab('1')}
                                location={{}}
                                to='#'
                            >
                                <IntlMessages id='general.info' />
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classnames({
                                    active: this.state.activeTab === '2',
                                    'nav-link': true,
                                })}
                                onClick={() => this.toggleTab('2')}
                                location={{}}
                                to='#'
                            >
                                <IntlMessages id='category.nav-tab' />
                                <TooltipItem
                                    item={this.state.categoriesTooltip}
                                    id={16}
                                />
                            </NavLink>
                        </NavItem>
                    </div>

                    {/* <NavItem>
                        <NavLink
                            className={classnames({
                                active: this.state.activeTab === '3',
                                'nav-link': true,
                            })}
                            onClick={() => this.toggleTab('3')}
                            location={{}}
                            to='#'
                        >
                            <span className='mr-2'>
                                <i className='simple-icon-settings'></i>
                            </span>
                            <IntlMessages id='general.setting' />
                        </NavLink>
                    </NavItem> */}
                </Nav>
                <TabContent
                    activeTab={this.state.activeTab}
                    className='mh-53vh'
                >
                    <TabPane tabId='1'>
                        {data.length === 0 ? (
                            <Card className='mt-4 mb-4 mh-53vh'>
                                <CardBody>Chưa có dữ liệu...</CardBody>
                            </Card>
                        ) : (
                            <>
                                <Row className='content-page__row d-none d-md-flex'>
                                    <Col
                                        xs='12'
                                        sm='12'
                                        md='12'
                                        lg={
                                            show.view &&
                                            displayMode === 'thumblist'
                                                ? 6
                                                : 12
                                        }
                                        xl={
                                            show.view &&
                                            displayMode === 'thumblist'
                                                ? 6
                                                : 12
                                        }
                                    >
                                        {data?.length ? (
                                            displayMode === 'thumblist' ? (
                                                <>
                                                    {data?.map((item, idx) => (
                                                        <ListItemLeftForm
                                                            onClickedItem={
                                                                this
                                                                    .onClickedItem
                                                            }
                                                            onUpdate={
                                                                this
                                                                    .onClickedUpdate
                                                            }
                                                            onUpdateCost={
                                                                this
                                                                    .onClickedUpdateCost
                                                            }
                                                            onDelete={
                                                                this
                                                                    .onClickedDelete
                                                            }
                                                            onSoftHide={
                                                                this
                                                                    .onClickedSoftHide
                                                            }
                                                            onBestter={
                                                                this
                                                                    .onClickedBestter
                                                            }
                                                            onChangeCheckbox={
                                                                this
                                                                    .onClickedCheckbox
                                                            }
                                                            isDisabledUpdate={
                                                                !isU(ROLE_ALIAS)
                                                            }
                                                            isDisabledUpdateCost={
                                                                !isU(ROLE_ALIAS)
                                                            }
                                                            isDisabledDelete={
                                                                !isD(ROLE_ALIAS)
                                                            }
                                                            selectedItems={
                                                                selectedItems
                                                            }
                                                            key={idx}
                                                            idx={idx}
                                                            item={item}
                                                            make_products={
                                                                make_products
                                                            }
                                                        />
                                                    ))}
                                                    {/* {data?.length <
                                                    12 ? null : ( */}
                                                    <Pagination
                                                        currentPage={
                                                            page.currentPage
                                                        }
                                                        totalPage={
                                                            page.totalPage
                                                        }
                                                        onChangePage={(i) =>
                                                            this.onChangePage(i)
                                                        }
                                                    />
                                                    {/* )} */}
                                                </>
                                            ) : (
                                                <ContentMultiForm
                                                    data={data_all}
                                                />
                                            )
                                        ) : (
                                            <Card className='d-flex justify-content-center align-items-center'>
                                                <CardBody>
                                                    Không có dữ liệu khách hàng
                                                    nào phù hợp với lựa chọn của
                                                    bạn.
                                                </CardBody>
                                            </Card>
                                        )}
                                    </Col>
                                    {show.view ? (
                                        <Col
                                            xs='12'
                                            sm='12'
                                            md='12'
                                            lg='6'
                                            xl='6'
                                        >
                                            <ContentDisplay
                                                // data_dynamic_form={data_dynamic_form}
                                                values={form}
                                                sku={sku}
                                                setting={show}
                                                onChange={this.onChangeForm}
                                            />
                                            <ContentDisplayCost
                                                toggleFunc={() => {
                                                    this.toggle('update_cost');
                                                }}
                                                values={form}
                                                make_products={make_products}
                                                // form_cost={form_cost}
                                                // setting={show}
                                                data_stocks={data_stocks}
                                            />
                                        </Col>
                                    ) : null}
                                </Row>
                                <Row className='content-page__row d-block d-md-none mb-4'>
                                    <Col>
                                        {data?.length ? (
                                            data?.map((item, idx) => {
                                                // console.log(item);
                                                return (
                                                    <div
                                                        className='d-flex mb-3 card'
                                                        key={item.id}
                                                    >
                                                        <ListItemFormMobile
                                                            onClickedItem={
                                                                this
                                                                    .onClickedItem
                                                            }
                                                            onUpdate={
                                                                this
                                                                    .onClickedUpdate
                                                            }
                                                            onUpdateCost={
                                                                this
                                                                    .onClickedUpdateCost
                                                            }
                                                            onDelete={
                                                                this
                                                                    .onClickedDelete
                                                            }
                                                            onSoftHide={
                                                                this
                                                                    .onClickedSoftHide
                                                            }
                                                            onBestter={
                                                                this
                                                                    .onClickedBestter
                                                            }
                                                            onChangeCheckbox={
                                                                this
                                                                    .onClickedCheckbox
                                                            }
                                                            isDisabledUpdate={
                                                                !isU(ROLE_ALIAS)
                                                            }
                                                            isDisabledUpdateCost={
                                                                !isU(ROLE_ALIAS)
                                                            }
                                                            isDisabledDelete={
                                                                !isD(ROLE_ALIAS)
                                                            }
                                                            selectedItems={
                                                                selectedItems
                                                            }
                                                            key={idx}
                                                            idx={idx}
                                                            item={item}
                                                            form={form}
                                                            accordion={
                                                                accordion
                                                            }
                                                            toggleAccordion={
                                                                this
                                                                    .toggleAccordion
                                                            }
                                                            onChange={
                                                                this
                                                                    .onChangeForm
                                                            }
                                                            make_products={
                                                                make_products
                                                            }
                                                            data_stocks={
                                                                data_stocks
                                                            }
                                                            sku={sku}
                                                        />
                                                    </div>
                                                );
                                            })
                                        ) : (
                                            <Card className='d-flex justify-content-center align-items-center'>
                                                <CardBody>
                                                    Không có dữ liệu khách hàng
                                                    nào phù hợp với lựa chọn của
                                                    bạn.
                                                </CardBody>
                                            </Card>
                                        )}
                                        {/* {data?.length < 12 ? null : ( */}
                                        <Pagination
                                            currentPage={page.currentPage}
                                            totalPage={page.totalPage}
                                            onChangePage={(i) =>
                                                this.onChangePage(i)
                                            }
                                        />
                                        {/* )} */}
                                    </Col>
                                </Row>
                            </>
                        )}
                    </TabPane>
                    <TabPane tabId='2'>
                        <Row>
                            <Colxx>
                                <Category />
                            </Colxx>
                        </Row>
                    </TabPane>
                    {/* <TabPane tabId='3'>
                        <Setting />
                    </TabPane> */}
                </TabContent>

                <ProductApplicationMenu
                    data={data_all}
                    make_products={make_products}
                    filter={filter}
                    filterData={(key, value) => this.filterData(key, value)}
                />
                <Separator />
                <FooterPage>
                    <FooterCustomer />
                </FooterPage>
            </div>
        );
    }
}

export default ContentComponent;
