import React, { useState } from 'react';
import {
    Input,
    InputGroup,
    Label,
    Form,
    FormGroup,
    CustomInput,
    Row,
} from 'reactstrap';
import IntlMessages from '../../../helpers/IntlMessages';
import Select from 'react-select';
import CustomSelectInput from '../../../components/common/CustomSelectInput';
import { END_POINT } from '../../../constants/defaultValues';
import { AddCommaNumber } from '../../../helpers/Utils';
import { SetIceOrSugar } from '../../../helpers/Product';
import { Creatable } from 'react-select';
import { Colxx } from '../../../components/common/CustomBootstrap';
import SizeAndTopping from './SizeAndTopping';
import IceAndSugar from './IceAndSugar';
import DynamicForm from '../general/DynamicForm';
import TooltipItem from '../../../components/common/TooltipItem';

const ContentComponent = (props) => {
    let default_category = props.data_categories.find(
        (cate) => cate.id === props.values.category_id
    );

    if (!default_category) {
        default_category = '';
    } else {
        default_category = default_category.value;
    }

    const [categoryTooltip] = useState({
        placement: 'top',
        text: '',
        body: 'Là danh mục cha của sản phẩm này. Ví dụ: Danh mục "Cà phê" là danh mục cha của sản phẩm "Cà phê sữa đá".',
    });

    const [priceTooltip] = useState({
        placement: 'top',
        text: '',
        body: 'Giá này là giá bán ra của sản phẩm nếu như "Giá khuyến mãi" không được tick chọn.',
    });

    const [priceSaleTooltip] = useState({
        placement: 'top',
        text: '',
        body: 'Tick chọn vào ô này và điền Giá khuyến mãi để thay đổi giá (giảm giá) bán của sản phẩm.',
    });

    const [sizeTooltip] = useState({
        placement: 'top',
        text: '',
        body: 'Là nơi để nhập tên và giá tiền theo kích thước tương ứng được cộng thêm vào giá bán của sản phẩm này.',
    });

    const [toppingTooltip] = useState({
        placement: 'top',
        text: '',
        body: 'Là nơi để nhập các sản phẩm đi kèm với sản phẩm này và giá tiền tương ứng được cộng thêm vào giá bán.',
    });

    const [iceTooltip] = useState({
        placement: 'top',
        text: '',
        body: 'Tick chọn 4 ô để khách hàng/người gọi sản phẩm có thể chọn Lượng đá của sản phẩm trong Menu.',
    });

    const [sugarTooltip] = useState({
        placement: 'top',
        text: '',
        body: 'Tick chọn 4 ô để khách hàng/người gọi sản phẩm có thể chọn Độ ngọt của sản phẩm trong Menu.',
    });

    return (
        <Form>
            <FormGroup>
                <Label for='name'>
                    <IntlMessages id='pages.product-name' />
                </Label>
                <Input
                    type='text'
                    name='name'
                    id='name'
                    disabled={props.setting.viewonly && !props.setting.add}
                    value={props.values.name}
                    onChange={props.onChange}
                />
            </FormGroup>
            <FormGroup>
                <Label for='category'>
                    <IntlMessages id='pages.category' />
                    <TooltipItem item={categoryTooltip} id={9} />
                </Label>
                <Select
                    isDisabled={props.setting.viewonly && !props.setting.add}
                    components={{ Input: CustomSelectInput }}
                    className='react-select'
                    classNamePrefix='react-select'
                    name='category'
                    placeholder={<IntlMessages id='pages.select-category' />}
                    options={props.data_categories}
                    onChange={(e) => props.onChange(e, 'category')}
                    defaultInputValue={default_category}
                />
            </FormGroup>

            {/* PRICE ORIGINAL & PRICE SALE */}
            <Row>
                <Colxx
                    sm={12}
                    md={props.setting.add ? 12 : 6}
                    lg={props.setting.add ? 12 : 6}
                >
                    <FormGroup>
                        <Label for='price'>
                            <IntlMessages id='product.price.original' />
                            <TooltipItem item={priceTooltip} id={10} />
                        </Label>
                        <Input
                            name='price'
                            id='price'
                            disabled={
                                props.setting.viewonly && !props.setting.add
                            }
                            placeholder={'0'}
                            value={AddCommaNumber(props.values.price)}
                            onChange={props.onChange}
                        />
                    </FormGroup>
                </Colxx>
                <Colxx
                    sm={12}
                    md={props.setting.add ? 12 : 6}
                    lg={props.setting.add ? 12 : 6}
                >
                    <FormGroup check className='mb-2'>
                        <Input
                            disabled={
                                props.setting.viewonly && !props.setting.add
                            }
                            checked={props.values.active_sale}
                            onChange={() =>
                                props.onChange(
                                    !props.values.active_sale,
                                    'active_sale'
                                )
                            }
                            type='checkbox'
                            name='check'
                            id='checkbox-sale'
                        />{' '}
                        <Label for='checkbox-sale' check>
                            <IntlMessages id='product.price.sale' />
                            <TooltipItem item={priceSaleTooltip} id={11} />
                        </Label>
                    </FormGroup>
                    <FormGroup>
                        <Input
                            name='price_sale'
                            id='price_sale'
                            disabled={
                                props.setting.viewonly && !props.setting.add
                            }
                            placeholder={'0'}
                            value={
                                props.values.price_sale
                                    ? AddCommaNumber(props.values.price_sale)
                                    : '0'
                            }
                            onChange={props.onChange}
                        />
                    </FormGroup>
                </Colxx>
            </Row>

            {/* SIZE */}
            <div className='custom-line mb-4 mt-3' />
            <FormGroup className='mb-4'>
                {props.setting.viewonly && !props.setting.add ? (
                    <Label for='sizeTitle'>
                        {props.values.sizeTitle ? (
                            <span>{props.values.sizeTitle}</span>
                        ) : (
                            <IntlMessages id='product.size' />
                        )}
                    </Label>
                ) : (
                    <div className='d-flex'>
                        <Input
                            className='mb-3 custom-input-underline w-30 pad-2px'
                            type='text'
                            name='sizeTitle'
                            id='sizeTitle'
                            disabled={
                                props.setting.viewonly && !props.setting.add
                            }
                            value={props.values.sizeTitle}
                            placeholder='Kích thước'
                            onChange={props.onChange}
                        />
                        <TooltipItem item={sizeTooltip} id={12} />
                    </div>
                )}
                <SizeAndTopping
                    onAdd={() => props.onAddSizeAndTopping('size')}
                    onChange={props.onChangeSizeAndTopping}
                    onRemove={props.onRemoveSizeAndTopping}
                    name={'size'}
                    values={props.values.size}
                    disabled={props.setting.viewonly && !props.setting.add}
                />
            </FormGroup>

            {/* TOPPING */}
            <div className='custom-line mb-4 mt-3' />
            <FormGroup className='mb-4'>
                {props.setting.viewonly && !props.setting.add ? (
                    <Label for='toppingTitle'>
                        {props.values.toppingTitle ? (
                            <span>{props.values.toppingTitle}</span>
                        ) : (
                            <IntlMessages id='product.topping' />
                        )}
                    </Label>
                ) : (
                    <div className='d-flex'>
                        <Input
                            className='mb-3 custom-input-underline w-30 pad-2px'
                            type='text'
                            name='toppingTitle'
                            id='toppingTitle'
                            disabled={
                                props.setting.viewonly && !props.setting.add
                            }
                            value={props.values.toppingTitle}
                            placeholder='Topping'
                            onChange={props.onChange}
                        />
                        <TooltipItem item={toppingTooltip} id={13} />
                    </div>
                )}
                <SizeAndTopping
                    onAdd={() => props.onAddSizeAndTopping('topping')}
                    onChange={props.onChangeSizeAndTopping}
                    onRemove={props.onRemoveSizeAndTopping}
                    name={'topping'}
                    values={props.values.topping}
                    disabled={props.setting.viewonly && !props.setting.add}
                />
            </FormGroup>

            {/* ICE */}
            <div className='custom-line mb-4 mt-3' />
            <FormGroup className='mb-4'>
                {props.setting.viewonly && !props.setting.add ? (
                    <Label for='iceTitle'>
                        {props.values.iceTitle ? (
                            <span>{props.values.iceTitle}</span>
                        ) : (
                            <IntlMessages id='product.ice' />
                        )}
                    </Label>
                ) : (
                    <div className='d-flex'>
                        <Input
                            className='mb-3 custom-input-underline w-30 pad-2px'
                            type='text'
                            name='iceTitle'
                            id='iceTitle'
                            disabled={
                                props.setting.viewonly && !props.setting.add
                            }
                            value={props.values.iceTitle}
                            placeholder='Lượng đá'
                            onChange={props.onChange}
                        />
                        <TooltipItem item={iceTooltip} id={14} />
                    </div>
                )}
                <IceAndSugar
                    selectedValue={props.values.ice}
                    onSelected={props.onChangeIceAndSugar}
                    name='ice'
                    disabled={props.setting.viewonly && !props.setting.add}
                />
            </FormGroup>

            {/* SUGAR */}
            <div className='custom-line mb-4 mt-3' />
            <FormGroup className='mb-4'>
                {props.setting.viewonly && !props.setting.add ? (
                    <Label for='sugarTitle'>
                        {props.values.sugarTitle ? (
                            <span>{props.values.sugarTitle}</span>
                        ) : (
                            <IntlMessages id='product.sugar' />
                        )}
                    </Label>
                ) : (
                    <div className='d-flex'>
                        <Input
                            className='mb-3 custom-input-underline w-30 pad-2px'
                            type='text'
                            name='sugarTitle'
                            id='sugarTitle'
                            disabled={
                                props.setting.viewonly && !props.setting.add
                            }
                            value={props.values.sugarTitle}
                            placeholder='Độ ngọt'
                            onChange={props.onChange}
                        />
                        <TooltipItem item={sugarTooltip} id={15} />
                    </div>
                )}
                <IceAndSugar
                    selectedValue={props.values.sugar}
                    onSelected={props.onChangeIceAndSugar}
                    name='sugar'
                    disabled={props.setting.viewonly && !props.setting.add}
                />
            </FormGroup>

            {/* IMAGE */}
            <div className='custom-line mb-4 mt-3' />
            <FormGroup>
                <Label for='image'>
                    <IntlMessages id='pages.image' />{' '}
                    {props.setting.viewonly && !props.setting.add ? null : (
                        <span
                            onClick={() => props.onDeleteImage()}
                            className={
                                'cursor-pointer glyph-icon simple-icon-pencil'
                            }
                        />
                    )}
                </Label>
                {props.values.image ? (
                    props.values.image.name ? (
                        <InputGroup
                            className='mb-3'
                            encType='multipart/form-data'
                        >
                            <CustomInput
                                label={props.values.image.name}
                                disabled={
                                    props.setting.viewonly && !props.setting.add
                                }
                                type='file'
                                id='image'
                                name='image'
                                onChange={props.onChange}
                            />
                        </InputGroup>
                    ) : (
                        <FormGroup>
                            <img
                                onError={(e) => {
                                    e.target.onerror = null;
                                    e.target.src =
                                        'https://placehold.co/320x240';
                                }}
                                alt={''}
                                src={
                                    props.values.image.indexOf('http') >= 0
                                        ? props.values.image
                                        : `${END_POINT}/public/${props.values.image}`
                                }
                                className='list-thumbnail responsive border-0'
                            />
                        </FormGroup>
                    )
                ) : (
                    <InputGroup className='mb-3' encType='multipart/form-data'>
                        <CustomInput
                            disabled={
                                props.setting.viewonly && !props.setting.add
                            }
                            type='file'
                            id='image'
                            name='image'
                            onChange={props.onChange}
                        />
                    </InputGroup>
                )}
            </FormGroup>

            <DynamicForm
                values={props.values.dynamic_form}
                data={props.data_dynamic_form}
                onChange={props.onChangeDynamicForm}
                disabled={props.setting.viewonly && !props.setting.add}
            />
        </Form>
    );
};

export default ContentComponent;

const GetImage = (image) => {
    if (image.target.files) {
        console.log(image.target.files);

        return image.target.files[0].name;
    }

    return image;
};
