export default {
    'bill-delete': 'Huỷ hoá đơn thành công',
    'discount-create': 'Tạo chiết khấu thành công',
    'tax-fee-create': 'Thu thuế thành công',
    'fee-other-create': 'Thu phí thành công',
    'order-done': 'Đặt hàng thành công',
    'order-change': 'Điều chỉnh đơn hàng thành công',
    'product-create': 'Thêm sản phẩm thành công',
    'product-update': 'Cập nhật sản phẩm thành công',
    'product-delete': 'Xóa sản phẩm thành công',
    'category-create': 'Thêm danh mục sản phẩm thành công',
    'category-update': 'Cập nhật danh mục sản phẩm thành công',
    'category-delete': 'Xóa danh mục sản phẩm thành công',
    'todo-create': 'Thêm sự kiện thành công',
    'todo-update': 'Cập nhật sự kiện thành công',
    'todo-delete': 'Xóa sự kiện thành công',
    'table-setting-update': 'Cập nhật sơ đồ bàn ghế thành công',
    'stock-create': 'Thêm nguyên vật liệu thành công',
    'stock-add-more': 'Bổ sung nguyên vật liệu thành công',
    'stock-update': 'Cập nhật nguyên vật liệu thành công',
    'stock-delete': 'Xóa nguyên vật liệu thành công',
    'stock-reset': 'Reset nguyên vật liệu thành công',
    'update-stock': 'Cập nhật số lượng nguyên vật liệu còn lại thành công',
    'make-product-create': 'Thêm định lượng thành công',
    'make-product-update': 'Cập nhật định lượng thành công',
    'make-product-delete': 'Xóa định lượng thành công',
    'invoice-print': 'Xuất hóa đơn thành công',
    'profile-update': 'Cập nhật thông tin cá nhân thành công',
    'profile-company-update': 'Cập nhật thông tin cửa hàng thành công',
    'customer-create': 'Thêm khách hàng thành công',
    'customer-update': 'Cập nhật khách hàng thành công',
    'customer-debt-update': 'Thay đổi công nợ khách hàng thành công',
    'customer-delete': 'Xóa khách hàng thành công',
    'customer-debt-create': 'Tạo công nợ cho khách hàng thành công',
    'income-create': 'Thêm phiếu thu thành công',
    'income-update': 'Cập nhật phiếu thu thành công',
    'income-delete': 'Xóa phiếu thu thành công',
    'outcome-create': 'Thêm phiếu chi thành công',
    'outcome-update': 'Cập nhật phiếu chi thành công',
    'outcome-delete': 'Xóa phiếu chi thành công',
    'treasury-create': 'Thêm tài sản thành công',
    'treasury-update': 'Cập nhật tài sản thành công',
    'treasury-delete': 'Xóa tài sản thành công',
    'company-provider-create': 'Thêm nhà cung cấp thành công',
    'company-provider-create-log': 'Thêm giao dịch nhà cung cấp thành công',
    'company-provider-update': 'Cập nhật nhà cung cấp thành công',
    'company-provider-delete': 'Xóa nhà cung cấp thành công',
    'user-staff-create': 'Thêm nhân viên thành công',
    'user-staff-create-log': 'Thêm khác thành công',
    'user-staff-update': 'Cập nhật nhân viên thành công',
    'user-staff-delete': 'Xóa nhân viên thành công',
    'form-dynamic-create': 'Thêm trường thông tin vào form thành công',
    'form-dynamic-update': 'Cập nhật trường thông tin vào form thành công',
    'form-dynamic-delete': 'Xóa trường thông tin vào form thành công',
    'role-create': 'Thêm phân quyền thành công',
    'role-update': 'Cập nhật phân quyền thành công',
    'role-delete': 'Xóa phân quyền thành công',
    'user-role-create': 'Thêm nhân viên phân quyền thành công',
    'user-role-update': 'Cập nhật nhân viên phân quyền thành công',
    'user-role-delete': 'Xóa nhân viên phân quyền thành công',
    'companies-create': 'Thêm liên kết đại lý thành công',
    'companies-update': 'Cập nhật liên kết đại lý thành công',
    'companies-delete': 'Xóa liên kết đại lý thành công',
    'warehouseImport-create': 'Tạo phiếu nhập kho thành công',
    'warehouseImport-update': 'Cập nhật phiếu nhập kho thành công',
    'warehouseImport-delete': 'Xoá phiếu nhập kho thành công',
    'warehouseExport-create': 'Tạo phiếu xuất kho thành công',
    'warehouseExport-update': 'Cập nhật phiếu xuất kho thành công',
    'warehouseExport-delete': 'Xoá phiếu xuất kho thành công',
    'warehouseTransfer-create': 'Tạo phiếu chuyển kho thành công',
    'warehouseTransfer-update': 'Cập nhật phiếu chuyển kho thành công',
    'warehouseTransfer-delete': 'Xoá phiếu chuyển kho thành công',
    'promotion-create': 'Tạo chương trình khuyến mại thành công',
    'promotion-delete': 'Xoá chương trình khuyến mại thành công',
    'promotion-update': 'Cập nhật chương trình khuyến mại thành công',
};
