import React, { useState } from 'react';
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Input,
    Label,
    Form,
    FormGroup,
} from 'reactstrap';
import IntlMessages from '../../../../helpers/IntlMessages';
import PerfectScrollbar from 'react-perfect-scrollbar';

// import Select from 'react-select';
// import CustomSelectInput from '../../../../components/common/CustomSelectInput';
// import { GetSelectTime, SetSelectTime } from '../../../../helpers/SelectTime';
// import { AddCommaNumber } from '../../../../helpers/Utils';
import DynamicForm from '../../general/DynamicForm';
// import TooltipItem from '../../../../components/common/TooltipItem';

const ContentComponent = ({
    title,
    isOpen,
    toggle,
    onSubmit,
    data_dynamic_form,
    setting,
    values,
    onChange,
    onChangeDynamicForm,
}) => {
    // console.log('render UpdateComponent');
    // console.log('values: ', values);
    const [timeTooltip] = useState({
        placement: 'top',
        text: '',
        body: 'Nếu chọn "Đưa vào thống kê" thì số liệu này sẽ được tính như một khoản chi trong ngày. Nếu chọn "Không đưa vào thống kê" thì chỉ xem như một con số tượng trưng.',
    });
    return (
        <Modal
            isOpen={isOpen}
            toggle={toggle}
            wrapClassName='modal-right'
            backdrop={true}
        >
            <Form onSubmit={(e) => onSubmit(e)}>
                <ModalHeader toggle={toggle}>{title}</ModalHeader>
                <ModalBody className='pad-125rem scrool modal-right-add'>
                    <PerfectScrollbar
                        options={{
                            suppressScrollX: true,
                            wheelPropagation: false,
                        }}
                        id='modal-right__body'
                    >
                        <FormGroup>
                            <Label for='name'>
                                <IntlMessages id='promotion-name' /><span style={{color: 'red'}}>*</span>
                            </Label>
                            <Input
                                autoFocus={true}
                                type='text'
                                name='name'
                                id='name'
                                value={values.name}
                                onChange={onChange}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for='quantity'>
                                <IntlMessages id='promotion-quantity' /><span style={{color: 'red'}}>*</span>
                            </Label>
                            <Input
                                type='text'
                                name='quantity'
                                id='quantity'
                                value={values.quantity}
                                onChange={onChange}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for='code'>
                                <IntlMessages id='promotion-code' /><span style={{color: 'red'}}>*</span>
                            </Label>
                            <Input
                                type='text'
                                name='code'
                                id='code'
                                value={values.code}
                                onChange={onChange}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for='description'>
                                <IntlMessages id='promotion-description' />
                            </Label>
                            <Input
                                type='text'
                                name='description'
                                id='description'
                                value={values.description}
                                onChange={onChange}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for='date_start'>
                                <IntlMessages id='promotion-date_start' /><span style={{color: 'red'}}>*</span>
                            </Label>
                            <Input
                                type='text'
                                name='date_start'
                                id='date_start'
                                value={values.date_start}
                                onChange={onChange}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for='date_end'>
                                <IntlMessages id='promotion-date_end' /><span style={{color: 'red'}}>*</span>
                            </Label>
                            <Input
                                type='text'
                                name='date_end'
                                id='date_end'
                                value={values.date_end}
                                onChange={onChange}
                            />
                        </FormGroup>

                        <DynamicForm
                            values={values.dynamic_form}
                            data={data_dynamic_form}
                            onChange={onChangeDynamicForm}
                        />
                    </PerfectScrollbar>
                </ModalBody>
                <ModalFooter>
                    <Button color='primary'>
                        <IntlMessages id='general.btn-update' />
                    </Button>{' '}
                </ModalFooter>
            </Form>
        </Modal>
    );
};

export default React.memo(ContentComponent);
