import React, { Fragment, useEffect, useState } from 'react';
import Select from 'react-select';
import {
    Button,
    FormGroup,
    Input,
    Label,
    Row,
    Col,
    InputGroup,
} from 'reactstrap';
import { Colxx } from '../../../../components/common/CustomBootstrap';
import IntlMessages from '../../../../helpers/IntlMessages';
import axios from 'axios';
import { END_POINT } from '../../../../constants/defaultValues';
import {
    AddCommaNumber,
    NotificatioErrController,
} from '../../../../helpers/Utils';
import CustomSelectInput from '../../../../components/common/CustomSelectInput';

const fetchProductFromAPI = () => {
    let tokenStr = localStorage.getItem('access_token');
    return axios
        .get(END_POINT + '/product/all', {
            headers: { Authorization: `Bearer ${tokenStr}` },
        })
        .then((res) => res.data)
        .catch((err) => {
            NotificatioErrController(err.response.data.responseText);
            return false;
        });
};

const fetchCategoriesFromAPI = () => {
    let tokenStr = localStorage.getItem('access_token');
    return axios
        .get(END_POINT + '/category', {
            headers: { Authorization: `Bearer ${tokenStr}` },
        })
        .then((res) => res.data)
        .catch((err) => {
            NotificatioErrController(err.response.data.responseText);
            return false;
        });
};

export const CustomOptionComponentDiscount = ({
    selectedOption,
    onChangeItem,
    onAddItem,
    onAddProduct,
    onRemoveDiscount,
    onRemoveProduct,
    discountRange,
    oneProduct,
    oneTypeProduct,
    quantityProduct,
    quantityTypeProduct,
    customerPoint,
}) => {
    const [products, setProducts] = useState(null);
    const [categories, setCategories] = useState(null);

    useEffect(() => {
        fetchProductFromAPI().then((data) => {
            setProducts(data);
        });
        fetchCategoriesFromAPI().then((data) => {
            setCategories(data);
        });
    }, []);

    const products_discount = products?.data?.map(({ label, value }) => ({
        label,
        value,
    }));

    const categories_discount = categories?.data?.map(({ label, value }) => ({
        label,
        value,
    }));

    const AddMoreButton = ({ category, onAddItem }) => {
        return (
            <Row className='pt-4'>
                <Col sm={12} xs={12} md={12} lg={12}>
                    <span
                        className='working_hours_btn working_hours_btn-zero-top mb-3 disable-text-selection'
                        onClick={() => onAddItem(category)}
                    >
                        +
                    </span>
                </Col>
            </Row>
        );
    };

    if (selectedOption === 'total-order-value') {
        return (
            <>
                {discountRange?.map((range, idx) => {
                    return (
                        <div key={idx}>
                            <div className='position-relative custom-line-dashed mb-3 mt-4'>
                                <i
                                    className='simple-icon-minus absolute-icon cursor-pointer'
                                    onClick={() =>
                                        onRemoveDiscount(idx, 'discountRange')
                                    }
                                />
                            </div>
                            <Row className='d-flex'>
                                <Colxx xxs='6'>
                                    <FormGroup>
                                        <Label
                                            className='text-muted'
                                            for='price_start'
                                        >
                                            Đơn hàng từ:
                                        </Label>
                                        <Input
                                            type='number'
                                            name='price_start'
                                            id='price_start'
                                            value={AddCommaNumber(
                                                range.price_start
                                            )}
                                            onChange={(e) =>
                                                onChangeItem(
                                                    'discountRange',
                                                    idx,
                                                    'price_start',
                                                    e
                                                )
                                            }
                                        />
                                    </FormGroup>
                                </Colxx>
                                <Colxx>
                                    <FormGroup>
                                        <Label
                                            className='text-muted'
                                            for='price_end'
                                        >
                                            đến:
                                        </Label>
                                        <Input
                                            type='number'
                                            name='price_end'
                                            id='price_end'
                                            value={AddCommaNumber(
                                                range.price_end
                                            )}
                                            onChange={(e) =>
                                                onChangeItem(
                                                    'discountRange',
                                                    idx,
                                                    'price_end',
                                                    e
                                                )
                                            }
                                        />
                                    </FormGroup>
                                </Colxx>
                            </Row>

                            <Label className='text-muted' for='discount-value'>
                                Mức giảm
                            </Label>
                            <InputGroup>
                                <Input
                                    type='number'
                                    name='discount_value'
                                    id='discount_value'
                                    value={AddCommaNumber(range.discount_value)}
                                    onChange={(e) =>
                                        onChangeItem(
                                            'discountRange',
                                            idx,
                                            'discount_value',
                                            e
                                        )
                                    }
                                />
                                <Button
                                    className='border-radius-0'
                                    color='primary'
                                    outline
                                    size='sm'
                                    onClick={(e) =>
                                        onChangeItem(
                                            'discountRange',
                                            idx,
                                            'discount_type',
                                            e
                                        )
                                    }
                                    active={range.discount_type === 1}
                                >
                                    VNĐ
                                </Button>
                                <Button
                                    className='border-radius-topbot-right'
                                    color='primary'
                                    outline
                                    size='sm'
                                    onClick={(e) =>
                                        onChangeItem(
                                            'discountRange',
                                            idx,
                                            'discount_type',
                                            e
                                        )
                                    }
                                    active={range.discount_type === 2}
                                >
                                    %
                                </Button>
                            </InputGroup>
                        </div>
                    );
                })}
                <AddMoreButton category='discountRange' onAddItem={onAddItem} />
            </>
        );
    }
    if (selectedOption === 'one-product') {
        return (
            <>
                {oneProduct?.map((range, idx) => {
                    // const product = range.product;
                    // console.log('product: ', product);
                    return (
                        <div key={idx}>
                            <div className='position-relative custom-line-dashed mb-3 mt-4'>
                                <i
                                    className='simple-icon-minus absolute-icon cursor-pointer'
                                    onClick={() =>
                                        onRemoveDiscount(idx, 'oneProduct')
                                    }
                                />
                            </div>
                            <Row className='d-flex position-relative'>
                                <Label
                                    className='text-muted pl-3'
                                    for='product'
                                >
                                    Chọn sản phẩm
                                </Label>
                                {range?.product?.map((p, index) => {
                                    return (
                                        <div
                                            key={index}
                                            className='w-100 d-flex position-relative'
                                        >
                                            <Colxx xxs='9'>
                                                <FormGroup className='mb-1'>
                                                    <Select
                                                        components={{
                                                            Input: CustomSelectInput,
                                                        }}
                                                        className='react-select pb-1'
                                                        classNamePrefix='react-select'
                                                        name='product'
                                                        placeholder={
                                                            <IntlMessages id='discount.select-one-product' />
                                                        }
                                                        options={
                                                            products_discount
                                                        }
                                                        onChange={(e) =>
                                                            onChangeItem(
                                                                'oneProduct',
                                                                idx,
                                                                'product',
                                                                e,
                                                                index
                                                            )
                                                        }
                                                        value={p}
                                                    />
                                                </FormGroup>
                                            </Colxx>
                                            <Colxx xxs='3' className='pl-0'>
                                                <FormGroup className='mb-1'>
                                                    {/* <Label
                                                        className='text-muted'
                                                        for='quantity'
                                                    >
                                                        Số lượng
                                                    </Label> */}
                                                    <Input
                                                        bsSize='lg'
                                                        type='number'
                                                        name='quantity'
                                                        value={p.quantity}
                                                        onChange={(e) =>
                                                            onChangeItem(
                                                                'oneProduct',
                                                                idx,
                                                                'quantity_product',
                                                                e,
                                                                index
                                                            )
                                                        }
                                                    />
                                                </FormGroup>
                                            </Colxx>
                                            <i
                                                className='simple-icon-minus absolute-icon-minus-product cursor-pointer'
                                                onClick={() =>
                                                    onRemoveProduct(
                                                        idx,
                                                        index,
                                                        'oneProduct',
                                                        'product'
                                                    )
                                                }
                                            />
                                        </div>
                                    );
                                })}
                                <i
                                    className='simple-icon-plus absolute-icon-plus cursor-pointer'
                                    onClick={() =>
                                        onAddProduct(
                                            idx,
                                            'oneProduct',
                                            'product'
                                        )
                                    }
                                />
                            </Row>
                            <Label className='text-muted' for='discount_value'>
                                Mức giảm
                            </Label>
                            <InputGroup>
                                <Input
                                    type='number'
                                    value={AddCommaNumber(range.discount_value)}
                                    onChange={(e) =>
                                        onChangeItem(
                                            'oneProduct',
                                            idx,
                                            'discount_value',
                                            e
                                        )
                                    }
                                    name='discount_value'
                                />
                                <Button
                                    className='border-radius-0'
                                    color='primary'
                                    outline
                                    size='sm'
                                    onClick={(e) =>
                                        onChangeItem(
                                            'oneProduct',
                                            idx,
                                            'discount_type',
                                            e
                                        )
                                    }
                                    active={range.discount_type === 1}
                                >
                                    VNĐ
                                </Button>
                                <Button
                                    className='border-radius-topbot-right'
                                    color='primary'
                                    outline
                                    size='sm'
                                    onClick={(e) =>
                                        onChangeItem(
                                            'oneProduct',
                                            idx,
                                            'discount_type',
                                            e
                                        )
                                    }
                                    active={range.discount_type === 2}
                                >
                                    %
                                </Button>
                            </InputGroup>
                        </div>
                    );
                })}
                <AddMoreButton category='oneProduct' onAddItem={onAddItem} />
            </>
        );
    }
    if (selectedOption === 'one-type-product') {
        return (
            <>
                {oneTypeProduct?.map((range, idx) => {
                    return (
                        <div key={idx}>
                            <div className='position-relative custom-line-dashed mb-3 mt-4'>
                                <i
                                    className='simple-icon-minus absolute-icon cursor-pointer'
                                    onClick={() =>
                                        onRemoveDiscount(idx, 'oneTypeProduct')
                                    }
                                />
                            </div>
                            <Row className='d-flex position-relative'>
                                <Label
                                    className='text-muted pl-3'
                                    for='category'
                                >
                                    Chọn loại sản phẩm
                                </Label>
                                {range?.category?.map((p, index) => {
                                    return (
                                        <div
                                            key={index}
                                            className='w-100 d-flex position-relative'
                                        >
                                            <Colxx xxs='9'>
                                                <FormGroup className='mb-1'>
                                                    <Select
                                                        components={{
                                                            Input: CustomSelectInput,
                                                        }}
                                                        className='react-select pb-1'
                                                        classNamePrefix='react-select'
                                                        name='type'
                                                        placeholder={
                                                            <IntlMessages id='discount.select-one-type-product' />
                                                        }
                                                        options={
                                                            categories_discount
                                                        }
                                                        onChange={(e) =>
                                                            onChangeItem(
                                                                'oneTypeProduct',
                                                                idx,
                                                                'category',
                                                                e,
                                                                index
                                                            )
                                                        }
                                                        value={p}
                                                    />
                                                </FormGroup>
                                            </Colxx>
                                            <Colxx xxs='3' className='pl-0'>
                                                <FormGroup className='mb-1'>
                                                    {/* <Label
                                                        className='text-muted'
                                                        for='quantity'
                                                    >
                                                        Số lượng
                                                    </Label> */}
                                                    <Input
                                                        bsSize='lg'
                                                        type='number'
                                                        name='quantity'
                                                        value={p.quantity}
                                                        onChange={(e) =>
                                                            onChangeItem(
                                                                'oneTypeProduct',
                                                                idx,
                                                                'quantity_category',
                                                                e,
                                                                index
                                                            )
                                                        }
                                                    />
                                                </FormGroup>
                                            </Colxx>
                                            <i
                                                className='simple-icon-minus absolute-icon-minus-product cursor-pointer'
                                                onClick={() =>
                                                    onRemoveProduct(
                                                        idx,
                                                        index,
                                                        'oneTypeProduct',
                                                        'category'
                                                    )
                                                }
                                            />
                                        </div>
                                    );
                                })}
                                <i
                                    className='simple-icon-plus absolute-icon-plus cursor-pointer'
                                    onClick={() =>
                                        onAddProduct(
                                            idx,
                                            'oneTypeProduct',
                                            'category'
                                        )
                                    }
                                />
                            </Row>
                            <Label className='text-muted' for='discount_value'>
                                Mức giảm
                            </Label>
                            <InputGroup>
                                <Input
                                    type='number'
                                    name='discount_value'
                                    value={AddCommaNumber(range.discount_value)}
                                    onChange={(e) =>
                                        onChangeItem(
                                            'oneTypeProduct',
                                            idx,
                                            'discount_value',
                                            e
                                        )
                                    }
                                />
                                <Button
                                    className='border-radius-0'
                                    color='primary'
                                    outline
                                    size='sm'
                                    onClick={(e) =>
                                        onChangeItem(
                                            'oneTypeProduct',
                                            idx,
                                            'discount_type',
                                            e
                                        )
                                    }
                                    active={range.discount_type === 1}
                                >
                                    VNĐ
                                </Button>
                                <Button
                                    className='border-radius-topbot-right'
                                    color='primary'
                                    outline
                                    size='sm'
                                    onClick={(e) =>
                                        onChangeItem(
                                            'oneTypeProduct',
                                            idx,
                                            'discount_type',
                                            e
                                        )
                                    }
                                    active={range.discount_type === 2}
                                >
                                    %
                                </Button>
                            </InputGroup>
                        </div>
                    );
                })}
                <AddMoreButton
                    category='oneTypeProduct'
                    onAddItem={onAddItem}
                />
            </>
        );
    }
    if (selectedOption === 'quantity-product') {
        return (
            <>
                {quantityProduct?.map((range, idx) => {
                    return (
                        <div key={idx}>
                            <div className='position-relative custom-line-dashed mb-3 mt-4'>
                                <i
                                    className='simple-icon-minus absolute-icon cursor-pointer'
                                    onClick={() =>
                                        onRemoveDiscount(idx, 'quantityProduct')
                                    }
                                />
                            </div>
                            <Row className='d-flex'>
                                <Colxx xxs='12'>
                                    <FormGroup>
                                        <Label
                                            className='text-muted'
                                            for='quantity_product'
                                        >
                                            Số lượng sản phẩm cần mua
                                        </Label>
                                        <Input
                                            type='number'
                                            name='quantity_product'
                                            value={range.quantity_product}
                                            onChange={(e) =>
                                                onChangeItem(
                                                    'quantityProduct',
                                                    idx,
                                                    'quantity_product',
                                                    e
                                                )
                                            }
                                        />
                                    </FormGroup>
                                </Colxx>
                            </Row>
                            <Label className='text-muted' for='discount_value'>
                                Mức giảm
                            </Label>
                            <InputGroup>
                                <Input
                                    type='number'
                                    name='discount_value'
                                    value={AddCommaNumber(range.discount_value)}
                                    onChange={(e) =>
                                        onChangeItem(
                                            'quantityProduct',
                                            idx,
                                            'discount_value',
                                            e
                                        )
                                    }
                                />
                                <Button
                                    className='border-radius-0'
                                    color='primary'
                                    outline
                                    size='sm'
                                    onClick={(e) =>
                                        onChangeItem(
                                            'quantityProduct',
                                            idx,
                                            'discount_type',
                                            e
                                        )
                                    }
                                    active={range.discount_type === 1}
                                >
                                    VNĐ
                                </Button>
                                <Button
                                    className='border-radius-topbot-right'
                                    color='primary'
                                    outline
                                    size='sm'
                                    onClick={(e) =>
                                        onChangeItem(
                                            'quantityProduct',
                                            idx,
                                            'discount_type',
                                            e
                                        )
                                    }
                                    active={range.discount_type === 2}
                                >
                                    %
                                </Button>
                            </InputGroup>
                        </div>
                    );
                })}
                <AddMoreButton
                    category='quantityProduct'
                    onAddItem={onAddItem}
                />
            </>
        );
    }
    if (selectedOption === 'quantity-type-product') {
        return (
            <>
                {quantityTypeProduct?.map((range, idx) => {
                    return (
                        <div key={idx}>
                            <div className='position-relative custom-line-dashed mb-3 mt-4'>
                                <i
                                    className='simple-icon-minus absolute-icon cursor-pointer'
                                    onClick={() =>
                                        onRemoveDiscount(
                                            idx,
                                            'quantityTypeProduct'
                                        )
                                    }
                                />
                            </div>
                            <Row className='d-flex'>
                                <Colxx xxs='12'>
                                    <FormGroup>
                                        <Label
                                            className='text-muted'
                                            for='quantity_type_product'
                                        >
                                            Số lượng loại sản phẩm
                                        </Label>
                                        <Input
                                            type='number'
                                            name='quantity_type_product'
                                            value={range.quantity_type_product}
                                            onChange={(e) =>
                                                onChangeItem(
                                                    'quantityTypeProduct',
                                                    idx,
                                                    'quantity_type_product',
                                                    e
                                                )
                                            }
                                        />
                                    </FormGroup>
                                </Colxx>
                            </Row>
                            <Label className='text-muted' for='discount_value'>
                                Mức giảm
                            </Label>
                            <InputGroup>
                                <Input
                                    type='number'
                                    name='discount_value'
                                    value={AddCommaNumber(range.discount_value)}
                                    onChange={(e) =>
                                        onChangeItem(
                                            'quantityTypeProduct',
                                            idx,
                                            'discount_value',
                                            e
                                        )
                                    }
                                />
                                <Button
                                    className='border-radius-0'
                                    color='primary'
                                    outline
                                    size='sm'
                                    onClick={(e) =>
                                        onChangeItem(
                                            'quantityTypeProduct',
                                            idx,
                                            'discount_type',
                                            e
                                        )
                                    }
                                    active={range.discount_type === 1}
                                >
                                    VNĐ
                                </Button>
                                <Button
                                    className='border-radius-topbot-right'
                                    color='primary'
                                    outline
                                    size='sm'
                                    onClick={(e) =>
                                        onChangeItem(
                                            'quantityTypeProduct',
                                            idx,
                                            'discount_type',
                                            e
                                        )
                                    }
                                    active={range.discount_type === 2}
                                >
                                    %
                                </Button>
                            </InputGroup>
                        </div>
                    );
                })}
                <AddMoreButton
                    category='quantityTypeProduct'
                    onAddItem={onAddItem}
                />
            </>
        );
    }
    if (selectedOption === 'customer-accrual-points') {
        return (
            <>
                {customerPoint?.map((range, idx) => {
                    return (
                        <div key={idx}>
                            <div className='position-relative custom-line-dashed mb-3 mt-4'>
                                <i
                                    className='simple-icon-minus absolute-icon cursor-pointer'
                                    onClick={() =>
                                        onRemoveDiscount(idx, 'customerPoint')
                                    }
                                />
                            </div>
                            <Row className='d-flex'>
                                <Colxx xxs='12'>
                                    <FormGroup>
                                        <Label
                                            className='text-muted'
                                            for='customer_point'
                                        >
                                            Mốc tích điểm
                                        </Label>
                                        <Input
                                            type='number'
                                            name='customer_point'
                                            value={range.customer_point}
                                            onChange={(e) =>
                                                onChangeItem(
                                                    'customerPoint',
                                                    idx,
                                                    'customer_point',
                                                    e
                                                )
                                            }
                                        />
                                    </FormGroup>
                                </Colxx>
                            </Row>
                            <Label className='text-muted' for='discount_value'>
                                Mức giảm
                            </Label>
                            <InputGroup>
                                <Input
                                    type='number'
                                    name='discount_value'
                                    value={AddCommaNumber(range.discount_value)}
                                    onChange={(e) =>
                                        onChangeItem(
                                            'customerPoint',
                                            idx,
                                            'discount_value',
                                            e
                                        )
                                    }
                                />
                                <Button
                                    className='border-radius-0'
                                    color='primary'
                                    outline
                                    size='sm'
                                    onClick={(e) =>
                                        onChangeItem(
                                            'customerPoint',
                                            idx,
                                            'discount_type',
                                            e
                                        )
                                    }
                                    active={range.discount_type === 1}
                                >
                                    VNĐ
                                </Button>
                                <Button
                                    className='border-radius-topbot-right'
                                    color='primary'
                                    outline
                                    size='sm'
                                    onClick={(e) =>
                                        onChangeItem(
                                            'customerPoint',
                                            idx,
                                            'discount_type',
                                            e
                                        )
                                    }
                                    active={range.discount_type === 2}
                                >
                                    %
                                </Button>
                            </InputGroup>
                        </div>
                    );
                })}
                <AddMoreButton category='customerPoint' onAddItem={onAddItem} />
            </>
        );
    }
};

export const CustomOptionComponentGift = ({
    selectedOption,
    productGetProduct,
    orderGetProduct,
    customerPointGetProduct,
    onAddItem,
    onAddProduct,
    onRemoveDiscount,
    onRemoveProduct,
    onChangeItem,
}) => {
    const [products, setProducts] = useState(null);

    useEffect(() => {
        fetchProductFromAPI().then((data) => {
            setProducts(data);
        });
    }, []);

    const products_discount = products?.data?.map(({ label, value }) => ({
        label,
        value,
    }));

    const AddMoreButton = ({ category, onAddItem }) => {
        return (
            <Row className='pt-4'>
                <Col sm={12} xs={12} md={12} lg={12}>
                    <span
                        className='working_hours_btn working_hours_btn-zero-top mb-3 disable-text-selection'
                        onClick={() => onAddItem(category)}
                    >
                        +
                    </span>
                </Col>
            </Row>
        );
    };

    if (selectedOption === 'product-get-product') {
        return (
            <>
                {productGetProduct?.map((range, idx) => {
                    return (
                        <div key={idx}>
                            <div className='position-relative custom-line-dashed mb-3 mt-4'>
                                <i
                                    className='simple-icon-minus absolute-icon cursor-pointer'
                                    onClick={() =>
                                        onRemoveDiscount(
                                            idx,
                                            'productGetProduct'
                                        )
                                    }
                                />
                            </div>
                            <Row className='d-flex position-relative'>
                                <Label
                                    className='text-muted pl-3'
                                    for='product_buy'
                                >
                                    Sản phẩm mua
                                </Label>
                                {range?.pgp_buy?.map((p, index) => {
                                    return (
                                        <div
                                            key={index}
                                            className='w-100 d-flex position-relative'
                                        >
                                            <Colxx xxs='9'>
                                                <FormGroup className='mb-1'>
                                                    <Select
                                                        components={{
                                                            Input: CustomSelectInput,
                                                        }}
                                                        className='react-select pb-1'
                                                        classNamePrefix='react-select'
                                                        name='product_buy'
                                                        placeholder={
                                                            <IntlMessages id='discount.select-one-product' />
                                                        }
                                                        options={
                                                            products_discount
                                                        }
                                                        onChange={(e) =>
                                                            onChangeItem(
                                                                'productGetProduct',
                                                                idx,
                                                                'pgp_buy',
                                                                e,
                                                                index
                                                            )
                                                        }
                                                        value={p}
                                                    />
                                                </FormGroup>
                                            </Colxx>
                                            <Colxx xxs='3' className='pl-0'>
                                                <FormGroup className='mb-1'>
                                                    {/* <Label
                                                        className='text-muted'
                                                        for='quantity_pgp_buy'
                                                    >
                                                        Số lượng
                                                    </Label> */}
                                                    <Input
                                                        bsSize='lg'
                                                        type='number'
                                                        name='quantity_pgp_buy'
                                                        value={p.quantity}
                                                        onChange={(e) =>
                                                            onChangeItem(
                                                                'productGetProduct',
                                                                idx,
                                                                'quantity_pgp_buy',
                                                                e,
                                                                index
                                                            )
                                                        }
                                                    />
                                                </FormGroup>
                                            </Colxx>
                                            <i
                                                className='simple-icon-minus absolute-icon-minus-product cursor-pointer'
                                                onClick={() =>
                                                    onRemoveProduct(
                                                        idx,
                                                        index,
                                                        'productGetProduct',
                                                        'pgp_buy'
                                                    )
                                                }
                                            />
                                        </div>
                                    );
                                })}
                                <i
                                    className='simple-icon-plus absolute-icon-plus cursor-pointer'
                                    onClick={() =>
                                        onAddProduct(
                                            idx,
                                            'productGetProduct',
                                            'pgp_buy'
                                        )
                                    }
                                />
                            </Row>
                            <Row className='d-flex position-relative'>
                                <Label
                                    className='text-muted pl-3'
                                    for='product_gift'
                                >
                                    Sản phẩm tặng
                                </Label>
                                {range?.pgp_gift?.map((p, index) => {
                                    return (
                                        <div
                                            key={index}
                                            className='w-100 d-flex position-relative'
                                        >
                                            <Colxx xxs='9'>
                                                <FormGroup className='mb-1'>
                                                    <Select
                                                        components={{
                                                            Input: CustomSelectInput,
                                                        }}
                                                        className='react-select pb-1'
                                                        classNamePrefix='react-select'
                                                        name='product_gift'
                                                        placeholder={
                                                            <IntlMessages id='discount.select-one-product' />
                                                        }
                                                        options={
                                                            products_discount
                                                        }
                                                        onChange={(e) =>
                                                            onChangeItem(
                                                                'productGetProduct',
                                                                idx,
                                                                'pgp_gift',
                                                                e,
                                                                index
                                                            )
                                                        }
                                                        value={p}
                                                    />
                                                </FormGroup>
                                            </Colxx>
                                            <Colxx xxs='3' className='pl-0'>
                                                <FormGroup className='mb-1'>
                                                    {/* <Label
                                                        className='text-muted'
                                                        for='quantity_pgp_gift'
                                                    >
                                                        Số lượng
                                                    </Label> */}
                                                    <Input
                                                        bsSize='lg'
                                                        type='number'
                                                        name='quantity_pgp_gift'
                                                        value={p.quantity}
                                                        onChange={(e) =>
                                                            onChangeItem(
                                                                'productGetProduct',
                                                                idx,
                                                                'quantity_pgp_gift',
                                                                e,
                                                                index
                                                            )
                                                        }
                                                    />
                                                </FormGroup>
                                            </Colxx>
                                            <i
                                                className='simple-icon-minus absolute-icon-minus-product cursor-pointer'
                                                onClick={() =>
                                                    onRemoveProduct(
                                                        idx,
                                                        index,
                                                        'productGetProduct',
                                                        'pgp_gift'
                                                    )
                                                }
                                            />
                                        </div>
                                    );
                                })}
                                <i
                                    className='simple-icon-plus absolute-icon-plus cursor-pointer'
                                    onClick={() =>
                                        onAddProduct(
                                            idx,
                                            'productGetProduct',
                                            'pgp_gift'
                                        )
                                    }
                                />
                            </Row>
                        </div>
                    );
                })}
                <AddMoreButton
                    category='productGetProduct'
                    onAddItem={onAddItem}
                />
            </>
        );
    }
    if (selectedOption === 'total-order-value-get-product') {
        return (
            <>
                {orderGetProduct?.map((range, idx) => {
                    return (
                        <div key={idx}>
                            <div className='position-relative custom-line-dashed mb-3 mt-4'>
                                <i
                                    className='simple-icon-minus absolute-icon cursor-pointer'
                                    onClick={() =>
                                        onRemoveDiscount(idx, 'orderGetProduct')
                                    }
                                />
                            </div>
                            <Row className='d-flex'>
                                <Colxx xxs='6'>
                                    <FormGroup>
                                        <Label
                                            className='text-muted'
                                            for='price_start'
                                        >
                                            Đơn hàng từ...
                                        </Label>
                                        <Input
                                            type='number'
                                            name='price_start'
                                            id='price_start'
                                            value={AddCommaNumber(
                                                range.price_start
                                            )}
                                            onChange={(e) =>
                                                onChangeItem(
                                                    'orderGetProduct',
                                                    idx,
                                                    'price_start',
                                                    e
                                                )
                                            }
                                        />
                                    </FormGroup>
                                </Colxx>
                                <Colxx xxs='6'>
                                    <FormGroup>
                                        <Label
                                            className='text-muted'
                                            for='price_end'
                                        >
                                            đến...
                                        </Label>
                                        <Input
                                            type='number'
                                            name='price_end'
                                            id='price_end'
                                            value={AddCommaNumber(
                                                range.price_end
                                            )}
                                            onChange={(e) =>
                                                onChangeItem(
                                                    'orderGetProduct',
                                                    idx,
                                                    'price_end',
                                                    e
                                                )
                                            }
                                        />
                                    </FormGroup>
                                </Colxx>
                            </Row>
                            <Row className='d-flex position-relative'>
                                <Label
                                    className='text-muted pl-3'
                                    for='product_gift'
                                >
                                    Sản phẩm tặng
                                </Label>
                                {range?.ogp_gift?.map((p, index) => {
                                    return (
                                        <div
                                            key={index}
                                            className='w-100 d-flex position-relative'
                                        >
                                            <Colxx xxs='9'>
                                                <FormGroup className='mb-1'>
                                                    <Select
                                                        components={{
                                                            Input: CustomSelectInput,
                                                        }}
                                                        className='react-select pb-1'
                                                        classNamePrefix='react-select'
                                                        name='ogp_gift'
                                                        placeholder={
                                                            <IntlMessages id='discount.select-one-product' />
                                                        }
                                                        options={
                                                            products_discount
                                                        }
                                                        onChange={(e) =>
                                                            onChangeItem(
                                                                'orderGetProduct',
                                                                idx,
                                                                'ogp_gift',
                                                                e,
                                                                index
                                                            )
                                                        }
                                                        value={p}
                                                    />
                                                </FormGroup>
                                            </Colxx>
                                            <Colxx xxs='3' className='pl-0'>
                                                <FormGroup className='mb-1'>
                                                    {/* <Label
                                            className='text-muted'
                                            for='quantity_ogp_gift'
                                        >
                                            Số lượng
                                        </Label> */}
                                                    <Input
                                                        bsSize='lg'
                                                        type='number'
                                                        name='quantity_ogp_gift'
                                                        value={p.quantity}
                                                        onChange={(e) =>
                                                            onChangeItem(
                                                                'orderGetProduct',
                                                                idx,
                                                                'quantity_ogp_gift',
                                                                e,
                                                                index
                                                            )
                                                        }
                                                    />
                                                </FormGroup>
                                            </Colxx>
                                            <i
                                                className='simple-icon-minus absolute-icon-minus-product cursor-pointer'
                                                onClick={() =>
                                                    onRemoveProduct(
                                                        idx,
                                                        index,
                                                        'orderGetProduct',
                                                        'ogp_gift'
                                                    )
                                                }
                                            />
                                        </div>
                                    );
                                })}
                                <i
                                    className='simple-icon-plus absolute-icon-plus cursor-pointer'
                                    onClick={() =>
                                        onAddProduct(
                                            idx,
                                            'orderGetProduct',
                                            'ogp_gift'
                                        )
                                    }
                                />
                            </Row>
                        </div>
                    );
                })}
                <AddMoreButton
                    category='orderGetProduct'
                    onAddItem={onAddItem}
                />
            </>
        );
    }
    if (selectedOption === 'customer-accrual-points-get-product') {
        return (
            <>
                {customerPointGetProduct?.map((range, idx) => {
                    return (
                        <div key={idx}>
                            <div className='position-relative custom-line-dashed mb-3 mt-4'>
                                <i
                                    className='simple-icon-minus absolute-icon cursor-pointer'
                                    onClick={() =>
                                        onRemoveDiscount(
                                            idx,
                                            'customerPointGetProduct'
                                        )
                                    }
                                />
                            </div>
                            <Row className='d-flex'>
                                <Colxx xxs='12'>
                                    <FormGroup>
                                        <Label
                                            className='text-muted'
                                            for='customer_point'
                                        >
                                            Mốc điểm tặng
                                        </Label>
                                        <Input
                                            type='number'
                                            name='customer_point'
                                            id='customer_point'
                                            value={AddCommaNumber(
                                                range.customer_point
                                            )}
                                            onChange={(e) =>
                                                onChangeItem(
                                                    'customerPointGetProduct',
                                                    idx,
                                                    'customer_point',
                                                    e
                                                )
                                            }
                                        />
                                    </FormGroup>
                                </Colxx>
                            </Row>
                            <Row className='d-flex position-relative'>
                                <Label
                                    className='text-muted pl-3'
                                    for='product_gift'
                                >
                                    Sản phẩm tặng
                                </Label>
                                {range?.cpgp_gift?.map((p, index) => {
                                    return (
                                        <div
                                            key={index}
                                            className='w-100 d-flex position-relative'
                                        >
                                            <Colxx xxs='9'>
                                                <FormGroup className='mb-1'>
                                                    <Select
                                                        components={{
                                                            Input: CustomSelectInput,
                                                        }}
                                                        className='react-select pb-1'
                                                        classNamePrefix='react-select'
                                                        name='cpgp_gift'
                                                        placeholder={
                                                            <IntlMessages id='discount.select-one-product' />
                                                        }
                                                        options={
                                                            products_discount
                                                        }
                                                        onChange={(e) =>
                                                            onChangeItem(
                                                                'customerPointGetProduct',
                                                                idx,
                                                                'cpgp_gift',
                                                                e,
                                                                index
                                                            )
                                                        }
                                                        value={p}
                                                    />
                                                </FormGroup>
                                            </Colxx>
                                            <Colxx xxs='3' className='pl-0'>
                                                <FormGroup className='mb-1'>
                                                    {/* <Label
                                            className='text-muted'
                                            for='quantity_cpgp_gift'
                                        >
                                            Số lượng
                                        </Label> */}
                                                    <Input
                                                        bsSize='lg'
                                                        type='number'
                                                        name='quantity_cpgp_gift'
                                                        value={p.quantity}
                                                        onChange={(e) =>
                                                            onChangeItem(
                                                                'customerPointGetProduct',
                                                                idx,
                                                                'quantity_cpgp_gift',
                                                                e,
                                                                index
                                                            )
                                                        }
                                                    />
                                                </FormGroup>
                                            </Colxx>
                                            <i
                                                className='simple-icon-minus absolute-icon-minus-product cursor-pointer'
                                                onClick={() =>
                                                    onRemoveProduct(
                                                        idx,
                                                        index,
                                                        'customerPointGetProduct',
                                                        'cpgp_gift'
                                                    )
                                                }
                                            />
                                        </div>
                                    );
                                })}
                                <i
                                    className='simple-icon-plus absolute-icon-plus cursor-pointer'
                                    onClick={() =>
                                        onAddProduct(
                                            idx,
                                            'customerPointGetProduct',
                                            'cpgp_gift'
                                        )
                                    }
                                />
                            </Row>
                        </div>
                    );
                })}
                <AddMoreButton
                    category='customerPointGetProduct'
                    onAddItem={onAddItem}
                />
            </>
        );
    }
};
