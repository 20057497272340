import React, { Component } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Card, CardBody, CardTitle, Badge } from 'reactstrap';

import IntlMessages from '../../helpers/IntlMessages';
import { END_POINT } from '../../constants/defaultValues';
import STATUS from '../../constants/status';
import { isR } from '../../helpers/Roles';
import classnames from 'classnames';

class RecentOrders extends Component {
    constructor(props) {
        super(props);

        this.state = {
            items: [],
            isLoading: false,
        };
    }

    componentDidMount() {
        this.setState({
            isLoading: true,
        });
    }

    render() {
        let { isLoading } = this.state;

        let { items } = this.props;

        return !isLoading ? (
            <div className='loading' />
        ) : (
            <div className='scroll dashboard-list-with-user'>
                {items.length ? (
                    <PerfectScrollbar
                        options={{
                            suppressScrollX: true,
                            wheelPropagation: true,
                        }}
                    >
                        {items?.length &&
                            items?.map((order, index) => {
                                return (
                                    <div
                                        key={index}
                                        className='d-flex flex-row mb-3'
                                    >
                                        <section className='d-block position-relative'>
                                            <img
                                                onError={(e) => {
                                                    e.target.onerror = null;
                                                    e.target.src =
                                                        'https://placehold.co/320x240';
                                                }}
                                                src={
                                                    order.product.image.indexOf(
                                                        'http'
                                                    ) >= 0
                                                        ? order.product.image
                                                        : END_POINT +
                                                          '/public/' +
                                                          order.product.image
                                                }
                                                alt={order.product.name}
                                                className='list-thumbnail border-0'
                                            />
                                            <Badge
                                                key={index}
                                                className='position-absolute badge-top-right'
                                                color={
                                                    order.status.uuid ===
                                                    STATUS.CREATED
                                                        ? 'secondary'
                                                        : order.status.uuid ===
                                                              STATUS.CLOSED ||
                                                          order.status.uuid ===
                                                              STATUS.DESTROY
                                                        ? 'danger'
                                                        : order.status.uuid ===
                                                              STATUS.COMPLETED ||
                                                          order.status.uuid ===
                                                              STATUS.PAYMENTED
                                                        ? 'success'
                                                        : 'primary'
                                                }
                                                pill
                                            >
                                                {order.status.name}
                                            </Badge>
                                        </section>
                                        <div className='pl-3 pr-2 pb-2'>
                                            <section>
                                                <sup className='text-primary fz-sub'>
                                                    {order.user_identify}
                                                </sup>
                                                <span className='list-item-heading'>
                                                    {order.product.name}
                                                </span>
                                                <br />
                                                <span className='text-muted text-small mb-1'>
                                                    {order.note ? (
                                                        <span>
                                                            &#187; {order.note}
                                                        </span>
                                                    ) : (
                                                        <br />
                                                    )}
                                                </span>
                                                <div className='pr-4'>
                                                    <p className='text-muted mb-1 text-small'>
                                                        {'Số lượng: ' +
                                                            order.count}
                                                    </p>
                                                </div>
                                                <div className='text-primary text-small font-weight-medium d-sm-block'>
                                                    {order.date +
                                                        ' - ' +
                                                        order.time}
                                                </div>
                                            </section>
                                        </div>
                                    </div>
                                );
                            })}
                    </PerfectScrollbar>
                ) : (
                    <div className='icon-empty d-flex flex-column justify-content-center align-items-center text-muted'>
                        <i className='iconsminds-remove-bag mb-3' />
                        <p>Chưa có sản phẩm nào trong danh sách!</p>
                    </div>
                )}
            </div>
            // <Card
            //     className={classnames({
            //         disable: !isR('recent_orders'),
            //     })}
            // >
            //     <CardBody>
            //         <CardTitle>
            //             <IntlMessages id='dashboards.recent-orders' />
            //         </CardTitle>
            //         <div className='scroll disable-text-selection recent-orders'>
            //             <PerfectScrollbar
            //                 options={{
            //                     suppressScrollX: true,
            //                     wheelPropagation: true,
            //                 }}
            //             >
            //                 {items?.length ? (
            //                     items?.length &&
            //                     items?.map((order, index) => {
            //                         return (
            //                             <div
            //                                 key={index}
            //                                 className='d-flex flex-row mb-3'
            //                             >
            //                                 <section className='d-block position-relative'>
            //                                     <img
            //                                         onError={(e) => {
            //                                             e.target.onerror = null;
            //                                             e.target.src = `http://localhost:5000/public/${order.product.image}`;
            //                                         }}
            //                                         src={
            //                                             order.product.image.indexOf(
            //                                                 'http'
            //                                             ) >= 0
            //                                                 ? order.product
            //                                                       .image
            //                                                 : END_POINT +
            //                                                   '/public/' +
            //                                                   order.product
            //                                                       .image
            //                                         }
            //                                         alt={order.product.name}
            //                                         className='list-thumbnail border-0'
            //                                     />
            //                                     <Badge
            //                                         key={index}
            //                                         className='position-absolute badge-top-right'
            //                                         color={
            //                                             order.status.uuid ===
            //                                             STATUS.CREATED
            //                                                 ? 'secondary'
            //                                                 : order.status
            //                                                       .uuid ===
            //                                                       STATUS.CLOSED ||
            //                                                   order.status
            //                                                       .uuid ===
            //                                                       STATUS.DESTROY
            //                                                 ? 'danger'
            //                                                 : order.status
            //                                                       .uuid ===
            //                                                       STATUS.COMPLETED ||
            //                                                   order.status
            //                                                       .uuid ===
            //                                                       STATUS.PAYMENTED
            //                                                 ? 'success'
            //                                                 : 'primary'
            //                                         }
            //                                         pill
            //                                     >
            //                                         {order.status.name}
            //                                     </Badge>
            //                                 </section>
            //                                 <div className='pl-3 pr-2 pb-2'>
            //                                     <section>
            //                                         <sup className='text-primary fz-sub'>
            //                                             {order.user_identify}
            //                                         </sup>
            //                                         <span className='list-item-heading'>
            //                                             {order.product.name}
            //                                         </span>
            //                                         <br />
            //                                         <span className='text-muted text-small mb-1'>
            //                                             {order.note ? (
            //                                                 <span>
            //                                                     &#187;{' '}
            //                                                     {order.note}
            //                                                 </span>
            //                                             ) : (
            //                                                 <br />
            //                                             )}
            //                                         </span>
            //                                         <div className='pr-4'>
            //                                             <p className='text-muted mb-1 text-small'>
            //                                                 {'Số lượng: ' +
            //                                                     order.count}
            //                                             </p>
            //                                         </div>
            //                                         <div className='text-primary text-small font-weight-medium d-sm-block'>
            //                                             {order.date +
            //                                                 ' - ' +
            //                                                 order.time}
            //                                         </div>
            //                                     </section>
            //                                 </div>
            //                             </div>
            //                         );
            //                     })
            //                 ) : (
            //                     <p className='d-flex justify-content-center align-items-center mh-100'>
            //                         {' '}
            //                         Chưa có sản phẩm nào được gọi!
            //                     </p>
            //                 )}
            //             </PerfectScrollbar>
            //         </div>
            //     </CardBody>
            // </Card>
        );
    }
}

export default RecentOrders;
