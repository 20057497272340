import React, { Fragment } from 'react';
import { Badge, Button, Row } from 'reactstrap';
import { Colxx, Separator } from '../../../components/common/CustomBootstrap';
import IntlMessages from '../../../helpers/IntlMessages';
import { AddCommaNumber } from '../../../helpers/Utils';

const ContentDisplayMobile = (props) => {
    // console.log(props);
    const { values, make_products, data_stocks } = props;

    const make_product = make_products.filter(
        (product) => product.product_id === values.id
    );
    // console.log('make_product: ', make_product);

    let priceCost = make_product
        .map((item) => {
            let price = data_stocks?.find(
                (i) => i.id === item.stock.id
            )?.avarage_price;
            // console.log('data_stocks: ', data_stocks);
            // console.log('price: ', price);
            return parseFloat(price);
        })
        .reduce((sum, item) => sum + item, 0);

    return (
        <>
            <Row className='d-flex align-items-center mt-3'>
                <Colxx xxs='6' lg='6'>
                    <p className='text-muted text-small'>
                        <IntlMessages id='category.thumb-list' />
                    </p>
                </Colxx>
                <Colxx xxs='6' lg='6' className='text-right'>
                    <p>{props.values?.category?.name}</p>
                </Colxx>
            </Row>
            <Row className='d-flex align-items-center'>
                <Colxx xxs='6' lg='6'>
                    <p className='text-muted text-small'>Mã SKU</p>
                </Colxx>
                <Colxx xxs='6' lg='6' className='text-right'>
                    <p>{props.sku}</p>
                </Colxx>
            </Row>
            <Row className='d-flex align-items-center'>
                <Colxx xxs='6' lg='6'>
                    <p className='text-muted text-small'>
                        <IntlMessages id='product.price.original' />
                    </p>
                </Colxx>
                <Colxx xxs='6' lg='6' className='text-right'>
                    <p className='text-lowercase'>
                        {props.values.price_sale !== '0' ? (
                            <>
                                <Badge
                                    color='primary'
                                    pill
                                    className='spacer-around-1'
                                >
                                    {AddCommaNumber(props.values.price_sale)}
                                </Badge>
                                <Badge
                                    color='light'
                                    pill
                                    className='spacer-around-1'
                                >
                                    <del>
                                        {AddCommaNumber(props.values.price)}
                                    </del>
                                </Badge>
                            </>
                        ) : (
                            <Badge color='primary' pill>
                                {AddCommaNumber(props.values.price)}
                            </Badge>
                        )}
                    </p>
                </Colxx>
            </Row>
            <Row>
                <Colxx xxs='6' lg='6'>
                    <p className='mb-2 text-muted text-small'>
                        {props.values.sizeTitle ? (
                            <span>{props.values.sizeTitle}</span>
                        ) : (
                            <IntlMessages id='product.size' />
                        )}
                    </p>
                </Colxx>
                <Colxx xxs='6' lg='6' className='text-right'>
                    <p className='text-muted text-small mb-2'>
                        <IntlMessages id='product.add_price' />
                    </p>
                </Colxx>
            </Row>
            <Row>
                {props.values?.size?.length ? (
                    props.values?.size?.map((s, idx) => (
                        <Fragment key={idx}>
                            <Colxx xxs='6' lg='6'>
                                <p className='text-capitalize'>{s.name}</p>
                            </Colxx>
                            <Colxx xxs='6' lg='6' className='text-right'>
                                <Badge
                                    color='primary'
                                    pill
                                    className='spacer-around-1'
                                >
                                    {AddCommaNumber(s.value)}
                                </Badge>
                            </Colxx>
                        </Fragment>
                    ))
                ) : (
                    <p className='p-rl-15px text-muted'>(chưa thiết lập)</p>
                )}
            </Row>

            <Row>
                <Colxx xxs='6' lg='6'>
                    <p className='text-muted text-small mb-2'>
                        {props.values.toppingTitle ? (
                            <span>{props.values.toppingTitle}</span>
                        ) : (
                            <IntlMessages id='product.topping' />
                        )}
                    </p>
                </Colxx>
                <Colxx xxs='6' lg='6' className='text-right'>
                    <p className='text-muted text-small mb-2'>
                        <IntlMessages id='product.add_price' />
                    </p>
                </Colxx>
            </Row>
            <Row>
                {props.values?.topping?.length ? (
                    props.values?.topping?.map((s, idx) => (
                        <Fragment key={idx}>
                            <Colxx xxs='6' lg='6'>
                                <p className='text-capitalize'>{s.name}</p>
                            </Colxx>
                            <Colxx xxs='6' lg='6' className='text-right'>
                                <Badge
                                    color='primary'
                                    pill
                                    className='spacer-around-1'
                                >
                                    {AddCommaNumber(s.value)}
                                </Badge>
                            </Colxx>
                        </Fragment>
                    ))
                ) : (
                    <p className='p-rl-15px text-muted'>(chưa thiết lập)</p>
                )}
            </Row>
            <Row>
                <Colxx xxs='12' lg='12'>
                    <p className='text-muted text-small mb-2'>
                        {props.values.sugarTitle ? (
                            <span>{props.values.sugarTitle}</span>
                        ) : (
                            <IntlMessages id='product.sugar' />
                        )}
                    </p>
                </Colxx>
            </Row>
            <Row className='p-rf-15px'>
                {props.values?.sugar?.length ? (
                    props.values?.sugar?.map((_item, idx) => (
                        <Colxx key={idx} _id={idx} className='pad-2px w-20'>
                            <Button color='secondary' size='sm' outline block>
                                {`${_item} %`}
                            </Button>
                        </Colxx>
                    ))
                ) : (
                    <p className='p-rl-15px text-muted'>(chưa thiết lập)</p>
                )}
            </Row>
            <Row>
                <Colxx xxs='12' lg='6'>
                    <p className='text-muted text-small mb-2'>
                        {props.values.iceTitle ? (
                            <span>{props.values.iceTitle}</span>
                        ) : (
                            <IntlMessages id='product.ice' />
                        )}
                    </p>
                </Colxx>
            </Row>

            <Row className='p-rf-15px'>
                {props.values?.ice?.length ? (
                    props.values?.ice?.map((_item, idx) => (
                        <Colxx key={idx} _id={idx} className='pad-2px w-20'>
                            <Button color='secondary' size='sm' outline block>
                                {`${_item} %`}
                            </Button>
                        </Colxx>
                    ))
                ) : (
                    <p className='p-rl-15px text-muted'>(chưa thiết lập)</p>
                )}
            </Row>
            <Separator className='mt-4 mb-4' />
            <h5 className='text-center'>Định lượng</h5>
            <Row className='d-flex align-items-center'>
                <Colxx xxs='8' lg='6'>
                    <p className='text-muted text-small'>Giá cost</p>
                </Colxx>
                <Colxx xxs='4' lg='6' className='text-right'>
                    <Badge color='primary' pill className='mb-3'>
                        {AddCommaNumber(priceCost)}
                    </Badge>
                </Colxx>
            </Row>
            <Row className='d-flex align-items-center'>
                <Colxx xxs='8' lg='6'>
                    <p className='text-muted text-small'>
                        Phần trăm giá cost/giá bán
                    </p>
                </Colxx>
                <Colxx xxs='4' lg='6' className='text-right'>
                    <Badge color='primary' pill className='mb-3'>
                        {(
                            (priceCost /
                                (values.active_sale
                                    ? parseFloat(values.price_sale)
                                    : parseFloat(values.price))) *
                            100
                        ).toFixed(2)}{' '}
                        %
                    </Badge>
                </Colxx>
            </Row>
            <Row className='d-flex align-items-center'>
                <Colxx xxs='8' lg='6'>
                    <p className='text-muted text-small'>
                        Số nguyên liệu sử dụng
                    </p>
                </Colxx>
                <Colxx xxs='4' lg='6' className='text-right'>
                    <p className='mb-3'>{make_product?.length}</p>
                </Colxx>
            </Row>
            {make_product?.length ? (
                <ul className={'orders-pending'}>
                    {make_product?.map((stock, idx) => (
                        <li key={idx} className='pr-2'>
                            <Button color='primary'>
                                {stock.stock.name}
                            </Button>
                        </li>
                    ))}
                </ul>
            ) : null}
        </>
    );
};

export default ContentDisplayMobile;
