import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Card, CardBody, CardTitle, Badge } from 'reactstrap';
import { END_POINT } from '../../constants/defaultValues';
import classnames from 'classnames';
import IntlMessages from '../../helpers/IntlMessages';
import { isR } from '../../helpers/Roles';

const ListRefundOrders = ({ items, selectedItems, onCheckItem, onActions }) => {
    return (
        <div className='scroll dashboard-list-with-user'>
            {items.length ? (
                <PerfectScrollbar
                    options={{
                        suppressScrollX: true,
                        wheelPropagation: true,
                    }}
                >
                    {items?.length &&
                        items?.map((order, index) => {
                            return (
                                <div key={order.id}>
                                    <div
                                        // onClick={(event) =>
                                        //     onCheckItem(event, order.id)
                                        // }
                                        // className={classnames(
                                        //     'd-flex flex-row mb-3',
                                        //     {
                                        //         active: selectedItems.includes(
                                        //             order.id
                                        //         ),
                                        //     }
                                        // )}
                                        className='d-flex flex-row mb-3'
                                    >
                                        <div className='d-block position-relative'>
                                            <img
                                                onError={(e) => {
                                                    e.target.onerror = null;
                                                    e.target.src =
                                                        'https://placehold.co/320x240';
                                                }}
                                                src={
                                                    order.product.image.indexOf(
                                                        'http'
                                                    ) >= 0
                                                        ? order.product.image
                                                        : END_POINT +
                                                          '/public/' +
                                                          order.product.image
                                                }
                                                alt={order.product.name}
                                                className='list-thumbnail border-0'
                                            />
                                            <Badge
                                                key={index}
                                                className='position-absolute badge-top-right'
                                                color={'danger'}
                                                pill
                                            >
                                                {order.status.name}
                                            </Badge>
                                        </div>
                                        <div className='d-flex pl-3 pr-2 pb-2'>
                                            {/* <section>
              <p className="list-item-heading">{order.product.name}</p>
              <div className="pr-4">
                <p className="text-muted mb-1 text-small">
                  {"Số lượng: " + order.count}
                </p>
              </div>
              <div className="text-primary text-small font-weight-medium d-sm-block">
                {order.date + " - " + order.time}
              </div>
            </section> */}
                                            <section>
                                                <sup className='text-primary fz-sub'>
                                                    {order.user_identify}
                                                </sup>
                                                <span className='list-item-heading'>
                                                    {order.product.name}
                                                </span>
                                                <br />
                                                <span className='text-muted text-small mb-1'>
                                                    {order.note ? (
                                                        <span>
                                                            &#187; {order.note}
                                                        </span>
                                                    ) : (
                                                        <br />
                                                    )}
                                                </span>
                                                <div className='pr-4'>
                                                    <p className='text-muted mb-1 text-small'>
                                                        {'Số lượng: ' +
                                                            order.count}
                                                    </p>
                                                </div>
                                                <div className='text-primary text-small font-weight-medium d-sm-block'>
                                                    {order.date +
                                                        ' - ' +
                                                        order.time}
                                                </div>
                                            </section>
                                        </div>
                                        {/* <div className="custom-control custom-checkbox pl-1 align-self-center pr-4">
              <CustomInput
                className="item-check mb-0"
                type="checkbox"
                id={`check_${order.id}`}
                checked={selectedItems.includes(order.id)}
                onChange={() => { }}
                label=""
              />
            </div> */}
                                    </div>
                                </div>
                            );
                        })}
                </PerfectScrollbar>
            ) : (
                <div className='icon-empty d-flex flex-column justify-content-center align-items-center text-muted'>
                    <i className='iconsminds-remove-bag mb-3' />
                    <p>Chưa có sản phẩm nào trong danh sách!</p>
                </div>
            )}
        </div>
        // <Card
        //     className={classnames({
        //         disable: !isR('orders_close_destroy'),
        //     })}
        // >
        //     <CardBody>
        //         <CardTitle>
        //             <IntlMessages id='dashboards.refund-requests' />{' '}
        //             {` - ${items?.length}`}
        //         </CardTitle>
        //         <div className='scroll dashboard-list-with-thumbs-small'>
        //             <PerfectScrollbar
        //                 options={{
        //                     suppressScrollX: true,
        //                     wheelPropagation: true,
        //                 }}
        //             >
        //                 {items?.length &&
        //                     items?.map((order, index) => {
        //                         return (
        //                             <div key={order.id}>
        //                                 <div
        //                                     // onClick={(event) =>
        //                                     //     onCheckItem(event, order.id)
        //                                     // }
        //                                     // className={classnames(
        //                                     //     'd-flex flex-row mb-3',
        //                                     //     {
        //                                     //         active: selectedItems.includes(
        //                                     //             order.id
        //                                     //         ),
        //                                     //     }
        //                                     // )}
        //                                     className='d-flex flex-row mb-3'
        //                                 >
        //                                     <div className='d-block position-relative'>
        //                                         <img
        //                                             onError={(e) => {
        //                                                 e.target.onerror = null;
        //                                                 e.target.src = `http://localhost:5000/public/${order.product.image}`;
        //                                             }}
        //                                             src={
        //                                                 order.product.image.indexOf(
        //                                                     'http'
        //                                                 ) >= 0
        //                                                     ? order.product
        //                                                           .image
        //                                                     : END_POINT +
        //                                                       '/public/' +
        //                                                       order.product
        //                                                           .image
        //                                             }
        //                                             alt={order.product.name}
        //                                             className='list-thumbnail border-0'
        //                                         />
        //                                         <Badge
        //                                             key={index}
        //                                             className='position-absolute badge-top-right'
        //                                             color={'danger'}
        //                                             pill
        //                                         >
        //                                             {order.status.name}
        //                                         </Badge>
        //                                     </div>
        //                                     <div className='card-body-custom align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero align-items-lg-center'>
        //                                         {/* <section>
        //               <p className="list-item-heading">{order.product.name}</p>
        //               <div className="pr-4">
        //                 <p className="text-muted mb-1 text-small">
        //                   {"Số lượng: " + order.count}
        //                 </p>
        //               </div>
        //               <div className="text-primary text-small font-weight-medium d-sm-block">
        //                 {order.date + " - " + order.time}
        //               </div>
        //             </section> */}
        //                                         <section>
        //                                             <sup className='text-primary fz-sub'>
        //                                                 {order.user_identify}
        //                                             </sup>
        //                                             <span className='list-item-heading'>
        //                                                 {order.product.name}
        //                                             </span>
        //                                             <br />
        //                                             <span className='text-muted text-small mb-1'>
        //                                                 {order.note ? (
        //                                                     <span>
        //                                                         &#187;{' '}
        //                                                         {order.note}
        //                                                     </span>
        //                                                 ) : (
        //                                                     <br />
        //                                                 )}
        //                                             </span>
        //                                             <div className='pr-4'>
        //                                                 <p className='text-muted mb-1 text-small'>
        //                                                     {'Số lượng: ' +
        //                                                         order.count}
        //                                                 </p>
        //                                             </div>
        //                                             <div className='text-primary text-small font-weight-medium d-sm-block'>
        //                                                 {order.date +
        //                                                     ' - ' +
        //                                                     order.time}
        //                                             </div>
        //                                         </section>
        //                                     </div>
        //                                     {/* <div className="custom-control custom-checkbox pl-1 align-self-center pr-4">
        //               <CustomInput
        //                 className="item-check mb-0"
        //                 type="checkbox"
        //                 id={`check_${order.id}`}
        //                 checked={selectedItems.includes(order.id)}
        //                 onChange={() => { }}
        //                 label=""
        //               />
        //             </div> */}
        //                                 </div>
        //                             </div>
        //                         );
        //                     })}
        //             </PerfectScrollbar>
        //         </div>
        //     </CardBody>
        // </Card>
    );
};

export default ListRefundOrders;
