import React, { memo } from 'react';
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Input,
    InputGroup,
    InputGroupAddon,
    Label,
    Form,
    FormGroup,
    Row,
    Col,
} from 'reactstrap';
import IntlMessages from '../../../helpers/IntlMessages';
import PerfectScrollbar from 'react-perfect-scrollbar';

import { AddCommaNumber } from '../../../helpers/Utils';
import DynamicForm from '../general/DynamicForm';
import { Colxx } from '../../../components/common/CustomBootstrap';

const ContentComponent = ({
    title,
    isOpen,
    toggle,
    onSubmit,
    data_dynamic_form,
    values,
    sku,
    setting,
    onChange,
    onReset,
    onChangeDynamicForm,
}) => {
    // console.log('render AddComponent');
    return (
        <Modal
            isOpen={isOpen}
            toggle={toggle}
            wrapClassName='modal-right'
            backdrop={true}
            autoFocus={false}
        >
            <Form onSubmit={(e) => onSubmit(e)}>
                <ModalHeader toggle={toggle}>{title}</ModalHeader>
                <ModalBody className='scrool modal-right-add'>
                    <PerfectScrollbar
                        options={{
                            suppressScrollX: true,
                            wheelPropagation: false,
                        }}
                        id='modal-right__body'
                    >
                        <Row>
                            <Colxx xxs='8'>
                                <FormGroup className='mt-3 has-float-label'>
                                    <Label for='name' className='text-muted'>
                                        <IntlMessages id='stock.name' /><span style={{color: 'red'}}>*</span>
                                    </Label>
                                    <Input
                                        type='text'
                                        name='name'
                                        id='name'
                                        autoFocus={true}
                                        value={values.name}
                                        onChange={onChange}
                                    />
                                </FormGroup>
                            </Colxx>
                            <Colxx xxs='4' className='pl-0'>
                                <FormGroup className='mt-3 has-float-label'>
                                    <Label for='name' className='text-muted'>
                                        Mã SKU
                                    </Label>
                                    <Input
                                        disabled
                                        type='text'
                                        name='sku'
                                        id='sku'
                                        value={sku}
                                    />
                                </FormGroup>
                            </Colxx>
                        </Row>
                        <FormGroup className='has-float-label'>
                            <Label for='unit' className='text-muted'>
                                <IntlMessages id='stock.unit' />
                            </Label>
                            <Input
                                type='text'
                                name='unit'
                                id='unit'
                                disabled={setting.viewonly && !setting.add}
                                value={values.unit}
                                onChange={onChange}
                            />
                        </FormGroup>

                        {setting.add ? (
                            <FormGroup className='has-float-label'>
                                <Label for='unit_price' className='text-muted'>
                                    <IntlMessages id='stock.unit-price' /><span style={{color: 'red'}}>*</span>
                                </Label>
                                <Input
                                    name='unit_price'
                                    id='unit_price'
                                    value={AddCommaNumber(values.unit_price)}
                                    onChange={onChange}
                                />
                            </FormGroup>
                        ) : null}

                        {setting.add ? (
                            <FormGroup className='has-float-label'>
                                <Label for='new_count' className='text-muted'>
                                    <IntlMessages id='stock.count' /><span style={{color: 'red'}}>*</span>
                                </Label>
                                <Input
                                    name='new_count'
                                    id='new_count'
                                    type='number'
                                    step='1'
                                    value={values.new_count}
                                    onChange={onChange}
                                />
                            </FormGroup>
                        ) : null}

                        {setting.update ? (
                            <>
                                <FormGroup>
                                    <Label for='count' className='text-muted'>
                                        <IntlMessages id='stock.count-current' />
                                    </Label>
                                    <Row>
                                        <Col xs='6' sm='6' md='6' lg='6'>
                                            <InputGroup>
                                                <Input
                                                    type='text'
                                                    name='count'
                                                    id='count'
                                                    disabled={true}
                                                    value={values.count}
                                                />
                                                <InputGroupAddon addonType='append'>
                                                    {values.unit}
                                                </InputGroupAddon>
                                            </InputGroup>
                                        </Col>
                                        <Col xs='6' sm='6' md='6' lg='6'>
                                            <Button
                                                disabled={
                                                    setting.viewonly &&
                                                    !setting.add
                                                }
                                                onClick={() => {
                                                    onReset(values);
                                                }}
                                            >
                                                <IntlMessages id='stock.reset' />
                                            </Button>
                                        </Col>
                                    </Row>
                                </FormGroup>
                                <FormGroup className='has-float-label'>
                                    <Label for='total' className='text-muted'>
                                        <IntlMessages id='stock.count-total' />
                                    </Label>
                                    <InputGroup>
                                        <Input
                                            type='text'
                                            name='total'
                                            id='total'
                                            disabled={true}
                                            value={values.total}
                                        />
                                        <InputGroupAddon addonType='append'>
                                            {values.unit}
                                        </InputGroupAddon>
                                    </InputGroup>
                                </FormGroup>
                            </>
                        ) : null}

                        <DynamicForm
                            values={values.dynamic_form}
                            data={data_dynamic_form}
                            onChange={onChangeDynamicForm}
                            disabled={setting.viewonly && !setting.add}
                        />
                    </PerfectScrollbar>
                </ModalBody>
                <ModalFooter>
                    <Button color='primary'>
                        <IntlMessages id='stock.btn-add' />
                    </Button>{' '}
                </ModalFooter>
            </Form>
        </Modal>
    );
};

export default memo(ContentComponent);
